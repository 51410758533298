import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeInfantHeadCircumferenceLMSParams: LMSParamsMap = {
  1: { lambda: 3.861, mu: 35.904, sigma: 0.027 },
  2: { lambda: 3.861, mu: 37.374, sigma: 0.027 },
  3: { lambda: 3.861, mu: 38.776, sigma: 0.027 },
  4: { lambda: 3.861, mu: 40.036, sigma: 0.027 },
  5: { lambda: 3.861, mu: 41.058, sigma: 0.027 },
  6: { lambda: 3.861, mu: 41.858, sigma: 0.027 },
  7: { lambda: 3.861, mu: 42.465, sigma: 0.027 },
  8: { lambda: 3.861, mu: 42.933, sigma: 0.027 },
  9: { lambda: 3.861, mu: 43.317, sigma: 0.027 },
  10: { lambda: 3.861, mu: 43.645, sigma: 0.027 },
  11: { lambda: 3.861, mu: 43.924, sigma: 0.027 },
  12: { lambda: 3.861, mu: 44.169, sigma: 0.027 },
  13: { lambda: 3.861, mu: 44.386, sigma: 0.027 },
  14: { lambda: 3.861, mu: 44.578, sigma: 0.027 },
  15: { lambda: 3.861, mu: 44.751, sigma: 0.027 },
  16: { lambda: 3.861, mu: 44.907, sigma: 0.027 },
  17: { lambda: 3.861, mu: 45.048, sigma: 0.027 },
  18: { lambda: 3.861, mu: 45.178, sigma: 0.027 },
  19: { lambda: 3.861, mu: 45.298, sigma: 0.027 },
  20: { lambda: 3.861, mu: 45.409, sigma: 0.027 },
  21: { lambda: 3.861, mu: 45.511, sigma: 0.027 },
  22: { lambda: 3.861, mu: 45.611, sigma: 0.027 },
  23: { lambda: 3.861, mu: 45.715, sigma: 0.027 },
  24: { lambda: 3.861, mu: 45.826, sigma: 0.027 },
  25: { lambda: 3.861, mu: 45.941, sigma: 0.027 },
  26: { lambda: 3.861, mu: 46.055, sigma: 0.027 },
  27: { lambda: 3.861, mu: 46.159, sigma: 0.027 },
  28: { lambda: 3.861, mu: 46.25, sigma: 0.027 },
  29: { lambda: 3.861, mu: 46.333, sigma: 0.027 },
  30: { lambda: 3.861, mu: 46.41, sigma: 0.027 },
  31: { lambda: 3.861, mu: 46.483, sigma: 0.027 },
  32: { lambda: 3.861, mu: 46.554, sigma: 0.027 },
  33: { lambda: 3.861, mu: 46.623, sigma: 0.027 },
  34: { lambda: 3.861, mu: 46.689, sigma: 0.027 },
  35: { lambda: 3.861, mu: 46.753, sigma: 0.027 },
  36: { lambda: 3.861, mu: 46.813, sigma: 0.027 },
};

const femaleDownSyndromeInfantHeadCircumferenceLMSParams: LMSParamsMap = {
  1: { lambda: 3.049, mu: 36.352, sigma: 0.03 },
  2: { lambda: 3.049, mu: 37.148, sigma: 0.03 },
  3: { lambda: 3.049, mu: 37.937, sigma: 0.03 },
  4: { lambda: 3.049, mu: 38.705, sigma: 0.03 },
  5: { lambda: 3.049, mu: 39.436, sigma: 0.03 },
  6: { lambda: 3.049, mu: 40.119, sigma: 0.03 },
  7: { lambda: 3.049, mu: 40.737, sigma: 0.03 },
  8: { lambda: 3.049, mu: 41.282, sigma: 0.03 },
  9: { lambda: 3.049, mu: 41.758, sigma: 0.03 },
  10: { lambda: 3.049, mu: 42.17, sigma: 0.03 },
  11: { lambda: 3.049, mu: 42.526, sigma: 0.03 },
  12: { lambda: 3.049, mu: 42.837, sigma: 0.03 },
  13: { lambda: 3.049, mu: 43.111, sigma: 0.03 },
  14: { lambda: 3.049, mu: 43.352, sigma: 0.03 },
  15: { lambda: 3.049, mu: 43.57, sigma: 0.03 },
  16: { lambda: 3.049, mu: 43.767, sigma: 0.03 },
  17: { lambda: 3.049, mu: 43.949, sigma: 0.03 },
  18: { lambda: 3.049, mu: 44.119, sigma: 0.03 },
  19: { lambda: 3.049, mu: 44.279, sigma: 0.03 },
  20: { lambda: 3.049, mu: 44.428, sigma: 0.03 },
  21: { lambda: 3.049, mu: 44.571, sigma: 0.03 },
  22: { lambda: 3.049, mu: 44.71, sigma: 0.03 },
  23: { lambda: 3.049, mu: 44.847, sigma: 0.03 },
  24: { lambda: 3.049, mu: 44.983, sigma: 0.03 },
  25: { lambda: 3.049, mu: 45.114, sigma: 0.03 },
  26: { lambda: 3.049, mu: 45.237, sigma: 0.03 },
  27: { lambda: 3.049, mu: 45.347, sigma: 0.03 },
  28: { lambda: 3.049, mu: 45.441, sigma: 0.03 },
  29: { lambda: 3.049, mu: 45.521, sigma: 0.03 },
  30: { lambda: 3.049, mu: 45.587, sigma: 0.03 },
  31: { lambda: 3.049, mu: 45.642, sigma: 0.03 },
  32: { lambda: 3.049, mu: 45.683, sigma: 0.03 },
  33: { lambda: 3.049, mu: 45.715, sigma: 0.03 },
  34: { lambda: 3.049, mu: 45.743, sigma: 0.03 },
  35: { lambda: 3.049, mu: 45.772, sigma: 0.03 },
  36: { lambda: 3.049, mu: 45.795, sigma: 0.03 },
};

export const getDownSyndromeHeadCircPercentile = (
  gender: string,
  ageInMonths: number,
  headCirc: number,
) => {
  const dsHeadCircLMSParams =
    gender === 'Female'
      ? femaleDownSyndromeInfantHeadCircumferenceLMSParams
      : maleDownSyndromeInfantHeadCircumferenceLMSParams;

  return zScoreFromMeasurement(headCirc, ageInMonths, dsHeadCircLMSParams);
};
