// @ts-strict-ignore
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import {
  Renewal,
  RenewalCart,
} from '@app/features/renewals/shared/renewals.type';
import { PatientMedicationActions } from '@app/modules/medications/store/patient-medication.actions';
import { CheckoutDialogComponent } from '@app/modules/shared-rx/components/checkout-dialog/checkout-dialog.component';
import { RxCartMeasurement } from '@app/modules/shared-rx/shared-rx.type';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';

import { RenewalCheckoutForm } from '../../shared/renewal-checkout-form';
import { RenewalFormService } from '../../shared/renewal-form.service';
import { RenewalActions, RenewalSelectors } from '../../store';
import { defaultCartState } from '../../store/renewals.reducer';
import { RenewalSignatureComponent } from '../renewal-signature/renewal-signature.component';

interface RenewalCheckoutModalData {
  renewals: Renewal[];
  acceptsDigitalCommunications: boolean;
  cartId: number;
}

@Component({
  selector: 'omg-renewal-checkout-dialog',
  templateUrl: './renewal-checkout-dialog.component.html',
  styleUrls: ['./renewal-checkout-dialog.component.scss'],
})
export class RenewalCheckoutDialogComponent implements OnInit, OnDestroy {
  patient$: Observable<Patient>;
  cartState$: Observable<RenewalCart>;
  rxCartMeasurements$: Observable<RxCartMeasurement[]>;
  checkoutForm: RenewalCheckoutForm;
  private unsubscribe$ = new Subject<void>();

  @ViewChild(RenewalSignatureComponent)
  renewalSignatureComponent: RenewalSignatureComponent;

  constructor(
    public dialogRef: DialogRef<CheckoutDialogComponent>,
    private patientSelectors: PatientSelectors,
    @Inject(OMG_DIALOG_DATA) public data: RenewalCheckoutModalData,
    private renewalFormService: RenewalFormService,
    private renewalSelectors: RenewalSelectors,
    private renewalActions: RenewalActions,
    private patientMedicationActions: PatientMedicationActions,
  ) {}

  ngOnInit() {
    this.patient$ = this.patientSelectors.patient;
    this.cartState$ = this.renewalSelectors.cartState;
    this.rxCartMeasurements$ = this.renewalSelectors.rxCartMeasurements;
    this.buildForm();
    this.setListeners();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close = () => {
    this.renewalActions.updateCartState(defaultCartState);
    this.dialogRef.close();
  };

  private buildForm() {
    this.checkoutForm = this.renewalFormService.buildCheckoutForm(
      this.data.renewals,
      this.data.acceptsDigitalCommunications,
      this.data.cartId,
    );
  }

  private setListeners() {
    this.renewalSelectors.cartCompleteAt
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(complete => !!complete),
        switchMap(() => this.patientSelectors.patientId),
      )
      .subscribe(patientId => {
        this.patientMedicationActions.loadPatientMedications(patientId);
        this.close();
      });
  }
}
