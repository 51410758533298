<small om-layout
       om-layout-gutter>
  <div om-flex="50">
    <span *ngFor="let key of detailKeys"
          [ngClass]="{'highlighted': details[key].different}">
      {{details[key].requested}}</span>
  </div>
  <div>
    <span *ngFor="let key of detailKeys"
          [ngClass]="{'highlighted': details[key].different}">
      {{details[key].matched}}</span>
  </div>
</small>
