<hr class="-spaced" />
<strong>Patient Information</strong>
<div>
  <small om-layout
         om-layout-gutter>
    <strong om-flex="50">{{pharmacyName}}</strong>
    <strong>1Life</strong>
  </small>
  <omg-mismatched-patient-detail-item [detail]="details.firstName"></omg-mismatched-patient-detail-item>
  <omg-mismatched-patient-detail-item [detail]="details.lastName"></omg-mismatched-patient-detail-item>

  <small om-layout
         om-layout-gutter>
    <div om-flex="50">
      <span [ngClass]="{'highlighted': details.gender.different}">{{details.gender.requested}},</span> DOB:
      <span [ngClass]="{'highlighted': details.dateOfBirth.different}">{{details.dateOfBirth.requested}}</span>
    </div>
    <div>
      <span [ngClass]="{'highlighted': details.gender.different}">{{details.gender.matched}},</span> DOB:
      <span [ngClass]="{'highlighted': details.dateOfBirth.different}">{{details.dateOfBirth.matched}}</span>
    </div>
  </small>

  <omg-mismatched-patient-detail-item [detail]="details.address1"></omg-mismatched-patient-detail-item>
  <omg-mismatched-patient-detail-item [detail]="details.address2"></omg-mismatched-patient-detail-item>

  <omg-mismatched-patient-detail-row [details]="addressDetails"></omg-mismatched-patient-detail-row>

</div>
