import { Component, Input, OnInit } from '@angular/core';

import {
  PharmacyAddress,
  RenewalPharmacy,
} from '@app/modules/pharmacy-picker/shared/pharmacy.type';

@Component({
  selector: 'omg-rx-item-pharmacy',
  templateUrl: './rx-item-pharmacy.component.html',
})
export class RxItemPharmacyComponent implements OnInit {
  @Input() pharmacy: RenewalPharmacy;
  address: PharmacyAddress;

  get displayAddressStreet() {
    return (
      !!this.address.displayAddressStreet &&
      this.address.displayAddressStreet !== 'Unknown'
    );
  }

  get cityAndState() {
    return [this.address.city, this.address.state].join(', ');
  }

  get showZip() {
    return !!this.address.zip && this.address.zip !== '00000';
  }

  ngOnInit() {
    this.address = this.pharmacy.address;
  }
}
