import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessive',
})
export class PossessivePipe implements PipeTransform {
  transform(name: string, args?: any): any {
    if (name.endsWith('s') || name.endsWith('z')) {
      return `${name}'`;
    }
    return `${name}'s`;
  }
}
