// @ts-strict-ignore
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { ServiceTicketForm } from '@app/features/service-ticket/shared/service-ticket-form';
import { DropdownItem } from '@app/shared';
import { ButtonGroupOption } from '@app/shared/components/button-group/button-group.component';
import { castArray, filterTruthy } from '@app/utils';

import { PatientChartActions } from '../../../patient-chart/store/patient-chart.actions';
import {
  VisitProcedure,
  VisitProcedureError,
  VisitProcedureRule,
  VisitProcedureTypeSystemName,
} from '../../shared/visit-procedure.type';
import { VisitProcedureActions } from '../../store/visit-procedure.actions';
import { VisitProcedureSelectors } from '../../store/visit-procedure.selectors';

@Component({
  selector: 'omg-service-ticket',
  templateUrl: './service-ticket.component.html',
  styleUrls: ['./service-ticket.component.scss'],
})
export class ServiceTicketComponent implements OnInit, OnDestroy {
  @Input() visitProcedureId: number;
  @Input() signed?: boolean;

  form: ServiceTicketForm;
  visitProcedure$: Observable<VisitProcedure>;
  dropdownItems$: Observable<DropdownItem[]>;
  options$: Observable<ButtonGroupOption[]>;
  nonFormControlErrors: { procedures?: ValidationErrors } = {};

  phoneMedicalDiscussionVisitTypeActive$: Observable<boolean>;

  featureUseAssessedProblems: Observable<boolean>;

  private unsubscribe = new Subject<void>();

  constructor(
    private patientChartActions: PatientChartActions,
    private visitProcedureActions: VisitProcedureActions,
    private visitProcedureSelectors: VisitProcedureSelectors,
    private analytics: AnalyticsService,
  ) {
    this.form = new ServiceTicketForm(
      this.visitProcedureActions,
      this.visitProcedureSelectors,
    );
  }

  ngOnInit() {
    this.visitProcedureActions.getById({ id: this.visitProcedureId });
    this.visitProcedure$ = this.visitProcedureSelectors
      .getById(this.visitProcedureId)
      .pipe(
        takeUntil(this.unsubscribe),
        filter(visitProcedure => !!visitProcedure),
        tap(visitProcedure => this.setupServiceTicketForm(visitProcedure)),
        shareReplay(1),
      );

    this.setUpErrorHandling();
    this.mapSourcesToDropdownItems();
    this.mapSourcesToOptions();
    this.loadFeatures();
    this.trackAnalytics();
    this.setUpTimeBasedUpdate();
  }

  ngOnDestroy() {
    this.visitProcedureActions.resetSigningError();
    this.form.dispose();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  closeServiceTicket() {
    this.patientChartActions.expandWorkspace(false);
  }

  setVisitType(visitTypeSystemName: VisitProcedureTypeSystemName) {
    this.visitProcedure$.pipe(take(1)).subscribe(visitProcedure => {
      const visitType = visitProcedure.visitProcedureTypes.find(
        visitProcedureType =>
          visitProcedureType.systemName === visitTypeSystemName,
      );

      if (visitType) {
        this.form.controls
          .get(this.form.CONTROL_NAME_VISIT_TYPE)
          .setValue(visitType.id);
      }
    });
  }

  fieldInvalid(control) {
    const formContol = this.form.controls.get(control);
    return formContol.invalid && !formContol.dirty;
  }

  private loadFeatures() {
    this.featureUseAssessedProblems = this.visitProcedure$.pipe(
      map(visitProcedure => !!visitProcedure.assessedProblems),
    );

    this.phoneMedicalDiscussionVisitTypeActive$ = this.visitProcedure$.pipe(
      map(visitProcedure => {
        const visitTypeExists = visitProcedure.visitProcedureTypes.find(
          visitProcedureType =>
            visitProcedureType.systemName === 'phone_medical_discussion',
        );

        const canBeVirtual =
          visitProcedure.virtual === true ||
          visitProcedure.requiredParams.includes('virtual');

        const isPhoneMedicalDiscussion =
          visitProcedure.visitProcedureType.systemName ===
          'phone_medical_discussion';

        return (
          !this.signed &&
          visitTypeExists &&
          canBeVirtual &&
          !isPhoneMedicalDiscussion
        );
      }),
    );
  }

  private setUpTimeBasedUpdate() {
    this.form.controls
      .get(this.form.CONTROL_NAME_VISIT_TYPE)
      .valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe(() => {
        this.form.controls
          .get(this.form.CONTROL_NAME_TIME_BASED_MINUTES)
          .setValue(null);
      });
  }

  private setUpErrorHandling() {
    // This subscription makes sure updating the visit procedure doesn't remove
    // the pending errors
    this.visitProcedure$
      .pipe(
        takeUntil(this.unsubscribe),
        // Needs to run on next tick otherwise errors don't show up under the rule
        delay(0),
        switchMap(() =>
          this.visitProcedureSelectors.signingError.pipe(take(1)),
        ),
      )
      .subscribe(errors => this.setErrors(errors));

    // This subscription sets the errors on the form when attempting to sign
    this.visitProcedureSelectors.signingError
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        filterTruthy(),
        tap(() =>
          this.visitProcedureActions.getById({ id: this.visitProcedureId }),
        ),
      )
      .subscribe(errors => {
        this.form.controls.markAsPristine(); // Forces all errors to show up, see *ngIf on error divs
        this.setErrors(errors);
      });

    // This subscription removes the errors when the visit type changes
    this.form.controls
      .get(this.form.CONTROL_NAME_VISIT_TYPE)
      .valueChanges.pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        skip(1),
      )
      .subscribe(() => this.visitProcedureActions.resetSigningError());
  }

  private setErrors(errors: VisitProcedureError) {
    this.form.controls
      .get(this.form.CONTROL_NAME_RULE)
      .setErrors(this.extractErrors(errors, 'visitProcedureProcedureCode'));

    this.form.controls
      .get(this.form.CONTROL_NAME_TIME_BASED_MINUTES)
      .setErrors(this.extractErrors(errors, 'visitProcedureTotalTime'));

    const problemsErrors = this.extractErrors(
      errors,
      'visitProcedureChiefComplaint',
      'visitProcedureProblemCodes',
    );

    this.form.controls
      .get(this.form.CONTROL_NAME_PRIMARY_PROBLEM_CODE)
      .setErrors(problemsErrors);

    this.form.controls
      .get(this.form.CONTROL_NAME_PRIMARY_ASSESSED_PROBLEM)
      .setErrors(problemsErrors);

    this.nonFormControlErrors.procedures = this.extractErrors(
      errors,
      'visitProcedureProcedures',
    );
  }

  private extractErrors(
    errors: VisitProcedureError | undefined,
    ...props: (keyof VisitProcedureError)[]
  ) {
    const errorMessages = [];
    if (errors) {
      props.forEach(prop => {
        if (errors[prop]) {
          errorMessages.push(...castArray(errors[prop]));
        }
      });
    }

    return errorMessages.length ? { serverErrors: errorMessages } : null;
  }

  /**
   * If given VisitProcedureRule has the same procedure code as any other
   * VisitProcedureRule in the visit procedure OR there's only one option,
   * display the first ICD-10 value if it exists instead
   */
  getOptionSubtitle(
    option: VisitProcedureRule,
    visitProcedure: VisitProcedure,
  ): string {
    const duplicateProcedureCode = visitProcedure.options
      .filter(o => o.id !== option.id)
      .some(o => option.procedureCode.code === o.procedureCode.code);
    const onlyOneOption = visitProcedure.options.length === 1;
    const hasProblemCodes = option.problemCodes.length;

    if (hasProblemCodes && (duplicateProcedureCode || onlyOneOption)) {
      return option.problemCodes[0].code;
    }
    return option.procedureCode.code;
  }

  getOptionTitle(visitProcedureRule: VisitProcedureRule): string {
    return visitProcedureRule.counselingThresholdMet
      ? `>50% of a ${visitProcedureRule.lengthOfVisit} min visit`
      : visitProcedureRule.displayName;
  }

  getOptionsHeader(visitProcedure: VisitProcedure): string {
    if (visitProcedure.requiredParams.includes('total_time')) {
      return '';
    }

    if (visitProcedure.visitProcedureType.systemName === 'problem_based') {
      return visitProcedure.counselingThresholdMet
        ? 'Time Spent Counseling'
        : 'Level';
    }
    return '';
  }

  private mapSourcesToDropdownItems() {
    const visitTypeControl = this.form.controls.get(
      this.form.CONTROL_NAME_VISIT_TYPE,
    );
    // We include the visit type control changes observable because we want the
    // dropdown items list to be reevaluated when the visit type changes. Some
    // visit types are not selectable in the dropdown, but are selectable via
    // other buttons - see the Phone Medical Discussion visit type
    this.dropdownItems$ = combineLatest([
      this.visitProcedure$,
      visitTypeControl.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([visitProcedure, _]) =>
        visitProcedure.visitProcedureTypes
          .filter(
            visitProcedureType =>
              visitTypeControl.value === visitProcedureType.id ||
              !visitProcedureType.notProviderSelectable,
          )
          .map(visitProcedureType => ({
            label: visitProcedureType.displayType,
            value: visitProcedureType.id,
          })),
      ),
    );
  }

  private mapSourcesToOptions() {
    this.options$ = this.visitProcedure$.pipe(
      map((visitProcedure: VisitProcedure) =>
        visitProcedure.options.map(option => ({
          label: `<b>${this.getOptionTitle(option)}</b>${this.getOptionSubtitle(
            option,
            visitProcedure,
          )}`,
          value: option.id,
        })),
      ),
    );
  }

  private trackAnalytics() {
    // Creates event when user selects a visit code or selects the Procedure Only
    // visit type
    combineLatest(
      this.form.controls.get(this.form.CONTROL_NAME_RULE).valueChanges,
      this.form.controls.get(this.form.CONTROL_NAME_VISIT_TYPE).valueChanges,
    )
      .pipe(
        takeUntil(this.unsubscribe),
        withLatestFrom(this.visitProcedure$),
        map(([[ruleId, visitTypeId], visitProcedure]) => {
          let visitCodeName;

          const rule = visitProcedure.options.find(
            option => option.id === ruleId,
          );
          if (rule) {
            visitCodeName = `${this.getOptionTitle(
              rule,
            )} ${this.getOptionSubtitle(rule, visitProcedure)}`;
          }

          // Since procedure only has no visit codes, we send an event for
          // selecting this type itself.
          if (visitTypeId) {
            const visitType = visitProcedure.visitProcedureTypes.find(
              type => type.id === visitTypeId,
            );
            if (visitType.systemName === 'procedure_only') {
              visitCodeName = visitType.displayType;
            }
          }
          return visitCodeName;
        }),
        distinctUntilChanged(),
        filterTruthy(),
        withLatestFrom(this.visitProcedure$),
      )
      .subscribe(
        ([visitCodeName, visitProcedure]: [string, VisitProcedure]) => {
          this.analytics.track(AnalyticsEvent.VisitCodeSelected, {
            workflow: 'Charting',
            component: 'eService Ticket',
            subcomponent: visitCodeName,
            appointmentId: visitProcedure.serviceTicket.appointment.id,
          });
        },
      );
  }

  setupServiceTicketForm(visitProcedure: VisitProcedure) {
    this.form.setVisitProcedure(visitProcedure);

    if (visitProcedure.serviceTicket.serviceTicketTransmission.locked) {
      this.form.toggleDisabled(true);
    } else if (this.signed) {
      this.form.toggleDisabled(true);
      // We only want to enable to specific fields when the note has been signed
      // but the transmission has not been locked.
      this.form.controls.controls.id.enable({ emitEvent: false });
      this.form.controls.controls.virtual.enable({ emitEvent: false });
      this.form.controls.controls.visitProcedureRuleId.enable({
        emitEvent: false,
      });
      this.form.controls.controls.firstVisit.enable({ emitEvent: false });
    } else if (visitProcedure.requiredParams.includes('total_time')) {
      this.form.controls.controls.visitProcedureRuleId.disable({
        emitEvent: false,
      });
    } else {
      this.form.toggleDisabled(false);
    }
  }
}
