// @ts-strict-ignore
import 'chartjs-adapter-date-fns';

import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.register(ChartDataLabels);
@Component({
  selector: 'omg-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  chart: Chart;

  @Input() chartConfig: Chart.ChartConfiguration;
  @Input() chartTitle: string;

  @ViewChild('graph', { static: true }) graph: ElementRef;
  context: CanvasRenderingContext2D;

  constructor() {}

  ngOnInit() {
    if (!this.context) {
      this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext(
        '2d',
      );
    }
    this.chart = this.createChart();
  }

  ngOnDestroy() {
    this.chart.destroy();
  }

  private createChart() {
    return new Chart(this.context, this.chartConfig);
  }
}
