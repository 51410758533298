// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PatientActions } from '@app/core';

@Component({
  selector: 'omg-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientActions: PatientActions,
  ) {}

  ngOnInit() {
    this.patientActions.resetPatient();
    const patientId = +this.route.snapshot.params.id;
    this.router.navigateByUrl(`/patients/${patientId}/chart/summaries`, {
      replaceUrl: true,
    });
  }
}
