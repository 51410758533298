<form [formGroup]="form.controls">
  <div class="padding-normal"
       om-layout-gutter
       om-layout="vertical">
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('stateLicenseNumber')">
      <div>State License Number
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-state-license-number"
             formControlName="stateLicenseNumber" />
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('DEALicenseNumber')">
      <div>DEA License Number
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-DEA-license-number"
             formControlName="DEALicenseNumber" />
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('stateControlledSubstanceNumber')">
      <div>State Controlled Substance Number
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-state-controlled-substance-number"
             formControlName="stateControlledSubstanceNumber" />
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('NADEALicenseNumber')">
      <div>NADEA License Number
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-NADEA-license-number"
             formControlName="NADEALicenseNumber" />
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('NPILicenseNumber')"
           data-cy="change-rx-NPI-license-number">
      <div>NPI License Number
      </div>
      <omg-auto-complete formControlName="NPILicenseNumber"
                         class="om-ui-select -limited-choice"
                         placeholder="Select a provider..."
                         [items]="providerNPIOptions"
                         bindLabel="provider"
                         bindValue="provider"
                         hideDropdownArrow="true"
                         [clearable]="false"
                         dropdownPosition="top">
      </omg-auto-complete>
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('prescriberEnrollmentDate')">
      <div>Prescriber Enrollment Date
      </div>
      <omg-date-picker formControlName="prescriberEnrollmentDate"
                       data-cy="change-rx-prescriber-enrollment-date">
      </omg-date-picker>
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('specialty')">
      <div>Specialty
      </div>
      <omg-auto-complete #specialtyInput
                         placeholder="Start typing a specialty...."
                         [items]="nuccProviderSpecialties$ | ngrxPush"
                         trackByKey="id"
                         (focusOnRequest)="specialtyInput.focus()"
                         omgStopEvent
                         formControlName="specialty"
                         bindLabel="displayName"
                         bindValue="code"
                         useVirtualScroll="true"
                         data-cy="change-rx-specialty"
                         om-layout-fill>
      </omg-auto-complete>
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('REMSEnrollmentDate')">
      <div>REMS Enrollment Date
      </div>
      <omg-date-picker formControlName="REMSEnrollmentDate"
                       data-cy="change-rx-REMS-enrollment-date">
      </omg-date-picker>
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('supervisingProviderInformation')">
      <div>Supervising Provider Information
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-supervising-provider-information"
             formControlName="supervisingProviderInformation" />
    </label>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('certificateToPrescribeNumber')">
      <div>Certificate to prescribe number
      </div>
      <input om-flex="80"
             omgInputText
             type="number"
             data-cy="change-rx-certificate-to-prescribe-number"
             formControlName="certificateToPrescribeNumber" />
    </label>
  </div>
</form>
