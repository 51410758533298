import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

const maleHeadCircLMSParams: LMSParamsMap = {
  0: { lambda: 1, mu: 34.4618, sigma: 0.03686 },
  1: { lambda: 1, mu: 37.2759, sigma: 0.03133 },
  2: { lambda: 1, mu: 39.1285, sigma: 0.02997 },
  3: { lambda: 1, mu: 40.5135, sigma: 0.02918 },
  4: { lambda: 1, mu: 41.6317, sigma: 0.02868 },
  5: { lambda: 1, mu: 42.5576, sigma: 0.02837 },
  6: { lambda: 1, mu: 43.3306, sigma: 0.02817 },
  7: { lambda: 1, mu: 43.9803, sigma: 0.02804 },
  8: { lambda: 1, mu: 44.53, sigma: 0.02796 },
  9: { lambda: 1, mu: 44.9998, sigma: 0.02792 },
  10: { lambda: 1, mu: 45.4051, sigma: 0.0279 },
  11: { lambda: 1, mu: 45.7573, sigma: 0.02789 },
  12: { lambda: 1, mu: 46.0661, sigma: 0.02789 },
  13: { lambda: 1, mu: 46.3395, sigma: 0.02789 },
  14: { lambda: 1, mu: 46.5844, sigma: 0.02791 },
  15: { lambda: 1, mu: 46.806, sigma: 0.02792 },
  16: { lambda: 1, mu: 47.0088, sigma: 0.02795 },
  17: { lambda: 1, mu: 47.1962, sigma: 0.02797 },
  18: { lambda: 1, mu: 47.3711, sigma: 0.028 },
  19: { lambda: 1, mu: 47.5357, sigma: 0.02803 },
  20: { lambda: 1, mu: 47.6919, sigma: 0.02806 },
  21: { lambda: 1, mu: 47.8408, sigma: 0.0281 },
  22: { lambda: 1, mu: 47.9833, sigma: 0.02813 },
  23: { lambda: 1, mu: 48.1201, sigma: 0.02817 },
  24: { lambda: 1, mu: 48.2515, sigma: 0.02821 },
};

const femaleHeadCircLMSParams: LMSParamsMap = {
  0: { lambda: 1, mu: 33.8787, sigma: 0.03496 },
  1: { lambda: 1, mu: 36.5463, sigma: 0.0321 },
  2: { lambda: 1, mu: 38.2521, sigma: 0.03168 },
  3: { lambda: 1, mu: 39.5328, sigma: 0.0314 },
  4: { lambda: 1, mu: 40.5817, sigma: 0.03119 },
  5: { lambda: 1, mu: 41.459, sigma: 0.03102 },
  6: { lambda: 1, mu: 42.1995, sigma: 0.03087 },
  7: { lambda: 1, mu: 42.829, sigma: 0.03075 },
  8: { lambda: 1, mu: 43.3671, sigma: 0.03063 },
  9: { lambda: 1, mu: 43.83, sigma: 0.03053 },
  10: { lambda: 1, mu: 44.2319, sigma: 0.03044 },
  11: { lambda: 1, mu: 44.5844, sigma: 0.03035 },
  12: { lambda: 1, mu: 44.8965, sigma: 0.03027 },
  13: { lambda: 1, mu: 45.1752, sigma: 0.03019 },
  14: { lambda: 1, mu: 45.4265, sigma: 0.03012 },
  15: { lambda: 1, mu: 45.6551, sigma: 0.03006 },
  16: { lambda: 1, mu: 45.865, sigma: 0.02999 },
  17: { lambda: 1, mu: 46.0598, sigma: 0.02993 },
  18: { lambda: 1, mu: 46.2424, sigma: 0.02987 },
  19: { lambda: 1, mu: 46.4152, sigma: 0.02982 },
  20: { lambda: 1, mu: 46.5801, sigma: 0.02977 },
  21: { lambda: 1, mu: 46.7384, sigma: 0.02972 },
  22: { lambda: 1, mu: 46.8913, sigma: 0.02967 },
  23: { lambda: 1, mu: 47.0391, sigma: 0.02962 },
  24: { lambda: 1, mu: 47.1822, sigma: 0.02957 },
};

export const getHeadCircPercentile = (
  gender: string,
  ageInMonths: number,
  headCirc: number,
) => {
  const headCircLMSDataTable =
    gender === 'Female' ? femaleHeadCircLMSParams : maleHeadCircLMSParams;

  return zScoreFromMeasurement(headCirc, ageInMonths, headCircLMSDataTable);
};
