// @ts-strict-ignore
import { isEqual } from '@app/utils';

import { Message, Post } from './messaging.type';

export const isPost = (data: Post | Message): boolean =>
  data ? !!(data as Post).contentAttributes : false;

export const isMessage = (data: Post | Message): boolean =>
  data
    ? !!(data as Message).text ||
      (data as Message).text === '' ||
      !!(data as Message).html ||
      (data as Message).html === ''
    : false;

export const canAutosaveMessage = (curr, prev) => {
  const { assignRepliesTo: prevAssignRepliesTo, ...currValue } = curr;
  const { assignRepliesTo: currAssignRepliesTo, ...prevValue } = prev;

  return (
    !isEqual(currValue, prevValue) ||
    (!!currAssignRepliesTo && !!prevAssignRepliesTo)
  );
};
