<ng-container omgCollapseProvider
              [collapseProvider]="collapseProvider">
  <button omgButton
          type="button"
          *ngIf="visible"
          omgCollapseToggle
          variant="link">
    <omg-collapsed disableAnimation="true"
                   data-cy="rx-cart-item-show-more-options">more options
    </omg-collapsed>
    <omg-expanded disableAnimation="true"
                  data-cy="rx-cart-item-hide-more-options">hide options</omg-expanded>
  </button>
</ng-container>
