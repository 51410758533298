// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';

import { get } from '@app/utils/shared/lodash-fp';

import { Credential, Prescriber } from '../../prescriber-credential.type';

@Component({
  selector: 'omg-checkout-prescriber-display',
  templateUrl: './checkout-prescriber-display.component.html',
  styleUrls: ['./checkout-prescriber-display.component.css'],
})
export class CheckoutPrescriberDisplayComponent implements OnInit {
  @Input() prescriber: Prescriber;
  @Input() prescribingCredential: Credential;
  supervisingPhysicianName: any;
  supervisingPhysicianCredential: any;

  constructor() {}

  ngOnInit() {
    const supervisingPhysician = get(
      'supervisingPhysician',
      this.prescribingCredential,
    );

    this.supervisingPhysicianName = get('name', supervisingPhysician);
    this.supervisingPhysicianCredential = get(
      'supervisingCredential',
      supervisingPhysician,
    );
  }
}
