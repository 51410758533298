<nav omgTheme>
  <div class="wrapper">
    <div class="om-nav"
         om-layout
         om-layout-align="space-between center">
      <ul om-layout>
        <li class="om-logo">
          <img src="assets/img/om-atom.4fb18f33.svg"
               alt="One Medical" />
        </li>
        <li class="om-nav-item"
            omgLegacyUiHref="/admin">
          <strong>Home</strong>
        </li>
        <li class="om-nav-item"
            data-cy="navbar-admin-app-url">
          <a [href]="adminAppURL">
            <strong>Tasks</strong>
          </a>
        </li>
        <li class="om-nav-item"
            *ngIf="isEngineer$ | ngrxPush">
          <a href="/storybook/index.html"
             target="_blank">
            <strong>Storybook</strong>
          </a>
        </li>
        <!-- <li class="om-nav-item"
          *ngIf="isEngineer$ | ngrxPush">
        <a (click)="toggleTheme()">
          <strong>Theme - {{(activeThemeName$ | ngrxPush)}}</strong>
        </a>
      </li> -->
        <li class="om-nav-item">
          <a [href]="templatesURL"
             data-cy="template-manager-link"
             target="_blank">
            <strong>Template Manager
            </strong>
          </a>
        </li>
      </ul>
      <ul om-layout
          om-layout-align="end center">
        <omg-resource-center-trigger *ngIf="activateNavbarResourceCenterTriggerAlsoRequiresPendoConfiguration">
        </omg-resource-center-trigger>
        <li class="presence-margin-top">
          <omg-presence></omg-presence>
        </li>
        <p-menu #profileMenu
                styleClass="signout-button"
                [model]="profileOptions | ngrxPush"
                [popup]="true"></p-menu>
        <li (click)="toggleProfileMenu($event)"
            class="om-nav-item">
          <strong>{{ primaryIdentityName | ngrxPush }}</strong>
          <i class="fa fa-caret-down"></i>
        </li>
      </ul>
    </div>
  </div>
</nav>
