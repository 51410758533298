// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { take } from 'rxjs/operators';

import { LoggerService } from '@app/core/logger';
import { windowToken } from '@app/shared/window/window.service';

import { S3Service } from '../../shared/s3.service';

@Component({
  selector: 'omg-file-link',
  templateUrl: './file-link.component.html',
  styleUrls: ['./file-link.component.scss'],
})
export class FileLinkComponent {
  @Input() key: string;
  @Input() bucket: string;
  @Input() encode: boolean;
  @Input() url: string;
  @Input() text: string;
  @Input() deletable: boolean;
  @Output() delete = new EventEmitter<void>();

  hovering = false;

  constructor(
    private s3Service: S3Service,
    private loggerService: LoggerService,
    @Inject(windowToken) private window: Window,
  ) {}

  directToFile() {
    if (this.key && this.bucket) {
      this.getFromS3();
    } else if (this.url) {
      this.openInNewWindow(this.url);
    }
  }

  openInNewWindow(url: string) {
    this.window.open(url, '_blank');
  }

  onDelete() {
    this.delete.emit();
  }

  onHoverEvent(hovering: boolean) {
    this.hovering = hovering;
  }

  private getFromS3() {
    this.s3Service
      .getURL(this.key, this.bucket)
      .pipe(take(1))
      .subscribe(
        url => {
          const encodedUrl = this.encode ? encodeURIComponent(url) : url;
          this.openInNewWindow(encodedUrl);
        },
        err =>
          this.loggerService.error(
            `Error opening file from S3 at bucket=${this.bucket} key=${this.key}`,
            err,
          ),
      );
  }
}
