import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ChangeRxForm } from '../../shared/change-rx-form';
import { RxChangeRequestOption } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-select',
  templateUrl: './change-rx-medication-select.component.html',
  styleUrls: ['./change-rx-medication-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationSelectComponent {
  @Input() form: ChangeRxForm;
  @Input() options: RxChangeRequestOption[];

  @Output() updated = new EventEmitter<RxChangeRequestOption>();

  onSelection(selected: RxChangeRequestOption) {
    this.updated.emit(selected);
  }
}
