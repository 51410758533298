import { Note, NoteDocument } from './note.type';

export const isPdfDocument = (doc?: NoteDocument): boolean =>
  !!doc && !!doc.pdfKey && !doc.forLab;

export const classNamify = (name: string): string =>
  name
    .replace(new RegExp(' ', 'g'), '-')
    .replace(new RegExp('_', 'g'), '-')
    .toLowerCase();

export const documentHasPdf = (doc: NoteDocument): boolean =>
  !!doc.pdfKey && !!doc.pdfBucket;

export const getAwsKey = (doc: NoteDocument): string | undefined =>
  documentHasPdf(doc) ? doc.pdfKey : doc.awsKey;

export const getAwsBucket = (doc: NoteDocument): string | undefined =>
  documentHasPdf(doc) ? doc.pdfBucket : doc.awsBucket;

export const isNoteResults = (note: Note) =>
  note.noteType && note.noteType.tags.findIndex(tag => tag === 'results') > -1;

export const isTreatMeNowNote = (note: Note) =>
  note?.noteType?.tags?.includes('treatmenow');

export const getDocumentExtension = (doc: NoteDocument) =>
  documentHasPdf(doc) ? 'pdf' : doc.ext;

export const getAttachmentTitle = (note: Note, doc: NoteDocument): string => {
  const ext = getDocumentExtension(doc);
  if (isNoteResults(note)) {
    return `Results ${note.id}.${ext}`;
  }
  return `Document ${note.id}.${ext}`;
};

export const getPostTitle = (note: Note) => {
  if (isNoteResults(note)) {
    return 'Your Test Results';
  }
  if (isTreatMeNowNote(note)) {
    return note.subject;
  }

  return null;
};

export const objectChanged = (obj, compareObj): boolean => {
  for (const key of Object.keys(obj)) {
    if (obj[key] !== compareObj[key]) {
      return true;
    }
  }

  return false;
};

export const arrayChanged = (arr, compareArr): boolean => {
  let changeFound = false;

  if (arr.length !== compareArr.length) {
    changeFound = true;
  }

  for (let i = 0; i < arr.length && !changeFound; i++) {
    if (arr[i] instanceof Array) {
      if (arrayChanged(arr[i], compareArr[i])) {
        changeFound = true;
      }
    } else if (arr[i] instanceof Object) {
      if (objectChanged(arr[i], compareArr[i])) {
        changeFound = true;
      }
    } else {
      if (arr[i] !== compareArr[i]) {
        changeFound = true;
      }
    }
  }
  return changeFound;
};
