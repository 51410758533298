export enum DateFormats {
  '2y' = 'MM/dd/yy',
  '4y' = 'MM/dd/yyyy',
  'withoutDay4y' = 'MMM yyyy',
  'withTime2y' = 'h:mm aa MM/dd/yy',
  'withTime2yTz' = 'MM/dd/yy h:mm aa',
  'withTime4y' = 'h:mm aa MM/dd/yyyy',
  '12Time' = 'h:mm aa',
  '24Time' = 'HH:mm',
}
