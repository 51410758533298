import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SummaryVaccination } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-vaccines',
  templateUrl: './linked-vaccines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedVaccinesComponent {
  @Input() signed: boolean;
  @Input() vaccinations: SummaryVaccination[];
  @Output() unlink = new EventEmitter();

  trackByFn = (index, vaccination: SummaryVaccination) =>
    vaccination.id || index;

  deleteFromNote() {
    this.unlink.emit();
  }
}
