<div om-layout="vertical"
     om-layout-gutter>

  <!-- Program care plan display -->
  <div *ngIf="!editingPlan">
    <div class="label"
         om-layout
         om-layout-align="space-between">
      {{ appointmentProgramEnrollment?.program?.displayName }} Program Care Plan
      <a (click)="toggleEditingPlan()">
        Edit Plan
      </a>
    </div>
    <p omgTheme
       [innerHTML]="programCarePlan.carePlan | safe:'html'">
    </p>
  </div>

  <!-- Program care plan form -->
  <div *ngIf="editingPlan">
    <div>
      <strong>Program(s)</strong>
      <omg-dropdown [options]="dropdownItems"
                    [formControl]="programControl"
                    flex="true"></omg-dropdown>
    </div>
    <div>
      <strong>Care Plan</strong>
      <omg-chart-text-box quillFormat="text"
                          [control]="carePlanControl"
                          (blurTextBox)="carePlanOnBlur()">
      </omg-chart-text-box>
    </div>
    <div om-layout="vertical"
         om-layout-gutter-large>
      <div om-layout="horizontal"
           om-layout-align="end">
        <i>Last updated {{ programCarePlan.updatedAt | omgDate: 'withTime2y'}} by
          {{ programCarePlan?.updatedBy?.displayName }}</i>
      </div>
      <a *ngIf="editingPlan"
         (click)="toggleEditingPlan()">
        <b>Close Care Plan</b>
      </a>
    </div>
  </div>
</div>
