import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FsaAction } from '../shared/fsa-action';
import { ActiveRouteItem, ActiveRoutesState } from './active-routes.reducer';

export enum ActiveRoutesActionTypes {
  SET_ORDERS = '[Active Routes] Setting orders',
  CLEAR_ORDERS = '[Active Routes] Clearing orders',
  SET_WORKSPACE = '[Active Routes] Setting workspace',
  CLEAR_WORKSPACE = '[Active Routes] Clearing workspace',
}

export class SetOrders implements FsaAction<ActiveRouteItem> {
  readonly type = ActiveRoutesActionTypes.SET_ORDERS;

  constructor(public payload: ActiveRouteItem) {}
}

export class ClearOrders implements FsaAction<void> {
  readonly type = ActiveRoutesActionTypes.CLEAR_ORDERS;
}

export class SetWorkspace implements FsaAction<ActiveRouteItem> {
  readonly type = ActiveRoutesActionTypes.SET_WORKSPACE;

  constructor(public payload: ActiveRouteItem) {}
}

export class ClearWorkspace implements FsaAction<void> {
  readonly type = ActiveRoutesActionTypes.CLEAR_WORKSPACE;
}

export type ActiveRoutesAction =
  | SetOrders
  | ClearOrders
  | SetWorkspace
  | ClearWorkspace;

@Injectable()
export class ActiveRoutesActions {
  constructor(private store: Store<ActiveRoutesState>) {}

  setOrders(active: ActiveRouteItem) {
    this.store.dispatch(new SetOrders(active));
  }

  clearOrders() {
    this.store.dispatch(new ClearOrders());
  }

  setWorkspace(active: ActiveRouteItem) {
    this.store.dispatch(new SetWorkspace(active));
  }

  clearWorkspace() {
    this.store.dispatch(new ClearWorkspace());
  }
}
