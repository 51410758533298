import { Component, Input } from '@angular/core';

import {
  ChangeRxForm,
  controlsByPrescriberAuthorizationSubcode,
} from '../../shared/change-rx-form';
import { PrescriberAuthorizationSubcodes } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-provider-auth-status',
  templateUrl: './change-rx-provider-auth-status.component.html',
  styleUrls: ['./change-rx-provider-auth-status.component.scss'],
})
export class ChangeRxProviderAuthStatusComponent {
  @Input() form: ChangeRxForm;
  prescriberAuthorizationSubcodes = PrescriberAuthorizationSubcodes;
  controlsByPrescriberAuthorizationSubcode = controlsByPrescriberAuthorizationSubcode;
}
