<div class="om-task-licensing-body"
     [formGroup]="form"
     *ngIf="(todo | ngrxPush) as todo">
  <div om-layout
       om-layout-gutter
       class="padding-normal-horizontal"
       om-layout-align="space-between center">
    <label om-layout
           om-flex
           om-layout-gutter
           om-layout-align="start center">
      <span om-flex="30"> Pt current location</span>
      <omg-dropdown formControlName="licensingBodyId"
                    class="task-licensing-body-dropdown"
                    data-cy="task-licensing-body"
                    [options]="licensingBodies"
                    [placeholder]="licensingBodyPlaceholder"
                    [autoDisplayFirst]="false"
                    data-cy="licensing-body-dropdown"
                    [flex]="false"
                    om-flex="100"></omg-dropdown>
    </label>
  </div>
  <ng-content></ng-content>
</div>
