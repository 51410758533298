<omg-section-title>
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Health Background</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgTooltip
          tooltipText="Delete from Note"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate">
    <div *ngIf="healthBackground.illnessesAndSurgeries.notes">
      <b>Important Events & Surgeries</b>
      <p>{{ healthBackground.illnessesAndSurgeries.notes }}</p>
    </div>
    <div *ngIf="implantableDevices">
      <b>Implantable Devices</b>
      <p *ngFor="let implantableDevice of implantableDevices">
        {{ implantableDevice.deviceType }},
        Active{{ implantableDevice.insertionDate ? ', ' : ''}}{{ implantableDevice.insertionDate }}
      <p>
    </div>
    <div *ngIf="healthBackground.familyData.notes">
      <b>Family Data</b>
      <p>{{ healthBackground.familyData.notes }}</p>
    </div>
    <div *ngIf="healthBackground.socialData.notes">
      <b>Social Data</b>
      <p>{{ healthBackground.socialData.notes }}</p>
    </div>
    <div *ngIf="smokingStatus">
      <b>Smoking Status</b>
      <p>{{ smokingStatus.displayDescription }} <span *ngIf="smokingStatus.fuzzyStartedOn">as of
          {{ smokingStatus.fuzzyStartedOn }}</span></p>
    </div>
  </li>
</ul>
