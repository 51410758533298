import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import * as utils from '../shared/patient-utils';
import { PatientState, patientStatePath } from './patient.reducer';

const medicare = 'Medicare';

export const selectPatientState = createFeatureSelector<PatientState>(
  patientStatePath,
);

export const selectPatient = createSelector(
  selectPatientState,
  state => state && state.entity,
);

export const selectPatientId = createSelector(selectPatient, p => p && p.id);
export const selectPatientFhirId = createSelector(
  selectPatient,
  p => p && p.fhirId,
);

export const selectPcpComments = createSelector(
  selectPatient,
  p => p && p.pcpComments,
);

export const selectPcpCommentHistories = createSelector(
  selectPatient,
  p => p && p.pcpCommentHistories,
);

export const selectPcpCommentLastEditedBy = createSelector(
  selectPatient,
  p => p && p.pcpCommentLastEditedBy,
);

export const selectPcpCommentLastUpdatedAt = createSelector(
  selectPatient,
  p => p && p.pcpCommentLastUpdatedAt,
);

export const selectHealthMaintenanceNote = createSelector(
  selectPatient,
  p => p && p.healthMaintenanceNote,
);

export const selectFullName = createSelector(selectPatient, p => {
  const fullNameExists = !!p && !!p.preferredName && !!p.lastName;
  return fullNameExists ? `${p.preferredName} ${p.lastName}` : null;
});

export const selectDateOfBirth = createSelector(
  selectPatient,
  p => p && p.dateOfBirth,
);

export const selectGender = createSelector(selectPatient, p => p && p.gender);

export const selectPastOrCurrentElectronicTosAccepted = createSelector(
  selectPatient,
  p => p && p.pastOrCurrentElectronicTosAccepted,
);

export const selectPatientStateName = createSelector(
  selectPatient,
  p =>
    p &&
    p.office &&
    p.office.address &&
    p.office.address.state &&
    p.office.address.state.name,
);

export const selectPatientOffice = createSelector(selectPatient, p => p.office);

export const selectPreferredName = createSelector(
  selectPatient,
  p => p.preferredName,
);

export const selectAddresses = createSelector(selectPatient, p => p.addresses);

export const selectFormattedAge = createSelector(
  selectPatient,
  p => p.formattedDisplayAge,
);

export const selectAgeInMonths = createSelector(
  selectPatient,
  p => p && p.ageInMonths,
);

export const selectIsUnderTwenty = createSelector(selectPatient, p =>
  utils.isUnderTwenty(p),
);

export const selectIsTwentyOneOrOver = createSelector(selectPatient, p =>
  utils.isTwentyOneOrOver(p),
);

export const selectIsMinor = createSelector(selectPatient, p =>
  utils.isMinor(p),
);

export const selectIsTeen = createSelector(selectPatient, p => utils.isTeen(p));

export const selectIsPreteen = createSelector(selectPatient, p =>
  utils.isPreteen(p),
);

export const selectHasIncompleteDemographics = createSelector(
  selectPatient,
  p => utils.hasIncompleteDemographics(p),
);

export const selectAcceptsDigitalCommunications = createSelector(
  selectPatient,
  p => utils.acceptsDigitalCommunications(p),
);

export const selectLimitedAccessMembership = createSelector(selectPatient, p =>
  utils.limitedAccessMembership(p),
);

export const selectExpiredMembership = createSelector(selectPatient, p =>
  utils.expiredMembership(p),
);

export const selectPatientWarnings = createSelector(selectPatient, p =>
  utils.buildPatientWarnings(p),
);

export const selectPrimaryInsurance = createSelector(
  selectPatient,
  p => p.primaryInsurance,
);

export const selectPrimaryInsuranceType = createSelector(
  selectPatient,
  p => p.primaryInsurance.type,
);

export const selectIsVirtual = createSelector(selectPatient, p => p.isVirtual);

export const selectIsShiftAvailable = createSelector(
  selectPatient,
  p => p.serviceArea.isShiftAvailable,
);

export const selectLoading = createSelector(
  selectPatientState,
  state => state.loading,
);

export const selectLoadingPcpComments = createSelector(
  selectPatientState,
  state => state.loadingPcpComments,
);

export const selectPendingAdmissions = createSelector(
  selectPatient,
  p => p.pendingAdmissions,
)

export const selectError = createSelector(
  selectPatientState,
  state => state.error,
);

export const selectPatientInfo = createSelector(
  selectPatient,
  selectFormattedAge,
  selectIsTeen,
  selectIsPreteen,
  selectAcceptsDigitalCommunications,
  selectHasIncompleteDemographics,
  selectExpiredMembership,
  selectLimitedAccessMembership,
  (
    patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  ) => ({
    ...patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  }),
);

export const selectVBCEligible = createSelector(
  selectPatient,
  p => p.vbcEligible,
);

/* istanbul ignore next */
@Injectable()
export class PatientSelectors {
  constructor(private store: Store<PatientState>) {}

  get acceptsDigitalCommunications() {
    return this.store.pipe(select(selectAcceptsDigitalCommunications));
  }

  get addresses() {
    return this.store.pipe(select(selectAddresses));
  }

  get ageInMonths() {
    return this.store.pipe(select(selectAgeInMonths));
  }

  get dateOfBirth() {
    return this.store.pipe(select(selectDateOfBirth));
  }

  get expiredMembership() {
    return this.store.pipe(select(selectExpiredMembership));
  }

  get formattedAge() {
    return this.store.pipe(select(selectFormattedAge));
  }

  get fullName() {
    return this.store.pipe(select(selectFullName));
  }

  get gender() {
    return this.store.pipe(select(selectGender));
  }

  get hasIncompleteDemographics() {
    return this.store.pipe(select(selectHasIncompleteDemographics));
  }

  get healthMaintenanceNote() {
    return this.store.pipe(select(selectHealthMaintenanceNote));
  }

  get isUnderTwenty() {
    return this.store.pipe(select(selectIsUnderTwenty));
  }

  get isTwentyOneOrOver() {
    return this.store.pipe(select(selectIsTwentyOneOrOver));
  }

  get isMinor() {
    return this.store.pipe(select(selectIsMinor));
  }

  get isPreteen() {
    return this.store.pipe(select(selectIsPreteen));
  }

  get isTeen() {
    return this.store.pipe(select(selectIsTeen));
  }

  get limitedAccessMembership() {
    return this.store.pipe(select(selectLimitedAccessMembership));
  }

  get loading() {
    return this.store.pipe(select(selectLoading));
  }

  get loadingPcpComments() {
    return this.store.pipe(select(selectLoadingPcpComments));
  }

  get error() {
    return this.store.pipe(select(selectError));
  }

  get office() {
    return this.store.pipe(select(selectPatientOffice));
  }

  get pastOrCurrentElectronicTosAccepted() {
    return this.store.pipe(select(selectPastOrCurrentElectronicTosAccepted));
  }

  get patient() {
    return this.store.pipe(select(selectPatient));
  }

  get patientId() {
    return this.store.pipe(select(selectPatientId));
  }

  get patientFhirId() {
    return this.store.pipe(select(selectPatientFhirId));
  }

  get pcpCommentHistories() {
    return this.store.pipe(select(selectPcpCommentHistories));
  }

  get pcpCommentLastEditedBy() {
    return this.store.pipe(select(selectPcpCommentLastEditedBy));
  }

  get pcpCommentLastUpdatedAt() {
    return this.store.pipe(select(selectPcpCommentLastUpdatedAt));
  }

  get patientInfo() {
    return this.store.pipe(select(selectPatientInfo));
  }

  get patientStateName() {
    return this.store.pipe(select(selectPatientStateName));
  }

  get patientWarnings() {
    return this.store.pipe(select(selectPatientWarnings));
  }

  get pcpComments() {
    return this.store.pipe(select(selectPcpComments));
  }

  get preferredName() {
    return this.store.pipe(select(selectPreferredName));
  }

  get primaryInsurance() {
    return this.store.pipe(select(selectPrimaryInsurance));
  }

  get primaryInsuranceType() {
    return this.store.pipe(select(selectPrimaryInsuranceType));
  }

  get vbcEligible() {
    return this.store.pipe(select(selectVBCEligible));
  }

  get isVirtual() {
    return this.store.pipe(select(selectIsVirtual));
  }

  get isMedicare() {
    return this.primaryInsuranceType.pipe(
      distinctUntilChanged(),
      map(insurance => insurance === medicare),
    );
  }

  get isShiftAvailable() {
    return this.store.pipe(select(selectIsShiftAvailable));
  }
}
