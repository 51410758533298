import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrderCommentsState, orderCommentsStatePath, selectAll, selectEntities } from './order-comments.reducer';

export const selectOrderCommentsState = createFeatureSelector<
  OrderCommentsState
>(orderCommentsStatePath);

export const selectOrderComments = createSelector(
  selectOrderCommentsState,
  selectEntities,
);

export const selectAllOrderComments = createSelector(
  selectOrderCommentsState,
  selectAll,
);

export const selectOrderCommentsPending = createSelector(
  selectOrderCommentsState,
  state => state.pending,
);

export const selectOrderCommentsError = createSelector(
  selectOrderCommentsState,
  state => state.error,
);
