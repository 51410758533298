// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';

import { PatientSelectors } from '@app/core/patient/store/patient.selectors';
import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { PrescriptionHistoryTabs } from '@app/features/prescription-history/shared/prescription-history.type';
import { PatientPharmacySelectors } from '@app/modules/pharmacy-picker/store/patient-pharmacy.selectors';
import { DialogService } from '@app/shared/components/dialog';
import { filterTruthy } from '@app/utils';

import {
  AbstractRx,
  PatientPrimaryAddressStateResponse,
  PdmpNonParticipatingStatesResponse,
} from '../../shared/pdmp.type';
import {
  formatStateList,
  hasControlledSubstanceChanges,
  hasControlledSubstanceFilter,
  mapPatientPrimaryAddressStateToString,
  mapToPdmpNonParticipatingStates,
  mapToRxCartReadyToSignResult,
} from '../../shared/pdmp.utils';

export const markRxCartReadyToSignMutation = gql`
  mutation MarkRxCartReadyToSign($rxCartId: ID!) {
    markRxCartReadyToSign(input: { rxCartId: $rxCartId }) {
      success
    }
  }
`;

export const pdmpNonParticipatingStatesQuery = gql`
  query GetPdmpNonParticipatingStates {
    pdmpNonParticipatingStates {
      id
      shortName
    }
  }
`;

export const patientPrimaryAddressStateQuery = gql`
  query GetPatientPrimaryAddress($id: ID!) {
    patient(id: $id) {
      id
      primaryAddress {
        id
        state
      }
    }
  }
`;

@Component({
  selector: 'omg-pdmp-dialogue-banner',
  templateUrl: './pdmp-dialogue-banner.component.html',
  styleUrls: ['./pdmp-dialogue-banner.component.scss'],
})
export class PdmpDialogueBannerComponent implements OnInit, OnChanges {
  @Input() cartId: number;
  @Input() cartItems: AbstractRx[];
  @Output() confirmedReportViewed = new EventEmitter<boolean>();

  isChecked: boolean;

  items$ = new ReplaySubject<AbstractRx[]>(1);
  requiredStates$: Observable<string>;
  reportStates$: Observable<string>;
  nonReportStates$: Observable<string>;

  constructor(
    private dialogService: DialogService,
    private apollo: Apollo,
    private patientPharmacySelectors: PatientPharmacySelectors,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit(): void {
    this.setupListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue = [] } = changes.cartItems;

    // Clear confirmation on changes to controlled substances
    if (hasControlledSubstanceChanges(currentValue, previousValue)) {
      setTimeout(() => this.confirmedReportViewed.emit(false)); // timeout to avoid `ExpressionChangedAfterItHasBeenCheckedError`
    }

    // Keep the checkbox value updated with item changes
    this.isChecked = currentValue
      .filter(hasControlledSubstanceFilter)
      .every(({ readyToSign }) => readyToSign);

    this.items$.next(currentValue);
  }

  onShowReportClick(): void {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      // Change this to use @Inject(OMG_DIALOG_DATA)
      dialogRef.componentInstance.currentHistoryTab =
        PrescriptionHistoryTabs.pdmp.id;
      dialogRef.componentInstance.rxCartId = this.cartId;
    }
  }

  onConfirmReportViewedChange(): void {
    if (this.isChecked) {
      this.markAsReadyToSign(this.cartId)
        .pipe(take(1))
        .subscribe(result => this.confirmedReportViewed.emit(!!result));
    } else {
      this.confirmedReportViewed.emit(false);
    }
  }

  private setupListeners(): void {
    const statesAndNonParticipation$ = combineLatest([
      this.controlledSubstanceStates$,
      this.nonParticipatingStates$,
    ]);

    this.reportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => !nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.nonReportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.requiredStates$ = this.controlledSubstanceStates$.pipe(
      map(formatStateList),
    );
  }

  private get controlledSubstanceStates$(): Observable<string[]> {
    return this.items$.pipe(
      switchMap(items =>
        combineLatest(
          items.filter(hasControlledSubstanceFilter).map(
            item =>
              'pharmacy' in item
                ? of(item.pharmacy) //From abstract rx
                : this.getPharmacy$(item.pharmacyId), //From patient pharmacy
          ),
        ),
      ),
      switchMap(pharmacies =>
        combineLatest(
          pharmacies.map(({ isMailOrder, address }) =>
            isMailOrder
              ? this.patientPrimaryAddressState$ // sub patient's primary state if delivery
              : of(address.state),
          ),
        ),
      ),
      map(states => [...new Set(states)].sort()), // To unique states
    );
  }

  private get nonParticipatingStates$(): Observable<string[]> {
    return this.apollo
      .use('onelife')
      .query<PdmpNonParticipatingStatesResponse>({
        query: pdmpNonParticipatingStatesQuery,
      })
      .pipe(map(mapToPdmpNonParticipatingStates));
  }

  private markAsReadyToSign = (cartId: number): Observable<boolean> =>
    this.apollo
      .use('onelife')
      .mutate({
        mutation: markRxCartReadyToSignMutation,
        variables: { rxCartId: cartId },
      })
      .pipe(map(mapToRxCartReadyToSignResult));

  private getPharmacy$ = (pharmacyId: number) =>
    this.patientPharmacySelectors.pharmacy(pharmacyId).pipe(
      filterTruthy(),
      map(({ pharmacy }) => pharmacy),
    );

  private get patientPrimaryAddressState$(): Observable<string> {
    return this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.apollo.use('onelife').query<PatientPrimaryAddressStateResponse>({
          query: patientPrimaryAddressStateQuery,
          variables: { id: patientId },
        }),
      ),
      map(mapPatientPrimaryAddressStateToString),
    );
  }
}
