// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';

import { VisitProcedureError } from '@app/features/service-ticket/shared/visit-procedure.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { camelCase, httpStatus, pickBy } from '@app/utils';

import { SummaryNoteType, SummaryProblem } from './summaries.type';

export const hasNoSignificantMedicalHistory = (
  activeProblemsLength: number,
  resolvedProblemsLength: number,
) => activeProblemsLength + resolvedProblemsLength === 0;

export const sortProblemsByClinicalDescription = (
  problems: SummaryProblem[],
) => {
  return problems.sort((a, b) => {
    const itemA = a.problemType.clinicalDescription.toLowerCase();
    const itemB = b.problemType.clinicalDescription.toLowerCase();

    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }
    return 0;
  });
};

export const isFinishedCosignTodo = (todo: Todo, todoId: number) => {
  return (
    todo.name === 'Co-Sign' && todo.state === 'finished' && todo.id === todo.id
  );
};

export const isWalkinVisit = (summaryNoteType: SummaryNoteType) => {
  return summaryNoteType.systemName === 'walkin_visit';
};

export const isOfficeVisit = (summaryNoteType: SummaryNoteType) => {
  return summaryNoteType.systemName === 'office_visit';
};

export const isMentalHealthVisit = (summaryNoteType: SummaryNoteType) => {
  return summaryNoteType.systemName === 'mental_health';
};

export const formatCptCodes = (cptCodes: Array<string>) => {
  return cptCodes.map(cptCode => (cptCode === '00000' ? 'No Bill' : cptCode));
};

export function mapVisitProcedureErrors(
  error: HttpErrorResponse,
): VisitProcedureError | undefined {
  const status = error && error.status;
  if (status === httpStatus.UNPROCESSABLE_ENTITY) {
    return pickBy(
      (_, key) => key.includes('visitProcedure'),
      camelCase(error.error),
    ) as VisitProcedureError;
  }
  return {};
}
