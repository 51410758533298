// @ts-strict-ignore
import { Injectable } from '@angular/core';

interface LibPendo {
  initialize(config: any): void;
  BuildingBlocks?: {
    BuildingBlockResourceCenter?: {
      getResourceCenterBadge?: () =>
        | {
            element?: () => HTMLElement | undefined;
          }
        | undefined;
      getResourceCenter?: () =>
        | {
            isShown?: () => boolean | undefined;
          }
        | undefined;
    };
  };
}

// At time of writing: Partially initialized key count is 6. Fully initialized key count is 236.
// Halfway between should provide adequate buffer if either partially initialized key expansion or fully initialized key contraction happens in the future.
const FULLY_INITIALIZED_KEYS_THRESHOLD = 121;

const UNINITIALIZED = Symbol();

@Injectable()
export class PendoService {
  private pendoGlobal?: LibPendo;
  private pendoPromise?: Promise<LibPendo | undefined> | null;
  private resolveAfterFullInitialization?:
    | ((value: LibPendo | undefined) => void)
    | null;
  private subscriptions?: Map<
    () => Promise<any>,
    {
      value: any;
      callbacks: Array<(value: any) => any>;
    }
  >;
  private mutationObserver?: MutationObserver;

  private get isPendoFullyInitialized() {
    return (
      this.pendoGlobal != null &&
      Object.keys(this.pendoGlobal).length > FULLY_INITIALIZED_KEYS_THRESHOLD
    );
  }

  constructor() {
    this.isBadgeElementInDOM = this.isBadgeElementInDOM.bind(this);
    this.isResourceCenterShown = this.isResourceCenterShown.bind(this);

    this.pendoGlobal = (window as any).pendo;
    Object.defineProperty(window, 'pendo', {
      get: () => this.pendoGlobal,
      set: value => {
        if (value !== this.pendoGlobal) {
          this.pendoPromise = null;
        }
        this.pendoGlobal = value;
        if (this.resolveAfterFullInitialization != null) {
          // The pendo global is assigned to several times before—and once at the start of—the final initialization.
          // To understand if it's undergoing final initialized or not, we have to schedule a task on the micro task queue,
          // which will execute after the synchronous initialization has completed if that initialization is indeed occurring now.
          Promise.resolve().then(() => {
            if (
              this.isPendoFullyInitialized &&
              this.resolveAfterFullInitialization
            ) {
              this.resolveAfterFullInitialization(this.pendoGlobal);
              this.resolveAfterFullInitialization = null;
            }
          });
        }
      },
    });
  }

  isBadgeElementInDOM(): Promise<boolean> {
    return this.getPendo().then(pendo => {
      const badgeElement = pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.getResourceCenterBadge?.()?.element?.();
      return badgeElement != null && document.contains(badgeElement);
    });
  }

  isResourceCenterShown(): Promise<boolean> {
    return this.getPendo().then(
      pendo =>
        pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.getResourceCenter?.()?.isShown?.() ??
        false,
    );
  }

  subscribe<T>(
    func: () => Promise<T>,
    callback: (value: T) => any,
    isInitialCallbackRequested = false,
  ): boolean {
    if (!this.isMemberFunction(func)) {
      return false;
    }

    if (!this.subscriptions) {
      this.subscriptions = new Map();
    }

    if (this.subscriptions.size === 0) {
      this.startMutationObserver();
    }

    if (!this.subscriptions.has(func)) {
      this.subscriptions.set(func, {
        value: UNINITIALIZED,
        callbacks: [],
      });
    }

    const subscription = this.subscriptions.get(func);
    if (!subscription) return false;
    const { callbacks } = subscription;

    if (callbacks.includes(callback)) {
      return false;
    }

    callbacks.push(callback);

    if (isInitialCallbackRequested) {
      if (subscription.value !== UNINITIALIZED) {
        callback(subscription.value);
      } else {
        func().then(value => {
          subscription.value = value;
          callback(value);
        });
      }
    }

    return true;
  }

  unsubscribe<T>(func: () => Promise<T>, callback: (value: T) => any): boolean {
    if (!this.subscriptions?.has(func)) {
      return false;
    }

    const sub = this.subscriptions?.get(func);
    if (!sub) return false;

    const callbacks = sub[1];
    const index = callbacks.indexOf(callback);
    if (index < 0) {
      return false;
    }
    callbacks.splice(index, 1);

    if (callbacks.length === 0) {
      this.subscriptions.delete(func);
    }

    if (this.subscriptions.size === 0) {
      this.stopMutationObserver();
    }

    return true;
  }

  private getPendo(): Promise<LibPendo | undefined> {
    if (this.pendoPromise) {
      return this.pendoPromise;
    }

    if (this.isPendoFullyInitialized) {
      return (this.pendoPromise = Promise.resolve(this.pendoGlobal));
    }

    return (this.pendoPromise = new Promise(resolve => {
      this.resolveAfterFullInitialization = resolve;
    }));
  }

  private isMemberFunction(func: (...args: any[]) => any): boolean {
    let name = func.name;
    const spaceIndex = name.lastIndexOf(' ');
    if (spaceIndex !== -1) {
      name = name.substring(spaceIndex + 1);
    }
    return this[name] === func;
  }

  private startMutationObserver() {
    if (this.mutationObserver == null) {
      this.mutationObserver = new MutationObserver(() => {
        if ((this.subscriptions?.size ?? 0) < 1) {
          return this.stopMutationObserver();
        }

        const subscriptions = this.subscriptions;
        if (!subscriptions) return;
        for (const [func, subscription] of subscriptions.entries()) {
          const { callbacks, value: lastValue } = subscription;

          if (callbacks.length < 1) {
            subscriptions.delete(func);
            continue;
          }

          func().then(nextValue => {
            if (nextValue !== lastValue) {
              subscription.value = nextValue;
              callbacks.forEach(callback => callback(nextValue));
            }
          });
        }
      });
    }

    this.mutationObserver.observe(document.body, {
      childList: true,
    });
  }

  private stopMutationObserver() {
    this.mutationObserver?.disconnect?.();
  }
}
