import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: '[omgCustomOptionHighlight]',
})
export class CustomOptionHighlightDirective
  implements AfterViewInit, OnChanges {
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('omgCustomOptionHighlight') term: string;
  element: HTMLElement;
  originalHighlightables: string[] = [];

  constructor(private el: ElementRef) {
    this.element = this.el.nativeElement;
  }

  ngAfterViewInit() {
    this.element = this.el.nativeElement;
    this.element.querySelectorAll('.highlightable').forEach(highlightable => {
      this.originalHighlightables.push(highlightable.innerHTML);
    });
    this.addHighlights();
  }

  ngOnChanges() {
    this.addHighlights();
  }

  addHighlights() {
    this.element.querySelectorAll('.highlightable').forEach((node, index) => {
      node.innerHTML = this.addHighlightSpan(
        this.originalHighlightables[index],
      );
    });
  }

  addHighlightSpan(nodeContent: string) {
    const startIndex = nodeContent.search(new RegExp(`\\b${this.term}`, 'i'));
    let newNodeContent = nodeContent;
    if (startIndex > -1 && this.term.length > 1) {
      newNodeContent =
        nodeContent.substring(0, startIndex) +
        `<span class='highlighted'>${nodeContent.substr(
          startIndex,
          this.term.length,
        )}</span>` +
        nodeContent.substring(
          startIndex + this.term.length,
          nodeContent.length,
        );
    }
    return newNodeContent;
  }
}
