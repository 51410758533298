// @ts-strict-ignore
import { Profile } from '../shared/profile.type';
import * as profileActions from './profile.actions';

export const profileStatePath = 'profile';

export interface ProfileState {
  entity: Profile;
  error: any;
  loading: boolean;
}

export const profileInitialState: ProfileState = {
  entity: null,
  error: null,
  loading: false,
};

export function profileReducer(
  state = profileInitialState,
  action: profileActions.ProfileAction,
): ProfileState {
  switch (action.type) {
    case profileActions.GET_PROFILE: {
      return {
        ...state,
        loading: true,
      };
    }

    case profileActions.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        entity: action.payload,
      };
    }

    case profileActions.GET_PROFILE_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        entity: null,
      };
    }

    default: {
      return { ...state };
    }
  }
}
