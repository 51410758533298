/* eslint-disable */
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HelpFlowTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HelpFlowTypesQuery = { __typename?: 'Query', helpflowTypes: Array<{ __typename?: 'HelpFlow', id: string, displayName: string }> };

export const HelpFlowTypesDocument = gql`
    query HelpFlowTypes {
  helpflowTypes {
    id
    displayName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HelpFlowTypesQueryService extends Apollo.Query<HelpFlowTypesQuery, HelpFlowTypesQueryVariables> {
    document = HelpFlowTypesDocument;
    client = 'onelife';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }