import { NgModule } from '@angular/core';

import { ElasticsearchLocationService } from './elasticsearch-location.service';
import { OpenSearchDataFetcher } from './open-search/open-search-data-fetcher';
import { SearchService } from './search.service';

@NgModule({
  imports: [],
  providers: [ElasticsearchLocationService, SearchService, OpenSearchDataFetcher],
})
export class SearchModule {
  constructor() {}
}
