<div class="padding-half"
     om-layout
     om-layout-gutter
     om-layout-align="space-between center">
  <div>{{text}} suggestion dismissed</div>
  <button omgButton
          variant="flat"
          (click)="giveFeedback.emit()"
          data-cy="ml-suggestion-feedback">
    Give Feedback
  </button>
</div>
