<div class="om-item">
  <div om-layout
       om-layout-gutter
       om-layout-align="space-between center">
    <span [ngClass]="{'critical-message': isDenied }">
      <b>{{ title }}</b> {{ updateMessage }}
    </span>

    <button omgButton
            variant="flat"
            [disabled]="loading$ | ngrxPush"
            (click)="undo()"
            data-cy="rx-renewal-undo-btn">Undo</button>
  </div>
  <div class="banner -error"
       *ngIf="!(isValid$ | ngrxPush)">
    {{ errorMessage }}
  </div>
</div>
