import { NewRxPharmacy } from './pharmacy.type';

export interface PatientPharmacy {
  id: number;
  patientId: number;
  isPreferred: boolean;
  useCount: number;
  lastUsedAt: any;
  customerCode: any;
  pharmacy: NewRxPharmacy;
}

/**
 * UI Types
 */

export interface PharmacyOption {
  id: number;
  name: string;
  displayName: string;
}

export const sendToPrinterOption: PharmacyOption = {
  id: -1,
  name: 'print',
  displayName: 'Send to Printer',
};

export const findAPharmacyOption: PharmacyOption = {
  id: -2,
  name: 'find',
  displayName: 'Find a Pharmacy',
};
