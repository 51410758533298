import { AbstractControl, ValidatorFn } from '@angular/forms';

export const phoneNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value = control.value;
    const nonDigitRegEx = /\D/g;
    const leadingOneRegEx = /^1/g;

    if (!value || control.pristine) {
      return null;
    }

    // Remove any non-numeric characters
    value = value.replace(nonDigitRegEx, '');
    // strip out leading 1 for country code
    value = value.replace(leadingOneRegEx, '');

    return value.length !== 10 ? { faxNumber: value } : null;
  };
};
