/**
 * Custom Error Class which allows you to wrap another error to propagate context and stack traces.
 */
export class WrappedError extends Error {
  constructor(message: string, wrappedError?: Error) {
    let errorMessage = message;
    if (wrappedError) {
      errorMessage = `${errorMessage}. Caused By: ${wrappedError.message}`;
    }
    super(errorMessage);
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
