<ng-container>
  <omg-section-title>
    Patient Information
  </omg-section-title>
  <div class="padding-normal">
    <strong>
      {{patient.firstName}} {{patient.lastName}}
    </strong>
    <div>
      {{patient.gender}}, DOB: {{patient.dateOfBirth | omgDate: '4y'}}
    </div>
    <div *ngIf="patient.primaryAddress">
      {{patient.primaryAddress.street}},
      <span *ngIf="patient.primaryAddress.address2">{{patient.primaryAddress.address2}},</span>
      {{patient.primaryAddress.city}},
      {{patient.primaryAddress.state?.name}}
      {{patient.primaryAddress.zipCode}}
    </div>
    <div *ngIf="patient.contactPhone?.number">
      ph: {{patient.contactPhone.number}}
    </div>
  </div>
  <div class="om-item"
       *ngIf="rxCartMeasurements">
    <div *ngFor="let measurement of rxCartMeasurements">
      {{measurement.name}}: {{measurement.valueWithUnit}} on {{measurement.collectedAt | omgDate: '4y'}}
    </div>
  </div>
  <div class="om-item"
       *ngIf="pediatricVitalsEnabled && (mostRecentVitals$ | ngrxPush) as mostRecentVitals">
    <div *ngFor="let vital of mostRecentVitals">
      {{vital.label}}: {{vital.value}} on {{vital.collectedAt | omgDate: '4y'}}
    </div>
  </div>
</ng-container>
