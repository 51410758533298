import { Component, Input, OnInit } from '@angular/core';

import { Renewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-completed-renewals-handwritten',
  templateUrl: './completed-renewals-handwritten.component.html',
})
export class CompletedRenewalsHandwrittenComponent implements OnInit {
  @Input() renewals: Renewal[] = [];
  countOfHandwrittenPrescriptionsPhrase: string;

  ngOnInit() {
    this.countOfHandwrittenPrescriptionsPhrase = `${
      this.renewals.length
    } renewal request${
      this.renewals.length > 1 ? 's' : ''
    } required a handwritten prescription`;
  }
}
