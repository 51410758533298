import { Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

/**
 * A single use delayed observable to wait for a condition to occur.
 *
 * @note This will unsubscribe automatically
 */
export const waitFor = <T>(
  selector: Observable<T>,
  predicate: (value: T) => boolean,
) =>
  selector.pipe(
    filter(value => predicate(value)),
    take(1),
    map(value => value),
  );

/**
 * Allows an observable completion/error to be forwarded and completed
 * in the future.
 *
 * @note This will unsubscribe automatically
 */
export const proxyWith = <T, E>() => {
  const subject = new Subject<T>();
  const observer = subject.asObservable();

  const complete = (success: T, error?: E) => {
    if (error) {
      subject.error(error);
    } else {
      subject.next(success);
      subject.complete();
    }
  };

  return { observer, complete };
};

export type ProxyWithFunction<T, E> = (success: T, error?: E) => void;
