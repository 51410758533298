import { Component, ContentChild, Input } from '@angular/core';

@Component({
  selector: 'omg-skeleton-repeater',
  templateUrl: './skeleton-repeater.component.html',
  styleUrls: ['./skeleton-repeater.component.scss'],
})
export class SkeletonRepeaterComponent {
  @ContentChild('repeaterTemplate') repeaterTemplate;
  @Input() repeat = 1;

  get repeatArray() {
    return Array(this.repeat);
  }
}
