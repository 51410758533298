import { Dictionary, EntityState } from '@ngrx/entity';

type EntityKey = string | number;

/**
 * Gets all entities in the entity map that match the ids
 *
 * @param state The EntityState<T> slice
 * @param ids An array of id keys
 */
export const pluckEntitiesByIds = <T>(
  state: Dictionary<T>,
  ids: EntityKey[],
): T[] =>
  (ids || []).reduce((accum, id) => {
    const entity = state && state.hasOwnProperty(id) ? state[id] : null;
    return [...accum, ...(entity ? [entity] : [])];
  }, []);

/**
 * Gets an entity in the entity map by id
 *
 * @param state The EntityState<T> slice
 * @param id The id key of the entity
 */
export const selectEntityById = <T>(state: EntityState<T>, id: EntityKey) =>
  state && state.entities[id];

/**
 * Gets all entities in the entity map by a list of ids
 *
 * @param state The EntityState<T> slice
 * @param ids An array of the id gets
 */
export const selectEntitiesByIds = <T>(
  state: EntityState<T>,
  ids: EntityKey[],
): T[] => pluckEntitiesByIds(state && state.entities, ids);

/**
 * Gets all entities in the entity that match a predicate
 *
 * @param state The EntityState<T> slice
 * @param predicate A function that indicates a match
 */
export const selectIdsBy = <T>(
  state: EntityState<T>,
  predicate: (entity: T | undefined) => boolean,
) =>
  (state.ids as EntityKey[]).reduce(
    (accum, id): EntityKey[] => [
      ...accum,
      ...(predicate(state.entities[id]) ? [id] : []),
    ],
    [],
  );

/**
 * Removes an entity key from a list of keys
 *
 * @note This is a useful normalization helper
 *
 * @param id The id to remove
 * @param list The list of ids
 */
export const removeKeyFromList = <T extends EntityKey>(id: T, list: T[]) =>
  list.filter(i => i !== id);
