// @ts-strict-ignore
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

/**
 * Utility component for rendering error messages based on Angular's AbstractControl.
 *
 * It's important to have read & understood the documentation on form validation
 * https://angular.io/guide/form-validation#validating-input-in-reactive-forms
 */
@Component({
  selector: 'omg-control-errors',
  templateUrl: './control-errors.component.html',
})
export class ControlErrorsComponent implements OnChanges, OnDestroy {
  @Input() control: AbstractControl;

  // Provide a function to be called to convert the entries in angular's
  // ValidationError type into human-readable error messages
  @Input() toErrorMessageFn: ErrorMessageFn = defaultErrorMessageFn;

  // if true, will additionally render error messages in all `parent` controls
  // via AbstractControl's `parent()` property
  @Input() includeAncestors = false;

  // an array of AbstractControl paths can be used to include errors from specific
  // child controls
  // `all` will render all children errors
  @Input() includeChildren: 'all' | string[] = [];

  // limits how many error messages are shown
  @Input() maxErrors = 1;

  errors: string[] = [];
  private controlSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges({ control }: SimpleChanges) {
    if (control) {
      this.controlSubscription?.unsubscribe();
      this.controlSubscription = this.control.statusChanges.subscribe(
        status => {
          this.errors = status === 'INVALID' ? this.getErrors() : [];
          this.changeDetectorRef.markForCheck();
        },
      );
    }
  }

  ngOnDestroy() {
    this.controlSubscription?.unsubscribe();
  }

  private getErrors(): string[] {
    const errors = [...this.renderErrorMessages(this.control)];

    if (this.includeAncestors) {
      let parent = this.control.parent;
      while (parent !== null) {
        errors.push(...this.renderErrorMessages(parent));
        parent = parent.parent;
      }
    }

    if (this.includeChildren === 'all') {
      const childControls = [this.control];
      while (childControls.length) {
        const control = childControls.shift();
        if (control instanceof UntypedFormArray) {
          childControls.push(...control.controls);
        } else if (control instanceof UntypedFormGroup) {
          childControls.push(...Object.values(control.controls));
        }

        if (control === this.control) {
          continue;
        } // this.control's errors are already in the array
        errors.push(...this.renderErrorMessages(control));
      }
    } else {
      (this.includeChildren || []).map(path => {
        errors.push(...this.renderErrorMessages(this.control.get(path)));
      });
    }
    return errors;
  }

  private renderErrorMessages(control: AbstractControl): string[] {
    return Object.entries(control.errors || {})
      .map(([errorKey, errorData]) => {
        let msg = this.toErrorMessageFn(errorKey, errorData, control);
        if (msg === undefined) {
          msg = defaultErrorMessageFn(errorKey, errorData);
        }
        return msg;
      })
      .filter(m => !isNil(m));
  }
}

/**
 * Implement this function type to give full control over how error messages
 * are rendered. AbstractControl's return an error object with a key for each
 * error. https://angular.io/guide/form-validation#validating-input-in-reactive-forms
 *
 * e.g. `control.errors = { required: true, min: { value: 0 } }`
 *
 * This method will iterate over each key in this object and expects a human-readable
 * error message to be returned.
 *
 * @param errorKey the name of the error (e.g. `required` and `min` in the example above)
 * @param errorDetails more details for this error (e.g. `true` and `{value: 0}` in the example above)
 * @param control the control that contains the error. This can be useful for
 *                exploring the rest of the control's hierarchy (via `#parent()` and `#controls`)
 * @returns a human-readable error message or `null` if an error should not be displayed
 *          NOTE: returning `undefined` will format the error using `defaultErrorMessageFn`
 *          (doing so makes sure errors aren't hidden in the case of an unhandled errorKey)
 */
export type ErrorMessageFn = (
  errorKey: string | number,
  errorDetails: any,
  control: AbstractControl,
) => string | null | undefined;

/**
 * This is the default error message render strategy. It is intentionally human-readable
 * for devs, but not for end users. An `ErrorMessageFn` tailored to the form you're
 * working with should always be provided before releasing to end users.
 */
const defaultErrorMessageFn = (
  errorKey: string | number,
  errorDetails: any,
): string => {
  return `Invalid (${errorKey}: ${JSON.stringify(errorDetails)})`;
};
