import {
  CancelPrescriber,
  PrescriberCredentials,
} from '../../../modules/shared-rx/prescriber-credential.type';

export interface PrescriptionHistory {
  id: number;
  quantity: number;
  refills: number;
  notesToPharmacist: string;
  dispenseAsWritten: Boolean;
  routeName: string;
  regimenTextDesc: string;
  dispensableTextDesc: string;
  layInstructionsForUse: string;
  sentAt: string;
  sentAs: string;
  cartCompleteAt: string;
  status: string;
  dispensableDosingUnitOfMeasureDesc: string;
  dispensableControlledSubstance: Boolean;
  signedAt: string;
  signedByName: string;
  signedOnBehalfOfName: string;
  medicationPackageSize: { id: number; desc: string; descPlural: string };
  pharmacy: {
    displayName: string;
    addressStreet: string;
    addressCsz: string;
    workPhoneNumber: string;
    faxPhoneNumber: string;
  };
  prescribingCredential: { deaLicenseNumber: string; licenseNumber: string };
  cancelPrescribers?: CancelPrescriber[];
  medicationPrescriptionTransmission: { id: number };
  medicationDescription: string;
  medicationDirections: string;
}

type HistoryTab = {
  id: string;
  heading: string;
};

export const PrescriptionHistoryTabs = {
  history: {
    id: 'History',
    heading: 'Prescription History',
  } as HistoryTab,
  pdmp: {
    id: 'PDMP',
    heading: 'Prescription Drug Monitoring Program (PDMP) Report',
  } as HistoryTab,
};
