import { Injectable } from '@angular/core';

import { HistoricalProcedureCaptureForm } from './historical-procedure-capture-form';

@Injectable({
  providedIn: 'root',
})
export class HistoricalProcedureCaptureFormService {
  constructor() {}

  buildForm() {
    return new HistoricalProcedureCaptureForm();
  }
}
