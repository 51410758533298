import { FilingTemplateResponse } from './recategorize-document-api.type';
import { FilingTemplate } from './recategorize-document.type';

/* istanbul ignore next */
export const mapFilingTemplateResponseToEntity = (
  data: FilingTemplateResponse,
): FilingTemplate => ({
  id: data.id,
  description: data.description,
  system_template: data.system_template,
});
