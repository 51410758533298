import { Injectable } from '@angular/core';

import { ChangeRxActions } from '../store/change-rx.actions';
import { ChangeRxSelectors } from '../store/change-rx.selectors';
import { ChangeRxForm } from './change-rx-form';
import { ChangeRx } from './change-rx.type';

@Injectable()
export class ChangeRxFormService {
  constructor(
    private actions: ChangeRxActions,
    private selectors: ChangeRxSelectors,
  ) {}

  buildForm(changeRx: ChangeRx) {
    return new ChangeRxForm(this.actions, this.selectors, changeRx);
  }
}
