<div class="padding-half-vertical">
  <div omgCollapse
       [expanded]="isCaptureFormExpanded$ | ngrxPush"
       #collapseRef="omgCollapse">
    <omg-expanded [collapseProvider]="collapseRef">
      <omg-procedure-capture-edit [form]="form"
                                  [documentId]="documentId"
                                  [isCaptureGenerated]="isCaptureGenerated$ | ngrxPush"
                                  [isCaptureVerified]="isCaptureVerified$ | ngrxPush"
                                  [isCaptureLoading]="isCaptureLoading$ | ngrxPush"
                                  (collapseForm)="collapseForm()">
      </omg-procedure-capture-edit>
    </omg-expanded>

    <omg-collapsed>
      <omg-procedure-capture-view *ngIf="verifiedProcedureCapture$ | ngrxPush as verifiedProcedureCapture"
                                  [procedureCapture]="verifiedProcedureCapture">
      </omg-procedure-capture-view>
    </omg-collapsed>
  </div>
</div>
