import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  RenewalCommentsState,
  renewalCommentsStatePath,
  selectAll,
  selectEntities,
} from './renewal-comments.reducer';

export const selectRenewalCommentsState = createFeatureSelector<
  RenewalCommentsState
>(renewalCommentsStatePath);

export const selectRenewalComments = createSelector(
  selectRenewalCommentsState,
  selectEntities,
);

export const selectAllRenewalComments = createSelector(
  selectRenewalCommentsState,
  selectAll,
);

export const selectRenewalCommentsError = createSelector(
  selectRenewalCommentsState,
  state => state.error,
);

export const selectRenewalCommentsPending = createSelector(
  selectRenewalCommentsState,
  state => state.pending,
);
