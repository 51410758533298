<omg-collapse [expanded]="!textIsCollapsed"
              [disabled]="!textIsCollapsed">
  <omg-collapse-toggle om-layout
                       om-layout-align="space-between"
                       class="padding-normal-vertical">
    <span om-layout
          om-layout-align="start center"
          om-layout-gutter>
      <omg-profile-image-bubble [url]="postedBy.profileImageUrl"
                                [initials]="postedBy.initials"
                                [colorCombo]="postedBy.id">
      </omg-profile-image-bubble>
      <b om-flex>{{postedBy.name}}</b>
      <span *ngIf="postedBy.preferredRole">({{postedBy.preferredRole}})</span>
    </span>
    <span>{{updatedAt | omgDate:'withTime2y'}}</span>
  </omg-collapse-toggle>
  <div omgExpanded>
    <div class="marked"
         markdown
         ngPreserveWhitespaces
         [data]="parsedMarkdownText"></div>
    <div class="padding-half-vertical"
         om-layout="vertical"
         om-layout-gutter>
      <ng-container *ngFor="let attachment of s3Pointers">
        <omg-file-link [bucket]="attachment.bucket"
                       [key]="attachment.key"
                       [text]="attachment.title">
        </omg-file-link>
      </ng-container>
    </div>
  </div>
  <div omgCollapsed
       omgCollapseToggle
       om-fade-out>
    <div class="marked"
         markdown
         ngPreserveWhitespaces
         [data]="parsedMarkdownText"></div>
  </div>
</omg-collapse>
