<ng-container>
  <div class="label">Destination</div>
  <div *ngIf="patientPharmacy && patientPharmacy.pharmacy">
    <strong>{{ patientPharmacy.pharmacy.displayName }}</strong>
    <div *ngIf="!isPrint">
      <div *ngIf="patientPharmacy.pharmacy.address && patientPharmacy.pharmacy.address.displayAddressStreet !== '&quot;Unknown&quot;'">
        {{ patientPharmacy.pharmacy.address.displayAddressStreet }}
      </div>
      <div *ngIf="patientPharmacy.pharmacy.address">
        {{patientPharmacy.pharmacy.address.city}}, {{patientPharmacy.pharmacy.address.state}}
        <span *ngIf="patientPharmacy.pharmacy.address.zip !== '00000'">
          {{ patientPharmacy.pharmacy.address.zip }}
        </span>
      </div>
      <div *ngIf="patientPharmacy.pharmacy.workPhoneNumber && patientPharmacy.pharmacy.workPhoneNumber.number">
        ph: {{ patientPharmacy.pharmacy.workPhoneNumber.number | omgPhoneNumber }}
      </div>
      <span *ngIf="!patientPharmacy.pharmacy.address
              && !patientPharmacy.pharmacy.isMailOrder">
        No address on file
      </span>
      <span *ngIf="patientPharmacy.pharmacy.isMailOrder && patientPharmacy.customerCode">
        Mail Order, Customer ID: {{ patientPharmacy.customerCode }}
      </span>
    </div>
  </div>
</ng-container>
