import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ChangeRxCommentsState,
  changeRxCommentsStatePath,
  selectAll,
  selectEntities,
} from './change-rx-comments.reducer';

export const selectChangeRxCommentsState = createFeatureSelector<
  ChangeRxCommentsState
>(changeRxCommentsStatePath);

export const selectChangeRxComments = createSelector(
  selectChangeRxCommentsState,
  selectEntities,
);

export const selectAllChangeRxComments = createSelector(
  selectChangeRxCommentsState,
  selectAll,
);

export const selectChangeRxCommentsError = createSelector(
  selectChangeRxCommentsState,
  state => state.error,
);

export const selectChangeRxCommentsPending = createSelector(
  selectChangeRxCommentsState,
  state => state.pending,
);
