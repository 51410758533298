import { CM_PER_INCH } from './constants';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Set up arrays for length, weight, and head circ LMS params
const maleLengthLMSParams: LMSParamsMap = {
  0: { lambda: 1, mu: 49.8842, sigma: 0.03795 },
  1: { lambda: 1, mu: 54.7244, sigma: 0.03557 },
  2: { lambda: 1, mu: 58.4249, sigma: 0.03424 },
  3: { lambda: 1, mu: 61.4292, sigma: 0.03328 },
  4: { lambda: 1, mu: 63.886, sigma: 0.03257 },
  5: { lambda: 1, mu: 65.9026, sigma: 0.03204 },
  6: { lambda: 1, mu: 67.6236, sigma: 0.03165 },
  7: { lambda: 1, mu: 69.1645, sigma: 0.03139 },
  8: { lambda: 1, mu: 70.5994, sigma: 0.03124 },
  9: { lambda: 1, mu: 71.9687, sigma: 0.03117 },
  10: { lambda: 1, mu: 73.2812, sigma: 0.03118 },
  11: { lambda: 1, mu: 74.5388, sigma: 0.03125 },
  12: { lambda: 1, mu: 75.7488, sigma: 0.03137 },
  13: { lambda: 1, mu: 76.9186, sigma: 0.03154 },
  14: { lambda: 1, mu: 78.0497, sigma: 0.03174 },
  15: { lambda: 1, mu: 79.1458, sigma: 0.03197 },
  16: { lambda: 1, mu: 80.2113, sigma: 0.03222 },
  17: { lambda: 1, mu: 81.2487, sigma: 0.0325 },
  18: { lambda: 1, mu: 82.2587, sigma: 0.03279 },
  19: { lambda: 1, mu: 83.2418, sigma: 0.0331 },
  20: { lambda: 1, mu: 84.1996, sigma: 0.03342 },
  21: { lambda: 1, mu: 85.1348, sigma: 0.03376 },
  22: { lambda: 1, mu: 86.0477, sigma: 0.0341 },
  23: { lambda: 1, mu: 86.941, sigma: 0.03445 },
  24: { lambda: 1, mu: 87.8161, sigma: 0.03479 },
};

const femaleLengthLMSParams: LMSParamsMap = {
  0: { lambda: 1, mu: 49.1477, sigma: 0.0379 },
  1: { lambda: 1, mu: 53.6872, sigma: 0.0364 },
  2: { lambda: 1, mu: 57.0673, sigma: 0.03568 },
  3: { lambda: 1, mu: 59.8029, sigma: 0.0352 },
  4: { lambda: 1, mu: 62.0899, sigma: 0.03486 },
  5: { lambda: 1, mu: 64.0301, sigma: 0.03463 },
  6: { lambda: 1, mu: 65.7311, sigma: 0.03448 },
  7: { lambda: 1, mu: 67.2873, sigma: 0.03441 },
  8: { lambda: 1, mu: 68.7498, sigma: 0.0344 },
  9: { lambda: 1, mu: 70.1435, sigma: 0.03444 },
  10: { lambda: 1, mu: 71.4818, sigma: 0.03452 },
  11: { lambda: 1, mu: 72.771, sigma: 0.03464 },
  12: { lambda: 1, mu: 74.015, sigma: 0.03479 },
  13: { lambda: 1, mu: 75.2176, sigma: 0.03496 },
  14: { lambda: 1, mu: 76.3817, sigma: 0.03514 },
  15: { lambda: 1, mu: 77.5099, sigma: 0.03534 },
  16: { lambda: 1, mu: 78.6055, sigma: 0.03555 },
  17: { lambda: 1, mu: 79.671, sigma: 0.03576 },
  18: { lambda: 1, mu: 80.7079, sigma: 0.03598 },
  19: { lambda: 1, mu: 81.7182, sigma: 0.0362 },
  20: { lambda: 1, mu: 82.7036, sigma: 0.03643 },
  21: { lambda: 1, mu: 83.6654, sigma: 0.03666 },
  22: { lambda: 1, mu: 84.604, sigma: 0.03688 },
  23: { lambda: 1, mu: 85.5202, sigma: 0.03711 },
  24: { lambda: 1, mu: 86.4153, sigma: 0.03734 },
};

export const getInfLenPercentile = (
  gender: string,
  ageInMonths: number,
  length: number,
) => {
  const lengthInCm = length * CM_PER_INCH;
  const lengthLMSDataTable =
    gender === 'Female' ? femaleLengthLMSParams : maleLengthLMSParams;

  return zScoreFromMeasurement(lengthInCm, ageInMonths, lengthLMSDataTable);
};
