import { createReducer, on } from '@ngrx/store';

import { addInternalUsers } from './internal-users.actions';

export const internalUsersStatePath = 'internalUsers';

export const internalUsersReducer = createReducer(
  {},
  on(addInternalUsers, (state, action) => {
    const newState = { ...state };
    Object.keys(action.internalUsers).forEach(
      id => (newState[id] = { ...action.internalUsers[id] }),
    );
    return newState;
  }),
);
