import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { take } from 'rxjs/operators';

export interface AutoCompleteSearchQuery {
  term: string;
  items?: any[];
}

@Component({
  selector: 'omg-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutoCompleteComponent),
    },
  ],
})
export class AutoCompleteComponent
  implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() items: any[] | null;
  @Input() placeholder = 'Select items';
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() customItem = false;
  @Input() clearable = true;
  @Input() resetItemsOnClear = true;
  @Input() addItemText = 'Add custom item';
  @Input() optionHighlight: string;
  @Input() trackByKey: string;
  @Input() hideInput: boolean;
  @Input() autoWidth: boolean;
  @Input() dropdownPosition = 'auto';
  @Input() fillLayout = true;
  @Input() highlightMatch = true;
  @Input() hideDropdownArrow = false;
  @Input() hideClearAll = false;
  @Input() searchFn: any;
  @Input() searchable = true;
  @Input() multiple = false;
  @Input() keepDropdownOpenOnSearchTermDelete = false;
  @Input() selectOnTab: boolean;
  @Input() useVirtualScroll: false;

  @ContentChild(TemplateRef)
  customDropdownTemplate: TemplateRef<any>;

  @ContentChild('labelTemplate')
  labelTemplate: TemplateRef<any>;

  @ContentChild('noResultsTemplate')
  noResultsTemplate: TemplateRef<any>;

  @ContentChild('customMultiLabelTemplate')
  customMultiLabelTemplate: TemplateRef<any>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('om-flex') flexValue: number | null = null;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() changeAutocomplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() blurAutocomplete: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgSelectComponent, { static: true })
  private ref: NgSelectComponent;

  initialItems: any[] | null;
  loading = false;
  disableNextOnChange = false;

  // This is a workaround to use the ng-select in a flexbox
  get inlineStyle() {
    const inlineStyle = {} as CSSStyleDeclaration;

    if (this.flexValue) {
      inlineStyle.width = `${this.flexValue}%`;
    }

    return inlineStyle;
  }

  ngOnInit() {
    this.checkAutoWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disableNextOnChange) {
      this.disableNextOnChange = false;
      return;
    }

    if (
      changes.items?.previousValue === null &&
      changes.items?.currentValue !== null
    ) {
      this.openOnStable();
    }
  }

  private openOnStable() {
    // since the dropdown items can change with an rxjs subscription while angular is conducting a view lifecycle,
    // we can use the stable zone event to open the dropdown and avoid ExpressionChangedAfterItHasBeenCheckedError
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.open();
    });
  }

  toggle() {
    this.ref.toggle();
  }

  open() {
    this.ref.open();
  }

  close() {
    this.ref.close();
  }

  trackByFn = (index, item: any) => item[this.trackByKey] || index;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.initialItems = this.items;
  }

  /* istanbul ignore next */
  onOpen(event) {
    this.opened.emit();

    this.ngZone.runOutsideAngular(() =>
      window.addEventListener('scroll', this.closeDropdown.bind(this), true),
    );
  }

  /* istanbul ignore next */
  onClose(event) {
    this.closed.emit();

    this.ngZone.runOutsideAngular(() =>
      window.removeEventListener('scroll', this.closeDropdown.bind(this), true),
    );
  }

  focus() {
    this.ref.focus();
  }

  registerOnChange(fn: any) {
    this.ref.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.ref.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.ref.setDisabledState(isDisabled);
  }

  writeValue(obj: any) {
    // This timeout pushes the value write into the next change detection cycle,
    // which allows the @Input values to bind to the `ng-select` component first.
    setTimeout(() => this.ref.writeValue(obj));
  }

  onSearch(query: AutoCompleteSearchQuery) {
    if (!!query) {
      this.open();
      this.search.emit(query.term);
    } else {
      if (this.resetItemsOnClear) {
        this.resetItems(this.initialItems);
      }
      if (!this.keepDropdownOpenOnSearchTermDelete) {
        this.close();
      }
    }
  }

  onFocus() {
    this.selectFocus.emit();
  }

  onBlur(text: string) {
    this.blurAutocomplete.emit(text);
  }

  resetItems(items?: any[] | null) {
    this.items = items ? items : null;
  }

  private checkAutoWidth() {
    const autoWidthClassName = 'auto-width';
    if (this.autoWidth) {
      if (this.ref.classes.indexOf(autoWidthClassName) === -1) {
        this.ref.classes = `${this.ref.classes} ${autoWidthClassName}`;
      } else {
        this.ref.classes = this.ref.classes;
      }
    }
  }

  /* istanbul ignore next */
  private closeDropdown(event) {
    if (this.ref && this.ref.isOpen) {
      this.ref.dropdownPanel.adjustPosition();
    }
  }
}
