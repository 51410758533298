import { NgModule } from '@angular/core';

import { ChartVariablesService } from './chart-variables.service';
import { PrivacyConsentStorageService } from './privacy-consent-storage.service';
import { StateValidator } from './state-validator.service';

@NgModule({
  providers: [
    ChartVariablesService,
    PrivacyConsentStorageService,
    StateValidator,
  ],
})
export class ServicesModule {}
