import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplitButtonStatesService {
  private splitButtonClickedSubject = new BehaviorSubject('');

  splitButtonClicked = this.splitButtonClickedSubject.asObservable();

  /**
   * uniqueClassName comes from the nativeElement that is rendered by Angular.
   * There seems to be a unique tag set by Angular on classNames, perhaps
   * to help in CSS scope encapsulation, so I hooked into the className
   * to determine whether it was the same splitButton clicked or not.
   *
   * e.g:
   *   ng-tns-c41-30 ng-star-inserted
   *   ng-tns-c41-32 ng-star-inserted
   *
   * Though I clicked two split buttons using the same component, the
   * classNames were different, and if I clicked the same split button twice
   * then the class name would be the same.
   *
   * The split button component listens to these unique identifiers everytime
   * a button is clicked and if it's not its own button clicked then it will
   * trigger a close, else, if it is the same identifier, there is already
   * logic within the component to close the dropdown and no additional
   * logic is needed.
   */
  registerSplitButtonClick(uniqueClassName: string) {
    this.splitButtonClickedSubject.next(uniqueClassName);
  }
}
