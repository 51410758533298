import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LabData } from '../../shared/lab-data.type';

@Component({
  selector: 'omg-lab-result-header',
  templateUrl: './lab-result-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabResultHeaderComponent {
  @Input() labData: LabData;
}
