<div om-layout
     om-layout-gutter
     om-layout-fill
     om-layout-align="space-between"
     class="list-item"
     (click)="routeToDetail()">
  <span om-flex>{{ renewal.medForDisplay?.name}} {{renewal.medForDisplay?.dispensableTextDesc}}</span>
  <span om-flex>{{ renewal.task?.assignee.name}}</span>
  <span om-flex>
    {{ renewal.requestedBy}} - {{ renewal.createdAt | omgDateAgo}}
  </span>
</div>
