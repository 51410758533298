import { Component, Input, OnInit } from '@angular/core';

import {
  OriginalRx,
  OriginalRxDispensedMedForDisplay,
  OriginalRxPatient,
  OriginalRxPharmacy,
  OriginalRxPrescribedMedForDisplay,
  OriginalRxPrescriber,
} from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-item-original-rx',
  templateUrl: './renewal-item-original-rx.component.html',
  styleUrls: ['./renewal-item-original-rx.component.scss'],
})
export class RenewalItemOriginalRxComponent implements OnInit {
  @Input() originalRx: OriginalRx;
  @Input() requestedByPatient: boolean;
  pharmacy: OriginalRxPharmacy;
  patient: OriginalRxPatient;
  prescriber: OriginalRxPrescriber;
  prescribedMedication: OriginalRxPrescribedMedForDisplay;
  dispensedMedication: OriginalRxDispensedMedForDisplay;

  constructor() {}

  ngOnInit() {
    const {
      pharmacy,
      patient,
      prescriber,
      prescribedMedForDisplay,
      dispensedMedForDisplay,
    } = this.originalRx;
    this.pharmacy = pharmacy;
    this.patient = patient;
    this.prescriber = prescriber;
    this.prescribedMedication = prescribedMedForDisplay;
    this.dispensedMedication = dispensedMedForDisplay;
  }
}
