import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SummaryAddendum } from '../../shared/summaries.type';

@Component({
  selector: 'omg-summary-addenda',
  templateUrl: './summary-addenda.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryAddendaComponent {
  @Input() addenda: SummaryAddendum[];

  trackByFn = (index, addendum: SummaryAddendum) => addendum.id || index;
}
