// @ts-strict-ignore
import { Validators } from '@angular/forms';
import { pad } from 'lodash';

import {
  DosingUnitOfMeasureDescription,
  MedicationPrescriptionTemplate,
} from '@app/modules/medications/shared';

export const validDosagePattern = /(^[1-9]+[0-9]*(-[0-9]+)?)$|(^[0-9]*((\.[0-9]+)$)|([1-9][0-9]*\/[1-9][0-9]*))/;

export const validDosageValidators = [
  Validators.required,
  Validators.minLength(1),
  Validators.pattern(validDosagePattern),
];

export const dosageDelimiter = {
  dash: '-',
  fractional: '/',
};
export const nullRegimenTextDescriptionPlaceholder = `${dosageDelimiter.dash}${dosageDelimiter.dash}`;

export const doseIsRange = (dose: any) => {
  const rangePattern = /\d+\s*-\s*\d+/;
  return rangePattern.test(`${dose}`);
};

export const doseIsFractional = dose => {
  const fractionPattern = /\d+\/\d+/;
  return fractionPattern.test(`${dose}`);
};

export const displayedDose = (dose: any) => {
  let dosageString = `${dose}`;
  if (doseIsRange(dose)) {
    dosageString = dosageString.replace('-', pad('-', 3, ' '));
  } else if (doseIsFractional(dose)) {
    const nums = dosageString.split('/');
    dosageString = (parseInt(nums[0], 0) / parseInt(nums[1], 0)).toFixed(2);
  }
  return dosageString;
};

export const displayedDosingUnitOfMeasure = (
  dose: any,
  unitOfMeasureDescription: DosingUnitOfMeasureDescription,
): string => {
  if (
    doseIsRange(dose) ||
    (parseFloat(displayedDose(dose)) > 1 && unitOfMeasureDescription.descPlural)
  ) {
    return unitOfMeasureDescription.descPlural;
  } else if (unitOfMeasureDescription.desc) {
    return unitOfMeasureDescription.desc;
  }
};

export const formatDosage = (
  medicationPrescriptionTemplate: MedicationPrescriptionTemplate,
) => {
  const { medicationPrescriptionItemTemplate } = medicationPrescriptionTemplate;

  if (medicationPrescriptionItemTemplate.doseHigh) {
    return `${parseFloat(
      medicationPrescriptionItemTemplate.doseLow,
    )}-${parseFloat(medicationPrescriptionItemTemplate.doseHigh)}`;
  } else if (medicationPrescriptionItemTemplate.doseLow) {
    return parseFloat(medicationPrescriptionItemTemplate.doseLow);
  }
};

export const parseDosage = (dose: string) => {
  let doseLow, doseHigh = null;
  if (doseIsRange(dose)) {
    const nums = dose.split(dosageDelimiter.dash);
    return { doseLow: nums[0], doseHigh: nums[1] };
  } else if (doseIsFractional(dose)) {
    doseLow = `${calculateFraction(dose)}`;
  } else {
    doseLow = `${dose}`
  }
  return { doseLow, doseHigh };
};

const calculateFraction = (fraction: string) => {
  const [numerator, denominator] = fraction.split('/');
  return (parseInt(numerator) / parseInt(denominator)).toFixed(2);
}
