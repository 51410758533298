// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';

import {
  ReferenceDataKeys,
  ReferenceDataSelectors,
} from '@app/modules/reference-data';

import { Todo, TodoLicensingBody } from '../../shared/todo.type';
import { TodoActions } from '../../store/todo.actions';

export interface TodoLicensingBodyDropDownItem {
  label: string;
  value: number;
  name: string;
  key: number;
}

@Component({
  selector: 'omg-task-licensing-body',
  templateUrl: './task-licensing-body.component.html',
  styleUrls: ['./task-licensing-body.component.scss'],
})
export class TaskLicensingBodyComponent implements OnInit, OnDestroy {
  @Input() todo: Observable<Todo>;
  form: UntypedFormGroup = new UntypedFormGroup({
    licensingBodyId: new UntypedFormControl(),
  });
  licensingBodies: TodoLicensingBodyDropDownItem[];
  licensingBodyPlaceholder: string = 'Select a location';

  currentTodo: Todo;
  private unsubscribe$ = new Subject<void>();

  @Output() todoLicensingBodyUpdate = new EventEmitter<number>();

  constructor(
    private todoActions: TodoActions,
    private referenceDataSelectors: ReferenceDataSelectors,
  ) {}

  ngOnInit(): void {
    this.loadLicensingBodies();
    this.setUp();
    this.watchLicensingBody();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapTodoLicensingBodyItem(
    licensingBody: TodoLicensingBody,
  ): TodoLicensingBodyDropDownItem {
    const { id, name, shortName } = licensingBody;
    return {
      key: Number(id),
      label: `${name} (${shortName})`,
      value: Number(id),
      name,
    };
  }

  private loadLicensingBodies(): void {
    this.referenceDataSelectors
      .get(ReferenceDataKeys.licensingBodies)
      .pipe(
        map(
          (data: TodoLicensingBody[]) =>
            (this.licensingBodies = data.map(
              (licensingBody: TodoLicensingBody) => {
                return this.mapTodoLicensingBodyItem(licensingBody);
              },
            )),
        ),
      )
      .subscribe();
  }

  private setUp(): void {
    this.todo
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(todo => {
          this.currentTodo = todo;
          this.form.get('licensingBodyId').patchValue(todo.licensingBody.id);
        }),
      )
      .subscribe();
  }

  private watchLicensingBody(): void {
    this.form
      .get('licensingBodyId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.updateLicensingBody(value);
        this.todoLicensingBodyUpdate.emit(value);
      });
  }

  private updateLicensingBody(value: TodoLicensingBodyDropDownItem['value']) {
    if (this.currentTodo.licensingBody.id !== value) {
      this.todoActions.updateTodo({
        ...this.currentTodo,
        licensingBodyIdOverride: value,
      });
    }
  }
}
