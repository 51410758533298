<div *ngIf="procedureOrder$ | ngrxPush as procedureOrder"
     om-layout
     om-layout-gutter>
  <div class="procedure-suggestion-form-container padding-normal"
       om-layout-fill>
    <div om-layout
         om-layout-gutter
         om-layout-align="start center"
         om-flex
         class="record-procedure-suggestion-header">
      <i class="om-icon icon-magic"></i>
      <strong>Record a performed procedure or service</strong>
      <button omgButton
              type="button"
              variant="none"
              class="clickable om-icon icon-close dimiss-button"
              (click)="onDismiss()">
      </button>
    </div>
    <div class="padding-normal-vertical">
      <div class="record-procedure-suggestion-body-header">Indication</div>
      <div>{{ procedureOrder.indications[0].clinicalDescription }} <span
              class="record-procedure-code">{{ procedureOrder.indications[0].code }}</span></div>
    </div>
    <div>
      <div class="record-procedure-suggestion-body-header">Procedure or Service</div>
      <div>{{ procedureOrder.procedureType.clinicalDescription }} <span
              class="record-procedure-code">{{ procedureOrder.procedureType.codes }}</span>
      </div>
    </div>
    <div class="padding-normal-vertical">
      <textarea omgTextarea
                [formControl]="form.controls.get('results')"
                om-layout-fill></textarea>
    </div>
    <div om-flex
         om-layout-gutter>
      <button type="submit"
              omgButton
              (click)="saveProcedureOrder()"
              variant="primary">Save</button>
      <button class="om-button clickable record-procedure-suggestion-discard-changes"
              (click)="deleteProcedureOrder()">
        Discard Changes</button>
    </div>
  </div>
</div>
