import { NgModule } from '@angular/core';
import { GMapModule } from 'primeng/gmap';

import { GoogleMapsComponent } from './google-maps.component';

const declarations = [GoogleMapsComponent];

@NgModule({
  imports: [GMapModule],
  declarations: [...declarations],
  exports: [GMapModule, ...declarations],
})
export class GoogleMapsModule {}
