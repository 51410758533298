<omg-expanded [collapseProvider]="collapseProvider">
  <div om-layout="vertical"
       class="padding-normal-vertical"
       om-layout-gutter>
    <div om-layout
         om-layout-align="space-between start"
         om-layout-gutter>
      <omg-checkbox [formControl]="dispenseAsWritten"
                    label="No Substitutions"
                    data-cy="rx-cart-item-no-substitutions-checkbox"></omg-checkbox>
      <omg-cart-item-fill-after-date om-flex="50"
                                     *ngIf="fillAfterDateVisible"
                                     [fillAfter]="fillAfterDate"
                                     [inputName]="fillAfterInputName"
                                     [maxEarliestFillDate]="maxEarliestFillDate"
                                     [minEarliestFillDate]="minEarliestFillDate"
                                     [isRequired]="fillAfterDateRequired"></omg-cart-item-fill-after-date>
    </div>
    <omg-note-to-recipient [maxLength]="maxNotesLength"
                           [placeholder]="notesToPharmacistPlaceholder"
                           [note]="notesToPharmacist"
                           [addenda]="notesAddenda"
                           recipient="Pharmacist"
                           data-cy="rx-cart-item-note-to-pharmacist"></omg-note-to-recipient>
  </div>
</omg-expanded>
