import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import { deleteCommentReducer } from './comments.reducer.utils';
import {
  OrderCommentsAction,
  OrderCommentsActionTypes,
} from './order-comments.actions';

export const orderCommentsStatePath = 'orderComments';

export interface OrderCommentsState extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

export function selectOrderCommentsId(comments: Comment): number {
  return comments.id;
}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectOrderCommentsId,
});

export const orderCommentsInitialState: OrderCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function orderCommentsReducer(
  state = orderCommentsInitialState,
  action: OrderCommentsAction,
): OrderCommentsState {
  switch (action.type) {
    case OrderCommentsActionTypes.GetOrderComments: {
      return { ...state, pending: true };
    }

    case OrderCommentsActionTypes.GetOrderCommentsSuccess: {
      state = { ...state, error: null, pending: false };
      return adapter.setAll(action.payload, state);
    }

    case OrderCommentsActionTypes.GetOrderCommentsError: {
      return { ...state, error: action.payload, pending: false };
    }

    case OrderCommentsActionTypes.CreateOrderComment: {
      return { ...state, error: null, pending: true };
    }

    case OrderCommentsActionTypes.CreateOrderCommentSuccess: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case OrderCommentsActionTypes.CreateOrderCommentError: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, <any>action, adapter);
    }
  }
}
