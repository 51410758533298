// @ts-strict-ignore
import { camelCase } from '@app/utils';

import {
  mapFormToMedicationPrn,
  mapFormToMedicationRegimen,
  mapMedicationRegimenToEntity,
} from './medications-api-mappers';
import { MedicationRouteResponse } from './medications-api.type';
import { MedicationRoute } from './medications.type';
import {
  GetMedsRecNotificationResponse,
  MedicationsReconciliationResponse,
  PatientMedicationPrescriptionHistoryItemResponse,
  PatientMedicationRegimenResponse,
  PatientMedicationResponse,
  PrescriptionChangeItemResponse,
  PrescriptionHistoryCartSourceResponse,
  PrescriptionHistoryMedForDisplayResponse,
  PrescriptionHistoryPharmacyAddressResponse,
  PrescriptionHistoryPharmacyResponse,
  SureScriptsServiceLevelsItemResponse,
} from './patient-medications-api.type';
import {
  MedicationsReconciliationEvent,
  MedsRecNotification,
  PatientMedication,
  PatientMedicationForm,
  PatientMedicationPrescriptionHistoryItem,
  PatientMedicationRegimen,
  PrescriptionChangeItem,
} from './patient-medications.type';

// istanbul ignore next
export const mapPatientMedicationRegimenToEntity = (
  response: PatientMedicationRegimenResponse,
): PatientMedicationRegimen => ({
  id: response.id,
  startedAt: response.started_at,
  endedAt: response.ended_at,
  dispensableDeaCode: response.dispensable_dea_code,
  discontinuesAt: response.discontinues_at,
  isPrn: response.is_prn,
  createdBy: response.created_by,
  medicationRegimen: response.medication_regimen
    ? mapMedicationRegimenToEntity(response.medication_regimen)
    : null,
});

// istanbul ignore next
const mapRouteSummary = (route: MedicationRouteResponse): MedicationRoute => {
  const {
    id,
    is_active: isActive,
    is_custom: isCustom,
    is_generic: isGeneric,
    legacy,
    name,
  } = route;

  return {
    id,
    isActive,
    isCustom,
    isGeneric,
    legacy,
    name,
  };
};

// istanbul ignore next
const mapToPatientMedicationResponse = (
  item: PatientMedicationResponse,
): Partial<PatientMedicationResponse> => {
  if (!item.prescription_history) {
    /*
      null value is used to check if prescriptionHistory is still loading as opposed
      to using an empty array which could mean an unloaded item or a loaded item that's
      empty.
    */
    item.prescription_history = null;
  }
  return item;
};

/* istanbul ignore next */
export const mapPatientMedicationResponseToEntity = (
  response: PatientMedicationResponse,
): PatientMedication => {
  const mappedItem = mapToPatientMedicationResponse(response);
  const latestPatientMedicationRegimen = mapPatientMedicationRegimenToEntity(
    response.latest_patient_medication_regimen,
  );
  const latestRegimen = mapMedicationRegimenToEntity(response.latest_regimen);
  const route = mapRouteSummary(response.route);

  const mappedMedication = {
    active: response.active,
    cartComplete: response.cart_complete,
    cartCompleteAt: response.cart_complete_at,
    displayDate: mappedItem.display_date,
    hasActiveTemplate: response.has_active_template,
    id: response.id,
    isActive: response.is_active,
    isDeleted: response.is_deleted,
    isPrescribable: response.is_prescribable,
    isResumable: response.is_resumable,
    latestPatientMedicationRegimen,
    latestRegimen,
    medicationRouteId: response.medication_route_id,
    name: response.name,
    prescriptionHistory: mappedItem.prescription_history,
    regimen: response.regimen,
    route,
  };

  return mappedMedication;
};

/* istanbul ignore next */
export const mapPatienMedicationsResponseToEntities = (
  response: PatientMedicationResponse[],
): PatientMedication[] => response.map(mapPatientMedicationResponseToEntity);

/**
 * Prescription History
 */

const mapPrescriptionHistoryMedForDisplayToEntity = (
  response: PrescriptionHistoryMedForDisplayResponse,
) => ({
  name: response.name,
  dispensableTextDesc: response.dispensable_text_desc,
  instructions: response.instructions,
  quantityDescription: response.quantity_description,
  refills: response.refills,
  actionDescriptor: response.action_descriptor,
  denialReason: response.denial_reason,
  description: response.description,
});

const mapPrescriptionHistoryPharmacyAddressResponseToEntity = (
  response: PrescriptionHistoryPharmacyAddressResponse,
) => ({
  address1: response.address1,
  address2: response.address2,
  city: response.city,
  state: response.state,
  zip: response.zip,
  latitude: response.latitude,
  longitude: response.longitude,
  displayAddressStreet: response.display_address_street,
});

const mapPrescriptionHistoryPharmacySsServiceLevelsToEntity = (
  response: SureScriptsServiceLevelsItemResponse,
) => ({
  keyword: response.keyword,
  description: response.description,
});

const mapPrescriptionHistoryPharmacyResponseToEntity = (
  response: PrescriptionHistoryPharmacyResponse,
) => ({
  id: response.id,
  name: response.name,
  displayName: response.display_name,
  isMailOrder: response.is_mail_order,
  isCustom: response.is_custom,
  isSurescripts: response.is_surescripts,
  labels: response.labels,
  workPhoneNumber: response.work_phone_number,
  faxPhoneNumber: response.fax_phone_number,
  ssServiceLevels: response.ss_service_levels
    ? response.ss_service_levels.map(item =>
        mapPrescriptionHistoryPharmacySsServiceLevelsToEntity(item),
      )
    : null,
  address: response.address
    ? mapPrescriptionHistoryPharmacyAddressResponseToEntity(response.address)
    : null,
});

const mapPrescriptionHistoryCartSourceToEntity = (
  response: PrescriptionHistoryCartSourceResponse,
) => ({
  signedByName: response.signed_by_name,
  signedOnBehalfOfId: response.signed_on_behalf_of_id,
  signedOnBehalfOfName: response.signed_on_behalf_of_name,
  signedOnBehalfOfNpi: response.signed_on_behalf_of_npi,
  signedOnBehalfOfPrescribingCredential: {
    deaLicenseNumber:
      response.signed_on_behalf_of_prescribing_credential &&
      response.signed_on_behalf_of_prescribing_credential.dea_license_number,
    licenseNumber:
      response.signed_on_behalf_of_prescribing_credential &&
      response.signed_on_behalf_of_prescribing_credential.license_number,
  },
});

const mapPrescriptionHistoryToEntity = (
  response: PatientMedicationPrescriptionHistoryItemResponse,
): PatientMedicationPrescriptionHistoryItem => ({
  id: response.id,
  routeName: response.route_name,
  createdAt: response.created_at,
  dispenseAsWritten: response.dispense_as_written,
  dispensableDeaCode: response.dispensable_dea_code,
  notesToPharmacist: response.notes_to_pharmacist,
  earliestFillDate: response.earliest_fill_date,
  status: response.status,
  sentAt: response.sent_at,
  sentAs: response.sent_as,
  updatedAt: response.updated_at,
  cartCompleteAt: response.cart_complete_at,
  isCancellable: response.is_cancellable,
  signedById: response.signed_by_id,
  signedOnBehalfOfId: response.signed_on_behalf_of_id,
  hasDenialReason: !!response.med_for_display.denial_reason,
  pharmacy: response.pharmacy
    ? mapPrescriptionHistoryPharmacyResponseToEntity(response.pharmacy)
    : null,
  pharmacyDescriptor: response.pharmacy_descriptor,
  providerReason: response.provider_reason,
  cartSource: response.cart_source
    ? mapPrescriptionHistoryCartSourceToEntity(response.cart_source)
    : null,
  rxCartMeasurements: response.rx_cart_measurements
    ? response.rx_cart_measurements.map(camelCase)
    : null,
  medForDisplay: response.med_for_display
    ? mapPrescriptionHistoryMedForDisplayToEntity(response.med_for_display)
    : null,
  originallyPrescribedAt: response.originally_prescribed_at,
  resolvedAt: response.resolved_at,
  resolvedStatus: response.resolved_status,
  isCancel: response.event_type === 'rx_cancel',
  rxCancel: response.rx_cancel
    ? mapPrescriptionHistoryToEntity(response.rx_cancel)
    : null,
  isChange: response.event_type === 'rx_change',
  rxChanges: response.rx_changes
    ? mapPrescriptionHistoryResponseToEntities(response.rx_changes)
    : null,
  responseNote: response.note,
  responseReason: response.reason,
  requestType: response.request_type,
  originalPrescriptionDescriptor: response.original_prescription_descriptor,
});

export const mapPrescriptionHistoryResponseToEntities = (
  response: PrescriptionChangeItemResponse[],
): PrescriptionChangeItem[] =>
  response.map((prescriptionChangeItem: PrescriptionChangeItemResponse) => {
    const rxChangeRequestPriorAuthorization = camelCase(prescriptionChangeItem)
      .rxChangeRequestPriorAuthorization;
    const prescriptionDetails = mapPrescriptionHistoryToEntity(
      prescriptionChangeItem,
    );
    return {
      ...prescriptionDetails,
      rxChangeRequestPriorAuthorization,
    };
  });

/**
 * Regimen History
 */

export const mapPatientMedicationRegimensToEntities = (
  response: PatientMedicationRegimenResponse[],
): PatientMedicationRegimen[] =>
  response.map((regimenHistory: PatientMedicationRegimenResponse) =>
    mapPatientMedicationRegimenToEntity(regimenHistory),
  );

/**
 * Forms
 */

/* istanbul ignore next */
export const mapFormToDrugInteractionOverride = (data: any) => {
  const patientAllergyIds = data.allergyInteractions
    ? data.allergyInteractions.map(interaction => interaction.patientAllergyId)
    : [];

  const medicationRouteIds = data.medicationInteractions
    ? data.medicationInteractions.map(med => med.interactingRoutedMedId)
    : [];

  return {
    interactingAllergyIds: patientAllergyIds,
    interactingMedicationIds: medicationRouteIds,
  };
};

/* istanbul ignore next */
export const mapFormToSavePatientMedicationPayload = (
  data: PatientMedicationForm,
) => {
  const medicationPrn = data.usePrn && mapFormToMedicationPrn(data);
  const overriddenInteractions = mapFormToDrugInteractionOverride(data);

  return {
    patient_id: data.patientId,
    cart_complete: true,
    medication_data: {
      ...(medicationPrn ? { medication_prn: medicationPrn } : {}),
      medication_regimen: mapFormToMedicationRegimen(data),
    },
    match_existing_patient_medication: !data.isConcurrentMedication,
    has_drug_interaction_override: data.hasDrugInteractionOverride,
    interacting_medication_ids: overriddenInteractions.interactingMedicationIds,
    interacting_allergy_ids: overriddenInteractions.interactingAllergyIds,
  };
};

/* istanbul ignore next */
export const mapFormToSavePatientMedicationRegimenPayload = (
  data: PatientMedicationForm,
) => {
  const medicationPrn = data.usePrn && mapFormToMedicationPrn(data);

  return {
    ...(medicationPrn ? { medication_prn: medicationPrn } : {}),
    medication_regimen: mapFormToMedicationRegimen(data),
    patient_medication_regimen: {
      patient_medication_id: data.id,
      medication_regimen_id: data.medicationRegimenId,
      cart_complete: true,
    },
  };
};

export const mapMedsRecResponseToMedsRecEntity = (
  response: MedicationsReconciliationResponse,
): MedicationsReconciliationEvent => {
  return {
    patientId: response.patient_id,
    reviewedByUserId: response.internal_user_id,
    reviewedAtTimestamp: response.reviewed_at,
  };
};

export function mapMedsRecNotificationToEntity(
  response: GetMedsRecNotificationResponse,
): MedsRecNotification {
  return {
    message: response.notification?.message,
  };
}
