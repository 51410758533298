// @ts-strict-ignore
import { createReducer, on } from '@ngrx/store';

import { merge } from '@app/utils';

import { Patient } from '../shared/patient.type';
import {
  GetPatient,
  GetPatientError,
  GetPatientSuccess,
  QueryPatient,
  QueryPatientError,
  QueryPatientSuccess,
  UpdatePatient,
  UpdatePatientError,
  UpdatePatientPcpComments,
  UpdatePatientPcpCommentsError,
  UpdatePatientPcpCommentsSuccess,
  UpdatePatientSuccess,
} from './patient.actions';

export const patientStatePath = 'patient';
export interface PatientState {
  entity: Patient;
  error: any;
  loading: boolean;
  loadingPcpComments: boolean;
}

export const patientInitialState: PatientState = {
  entity: null,
  error: null,
  loading: false,
  loadingPcpComments: false,
};

export const patientReducer = createReducer(
  patientInitialState,
  on(GetPatient, state => ({
    ...state,
    loading: true,
  })),
  on(GetPatientSuccess, (state, action) => ({
    ...state,
    loading: false,
    entity: merge(state.entity, action.payload),
  })),
  on(GetPatientError, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(QueryPatient, state => ({
    ...state,
    loading: true,
    loadingPcpComments: true,
  })),
  on(QueryPatientSuccess, (state, action) => ({
    ...state,
    loading: false,
    loadingPcpComments: false,
    entity: merge(state.entity, action.payload),
  })),
  on(QueryPatientError, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loadingPcpComments: false,
  })),
  on(UpdatePatient, state => ({
    ...state,
    loading: true,
  })),
  on(UpdatePatientSuccess, (state, action) => ({
    ...state,
    loading: false,
    entity: action.payload,
  })),
  on(UpdatePatientError, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  })),
  on(UpdatePatientPcpComments, state => ({
    ...state,
    loadingPcpComments: false,
  })),
  on(UpdatePatientPcpCommentsSuccess, (state, action) => ({
    ...state,
    loadingPcpComments: false,
    entity: action.payload,
  })),
  on(UpdatePatientPcpCommentsError, (state, action) => ({
    ...state,
    error: action.payload,
    loadingPcpComments: false,
  })),
);
