import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReferenceDataActions } from './reference-data.actions';
import { ReferenceDataSelectors } from './reference-data.selectors';

@NgModule({
  imports: [CommonModule],
  providers: [ReferenceDataSelectors, ReferenceDataActions],
})
export class ReferenceDataStoreModule {}
