// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PatientSelectors, Profile, ProfileSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { CheckoutRenewal } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-ready-for-checkout',
  templateUrl: './ready-for-checkout.component.html',
  styleUrls: ['./ready-for-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyForCheckoutComponent implements OnInit {
  approvedOrDeniedRenewals$: Observable<CheckoutRenewal[]>;
  acceptsDigitalCommunications$: Observable<boolean>;
  cartId$: Observable<number>;
  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  loading$: Observable<boolean>;
  incompleteDemographics$: Observable<boolean>;
  checkoutAttempted: boolean;
  checkoutPrevented: boolean;
  showPdmpDialogueBanner$: Observable<boolean>;

  @Input()
  isListView = false;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.acceptsDigitalCommunications$ = this.patientSelectors.acceptsDigitalCommunications;
    this.approvedOrDeniedRenewals$ = this.renewalSelectors.approvedOrDeniedRenewals.pipe(
      map(renewals =>
        renewals.map(renewal => {
          const readyToSign = !renewal.require2Fa;
          const handwrittenConfirmed = !renewal.mustHandwrite;

          return {
            ...renewal,
            readyToSign,
            handwrittenConfirmed,
          };
        }),
      ),
    );

    this.cartId$ = this.renewalSelectors.cartId;
    this.isProvider$ = this.profileSelectors.hasRole('provider');
    this.loading$ = this.renewalSelectors.loading;
    this.profile$ = this.profileSelectors.profile;
    this.incompleteDemographics$ = this.patientSelectors.hasIncompleteDemographics;

    this.showPdmpDialogueBanner$ = combineLatest([
      this.launchDarklyService.variation$(FeatureFlagNames.medsPDMP, false),
      this.approvedOrDeniedRenewals$,
    ]).pipe(
      tap(
        ([enabled, checkoutRenewals]) =>
          (this.checkoutPrevented =
            enabled &&
            !checkoutRenewals.every(({ readyToSign }) => readyToSign)),
      ),
      map(
        ([enabled, checkoutRenewals]) =>
          enabled && checkoutRenewals.some(({ require2Fa }) => require2Fa),
      ),
    );
  }

  onPdmpConfirmation(renewals: CheckoutRenewal[], isChecked: boolean) {
    renewals.forEach(renewal => (renewal.readyToSign = isChecked));
    this.checkoutPrevented = !isChecked;
  }
}
