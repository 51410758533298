// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';

import { PatientWithoutId } from '@app/core/patient/store/patient.effects';

import { PatientUpdateData } from '../shared/patient-update.type';
import { Patient } from '../shared/patient.type';
import { PatientState } from './patient.reducer';

export enum PatientActionTypes {
  GetPatient = '[Patient] Get Patient',
  GetPatientSuccess = '[Patient] Get Patient Success',
  GetPatientError = '[Patient] Get Patient Error',
  UpdatePatient = '[Patient] Update Patient',
  UpdatePatientSuccess = '[Patient] Update Patient Success',
  UpdatePatientError = '[Patient] Update Patient Error',
  UpdatePatientPcpComments = '[Patient] Update PCP Comments',
  UpdatePatientPcpCommentsSuccess = '[Patient] Update PCP Comments Success',
  UpdatePatientPcpCommentsError = '[Patient] Update PCP Comments Error',
  ResetPatient = '[Patient] Reset Patient',
  QueryPatient = '[Patient] Query Patient',
  QueryPatientSuccess = '[Patient] Query Patient Success',
  QueryPatientError = '[Patient] Query Patient Error',
}
interface GetPatientPayload {
  id: number;
  forceGet?: boolean;
}
interface UpdatePatientPayload {
  id: number;
  data: PatientUpdateData;
}

type PatientErrorPayload = any;

export const GetPatient = createAction(
  PatientActionTypes.GetPatient,
  props<{ payload: GetPatientPayload }>(),
);
export const GetPatientSuccess = createAction(
  PatientActionTypes.GetPatientSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const GetPatientError = createAction(
  PatientActionTypes.GetPatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

export const UpdatePatient = createAction(
  PatientActionTypes.UpdatePatient,
  props<{ payload: UpdatePatientPayload }>(),
);
export const UpdatePatientSuccess = createAction(
  PatientActionTypes.UpdatePatientSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const UpdatePatientError = createAction(
  PatientActionTypes.UpdatePatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

export const UpdatePatientPcpComments = createAction(
  PatientActionTypes.UpdatePatientPcpComments,
  props<{ payload: UpdatePatientPayload }>(),
);
export const UpdatePatientPcpCommentsSuccess = createAction(
  PatientActionTypes.UpdatePatientPcpCommentsSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const UpdatePatientPcpCommentsError = createAction(
  PatientActionTypes.UpdatePatientPcpCommentsError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

export const ResetPatient = createAction(PatientActionTypes.ResetPatient);

export const QueryPatient = createAction(
  PatientActionTypes.QueryPatient,
  props<{ payload: GetPatientPayload }>(),
);
export const QueryPatientSuccess = createAction(
  PatientActionTypes.QueryPatientSuccess,
  /*
   The patient id is excluded because the presence of the id in the NgRx store implies that the store has been
   updated with the response from the get patient REST endpoint.
  */
  (payload: PatientWithoutId) => ({ payload, error: false }),
);
export const QueryPatientError = createAction(
  PatientActionTypes.QueryPatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

@Injectable()
export class PatientActions {
  constructor(private store: Store<PatientState>) {}

  getPatient(id: number, forceGet?: boolean) {
    const payload = { id, forceGet };
    this.store.dispatch(GetPatient({ payload }));
    this.store.dispatch(QueryPatient({ payload }));
  }

  updatePatient(id: number, data: PatientUpdateData) {
    const payload = { id, data };
    this.store.dispatch(UpdatePatient({ payload }));
  }

  updatePatientPcpComments(id: number, data: PatientUpdateData) {
    const payload = { id, data };
    this.store.dispatch(UpdatePatientPcpComments({ payload }));
  }

  resetPatient() {
    this.store.dispatch(ResetPatient());
  }
}
