// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileSelectors } from '@app/core';
import { ProcedureSuggestion } from '@app/features/procedure-suggestion/shared/procedure-suggestion.type';
import { formatCptCodes } from '@app/features/summaries/shared/summaries-utils';

import { SummaryServiceTicketItem } from '../../shared/summaries.type';

@Component({
  selector: 'omg-procedures-and-services',
  templateUrl: './procedures-and-services.component.html',
  styleUrls: ['./procedures-and-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProceduresAndServicesComponent implements OnInit {
  @Input() serviceTicketItems: SummaryServiceTicketItem[];
  @Input() showServiceTicketDeleteError: boolean;
  @Input() procedureSuggestions: ProcedureSuggestion[];
  @Input() patientId?: number;

  @Output() unlink = new EventEmitter<SummaryServiceTicketItem>();

  canDeleteServiceTicketItem$: Observable<boolean>;

  formatCptCodes = formatCptCodes;
  trackByFn = (index, serviceTicketItem: SummaryServiceTicketItem) =>
    serviceTicketItem.id || index;

  constructor(private profile: ProfileSelectors) {}

  ngOnInit() {
    this.canDeleteServiceTicketItem$ = this.profile.hasPermission(
      'delete/ServiceTicketItem',
    );
  }

  deleteServiceTicketItem(serviceTicketItem: SummaryServiceTicketItem) {
    this.unlink.emit(serviceTicketItem);
  }
}
