import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProfileApiService } from './shared/profile-api.service';
import { ProfileActions } from './store/profile.actions';
import { ProfileEffects } from './store/profile.effects';
import { profileReducer, profileStatePath } from './store/profile.reducer';
import { ProfileSelectors } from './store/profile.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(profileStatePath, profileReducer),
    EffectsModule.forFeature([ProfileEffects]),
  ],
  providers: [ProfileApiService, ProfileActions, ProfileSelectors],
})
export class ProfileModule {}
