import { Pipe, PipeTransform } from '@angular/core';

type MapFunction<T, U> = (value: T, index?: number, array?: T[]) => U;

@Pipe({ name: 'applyEach' })
export class ApplyFuncEachPipe implements PipeTransform {
  constructor() {}

  transform<T, U>(obj: Array<T>, func: MapFunction<T, U>) {
    return obj.map(func);
  }
}
