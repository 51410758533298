export enum NotificationAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface Notification<T> {
  action: NotificationAction;
  payload: T;
  resource: String;
  sent_at: Date;
  scope?: String;
}
