// @ts-strict-ignore
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';

import { Template } from '@app/modules/messaging/shared/template-insertion.type';

@Component({
  selector: 'omg-inline-insertion-preview',
  templateUrl: './inline-insertion-preview.component.html',
  styleUrls: ['./inline-insertion-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineInsertionPreviewComponent implements AfterViewInit {
  @Input() template: Template;
  @Input() alignment = 'top';

  @ViewChild('insertionPreview') insertionPreview;

  direction = 'right';
  previewWidth = 300;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setDirection();
    this.changeDetectorRef.detectChanges();
  }

  setDirection() {
    const dropdownLeft = this.insertionPreview.nativeElement.offsetParent
      .offsetLeft;
    const dropdownRight =
      dropdownLeft +
      this.insertionPreview.nativeElement.offsetParent.offsetWidth;
    const screenWidth = this.getScreenWidth();

    if (dropdownRight + this.previewWidth > screenWidth) {
      this.direction = 'left';
      if (dropdownLeft - this.previewWidth < 0) {
        this.direction = 'bottom';
      }
    } else {
      this.direction = 'right';
    }
  }

  /* istanbul ignore next */
  getScreenWidth() {
    return window.innerWidth;
  }
}
