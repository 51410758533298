// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { FsaAction } from '@app/core/store';
import { StateActions } from '@app/core/store/shared/state';
import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';

import { ChangeRxSubmitAction } from './change-rx.reducer';

export const changeRxSubmitActionType = '[ChangeRx] Change Rx Submit';
export const changeRxSubmitSuccessActionType =
  '[ChangeRx] Change Rx Submit Success';
export const changeRxSubmitErrorActionType =
  '[ChangeRx] Change Rx Submit Error';
export const changeRxSubmitClearActionType =
  '[ChangeRx] Change Rx Submit Clear';
export const changeRxCommentUpdateTotalType = '[ChangeRx] Update Comment Total';
export const markReadyToSignType = '[ChangeRx] Mark Ready To Sign';
export const markReadyToSignSuccessType =
  '[ChangeRx] Mark Ready To Sign Success';
export const markReadyToSignErrorType = '[ChangeRx] Mark Ready To Sign Error';

export class ChangeRxSubmit implements FsaAction<ChangeRx> {
  type = changeRxSubmitActionType;

  constructor(public changeRxSubmitAction: ChangeRxSubmitAction) {}
}

export class ChangeRxSubmitSuccess implements FsaAction<ChangeRx> {
  type = changeRxSubmitSuccessActionType;

  constructor(public changeRxSubmitAction: ChangeRxSubmitAction) {}
}

export class ChangeRxSubmitError implements FsaAction<ChangeRx> {
  type = changeRxSubmitErrorActionType;

  constructor(
    public error: any,
    public changeRxSubmitAction: ChangeRxSubmitAction,
  ) {}
}

export class ChangeRxSubmitClear implements FsaAction<void> {
  type = changeRxSubmitClearActionType;
}

interface UpdateCommentCount {
  id: number;
  totalComments: number;
}

export class ChangeRxCommentUpdateTotal
  implements FsaAction<UpdateCommentCount> {
  type = changeRxCommentUpdateTotalType;
  error = false;

  constructor(public payload: UpdateCommentCount) {}
}

export class MarkReadyToSign implements FsaAction<number> {
  readonly type = markReadyToSignType;

  constructor(public payload: number) {}
}

export class MarkReadyToSignSuccess implements FsaAction<boolean> {
  readonly type = markReadyToSignSuccessType;

  constructor(public payload: boolean) {}
}

export class MarkReadyToSignError implements FsaAction<any> {
  readonly type = markReadyToSignErrorType;

  constructor(public payload: any) {}
}

export type ChangeRxSubmitActionTypes =
  | ChangeRxSubmit
  | ChangeRxSubmitSuccess
  | ChangeRxSubmitError
  | ChangeRxSubmitClear
  | MarkReadyToSign
  | MarkReadyToSignSuccess
  | MarkReadyToSignError;

export class ChangeRxActions extends StateActions<ChangeRx> {
  submit(changeRxSubmitAction: ChangeRxSubmitAction) {
    this.store.dispatch(new ChangeRxSubmit(changeRxSubmitAction));
  }

  clearChangeRxSuccess() {
    this.store.dispatch(new ChangeRxSubmitClear());
  }

  updateChangeRxCommentTotal(id: number, totalComments: number) {
    this.store.dispatch(new ChangeRxCommentUpdateTotal({ id, totalComments }));
  }

  markReadyToSign(id: number) {
    this.store.dispatch(new MarkReadyToSign(id));
  }
}
