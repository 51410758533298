// @ts-strict-ignore
import { RxChangeRequest } from '@app/features/change-rx/shared/change-rx.type';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { PendingNewRx, RxCart } from '@app/modules/rx-cart/shared';
import { httpStatus, sortBy } from '@app/utils';

import { CheckoutErrorPayload } from '../components/checkout-dialog/checkout-dialog.type';
import { Credential, Prescriber } from '../prescriber-credential.type';
import { CartPasswordState, CartStateErrors } from '../shared-rx.type';

export const showMoreOptions = (medication: PendingNewRx | Renewal) => {
  return !!(
    medication.notesToPharmacist ||
    medication.dispenseAsWritten ||
    medication.earliestFillDate ||
    medication.dispensableRestrictedControlledSubstance ||
    medication.requiresNoteOfMedicalNeed
  );
};

export const containsControlledSubstances = (
  cartItems: Array<PendingNewRx | Renewal>,
): boolean =>
  cartItems.some(
    (item: PendingNewRx | Renewal) => item.dispensableControlledSubstance,
  );

export const numberOfValidPrescribers = (
  entity: RxCart | Renewal | RxChangeRequest,
): number =>
  (entity && entity.validPrescribers && entity.validPrescribers.length) || 0;

export const sortValidPrescribers = (validPrescribers: Prescriber[]) =>
  validPrescribers &&
  sortBy(
    (validPrescriber: Prescriber) => validPrescriber.name,
    validPrescribers,
  );

export const handleCartSubmissionError = (
  error: CheckoutErrorPayload,
  status: number,
): CartPasswordState => {
  const { password_verified: passwordVerified, errors } = error;
  const exceptions = errors && errors.exceptions ? errors.exceptions : [];
  const exceptionsExist = exceptions.length > 0;

  const stateErrors: CartStateErrors = {
    invalidPassword: null,
    hasUnknownError: false,
    validationError: null,
  };

  if (!passwordVerified && status === httpStatus.UNAUTHORIZED) {
    if (exceptionsExist) {
      stateErrors.invalidPassword = exceptions.join(' ');
    } else {
      stateErrors.invalidPassword = errors.password.join(' ');
    }
  }

  if (status === httpStatus.INTERNAL_SERVER_ERROR) {
    stateErrors.hasUnknownError = true;
  }

  if (status === httpStatus.UNPROCESSABLE_ENTITY) {
    if (exceptionsExist) {
      stateErrors.validationError = exceptions.join(' ');
    } else {
      stateErrors.validationError = error.message
        ? error.message
        : 'Sorry, an unknown validation error occured. Please try again.';
    }
  }

  if (passwordVerified !== undefined) {
    return { ...stateErrors, passwordVerified };
  } else {
    return { ...stateErrors };
  }
};
export const mapCredentialsToDropdownItem = (credentials: Credential[]) =>
  credentials &&
  credentials.map((credential: Credential) => ({
    label: `${credential.deaLicenseNumber}  (${credential.serviceArea} - ${credential.stateOfIssue})`,
    value: credential.id,
  }));
