import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ProcedureInteractionCommentsState,
  procedureInteractionCommentsStatePath,
  selectAll,
  selectEntities,
} from './procedure-interaction-comments.reducer';

export const selectProcedureInteractionCommentsState = createFeatureSelector<
  ProcedureInteractionCommentsState
>(procedureInteractionCommentsStatePath);

export const selectProcedureInteractionComments = createSelector(
  selectProcedureInteractionCommentsState,
  selectEntities,
);

export const selectAllProcedureInteractionComments = createSelector(
  selectProcedureInteractionCommentsState,
  selectAll,
);

export const selectProcedureInteractionCommentsError = createSelector(
  selectProcedureInteractionCommentsState,
  state => state.error,
);

export const selectProcedureInteractionCommentsPending = createSelector(
  selectProcedureInteractionCommentsState,
  state => state.pending,
);
