<div omgCollapse
     #actionBarRef="omgCollapse">
  <omg-collapsed om-layout-fill>
    <div om-layout
         om-layout-align="space-between center"
         om-layout-fill
         class="action-bar">
      <span class="left-buttons-container"
            om-layout-align="start">
        <ng-content select="[left-buttons]"></ng-content>
      </span>
      <span class="right-buttons-container"
            om-layout-align="end">
        <ng-content select="[right-buttons]"></ng-content>
      </span>
    </div>
  </omg-collapsed>
  <omg-expanded om-layout-fill>
    <div om-layout
         om-layout-align="space-between center"
         om-layout-fill
         class="action-bar">
      <ng-content select="omg-confirm-drawer"></ng-content>
      <ng-content select="omg-sign-on-behalf-search"></ng-content>
      <ng-content select="omg-rejection-drawer"></ng-content>
      <ng-content select="omg-rx-item-deny-drawer"></ng-content>
    </div>
  </omg-expanded>
</div>
