<omg-section-title *ngIf="!completed">
  <div om-layout
       om-layout-align="space-between center"
       om-flex=100>
    <span>Chronic Care Management</span>
  </div>
</omg-section-title>
<omg-chronic-care-management-form *ngIf="(programVisit$ | ngrxPush) && !(loadingProgramVisit$ | ngrxPush)"
                                  [programVisit]="programVisit$ | ngrxPush"
                                  [completed]="completed"></omg-chronic-care-management-form>
