// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { filterTruthy } from '@app/utils';

import { ChangeRxForm } from './../../shared/change-rx-form';
import { ChangeRxFormService } from '../../shared/change-rx-form.service';
import { ChangeRxGuard } from '../../shared/change-rx.guard';
import {
  ChangeRx,
  ChangeRxTypes,
  rxChangeClass,
} from '../../shared/change-rx.type';
import { ChangeRxSelectors } from '../../store/change-rx.selectors';

@Component({
  selector: 'omg-change-item-detail',
  templateUrl: './change-item-detail.component.html',
  styleUrls: ['./change-item-detail.component.scss'],
})
export class ChangeItemDetailComponent implements OnInit {
  changeRx$: Observable<ChangeRx>;
  todo$: Observable<Todo>;
  pediatricVitalsEnabled$: Observable<boolean>;
  showPdmpDialogueBanner$: Observable<boolean>;

  form: ChangeRxForm;
  needsPediatricVitals: boolean;

  changeRxTypes = ChangeRxTypes;
  checkoutPrevented: boolean;

  constructor(
    private changeRxSelectors: ChangeRxSelectors,
    private route: ActivatedRoute,
    private changeRxGuard: ChangeRxGuard,
    private changeRxFormService: ChangeRxFormService,
    private patientSelectors: PatientSelectors,
    private todoSelectors: TodoSelectors,
    private todoActions: TodoActions,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.changeRxGuard
      .canActivate(this.route.snapshot)
      .pipe(
        filterTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();
  }

  updatePediatricVitalsStatus(hasVitals: boolean) {
    this.needsPediatricVitals = !hasVitals;
  }

  private setupSelectors() {
    this.changeRx$ = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')),
      switchMap((id: number) => this.changeRxSelectors.getById(id)),
      tap(change => {
        this.form = this.form || this.changeRxFormService.buildForm(change);
        this.loadAndSetTodo(change.id);
      }),
      shareReplay(1), // since this will be subscribed multiple times
    );

    this.pediatricVitalsEnabled$ = combineLatest([
      this.patientSelectors.isMinor,
      this.changeRx$.pipe(map(changeRx => !!changeRx.rxChangeRequest)),
    ]).pipe(
      map(([isMinor, isRxChangeRequest]) => isMinor && isRxChangeRequest),
    );

    this.showPdmpDialogueBanner$ = this.launchDarklyService
      .variation$(FeatureFlagNames.medsPDMP, false)
      .pipe(
        withLatestFrom(this.changeRx$),
        map(
          ([enabled, changeRx]) =>
            enabled && changeRx.rxChangeRequest?.require2Fa,
        ),
        tap(enabled => (this.checkoutPrevented = enabled)),
      );
  }

  onPdmpConfirmation(isChecked: boolean) {
    this.checkoutPrevented = !isChecked;
  }

  private loadAndSetTodo(id: number) {
    this.todo$ = this.todoSelectors.todoByEntity(id, rxChangeClass);
    this.todoActions.loadTodoByEntity(id, rxChangeClass);
  }
}
