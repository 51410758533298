import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-medication-dea-schedule',
  templateUrl: './medication-dea-schedule.component.html',
})
export class MedicationDeaScheduleComponent {
  @Input() deaSchedule: number;

  romanNumeralMap = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
    5: 'V',
  };
}
