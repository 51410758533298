import { Injectable } from '@angular/core';

import { RxVerificationSelectors } from '../store/rx-verification.selectors';
import { RxVerificationForm } from './rx-verification-form';

@Injectable()
export class RxVerificationFormService {
  constructor(private selectors: RxVerificationSelectors) {}

  buildForm() {
    return new RxVerificationForm(this.selectors);
  }
}
