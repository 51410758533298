export default {
  resourceType: 'ValueSet',
  name: 'screening-procedures',
  title: 'Screening Procedures',
  status: 'draft',
  experimental: false,
  compose: {
    include: [
      {
        concept: [
          {
            code: '392089008',
            display: 'Breast procedure (procedure)',
          },
          {
            code: '71651007',
            display: 'Diagnostic Mammography',
          },
          {
            code: '241190000',
            display: 'Breast pneumocystogram (procedure)',
          },
          {
            code: '241189009',
            display: 'Breast sinogram (procedure)',
          },
          {
            code: '241539009',
            display: 'Computed tomography of breast (procedure)',
          },
          {
            code: '726551006',
            display: 'Contrast enhanced spectral mammography (procedure)',
          },
          {
            code: '450566007',
            display: 'Digital breast tomosynthesis (procedure)',
          },
          {
            code: '709657006',
            display: 'Fluoroscopy of breast (procedure)',
          },
          {
            code: '713564003',
            display:
              'Localization of iodine 125 radioactive seed to breast lesion using X-ray guidance (procedure)',
          },
          {
            code: '18102001',
            display: 'Mammary ductogram (procedure)',
          },
          {
            code: '241056007',
            display: 'Mammogram - localization (procedure)',
          },
          {
            code: '241055006',
            display: 'Mammogram - symptomatic (procedure)',
          },
          {
            code: '241057003',
            display: 'Mammogram coned (procedure)',
          },
          {
            code: '439324009',
            display: 'Mammogram in compression view (procedure)',
          },
          {
            code: '241058008',
            display: 'Mammogram magnification (procedure)',
          },
          {
            code: '418074003',
            display: 'Mammography and aspiration (procedure)',
          },
          {
            code: '418378007',
            display: 'Mammography and biopsy (procedure)',
          },
          {
            code: '866234000',
            display: 'Mammography of breast implant (procedure)',
          },
          {
            code: '572701000119102',
            display: 'Mammography of left breast (procedure)',
          },
          {
            code: '566571000119105',
            display: 'Mammography of right breast (procedure)',
          },
          {
            code: '442869006',
            display:
              'Needle localization using ultrasound guidance and mammography (procedure)',
          },
          {
            code: '428907005',
            display:
              'Plain X-ray guided wire localization of breast lesion (procedure)',
          },
          {
            code: '80865008',
            display: 'Specimen mammography (procedure)',
          },
          {
            code: '719573001',
            display:
              'Vacuum assisted excision of lesion of breast using stereotactic X-ray guidance (procedure)',
          },
          {
            code: '394911000',
            display:
              'Wire guided wide local excision of breast lump under radiological control (procedure)',
          },
          {
            code: '12389009',
            display: 'Xeromammography (procedure)',
          },
          {
            code: '24623002',
            display: 'Screening mammography (procedure)',
          },
          {
            code: '392531000119105',
            display: 'Screening mammography of left breast (procedure)',
          },
          {
            code: '392521000119107',
            display: 'Screening mammography of right breast (procedure)',
          },
          {
            code: '118839001',
            display: 'Procedure on colon (procedure)',
          },
          {
            code: '73761001',
            display: 'Colonoscopy (procedure)',
          },
          {
            code: '310634005',
            display: 'Check colonoscopy (procedure)',
          },
          {
            code: '446745002',
            display: 'Colonoscopy and biopsy of colon (procedure)',
          },
          {
            code: '709421007',
            display:
              'Colonoscopy and dilatation of stricture of colon (procedure)',
          },
          {
            code: '446521004',
            display: 'Colonoscopy and excision of mucosa of colon (procedure)',
          },
          {
            code: '789778002',
            display:
              'Colonoscopy and fecal microbiota transplantation (procedure)',
          },
          {
            code: '447021001',
            display: 'Colonoscopy and tattooing (procedure)',
          },
          {
            code: '443998000',
            display:
              'Colonoscopy through colostomy with endoscopic biopsy of colon (procedure)',
          },
          {
            code: '710293001',
            display: 'Colonoscopy using fluoroscopic guidance (procedure)',
          },
          {
            code: '711307001',
            display: 'Colonoscopy using X-ray guidance (procedure)',
          },
          {
            code: '12350003',
            display:
              'Colonoscopy with rigid sigmoidoscope through colotomy (procedure)',
          },
          {
            code: '367535003',
            display: 'Fiberoptic colonoscopy (procedure)',
          },
          {
            code: '34264006',
            display:
              'Intraoperative colonoscopy (procedure) colonoscopy (procedure)',
          },
          {
            code: '235151005',
            display: 'Limited colonoscopy (procedure)',
          },
          {
            code: '174158000',
            display: 'Open colonoscopy (procedure)',
          },
          {
            code: '303587008',
            display: 'Therapeutic colonoscopy (procedure)',
          },
          {
            code: '235150006',
            display: 'Total colonoscopy (procedure)',
          },
          {
            code: '444783004',
            display: 'Screening colonoscopy (procedure)',
          },
          {
            code: '423342001',
            display: 'Colposcopic procedure (procedure)',
          },
          {
            code: '392003006',
            display: 'Colposcopy (procedure)',
          },
          {
            code: '176786003',
            display: 'Colposcopy of cervix (procedure)',
          },
          {
            code: '236839001',
            display: 'Colposcopy of vulva (procedure)',
          },
        ],
        system: 'http://snomed.info/sct',
      },
    ],
  },
  date: '2021-10-18',
  expansion: {
    contains: [
      {
        code: '392089008',
        contains: [
          {
            code: '71651007',
            contains: [
              {
                code: '241190000',
                display: 'Breast pneumocystogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241189009',
                display: 'Breast sinogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241539009',
                display: 'Computed tomography of breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '726551006',
                display: 'Contrast enhanced spectral mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '450566007',
                display: 'Digital breast tomosynthesis (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '709657006',
                display: 'Fluoroscopy of breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '713564003',
                display:
                  'Localization of iodine 125 radioactive seed to breast lesion using X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '18102001',
                display: 'Mammary ductogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241056007',
                display: 'Mammogram - localization (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241055006',
                display: 'Mammogram - symptomatic (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241057003',
                display: 'Mammogram coned (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '439324009',
                display: 'Mammogram in compression view (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241058008',
                display: 'Mammogram magnification (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '418074003',
                display: 'Mammography and aspiration (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '418378007',
                display: 'Mammography and biopsy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '866234000',
                display: 'Mammography of breast implant (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '572701000119102',
                display: 'Mammography of left breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '566571000119105',
                display: 'Mammography of right breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '442869006',
                display:
                  'Needle localization using ultrasound guidance and mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '428907005',
                display:
                  'Plain X-ray guided wire localization of breast lesion (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '80865008',
                display: 'Specimen mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '719573001',
                display:
                  'Vacuum assisted excision of lesion of breast using stereotactic X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '394911000',
                display:
                  'Wire guided wide local excision of breast lump under radiological control (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '12389009',
                display: 'Xeromammography (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Diagnostic Mammography',
            system: 'http://snomed.info/sct',
          },
          {
            code: '24623002',
            contains: [
              {
                code: '392531000119105',
                display: 'Screening mammography of left breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '392521000119107',
                display: 'Screening mammography of right breast (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Screening mammography (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Breast procedure (procedure)',
        system: 'http://snomed.info/sct',
      },
      {
        code: '118839001',
        contains: [
          {
            code: '73761001',
            contains: [
              {
                code: '310634005',
                display: 'Check colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '446745002',
                display: 'Colonoscopy and biopsy of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '709421007',
                display:
                  'Colonoscopy and dilatation of stricture of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '446521004',
                display:
                  'Colonoscopy and excision of mucosa of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '789778002',
                display:
                  'Colonoscopy and fecal microbiota transplantation (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '447021001',
                display: 'Colonoscopy and tattooing (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '443998000',
                display:
                  'Colonoscopy through colostomy with endoscopic biopsy of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '710293001',
                display: 'Colonoscopy using fluoroscopic guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '711307001',
                display: 'Colonoscopy using X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '12350003',
                display:
                  'Colonoscopy with rigid sigmoidoscope through colotomy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '367535003',
                display: 'Fiberoptic colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '34264006',
                display:
                  'Intraoperative colonoscopy (procedure) colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '235151005',
                display: 'Limited colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '174158000',
                display: 'Open colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '303587008',
                display: 'Therapeutic colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '235150006',
                display: 'Total colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Colonoscopy (procedure)',
            system: 'http://snomed.info/sct',
          },
          {
            code: '444783004',
            display: 'Screening colonoscopy (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Procedure on colon (procedure)',
        system: 'http://snomed.info/sct',
      },
      {
        code: '423342001',
        contains: [
          {
            code: '392003006',
            contains: [
              {
                code: '176786003',
                display: 'Colposcopy of cervix (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '236839001',
                display: 'Colposcopy of vulva (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Colposcopy (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Colposcopic procedure (procedure)',
        system: 'http://snomed.info/sct',
      },
    ],
    timestamp: '2021-10-18T14:43:00-07:00',
  },
  id: 'd55dc832-d935-42ec-8c40-dbb13912b6c6',
  meta: {
    lastUpdated: '2021-10-26T23:12:19Z',
    source: 'Device/d5e35a43-8aa5-483f-b469-5729b99a1900',
    versionId: '65597106-b041-40d6-8a36-af0184a1f595',
  },
};

export interface SnomedCodeValues {
  resourceType: 'ValueSet',
  name: 'screening-procedures',
  title: 'Screening Procedures',
  status: 'draft',
  experimental: false,
  compose: {
    include: [
      {
        concept: [
          {
            code: '392089008',
            display: 'Breast procedure (procedure)',
          },
          {
            code: '71651007',
            display: 'Diagnostic Mammography',
          },
          {
            code: '241190000',
            display: 'Breast pneumocystogram (procedure)',
          },
          {
            code: '241189009',
            display: 'Breast sinogram (procedure)',
          },
          {
            code: '241539009',
            display: 'Computed tomography of breast (procedure)',
          },
          {
            code: '726551006',
            display: 'Contrast enhanced spectral mammography (procedure)',
          },
          {
            code: '450566007',
            display: 'Digital breast tomosynthesis (procedure)',
          },
          {
            code: '709657006',
            display: 'Fluoroscopy of breast (procedure)',
          },
          {
            code: '713564003',
            display:
            'Localization of iodine 125 radioactive seed to breast lesion using X-ray guidance (procedure)',
          },
          {
            code: '18102001',
            display: 'Mammary ductogram (procedure)',
          },
          {
            code: '241056007',
            display: 'Mammogram - localization (procedure)',
          },
          {
            code: '241055006',
            display: 'Mammogram - symptomatic (procedure)',
          },
          {
            code: '241057003',
            display: 'Mammogram coned (procedure)',
          },
          {
            code: '439324009',
            display: 'Mammogram in compression view (procedure)',
          },
          {
            code: '241058008',
            display: 'Mammogram magnification (procedure)',
          },
          {
            code: '418074003',
            display: 'Mammography and aspiration (procedure)',
          },
          {
            code: '418378007',
            display: 'Mammography and biopsy (procedure)',
          },
          {
            code: '866234000',
            display: 'Mammography of breast implant (procedure)',
          },
          {
            code: '572701000119102',
            display: 'Mammography of left breast (procedure)',
          },
          {
            code: '566571000119105',
            display: 'Mammography of right breast (procedure)',
          },
          {
            code: '442869006',
            display:
            'Needle localization using ultrasound guidance and mammography (procedure)',
          },
          {
            code: '428907005',
            display:
            'Plain X-ray guided wire localization of breast lesion (procedure)',
          },
          {
            code: '80865008',
            display: 'Specimen mammography (procedure)',
          },
          {
            code: '719573001',
            display:
            'Vacuum assisted excision of lesion of breast using stereotactic X-ray guidance (procedure)',
          },
          {
            code: '394911000',
            display:
            'Wire guided wide local excision of breast lump under radiological control (procedure)',
          },
          {
            code: '12389009',
            display: 'Xeromammography (procedure)',
          },
          {
            code: '24623002',
            display: 'Screening mammography (procedure)',
          },
          {
            code: '392531000119105',
            display: 'Screening mammography of left breast (procedure)',
          },
          {
            code: '392521000119107',
            display: 'Screening mammography of right breast (procedure)',
          },
          {
            code: '118839001',
            display: 'Procedure on colon (procedure)',
          },
          {
            code: '73761001',
            display: 'Colonoscopy (procedure)',
          },
          {
            code: '310634005',
            display: 'Check colonoscopy (procedure)',
          },
          {
            code: '446745002',
            display: 'Colonoscopy and biopsy of colon (procedure)',
          },
          {
            code: '709421007',
            display:
            'Colonoscopy and dilatation of stricture of colon (procedure)',
          },
          {
            code: '446521004',
            display: 'Colonoscopy and excision of mucosa of colon (procedure)',
          },
          {
            code: '789778002',
            display:
            'Colonoscopy and fecal microbiota transplantation (procedure)',
          },
          {
            code: '447021001',
            display: 'Colonoscopy and tattooing (procedure)',
          },
          {
            code: '443998000',
            display:
            'Colonoscopy through colostomy with endoscopic biopsy of colon (procedure)',
          },
          {
            code: '710293001',
            display: 'Colonoscopy using fluoroscopic guidance (procedure)',
          },
          {
            code: '711307001',
            display: 'Colonoscopy using X-ray guidance (procedure)',
          },
          {
            code: '12350003',
            display:
            'Colonoscopy with rigid sigmoidoscope through colotomy (procedure)',
          },
          {
            code: '367535003',
            display: 'Fiberoptic colonoscopy (procedure)',
          },
          {
            code: '34264006',
            display:
            'Intraoperative colonoscopy (procedure) colonoscopy (procedure)',
          },
          {
            code: '235151005',
            display: 'Limited colonoscopy (procedure)',
          },
          {
            code: '174158000',
            display: 'Open colonoscopy (procedure)',
          },
          {
            code: '303587008',
            display: 'Therapeutic colonoscopy (procedure)',
          },
          {
            code: '235150006',
            display: 'Total colonoscopy (procedure)',
          },
          {
            code: '444783004',
            display: 'Screening colonoscopy (procedure)',
          },
          {
            code: '423342001',
            display: 'Colposcopic procedure (procedure)',
          },
          {
            code: '392003006',
            display: 'Colposcopy (procedure)',
          },
          {
            code: '176786003',
            display: 'Colposcopy of cervix (procedure)',
          },
          {
            code: '236839001',
            display: 'Colposcopy of vulva (procedure)',
          },
        ],
        system: 'http://snomed.info/sct',
      },
    ],
  },
  date: '2021-10-18',
  expansion: {
    contains: [
      {
        code: '392089008',
        contains: [
          {
            code: '71651007',
            contains: [
              {
                code: '241190000',
                display: 'Breast pneumocystogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241189009',
                display: 'Breast sinogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241539009',
                display: 'Computed tomography of breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '726551006',
                display: 'Contrast enhanced spectral mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '450566007',
                display: 'Digital breast tomosynthesis (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '709657006',
                display: 'Fluoroscopy of breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '713564003',
                display:
                'Localization of iodine 125 radioactive seed to breast lesion using X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '18102001',
                display: 'Mammary ductogram (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241056007',
                display: 'Mammogram - localization (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241055006',
                display: 'Mammogram - symptomatic (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241057003',
                display: 'Mammogram coned (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '439324009',
                display: 'Mammogram in compression view (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '241058008',
                display: 'Mammogram magnification (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '418074003',
                display: 'Mammography and aspiration (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '418378007',
                display: 'Mammography and biopsy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '866234000',
                display: 'Mammography of breast implant (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '572701000119102',
                display: 'Mammography of left breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '566571000119105',
                display: 'Mammography of right breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '442869006',
                display:
                'Needle localization using ultrasound guidance and mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '428907005',
                display:
                'Plain X-ray guided wire localization of breast lesion (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '80865008',
                display: 'Specimen mammography (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '719573001',
                display:
                'Vacuum assisted excision of lesion of breast using stereotactic X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '394911000',
                display:
                'Wire guided wide local excision of breast lump under radiological control (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '12389009',
                display: 'Xeromammography (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Diagnostic Mammography',
            system: 'http://snomed.info/sct',
          },
          {
            code: '24623002',
            contains: [
              {
                code: '392531000119105',
                display: 'Screening mammography of left breast (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '392521000119107',
                display: 'Screening mammography of right breast (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Screening mammography (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Breast procedure (procedure)',
        system: 'http://snomed.info/sct',
      },
      {
        code: '118839001',
        contains: [
          {
            code: '73761001',
            contains: [
              {
                code: '310634005',
                display: 'Check colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '446745002',
                display: 'Colonoscopy and biopsy of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '709421007',
                display:
                'Colonoscopy and dilatation of stricture of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '446521004',
                display:
                'Colonoscopy and excision of mucosa of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '789778002',
                display:
                'Colonoscopy and fecal microbiota transplantation (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '447021001',
                display: 'Colonoscopy and tattooing (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '443998000',
                display:
                'Colonoscopy through colostomy with endoscopic biopsy of colon (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '710293001',
                display: 'Colonoscopy using fluoroscopic guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '711307001',
                display: 'Colonoscopy using X-ray guidance (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '12350003',
                display:
                'Colonoscopy with rigid sigmoidoscope through colotomy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '367535003',
                display: 'Fiberoptic colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '34264006',
                display:
                'Intraoperative colonoscopy (procedure) colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '235151005',
                display: 'Limited colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '174158000',
                display: 'Open colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '303587008',
                display: 'Therapeutic colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '235150006',
                display: 'Total colonoscopy (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Colonoscopy (procedure)',
            system: 'http://snomed.info/sct',
          },
          {
            code: '444783004',
            display: 'Screening colonoscopy (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Procedure on colon (procedure)',
        system: 'http://snomed.info/sct',
      },
      {
        code: '423342001',
        contains: [
          {
            code: '392003006',
            contains: [
              {
                code: '176786003',
                display: 'Colposcopy of cervix (procedure)',
                system: 'http://snomed.info/sct',
              },
              {
                code: '236839001',
                display: 'Colposcopy of vulva (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            display: 'Colposcopy (procedure)',
            system: 'http://snomed.info/sct',
          },
        ],
        display: 'Colposcopic procedure (procedure)',
        system: 'http://snomed.info/sct',
      },
    ],
    timestamp: '2021-10-18T14:43:00-07:00',
  },
  id: 'd55dc832-d935-42ec-8c40-dbb13912b6c6',
  meta: {
    lastUpdated: '2021-10-26T23:12:19Z',
    source: 'Device/d5e35a43-8aa5-483f-b469-5729b99a1900',
    versionId: '65597106-b041-40d6-8a36-af0184a1f595',
  },
}

export interface SnomedCodeNode { code: string, label: string, children: Array<SnomedCodeNode> }
