import { Component, ViewChild } from '@angular/core';

import { CollapseDirective } from '../collapse/collapse.directive';

@Component({
  selector: 'omg-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent {
  @ViewChild('actionBarRef', { static: true })
  actionBarRef: CollapseDirective;

  get expanded() {
    return this.actionBarRef.expanded;
  }

  get collapsed() {
    return !this.expanded;
  }

  showConfirmDrawer() {
    this.actionBarRef.expand();
  }

  hideConfirmDrawer() {
    this.actionBarRef.collapse();
  }
}
