import { Component, OnDestroy, OnInit } from '@angular/core';

import { PendoService } from '../../../../core/services/pendo.service';

@Component({
  selector: 'omg-resource-center-trigger',
  templateUrl: './resource-center-trigger.component.html',
  styleUrls: ['./resource-center-trigger.component.scss'],
})
export class ResourceCenterTriggerComponent implements OnInit, OnDestroy {
  private mutationObserver: MutationObserver | null;
  isBadgePresent = false;
  isResourceCenterOpen = false;

  get isHidden() {
    return this.isBadgePresent;
  }

  constructor(private pendoService: PendoService) {
    this.setIsBadgePresent = this.setIsBadgePresent.bind(this);
    this.setIsResourceCenterOpen = this.setIsResourceCenterOpen.bind(this);
  }

  ngOnInit() {
    const pendoService = this.pendoService;
    pendoService.subscribe(
      pendoService.isBadgeElementInDOM,
      this.setIsBadgePresent,
      true,
    );
    pendoService.subscribe(
      pendoService.isResourceCenterShown,
      this.setIsResourceCenterOpen,
      true,
    );
  }

  ngOnDestroy() {
    const pendoService = this.pendoService;
    pendoService.unsubscribe(
      pendoService.isBadgeElementInDOM,
      this.setIsBadgePresent,
    );
    pendoService.unsubscribe(
      pendoService.isResourceCenterShown,
      this.setIsResourceCenterOpen,
    );
  }

  setIsBadgePresent(value: boolean) {
    this.isBadgePresent = value;
  }

  setIsResourceCenterOpen(value: boolean) {
    this.isResourceCenterOpen = value;
  }
}
