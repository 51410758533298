<div class="banner -success"
     om-layout-gutter
     om-layout="vertical"
     data-cy="rx-renewal-toast-msg"
     *ngIf="renewals.length > 0">
  {{ countOfRenewalsPhrase }}
  <strong>
    {{ pharmacyPhrase }}
  </strong>
  <div *ngIf="approvedRenewals.length > 0">
    <strong>Approved</strong>
    <ul>
      <li *ngFor="let renewal of approvedRenewals">
        {{ renewal.medForDisplay.name }} {{ renewal.medForDisplay.dispensableTextDesc }}
      </li>
    </ul>
  </div>
  <div *ngIf="deniedRenewals.length > 0">
    <strong>Denied</strong>
    <br>
    <em>NOTE - Patient requested renewal requests are <strong>not</strong> faxed to the pharmacy.</em>
    <div *ngIf="draftRenewalMessageEnabled$ | ngrxPush">
      The patient is <strong>not notified</strong> of these prescription denials.
      <br>
      <a class="om-link -plain-text"
         target="_blank"
         (click)="openMessage()">Compose message to communicate the reason for denial.
      </a>
    </div>
    <ul>
      <li *ngFor="let renewal of deniedRenewals">
        {{ renewal.medForDisplay.name }} {{ renewal.medForDisplay.dispensableTextDesc }}
      </li>
    </ul>
  </div>
</div>
