<div class="padding-normal procedure-suggestion-feedback-container"
     om-layout-fill>
  <strong>ML Suggestion feedback: {{ procedureDescription }}</strong>
  <div class=padding-normal-vertical>
    <div class="radio-group"
         om-layout="vertical">
      <label *ngFor="let feedbackCategory of feedbackCategories"
             class="procedure-suggestion-feedback-radio-button">
        <input omgRadioButton
               [(ngModel)]="selectedFeedbackCategory"
               type="radio"
               name="category"
               [value]="feedbackCategory" /> {{ feedbackCategory }}
      </label>
    </div>
    <div class="padding-normal-horizontal">
      <input omgInputText
             [(ngModel)]="feedbackText"
             [disabled]="selectedFeedbackCategory !== 'Other'"
             class="procedure-suggestion-feedback-text-input" />
    </div>
  </div>
  <div om-layout-gutter>
    <button omgButton
            variant="primary"
            (click)="sendFeedback()">
      Send Feedback
    </button>
    <button omgButton
            variant="flat"
            (click)="skipFeedback()">
      Never mind / Skip
    </button>
  </div>
</div>
