// @ts-strict-ignore
import { mapS3PointerResponseToEntity } from '@app/modules/aws/shared/aws-session.mapper';
import { Commentable } from '@app/modules/comments/shared/comments.type';

import {
  Message,
  MessageResponse,
  MessageSaveData,
  Post,
  PostContentAttributes,
  PostContentAttributesResponse,
  PostNotificationTargetResource,
  PostNotificationTargetResourceResponse,
  PostPostedBy,
  PostPostedByResponse,
  PostReplyTo,
  PostReplyToResponse,
  PostResponse,
  PostSaveData,
} from './messaging.type';

/* istanbul ignore next */
export const mapCommentableResponseToEntity = (data: {
  id: number;
  total_comments: number;
}): Commentable => ({
  id: data.id,
  totalComments: data.total_comments,
  commentableType: 'timeline_post',
});

/* istanbul ignore next */
export const mapReplyToResponseToEntity = (
  data: PostReplyToResponse,
): PostReplyTo => ({
  id: data ? data.id : null,
  name: data ? data.name : null,
  role: data ? data.role : null,
  identifier: data ? data.identifier : null,
  outUntil: data ? data.out_until : null,
  label: data ? `${data.name} (${data.role})` : null,
  assigneeIdent: data && data.assignee_ident ? data.assignee_ident : null,
});

/* istanbul ignore next */
export const mapPostedByResponseToEntity = (
  data: PostPostedByResponse,
): PostPostedBy => ({
  id: data.id,
  name: data.name,
  preferredRole: data.preferred_role,
  profileImageUrl: data.profile_image_url,
  initials: data.initials,
});

/* istanbul ignore next */
export const mapNotificationTargetResourceResponseToEntity = (
  data: PostNotificationTargetResourceResponse,
): PostNotificationTargetResource => ({
  globalId: data.global_id,
  displayName: data.display_name,
  firstName: data.first_name,
  lastName: data.last_name,
  profileImageUrl: data.profile_image_url,
  defaultOfficeTitle: data.default_office_title,
  responseTime: data.response_time,
  description: data.description,
  roleKind: data.role_kind,
  outOfOffice: data.out_of_office,
  returnDate: data.return_date,
});

/* istanbul ignore next */
export const mapContentAttributesResponseToEntity = (
  data: PostContentAttributesResponse,
): PostContentAttributes => ({
  id: data.id,
  topic: data.topic,
  text: data.text,
  notificationTarget: data.notification_target,
  html: data.html,
  notificationTargetResource: mapNotificationTargetResourceResponseToEntity(
    data.notification_target_resource,
  ),
  postedBy: mapPostedByResponseToEntity(data.posted_by),
});

/* istanbul ignore next */
export const mapMessageResponseToEntity = (data: MessageResponse): Message => ({
  createdAt: data.created_at,
  deletedAt: data.deleted_at,
  draft: data.draft,
  html: data.html,
  id: data.id,
  postId: data.post_id,
  postedBy: mapPostedByResponseToEntity(data.posted_by),
  read: data.read,
  replyTo: mapReplyToResponseToEntity(data.reply_to),
  s3Pointers: data.s3_pointers.map(mapS3PointerResponseToEntity),
  sentAt: data.sent_at,
  text: data.text,
  updatedAt: data.updated_at,
  notificationRecipient: mapReplyToResponseToEntity(
    data.notification_recipient,
  ),
  taskPriority: data.task_priority,
});

/* istanbul ignore next */
export const mapPostResponseToEntity = (response: PostResponse): Post => ({
  id: response.id,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  sentAt: response.sent_at,
  deletedAt: response.deleted_at,
  contentType: response.content_type,
  draft: response.draft,
  patientVisible: response.patient_visible,
  contentAttributes: mapContentAttributesResponseToEntity(
    response.content_attributes,
  ),
  read: response.read,
  messages: response.comments.map(mapMessageResponseToEntity),
  s3Pointers: response.s3_pointers.map(mapS3PointerResponseToEntity),
  commentable: mapCommentableResponseToEntity(response.commentable),
  replyTo: mapReplyToResponseToEntity(response.reply_to),
  notificationRecipient: mapReplyToResponseToEntity(
    response.notification_recipient,
  ),
  taskPriority: response.task_priority,
});

/* istanbul ignore next */
export const mapContentAttributesToSaveData = (
  data: PostContentAttributes,
) => ({
  id: data.id,
  topic: data.topic,
  text: data.text,
  notification_target: data.notificationTarget,
  html: data.html,
  notification_target_resource: {
    global_id: data.notificationTargetResource.globalId,
    display_name: data.notificationTargetResource.displayName,
    first_name: data.notificationTargetResource.firstName,
    last_name: data.notificationTargetResource.lastName,
    profile_image_url: data.notificationTargetResource.profileImageUrl,
    default_office_title: data.notificationTargetResource.defaultOfficeTitle,
    response_time: data.notificationTargetResource.responseTime,
    description: data.notificationTargetResource.description,
    role_kind: data.notificationTargetResource.roleKind,
    out_of_office: data.notificationTargetResource.outOfOffice,
    return_date: data.notificationTargetResource.returnDate,
  },
  posted_by: {
    id: data.postedBy.id,
    name: data.postedBy.name,
    preferred_role: data.postedBy.preferredRole,
    profile_image_url: data.postedBy.profileImageUrl,
    initials: data.postedBy.initials,
  },
});

/* istanbul ignore next */
export const mapMessageToSaveData = (message: Message): MessageSaveData => ({
  created_at: message.createdAt || null,
  deleted_at: message.deletedAt || null,
  draft: message.draft || false,
  html: message.html || null,
  id: message.id || null,
  post_id: message.postId || null,
  posted_by:
    message && message.postedBy
      ? {
          id: message.postedBy.id,
          name: message.postedBy.name,
          preferred_role: message.postedBy.preferredRole,
          profile_image_url: message.postedBy.profileImageUrl,
          initials: message.postedBy.initials,
        }
      : null,
  read: message.read || false,
  reply_to:
    message && message.replyTo
      ? {
          id: message.replyTo.id,
          name: message.replyTo.name,
          role: message.replyTo.role,
          identifier: message.replyTo.identifier,
          out_until: message.replyTo.outUntil,
          assignee_ident: message.replyTo.assigneeIdent,
        }
      : null,
  s3_pointers:
    message && message.s3Pointers
      ? message.s3Pointers.map(pointer => ({
          id: pointer.id,
          bucket: pointer.bucket,
          content_length: pointer.contentLength,
          content_type: pointer.contentType,
          key: pointer.key,
          title: pointer.title,
          _destroy: pointer.destroy,
        }))
      : null,
  sent_at: message.sentAt || null,
  text: message.text || null,
  updated_at: message.updatedAt || null,
  event: message.event || null,
  notification_recipient:
    message && message.notificationRecipient
      ? {
          id: message.notificationRecipient.id,
          name: message.notificationRecipient.name,
          role: message.notificationRecipient.role,
          identifier: message.notificationRecipient.identifier,
          out_until: message.notificationRecipient.outUntil,
          assignee_ident: message.notificationRecipient.assigneeIdent,
        }
      : null,
  task_priority: message.taskPriority || null,
});

/* istanbul ignore next */
export const mapPostToSaveData = (post: Post): PostSaveData => ({
  id: post.id,
  created_at: post.createdAt,
  updated_at: post.updatedAt,
  sent_at: post.sentAt,
  deleted_at: post.deletedAt,
  content_type: post.contentType,
  draft: post.draft,
  patient_visible: post.patientVisible,
  content_attributes: mapContentAttributesToSaveData(post.contentAttributes),
  comments: post.messages.map(mapMessageToSaveData),
  s3_pointers: post.s3Pointers.map(pointer => ({
    id: pointer.id,
    bucket: pointer.bucket,
    content_length: pointer.contentLength,
    content_type: pointer.contentType,
    key: pointer.key,
    title: pointer.title,
    _destroy: pointer.destroy,
  })),
  commentable: {
    id: post.commentable.id,
    total_comments: post.commentable.totalComments,
    comments: [],
    newComment: {},
  },
  html: post.contentAttributes.html,
  reply_to: {
    id: post.replyTo.id,
    name: post.replyTo.name,
    role: post.replyTo.role,
    identifier: post.replyTo.identifier,
    out_until: post.replyTo.outUntil,
  },
  event: post.event,
  new_patient_id: post.newPatientId,
  notification_recipient:
    post && post.notificationRecipient
      ? {
          id: post.notificationRecipient.id,
          name: post.notificationRecipient.name,
          role: post.notificationRecipient.role,
          identifier: post.notificationRecipient.identifier,
          out_until: post.notificationRecipient.outUntil,
          assignee_ident: post.notificationRecipient.assigneeIdent,
        }
      : null,
  task_priority: post.taskPriority || null,
});
