<aside class="padding-normal"
       omgCollapse
       #collapseRef="omgCollapse">

  <omg-collapsed>
    <omg-ml-suggestion text="Note titled automatically"
                       buttonText="Give Feedback"
                       buttonVariant="flat"
                       (accept)="showForm()"
                       (closeSuggestion)="dismissBanner()">
      <div class="popover-tooltip">
        <h4 class="popover-tooltip-header">
          <span class="popover-tooltip-header-text">
            How automatic note titling works
          </span>
          <span class="om-icon clickable icon-dismiss -gray"></span>
        </h4>
        <div class="popover-tooltip-body">
          <p>
            This feature identifies text that suggests an organization or individual of a given specialty,
            then automatically adds a matching specialty to the note title. Your feedback helps improve the
            accuracy of this tool. Read more on
            <a href="https://sites.google.com/onemedical.com/1life-chart-quality/features/hsp-note-retitling"
               target="_blank">our wiki</a>.
          </p>
          <p class="terms"
             *ngIf="terms$ | ngrxPush as terms">
            <i>
              Most predictive words: {{ terms }}
            </i>
          </p>
        </div>
      </div>
    </omg-ml-suggestion>
  </omg-collapsed>

  <omg-expanded [collapseProvider]="collapseRef">
    <form class="ml-note-retitle-feedback-form padding-normal"
          [formGroup]="form.controls"
          (submit)="submit()"
          om-layout="vertical"
          om-layout-gutter-large>

      <div class="form-heading"
           om-layout
           om-layout-align="start center"
           om-layout-gutter>
        <i class="om-icon icon-magic"></i>
        <strong>Automatic Note Retitle Feedback</strong>
      </div>

      <div om-layout="vertical"
           om-flex="70">
        <label>
          Select a different specialty
        </label>
        <omg-auto-complete formControlName="specialty"
                           [items]="form.specialtyOptions"
                           bindLabel="label"
                           bindValue="value"
                           trackByKey="value"
                           placeholder="Select specialty"
                           omgStopEvent>
        </omg-auto-complete>
        <p class="alert"
           *ngIf="form.controlHasError('specialty')">
          Please choose a specialty
        </p>
      </div>

      <div *ngIf="form.otherSpecialtySelected()"
           om-layout="vertical"
           om-flex="70">
        <label>
          Other specialty, not listed above
        </label>
        <input omgInputText
               type="text"
               name="result"
               formControlName="otherSpecialty">
      </div>

      <div om-layout="vertical">
        <label>
          Other feedback
        </label>
        <input omgInputText
               type="text"
               name="result"
               formControlName="otherFeedback">
      </div>

      <div om-layout
           om-layout-gutter>
        <button type="submit"
                omgButton
                variant="secondary">
          Send feedback
        </button>
        <button omgButton
                type="button"
                variant="flat"
                (click)="cancel()">
          Never mind
        </button>
      </div>
    </form>
  </omg-expanded>
</aside>
