<ng-container *ngIf="changeRxs$ | ngrxPush as changeRxs">
  <div class="data-table"
       *ngIf="changeRxs.length > 0">
    <omg-section-title>
      Change Requests ({{changeRxs.length}})</omg-section-title>
    <omg-section-title [altBackgroundColor]="true">
      <div om-layout
           om-layout-fill
           om-layout-gutter
           om-layout-align="space-between">
        <span om-flex>MEDICATION</span>
        <span om-flex>ASSIGNED TO</span>
        <span om-flex>REQUEST TYPE</span>
      </div>
    </omg-section-title>
  </div>
  <omg-change-rx-list-item *ngFor="let changeRx of changeRxs"
                           [changeRx]="changeRx"></omg-change-rx-list-item>
</ng-container>
<ng-container *ngIf="renewals$ | ngrxPush as renewals">
  <div class="data-table"
       *ngIf="renewals.length > 0">
    <omg-section-title *ngIf="changeRxEnabled$ | ngrxPush">
      Renewal Requests ({{renewals.length}})
    </omg-section-title>
    <omg-section-title [altBackgroundColor]="true">
      <div om-layout
           om-layout-fill
           om-layout-gutter
           om-layout-align="space-between">
        <span om-flex>MEDICATION</span>
        <span om-flex>ASSIGNED TO</span>
        <span om-flex>REQUESTED BY</span>
      </div>
    </omg-section-title>
  </div>
  <omg-renewal-list-item *ngFor="let renewal of renewals"
                         [renewal]="renewal"></omg-renewal-list-item>
</ng-container>
<omg-ready-for-checkout *ngIf="!(cartComplete$ | ngrxPush)"
                        [isListView]="true"></omg-ready-for-checkout>
