<div>
  <label class="padding-normal-horizontal"
         om-layout
         om-layout-gutter
         om-layout-align="start center"
         data-cy="task-template">
    <span om-flex="25">Task Template</span>
    <ng-container *ngIf="showDropdown; then templateForm"></ng-container>
    <ng-container *ngIf="!showDropdown; then currentTemplate"></ng-container>
  </label>
</div>


<ng-template #currentTemplate>
  <div om-flex
       om-layout
       om-layout-gutter
       om-layout-align="start center">
    <span>{{todo.taskTemplateName}}</span>
    <button type="button"
            omgButton
            variant="link"
            *ngIf="this.todo.taskTemplateIsChangeable"
            (click)="toggleDropdown()"
            data-cy="edit-task-template-link">
      Edit
    </button>
  </div>
</ng-template>

<ng-template #templateForm>
  <div [formGroup]="form"
       om-layout
       om-layout-gutter
       om-layout-align="start center"
       om-layout-fill>
    <omg-auto-complete [clearable]="true"
                       om-flex
                       autoWidth="true"
                       [items]="taskTemplateTypes"
                       bindLabel="label"
                       bindValue="value"
                       trackByKey="value"
                       formControlName="taskTemplateId"
                       data-cy="misc-task-template-select"
                       placeholder="Select Template">
    </omg-auto-complete>
    <button type="button"
            om-flex="5"
            omgButton
            variant="primary"
            data-cy="apply-new-note-template-button"
            [disabled]="inProgress"
            (click)="updateTodo()">
      Apply
    </button>
  </div>
</ng-template>
