import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule as NgFormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LetModule, PushModule } from '@ngrx/component';

import { FormStateComponent } from './form-model/form-state.component';
import { FormDirective } from './form-model/form.directive';

const declarations = [FormDirective, FormStateComponent];

@NgModule({
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    NgFormsModule,
    ReactiveFormsModule,
  ],
  exports: [NgFormsModule, ReactiveFormsModule, ...declarations],
  declarations: [...declarations],
})
export class FormsModule {}
