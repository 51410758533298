// @ts-strict-ignore
import { StateActions } from '@app/core/store/shared/state';
import { PrescriptionHistory } from '@app/features/prescription-history/shared/prescription-history.type';

import { CancelPrescribers } from '../../../modules/shared-rx/prescriber-credential.type';

export enum PrescriptionHistoryActionTypes {
  LoadPrescriptionHistoryPrescriberCredentials = '[PrescriptionHistory] Load Prescription History Prescriber Credentials',
  LoadPrescriptionHistoryPrescriberCredentialsSuccess = '[PrescriptionHistory] Load Prescription History Prescriber Credentials Success',
}

export class LoadPrescriptionHistoryPrescriberCredentials {
  readonly type =
    PrescriptionHistoryActionTypes.LoadPrescriptionHistoryPrescriberCredentials;

  constructor(public payload: { prescriptionId: number }) {}
}

export class LoadPrescriptionHistoryPrescriberCredentialsSuccess {
  readonly type =
    PrescriptionHistoryActionTypes.LoadPrescriptionHistoryPrescriberCredentialsSuccess;

  constructor(public payload: { response: CancelPrescribers }) {}
}

export class PrescriptionHistoryActions extends StateActions<
  PrescriptionHistory
> {
  loadPrescriberCredentials(prescriptionId: number) {
    this.store.dispatch(
      new LoadPrescriptionHistoryPrescriberCredentials({ prescriptionId }),
    );
  }
}

export type PrescriptionHistoryAction = LoadPrescriptionHistoryPrescriberCredentialsSuccess;
