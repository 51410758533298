// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { Post } from '@app/modules/messaging/shared/messaging.type';

import { MLNoteRetitleFeedback, Note } from '../shared/note.type';
import { FilingTemplate } from '../shared/recategorize-document.type';
import { NoteState } from './note.reducer';

export enum NoteActionTypes {
  LOAD_NOTE = '[Note] Load Note',
  LOAD_NOTE_SUCCESS = '[Note] Load Note Success',
  LOAD_NOTE_ERROR = '[Note] Load Note Error',
  LOAD_TEMPLATE = '[Template] Load Template',
  LOAD_TEMPLATE_ERROR = '[Template] Load Template Error',
  LOAD_NOTE_WITH_TODO = '[Note] Load Note With Todo',
  LOAD_NOTE_WITH_TODO_SUCCESS = '[Note] Load Note With Todo Success',
  LOAD_NOTE_WITH_TODO_ERROR = '[Note] Load Note With Todo Error',
  SAVE_NOTE = '[Note] Save Note',
  SAVE_NOTE_SUCCESS = '[Note] Save Note Success',
  SAVE_NOTE_ERROR = '[Note] Save Note Error',
  UPDATE_NOTE = '[Note] Update Note',
  UPDATE_NOTE_SUCCESS = '[Note] Update Note Success',
  UPDATE_NOTE_ERROR = '[Note] Update Note Error',
  DELETE_NOTE = '[Note] Delete Note',
  DELETE_LETTER = '[Note] Delete Letter',
  DELETE_NOTE_SUCCESS = '[Note] Delete Note Success',
  DELETE_NOTE_ERROR = '[Note] Delete Note Error',
  NEW_POST_FROM_NOTE = '[Note] New Post From Note',
  NEW_POST_FROM_NOTE_SUCCESS = '[Note] New Post From Note Success',
  NEW_POST_FROM_NOTE_ERROR = '[Note] New Post From Note Error',
  ATTACH_NOTE_DOCUMENTS_TO_POST = '[Note] Attach note documents to new Post',
  RECATEGORIZE_NOTE = '[Note] Recategorize Note',
  RECATEGORIZE_NOTE_SUCCESS = '[Note] Recategorize Note Success',
  RECATEGORIZE_NOTE_ERROR = '[Note] Recategorize Note Error',
  UNFILE_NOTE = '[Note] Unfile Note',
  UNFILE_NOTE_SUCCESS = '[Note] Unfile Note Success',
  UNFILE_NOTE_ERROR = '[Note] Unfile Note Error',
  CLONE_NOTE = '[Note] Clone Note',
  CLONE_NOTE_SUCCESS = '[Note] Clone Note Success',
  CLONE_NOTE_ERROR = '[Note] Clone Note Error',
  REDACT_NOTE = '[Note] Redact Note',
  REDACT_NOTE_SUCCESS = '[Note] Redact Note Success',
  REDACT_NOTE_ERROR = '[Note] Redact Note Errpr',
  GENERATE_LETTER = '[Note] Generate Letter',
  GENERATE_LETTER_SUCCESS = '[Note] Generate Letter Success',
  GENERATE_LETTER_ERROR = '[Note] Generate Letter Error',
  ADD_COMMENT = '[Note] Add Comment',
  REMOVE_COMMENT = '[Note] Remove Comment',
  SEND_ML_NOTE_RETITLE_FORM_FEEDBACK = '[Note] Send ML Note Retitle Form Feedback',
  SEND_ML_NOTE_RETITLE_DISMISSED_FEEDBACK = '[Note] Send ML Note Retitle Dismissed Feedback',
  SEND_ML_NOTE_RETITLE_FEEDBACK_ERROR = '[Note] Send ML Note Retitle Feedback Error',
}

type NoteId = number;
type NoteTypeId = number;
type NoteErrorData = any;

type TemplateId = number;
type TemplateErrorData = any;

interface LoadTemplatePayload {
  templateId: TemplateId;
  letterId: NoteId;
}

interface RecategorizeNoteData {
  noteId: number;
  template: FilingTemplate;
}

interface NoteDocumentToPost {
  note: Note;
  post: Post;
}

export class LoadNote implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.LOAD_NOTE;

  constructor(public payload: NoteId) {}
}

export class LoadNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.LOAD_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class LoadNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.LOAD_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}
export class LoadTemplate implements FsaAction<LoadTemplatePayload> {
  readonly type = NoteActionTypes.LOAD_TEMPLATE;

  constructor(public payload: LoadTemplatePayload) {}
}

export class LoadTemplateError implements FsaAction<TemplateErrorData> {
  readonly type = NoteActionTypes.LOAD_TEMPLATE_ERROR;

  constructor(public payload: TemplateErrorData) {}
}

export class LoadNoteWithTodo implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.LOAD_NOTE_WITH_TODO;

  constructor(public payload: NoteId) {}
}

export class LoadNoteWithTodoSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.LOAD_NOTE_WITH_TODO_SUCCESS;

  constructor(public payload: Note) {}
}

export class LoadNoteWithTodoError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.LOAD_NOTE_WITH_TODO_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class SaveNote implements FsaAction<NoteTypeId> {
  readonly type = NoteActionTypes.SAVE_NOTE;

  constructor(public payload: NoteTypeId) {}
}

export class SaveNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.SAVE_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class SaveNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.SAVE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class UpdateNote implements FsaAction<Note> {
  readonly type = NoteActionTypes.UPDATE_NOTE;

  constructor(public payload: Note) {}
}

export class UpdateNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.UPDATE_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class UpdateNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.UPDATE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class DeleteNote implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.DELETE_NOTE;

  constructor(public payload: NoteId) {}
}

export class DeleteLetter implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.DELETE_LETTER;

  constructor(public payload: NoteId) {}
}

export class DeleteNoteSuccess implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.DELETE_NOTE_SUCCESS;

  constructor(public payload: NoteId) {}
}

export class DeleteNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.DELETE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class NewPostFromNote implements FsaAction<Note> {
  readonly type = NoteActionTypes.NEW_POST_FROM_NOTE;

  constructor(public payload: Note) {}
}

export class NewPostFromNoteSuccess implements FsaAction<Post> {
  readonly type = NoteActionTypes.NEW_POST_FROM_NOTE_SUCCESS;

  constructor(public payload: Post) {}
}

export class NewPostFromNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.NEW_POST_FROM_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class AttachNoteDocumentsToPost
  implements FsaAction<NoteDocumentToPost> {
  readonly type = NoteActionTypes.ATTACH_NOTE_DOCUMENTS_TO_POST;

  constructor(public payload: NoteDocumentToPost) {}
}

export class RecategorizeNote implements FsaAction<RecategorizeNoteData> {
  readonly type = NoteActionTypes.RECATEGORIZE_NOTE;

  constructor(public payload: RecategorizeNoteData) {}
}

export class RecategorizeNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.RECATEGORIZE_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class RecategorizeNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.RECATEGORIZE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class UnfileNote implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.UNFILE_NOTE;

  constructor(public payload: NoteId) {}
}

export class UnfileNoteSuccess implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.UNFILE_NOTE_SUCCESS;

  constructor(public payload: NoteId) {}
}

export class UnfileNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.UNFILE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class CloneNote implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.CLONE_NOTE;

  constructor(public payload: NoteId) {}
}

export class CloneNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.CLONE_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class CloneNoteError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.CLONE_NOTE_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class RedactNote implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.REDACT_NOTE;

  constructor(public payload: NoteId) {}
}

export class RedactNoteSuccess implements FsaAction<Note> {
  readonly type = NoteActionTypes.REDACT_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class RedactNoteError implements FsaAction<Note> {
  readonly type = NoteActionTypes.REDACT_NOTE_ERROR;

  constructor(public payload: Note) {}
}

export class GenerateLetter implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.GENERATE_LETTER;

  constructor(public payload: NoteId) {}
}

export class GenerateLetterSuccess implements FsaAction<NoteId> {
  readonly type = NoteActionTypes.GENERATE_LETTER_SUCCESS;

  constructor(public payload: NoteId) {}
}

export class GenerateLetterError implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.GENERATE_LETTER_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export class AddNoteComment implements FsaAction<Note> {
  readonly type = NoteActionTypes.ADD_COMMENT;

  constructor(public note: Note) {}
}

export class RemoveNoteComment implements FsaAction<Note> {
  readonly type = NoteActionTypes.REMOVE_COMMENT;

  constructor(public note: Note) {}
}

export class SendMLNoteRetitleFormFeedback
  implements FsaAction<MLNoteRetitleFeedback> {
  readonly type = NoteActionTypes.SEND_ML_NOTE_RETITLE_FORM_FEEDBACK;

  constructor(public feedback: MLNoteRetitleFeedback) {}
}

export class SendMLNoteRetitleDismissedFeedback implements FsaAction<Note> {
  readonly type = NoteActionTypes.SEND_ML_NOTE_RETITLE_DISMISSED_FEEDBACK;

  constructor(public note: Note) {}
}

export class SendMLNoteRetitleFeedbackError
  implements FsaAction<NoteErrorData> {
  readonly type = NoteActionTypes.SEND_ML_NOTE_RETITLE_FEEDBACK_ERROR;

  constructor(public payload: NoteErrorData) {}
}

export type NoteAction =
  | LoadNote
  | LoadNoteSuccess
  | LoadNoteError
  | LoadTemplate
  | LoadTemplateError
  | LoadNoteWithTodo
  | LoadNoteWithTodoSuccess
  | LoadNoteWithTodoError
  | SaveNote
  | SaveNoteSuccess
  | SaveNoteError
  | UpdateNote
  | UpdateNoteSuccess
  | UpdateNoteError
  | DeleteNote
  | DeleteLetter
  | DeleteNoteSuccess
  | DeleteNoteError
  | NewPostFromNote
  | NewPostFromNoteSuccess
  | NewPostFromNoteError
  | AttachNoteDocumentsToPost
  | RecategorizeNote
  | RecategorizeNoteSuccess
  | RecategorizeNoteError
  | UnfileNote
  | UnfileNoteSuccess
  | UnfileNoteError
  | CloneNote
  | CloneNoteSuccess
  | CloneNoteError
  | RedactNote
  | RedactNoteSuccess
  | RedactNoteError
  | GenerateLetter
  | GenerateLetterSuccess
  | GenerateLetterError
  | AddNoteComment
  | RemoveNoteComment
  | SendMLNoteRetitleFormFeedback
  | SendMLNoteRetitleDismissedFeedback
  | SendMLNoteRetitleFeedbackError;

@Injectable()
export class NoteActions {
  constructor(private store: Store<NoteState>) {}

  loadNote(noteId: number) {
    this.store.dispatch(new LoadNote(noteId));
  }

  loadTemplate(templateId: number, letterId: number) {
    this.store.dispatch(new LoadTemplate({ templateId, letterId }));
  }

  loadNoteWithTodo(noteId: number) {
    this.store.dispatch(new LoadNoteWithTodo(noteId));
  }

  saveNote(noteTypeId: number) {
    this.store.dispatch(new SaveNote(noteTypeId));
  }

  updateNote(note: Note) {
    this.store.dispatch(new UpdateNote(note));
  }

  updateNoteSuccess(note: Note) {
    this.store.dispatch(new UpdateNoteSuccess(note));
  }

  deleteNote(noteId: number) {
    this.store.dispatch(new DeleteNote(noteId));
  }

  deleteLetter(letterId: number) {
    this.store.dispatch(new DeleteLetter(letterId));
  }

  newPostFromNote(note: Note) {
    this.store.dispatch(new NewPostFromNote(note));
  }

  recategorizeNote(noteId: number, template: FilingTemplate) {
    this.store.dispatch(new RecategorizeNote({ noteId, template }));
  }

  unfileNote(noteId: number) {
    this.store.dispatch(new UnfileNote(noteId));
  }

  cloneNote(noteId: number) {
    this.store.dispatch(new CloneNote(noteId));
  }

  redactNote(noteId: NoteId) {
    this.store.dispatch(new RedactNote(noteId));
  }

  generateLetter(noteId: NoteId) {
    this.store.dispatch(new GenerateLetter(noteId));
  }

  addNoteComment(note: Note): void {
    this.store.dispatch(new AddNoteComment(note));
  }

  removeNoteComment(note: Note): void {
    this.store.dispatch(new RemoveNoteComment(note));
  }

  sendMLNoteRetitleFormFeedback(feedback: MLNoteRetitleFeedback): void {
    this.store.dispatch(new SendMLNoteRetitleFormFeedback(feedback));
  }

  sendMLNoteRetitleDismissedFeedback(note: Note): void {
    this.store.dispatch(new SendMLNoteRetitleDismissedFeedback(note));
  }
}
