<omg-section-title>
  <div om-layout om-layout-fill om-layout-align="space-between center">
    <span>Prevention & Screening</span>
    <span class="om-icon clickable fa fa-trash" (click)="deleteFromNote()" *ngIf="!signed" omgStopEvent="click"
      omgTooltip tooltipText="Delete from Note" tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal" om-layout="vertical" om-layout-gutter>
  <li class="om-animate" *ngIf="healthMaintenanceNote.content">
    <p>{{ healthMaintenanceNote.content }}</p>
  </li>
  <li class="om-animate" *ngFor="let healthGoal of healthGoals; trackBy: trackByFn">
    <b>{{ healthGoal.healthGoalType.name }}</b>
    <div *ngIf="healthGoal.healthGoalAction">
      {{ healthGoal.healthGoalAction.name }}
      <span *ngIf="healthGoal.dueAt"> - due {{ healthGoal.dueAt | omgDate: '2y' }}</span>
      <span *ngIf="healthGoal.declined"> - declined by pt</span>
    </div>
    <p>{{ healthGoal.comment }}</p>

    <div *ngIf="healthGoal.healthGoalScreenings.length > 0">
      <div class="data-table">
        <omg-section-title [altBackgroundColor]="true" *ngIf="!vaccineRecommendationEnabled">
          Screening History
        </omg-section-title>

        <omg-section-title [altBackgroundColor]="true" *ngIf="vaccineRecommendationEnabled">
          Screening and Vaccine History
        </omg-section-title>
        <div class="row" *ngFor="let screening of healthGoal.healthGoalScreenings">
          <div om-flex="20">{{ screening.dateFuzzy }}</div>
          <div om-flex="40" *ngIf="screening.event">{{ screening.event.name }}</div>
          <div om-flex="20">{{ screening.result }}</div>
        </div>
      </div>
    </div>
  </li>
</ul>