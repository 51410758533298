import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-procedure-suggestion-explainability',
  templateUrl: './procedure-suggestion-explainability.component.html',
  styleUrls: ['./procedure-suggestion-explainability.component.scss'],
})
export class ProcedureSuggestionExplainabilityComponent {
  @Input() predictiveWords: String;
  @Input() confidence: String;

  constructor() {}
}
