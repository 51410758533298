// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';

import { DateFormats, formatDate } from '@app/utils';

import {
  MismatchedPatientDetail,
  MismatchedPatientDetails,
} from './../../shared/renewals.type';

@Component({
  selector: 'omg-mismatched-patient-details',
  templateUrl: './mismatched-patient-details.component.html',
})
export class MismatchedPatientDetailsComponent implements OnInit {
  @Input() details: MismatchedPatientDetails;
  @Input() pharmacyName: string;
  addressDetails: Partial<MismatchedPatientDetails>;

  constructor() {}

  ngOnInit() {
    if (this.details.dateOfBirth) {
      this.formatDateOfBirth();
    }
    this.addressDetails = {
      city: this.details.city,
      state: this.details.state,
      zipCode: this.details.zipCode,
    };
  }

  private formatDateOfBirth() {
    this.details.dateOfBirth = {
      ...this.details.dateOfBirth,
      ...this.formatDetailDate(this.details.dateOfBirth),
    };
  }

  private formatDetailDate(
    detail: MismatchedPatientDetail,
  ): Partial<MismatchedPatientDetail> {
    return {
      requested: formatDate(detail.requested, DateFormats['4y']),
      matched: formatDate(detail.matched, DateFormats['4y']),
    };
  }
}
