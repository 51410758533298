// @ts-strict-ignore
import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'omg-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnDestroy, OnInit, OnChanges {
  @Input() staticTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() loadingTemplate: TemplateRef<any>;
  @Input() lazyLoadingTemplate: TemplateRef<any>;
  @Input() items: any[];
  @Input() totalItems: number;
  @Input() itemHeight: number;
  @Input() minBufferPx = 100;
  @Input() maxBufferPx = 200;
  @Input() fixedScrollHeight: number = null;
  @Input() templateCacheSize = 20;
  @Input() loading = false;
  @Input() lazyLoadingEnabled: boolean;
  @Input() lazyLoading = false;
  @Output() lazyLoadEvent = new EventEmitter<Event>();

  unsubscribe$ = new Subject<void>();

  isListScrolled = false;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;

  @ViewChild('staticTemplateRef')
  staticTemplateRef: ElementRef<Element>;

  ngOnInit() {
    if (this.lazyLoadingEnabled) {
      this.listenForLazyLoadEvent();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && changes.loading.currentValue) {
      const staticTemplateOffset = this.staticTemplateRef
        ? this.staticTemplateRef.nativeElement.clientHeight
        : 0;
      this.isListScrolled =
        this.viewport.measureScrollOffset('top') - staticTemplateOffset > 0;
    }
  }

  trackBy = (_, item) => item.id;

  checkVirtualScrollSize() {
    this.viewport.checkViewportSize();
  }

  private listenForLazyLoadEvent(): void {
    this.viewport.renderedRangeStream
      .pipe(
        filter(res => this.checkIfItemsCanBeLoaded(res)),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.lazyLoadEvent.emit());
  }

  private checkIfItemsCanBeLoaded(listRange: ListRange) {
    return (
      listRange.end >= this.items.length &&
      this.items.length < this.totalItems &&
      !this.lazyLoading
    );
  }
}
