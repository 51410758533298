import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ChangeRx, ChangeRxTypes } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-list-item',
  templateUrl: './change-rx-list-item.component.html',
  styleUrls: ['./change-rx-list-item.component.scss'],
})
export class ChangeRxListItemComponent {
  @Input() changeRx: ChangeRx;

  changeRxTypes = ChangeRxTypes;

  constructor(private router: Router, private route: ActivatedRoute) {}

  routeToDetail() {
    this.router.navigate([`../change/${this.changeRx.id}`], {
      relativeTo: this.route.parent,
    });
  }
}
