import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  SectionLinkedItem,
  SectionLinkedQueueState,
} from '@app/core/section-linked-queue/store/section-linked-queue.reducer';
import { selectSectionLinkedHead } from '@app/core/section-linked-queue/store/section-linked-queue.selectors';

import * as SectionLinkedQueueActions from './store/section-linked-queue.actions';

/* Upgrade Notice
 * This service will be deprecated once the patient chart is completely downgraded
 * and section linking is completely handled in Angular 7.
 */

@Injectable()
export class SectionLinkedQueueService {
  sectionLinkedItem$: Observable<SectionLinkedItem | null> = this.store.pipe(
    select(selectSectionLinkedHead),
  );

  constructor(private store: Store<SectionLinkedQueueState>) {}

  addSectionLink(sectionKey: string, payload: any, eventKey = 'sectionLinked') {
    const sectionLinkedItem = { sectionKey, payload, eventKey };
    this.store.dispatch(
      new SectionLinkedQueueActions.AddSectionLink(sectionLinkedItem),
    );
  }

  removeSectionLink() {
    this.store.dispatch(new SectionLinkedQueueActions.RemoveSectionLink());
  }
}
