<div om-layout
     om-layout-gutter-large
     om-layout-align="start center">
  <label om-layout
         class="plain-text"
         om-layout-gutter
         om-layout-align="start center">
    <span>Qty</span>
    <div *ngIf="packageOptions?.defaultOption?.matchedOption; else staticDisplayText"
         om-layout
         om-layout-align="start center"
         om-layout-gutter-mini>
      <input omgInputNumber
             omgTrimTrailingZeros
             type="number"
             class="-small"
             [formControl]="quantity"
             data-cy="rx-renewal-approved-quantity-input" />
      <span *ngIf="packageOptions.total === 1; else packageOptionsSelector">
        {{ packageDescription }}
      </span>
    </div>
  </label>
</div>

<ng-template #staticDisplayText>
  <span>{{packageOptions.defaultOption.staticDisplay}}</span>
</ng-template>

<ng-template #packageOptionsSelector>
  <omg-auto-complete #packageSizeInput
                     [clearable]="false"
                     [omgFocusOn]="focusOnMedicationKey"
                     *ngIf="!!medicationPackageSizeId"
                     [formControl]="medicationPackageSizeId"
                     [items]="packageOptions.availableOptions"
                     bindValue="id"
                     [bindLabel]="quantity.value === 1 ? 'desc' : 'descPlural'"
                     [fillLayout]="false"
                     [resetItemsOnClear]="false"
                     (focusOnRequest)="packageSizeInput.focus()"
                     trackByKey="value">
  </omg-auto-complete>
</ng-template>
