import { Component, Input } from '@angular/core';

import { ChangeRxForm } from '../../shared/change-rx-form';

@Component({
  selector: 'omg-change-rx-prior-auth-status',
  templateUrl: './change-rx-prior-auth-status.component.html',
  styleUrls: ['./change-rx-prior-auth-status.component.css'],
})
export class ChangeRxPriorAuthStatusComponent {
  @Input() form: ChangeRxForm;
}
