import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  take,
  tap,
  timeout,
} from 'rxjs';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { InternalUserInfoService } from '@app/shared/components/internal-user-info/internal-user-info.service';
import { filterTruthy } from '@app/utils';

import { PatientMedicationSelectors } from '../store';
import {
  PatientMedicationsApiService,
  ResourceNotFoundError,
} from './patient-medications-api.service';
import {
  MedicationsReconciliationEvent,
  MedsRecNotification,
} from './patient-medications.type';

@Injectable({
  providedIn: 'root',
})
export class MedsRecService implements OnDestroy {
  private latestMedsRecSubject = new ReplaySubject<MedicationsReconciliationEvent | null>(
    1,
  );
  medsRec$ = this.latestMedsRecSubject.asObservable();

  private medsListReviewedSubject = new BehaviorSubject<boolean>(false);
  medsListReviewed$ = this.medsListReviewedSubject.asObservable();

  private medsRecNotificationSubject = new ReplaySubject<MedsRecNotification | null>(
    1,
  );
  medsRecNotification$ = this.medsRecNotificationSubject.asObservable();

  private activePatientMedicationsStream: Subscription;

  constructor(
    private patientMedsApi: PatientMedicationsApiService,
    private patientSelectors: PatientSelectors,
    private internalUserInfoService: InternalUserInfoService,
    private profileSelectors: ProfileSelectors,
    private patientMedicationSelectors: PatientMedicationSelectors,
  ) {
    this.setupReviewReactiveStream();
  }

  /**
   * Fetches the latest meds rec event for the patient and publishes it to the medsRec observable.
   * If no entity found, then a null value is published.
   *
   * @returns -- Observable which emits ones the call is complete
   */
  fetchLatestMedsRec$(): Observable<boolean> {
    return this.getCurrentPatientId$().pipe(
      mergeMap(patientId => this.patientMedsApi.getLatestMedsRec(patientId)),
      filterTruthy(),
      mergeMap(medsRec => this.addReviewNameToMedsRec(medsRec)),
      tap(medsRec => this.latestMedsRecSubject.next(medsRec)),
      catchError(err => {
        if (err instanceof ResourceNotFoundError) {
          console.log('No meds rec found for patient');
          this.latestMedsRecSubject.next(null);
          return of(null);
        }
        this.latestMedsRecSubject.error(err); // 500 or unknown error
        return of(null);
      }),
      map(_result => true),
      take(1),
    );
  }

  /**
   * Creates and saves a new meds rec event. The new event is published to the medsRecEvent subject once
   * saved.
   *
   * @param reviewedAt -- time at which the meds list was reviewed. This should be an ISO8601 timestamp
   * @returns -- an observable which emits the status of the save.
   */
  createMedsRec$(reviewedAt: string): Observable<boolean> {
    return this.getCurrentPatientId$().pipe(
      mergeMap(patientId =>
        this.patientMedsApi.saveMedsRec(patientId, reviewedAt),
      ),
      mergeMap(medsRec => this.addReviewNameToMedsRec(medsRec)),
      tap(() => this.medsListReviewedSubject.next(true)),
      tap(newMedsRec => this.latestMedsRecSubject.next(newMedsRec)),
      map(newMedsRec => !!newMedsRec),
      take(1),
    );
  }

  /**
   * Fetches any meds rec notification and publishes it to the meds rec notification subject.
   *
   * @returns -- an observable which completes when the fetch call completes
   */
  fetchNotification(): Observable<void> {
    return this.getCurrentPatientId$().pipe(
      mergeMap(patientId =>
        this.patientMedsApi.getMedsRecNotification(patientId),
      ),
      tap(medsRecNotification =>
        this.medsRecNotificationSubject.next(medsRecNotification),
      ),
      catchError(err => {
        console.log(`Error when getting meds rec notification ${err}`);
        return of(null);
      }),
      mergeMap(_result => EMPTY),
      take(1),
    );
  }

  /**
   * Checks if the current user has permissions to create a meds rec. Returns an observable which emits true.
   *
   * @returns -- Observable which emits true or false if user has permissions to create a meds rec
   */
  doesUserHavePermissionsToCreateMedsRec(): Observable<Boolean> {
    return this.profileSelectors.hasRole('provider');
  }

  ngOnDestroy(): void {
    this.activePatientMedicationsStream?.unsubscribe();
  }

  /**
   * Setup reactive observable to reset the review status whenever the patient's meds list changes.
   */
  private setupReviewReactiveStream(): void {
    this.activePatientMedicationsStream = this.patientMedicationSelectors
      .activePatientMedications()
      .subscribe(() => {
        this.medsListReviewedSubject.next(false);
      });
  }

  private getCurrentPatientId$(): Observable<number> {
    return this.patientSelectors.patientId.pipe(take(1));
  }

  private addReviewNameToMedsRec(
    medsRec: MedicationsReconciliationEvent,
  ): Observable<MedicationsReconciliationEvent> {
    return this.internalUserInfoService.get(medsRec.reviewedByUserId + '').pipe(
      filterTruthy(),
      timeout(2000),
      map(userInfo => {
        medsRec.reviewerDetails = {
          name: userInfo?.firstName + ' ' + userInfo?.lastName,
        };
        return medsRec;
      }),
      catchError(err => {
        console.error(`Failed to fetch internal user info: ${err}`);
        return of(medsRec);
      }),
    );
  }
}
