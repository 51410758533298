<div class="banner -info"
     *ngIf="fromPharmacist">
  <div class="header">Note From Pharmacy</div>
  {{fromPharmacist}}
</div>

<div class="banner -info"
     *ngIf="fromPatient">
  <div class="header">Note From Patient</div>
  <p>{{fromPatient}}</p>
</div>

<div class="banner -info"
     *ngIf="cancellationReason">
  <p>{{cancellationReason}}</p>
</div>
