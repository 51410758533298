// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core';

import { mapInternalUserPreferencesReponseToEntity } from './internal-user-preferences-mappers';
import {
  InternalUserPreferences,
  InternalUserPreferencesResponse,
} from './internal-user-preferences.type';

@Injectable()
export class InternalUserPreferencesService {
  private readonly preferencesPath = '/v2/admin/internal_user_preferences';

  constructor(private apiService: ApiService) {}

  get(): Observable<InternalUserPreferences> {
    return this.apiService
      .get<InternalUserPreferencesResponse>(this.preferencesPath)
      .pipe(map(mapInternalUserPreferencesReponseToEntity));
  }
}
