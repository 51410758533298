import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EncounterState } from './encounter.reducer';

export const selectEncounterState = createFeatureSelector<EncounterState>(
  'encounter',
);

export const selectSectionsLinkable = createSelector(
  selectEncounterState,
  (encounterState: EncounterState) => encounterState.sectionsLinkable,
);
