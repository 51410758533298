// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import { Profile } from '../shared/profile.type';
import { ProfileState } from './profile.reducer';

export const GET_PROFILE = '[Profile] Get Profile';
export const GET_PROFILE_SUCCESS = '[Profile] Get Profile Success';
export const GET_PROFILE_ERROR = '[Profile] Get Profile Error';

type GetProfileErrorPayload = any;

export class GetProfile implements FsaAction<void> {
  readonly type = GET_PROFILE;
  error = false;
}

export class GetProfileSuccess implements FsaAction<Profile> {
  readonly type = GET_PROFILE_SUCCESS;
  error = false;

  constructor(public payload: Profile) {}
}

export class GetProfileError implements FsaAction<any> {
  readonly type = GET_PROFILE_ERROR;
  error = true;

  constructor(public payload: GetProfileErrorPayload) {}
}

export type ProfileAction = GetProfile | GetProfileSuccess | GetProfileError;

@Injectable()
export class ProfileActions {
  constructor(private store: Store<ProfileState>) {}

  getProfile() {
    this.store.dispatch(new GetProfile());
  }
}
