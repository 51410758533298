import { CM_PER_INCH } from './constants';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeInfantLengthLMSParams: LMSParamsMap = {
  1: { lambda: 0.453, mu: 52.632, sigma: 0.039 },
  2: { lambda: 0.453, mu: 55.065, sigma: 0.04 },
  3: { lambda: 0.453, mu: 57.45, sigma: 0.04 },
  4: { lambda: 0.453, mu: 59.745, sigma: 0.04 },
  5: { lambda: 0.453, mu: 61.904, sigma: 0.04 },
  6: { lambda: 0.453, mu: 63.894, sigma: 0.041 },
  7: { lambda: 0.453, mu: 65.702, sigma: 0.041 },
  8: { lambda: 0.453, mu: 67.329, sigma: 0.041 },
  9: { lambda: 0.453, mu: 68.783, sigma: 0.041 },
  10: { lambda: 0.453, mu: 70.09, sigma: 0.042 },
  11: { lambda: 0.453, mu: 71.276, sigma: 0.042 },
  12: { lambda: 0.453, mu: 72.364, sigma: 0.042 },
  13: { lambda: 0.453, mu: 73.375, sigma: 0.042 },
  14: { lambda: 0.453, mu: 74.328, sigma: 0.043 },
  15: { lambda: 0.453, mu: 75.231, sigma: 0.043 },
  16: { lambda: 0.453, mu: 76.086, sigma: 0.043 },
  17: { lambda: 0.453, mu: 76.897, sigma: 0.043 },
  18: { lambda: 0.453, mu: 77.664, sigma: 0.043 },
  19: { lambda: 0.453, mu: 78.391, sigma: 0.044 },
  20: { lambda: 0.453, mu: 79.079, sigma: 0.044 },
  21: { lambda: 0.453, mu: 79.73, sigma: 0.044 },
  22: { lambda: 0.453, mu: 80.35, sigma: 0.044 },
  23: { lambda: 0.453, mu: 80.942, sigma: 0.044 },
  24: { lambda: 0.453, mu: 81.511, sigma: 0.044 },
  25: { lambda: 0.453, mu: 82.057, sigma: 0.044 },
  26: { lambda: 0.453, mu: 82.583, sigma: 0.045 },
  27: { lambda: 0.453, mu: 83.09, sigma: 0.045 },
  28: { lambda: 0.453, mu: 83.58, sigma: 0.045 },
  29: { lambda: 0.453, mu: 84.056, sigma: 0.045 },
  30: { lambda: 0.453, mu: 84.522, sigma: 0.045 },
  31: { lambda: 0.453, mu: 84.979, sigma: 0.045 },
  32: { lambda: 0.453, mu: 85.431, sigma: 0.045 },
  33: { lambda: 0.453, mu: 85.88, sigma: 0.045 },
  34: { lambda: 0.453, mu: 86.328, sigma: 0.045 },
  35: { lambda: 0.453, mu: 86.774, sigma: 0.046 },
  36: { lambda: 0.453, mu: 87.222, sigma: 0.046 },
};

const femaleDownSyndromeInfantLengthLMSParams: LMSParamsMap = {
  1: { lambda: 1.957, mu: 52.958, sigma: 0.037 },
  2: { lambda: 1.957, mu: 54.934, sigma: 0.038 },
  3: { lambda: 1.957, mu: 56.904, sigma: 0.038 },
  4: { lambda: 1.957, mu: 58.84, sigma: 0.038 },
  5: { lambda: 1.957, mu: 60.704, sigma: 0.039 },
  6: { lambda: 1.957, mu: 62.466, sigma: 0.039 },
  7: { lambda: 1.957, mu: 64.1, sigma: 0.039 },
  8: { lambda: 1.957, mu: 65.594, sigma: 0.04 },
  9: { lambda: 1.957, mu: 66.953, sigma: 0.04 },
  10: { lambda: 1.957, mu: 68.19, sigma: 0.04 },
  11: { lambda: 1.957, mu: 69.318, sigma: 0.041 },
  12: { lambda: 1.957, mu: 70.357, sigma: 0.041 },
  13: { lambda: 1.957, mu: 71.328, sigma: 0.041 },
  14: { lambda: 1.957, mu: 72.244, sigma: 0.042 },
  15: { lambda: 1.957, mu: 73.115, sigma: 0.042 },
  16: { lambda: 1.957, mu: 73.942, sigma: 0.042 },
  17: { lambda: 1.957, mu: 74.729, sigma: 0.043 },
  18: { lambda: 1.957, mu: 75.477, sigma: 0.043 },
  19: { lambda: 1.957, mu: 76.19, sigma: 0.043 },
  20: { lambda: 1.957, mu: 76.872, sigma: 0.043 },
  21: { lambda: 1.957, mu: 77.529, sigma: 0.043 },
  22: { lambda: 1.957, mu: 78.163, sigma: 0.044 },
  23: { lambda: 1.957, mu: 78.774, sigma: 0.044 },
  24: { lambda: 1.957, mu: 79.361, sigma: 0.044 },
  25: { lambda: 1.957, mu: 79.925, sigma: 0.044 },
  26: { lambda: 1.957, mu: 80.465, sigma: 0.044 },
  27: { lambda: 1.957, mu: 80.982, sigma: 0.045 },
  28: { lambda: 1.957, mu: 81.48, sigma: 0.045 },
  29: { lambda: 1.957, mu: 81.964, sigma: 0.045 },
  30: { lambda: 1.957, mu: 82.438, sigma: 0.045 },
  31: { lambda: 1.957, mu: 82.908, sigma: 0.045 },
  32: { lambda: 1.957, mu: 83.377, sigma: 0.045 },
  33: { lambda: 1.957, mu: 83.849, sigma: 0.046 },
  34: { lambda: 1.957, mu: 84.328, sigma: 0.046 },
  35: { lambda: 1.957, mu: 84.819, sigma: 0.046 },
  36: { lambda: 1.957, mu: 85.323, sigma: 0.046 },
};

export const getDownSyndromeInfantHeightPercentile = (
  gender: string,
  ageInMonths: number,
  length: number,
) => {
  const lengthInCm = length * CM_PER_INCH;

  const dsHeightLMSParams =
    gender === 'Female'
      ? femaleDownSyndromeInfantLengthLMSParams
      : maleDownSyndromeInfantLengthLMSParams;

  return zScoreFromMeasurement(lengthInCm, ageInMonths, dsHeightLMSParams);
};
