<div om-layout="vertical"
     *ngIf="medication">
  <div om-layout
       om-layout-align="space-between">
    <div om-flex="70">
      <omg-checkout-list-item-medication-display [medForDisplay]="medication.medForDisplay"
                                                 [deaSchedule]="medication.dispensableDeaCode"
                                                 [earliestFillDate]="medication.earliestFillDate"
                                                 [denialReason]="$any(medication).denialReason"
                                                 [refillText]="refillText"
                                                 [originalMedForDisplay]="$any(medication).originalRx?.dispensedMedForDisplay">
      </omg-checkout-list-item-medication-display>
      <omg-checkout-prescriber-display *ngIf="isRenewal"
                                       [prescriber]="$any(medication).prescriber"
                                       [prescribingCredential]="$any(medication).prescribingCredential">
      </omg-checkout-prescriber-display>
      <div *ngIf="medication.dispenseAsWritten">Do Not Substitute</div>
      <div *ngIf="!medication.dispenseAsWritten">Substitutions Allowed</div>
      <div class="-wrapped-text"
           *ngIf="medication.notesToPharmacist || medication.notesLegalAddendum">
        Note to pharmacist: {{ medication.notesToPharmacist }}{{ medication.notesLegalAddendum }}
      </div>
    </div>
    <div *ngIf="medication.require2Fa && !(showPDMP$ | ngrxPush)"
         om-flex
         om-layout="vertical"
         om-layout-align="start end">
      <omg-checkbox label="Ready to sign"
                    class="check-label"
                    plainLabel="true"
                    [formControl]="readyToSign"
                    required="true"
                    (click)="onReadyToSign()"
                    [ngClass]="{'-checked': readyToSign.value, '-unchecked': !readyToSign.value }"
                    data-cy="new-rx-ready-to-sign-checkbox">
      </omg-checkbox>
    </div>
  </div>
  <div class="banner -warning"
       om-layout="vertical"
       om-layout-gutter
       *ngIf="showMustHandWrite">
    <div>{{ messages.mustHandwrite }}</div>
    <div *ngIf="$any(medication)?.rxReferenceNumber">
      <p>The following text must be included on the prescription:</p>
      <br />
      <b>Response to EPCS renewal request. Ref. # {{ $any(medication).rxReferenceNumber }}</b>
    </div>
    <div class="padding-normal-horizontal">
      <omg-checkbox [label]="messages.handwrittenConfirmation"
                    [plainLabel]="true"
                    [required]="true"
                    (ngModelChange)="onHandwrittenConfirmation($event)"
                    [formControl]="handwrittenConfirmed"
                    data-cy="new-rx-handwritten-confirmation-checkbox"></omg-checkbox>
    </div>
  </div>
</div>
