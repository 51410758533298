import { convertAgeToHalfMonths } from '../vitals-utils';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeInfantWeightLMSParams: LMSParamsMap = {
  0: { lambda: 0.576, mu: 3.177, sigma: 0.151 },
  1: { lambda: 0.559, mu: 3.867, sigma: 0.148 },
  2: { lambda: 0.542, mu: 4.562, sigma: 0.146 },
  3: { lambda: 0.526, mu: 5.247, sigma: 0.144 },
  4: { lambda: 0.511, mu: 5.903, sigma: 0.141 },
  5: { lambda: 0.496, mu: 6.504, sigma: 0.139 },
  6: { lambda: 0.482, mu: 7.036, sigma: 0.138 },
  7: { lambda: 0.469, mu: 7.5, sigma: 0.136 },
  8: { lambda: 0.457, mu: 7.902, sigma: 0.135 },
  9: { lambda: 0.446, mu: 8.251, sigma: 0.134 },
  10: { lambda: 0.436, mu: 8.559, sigma: 0.133 },
  11: { lambda: 0.427, mu: 8.834, sigma: 0.132 },
  12: { lambda: 0.419, mu: 9.084, sigma: 0.131 },
  13: { lambda: 0.412, mu: 9.315, sigma: 0.131 },
  14: { lambda: 0.405, mu: 9.531, sigma: 0.13 },
  15: { lambda: 0.399, mu: 9.735, sigma: 0.13 },
  16: { lambda: 0.393, mu: 9.928, sigma: 0.13 },
  17: { lambda: 0.388, mu: 10.112, sigma: 0.129 },
  18: { lambda: 0.383, mu: 10.288, sigma: 0.129 },
  19: { lambda: 0.378, mu: 10.457, sigma: 0.129 },
  20: { lambda: 0.373, mu: 10.62, sigma: 0.129 },
  21: { lambda: 0.369, mu: 10.777, sigma: 0.129 },
  22: { lambda: 0.365, mu: 10.93, sigma: 0.129 },
  23: { lambda: 0.361, mu: 11.08, sigma: 0.129 },
  24: { lambda: 0.357, mu: 11.227, sigma: 0.129 },
  25: { lambda: 0.353, mu: 11.371, sigma: 0.129 },
  26: { lambda: 0.349, mu: 11.513, sigma: 0.129 },
  27: { lambda: 0.346, mu: 11.652, sigma: 0.129 },
  28: { lambda: 0.342, mu: 11.789, sigma: 0.129 },
  29: { lambda: 0.339, mu: 11.922, sigma: 0.129 },
  30: { lambda: 0.335, mu: 12.053, sigma: 0.129 },
  31: { lambda: 0.332, mu: 12.182, sigma: 0.129 },
  32: { lambda: 0.328, mu: 12.308, sigma: 0.13 },
  33: { lambda: 0.325, mu: 12.432, sigma: 0.13 },
  34: { lambda: 0.322, mu: 12.554, sigma: 0.13 },
  35: { lambda: 0.319, mu: 12.674, sigma: 0.13 },
  36: { lambda: 0.316, mu: 12.794, sigma: 0.13 },
};

const femaleDownSyndromeInfantWeightLMSParams: LMSParamsMap = {
  0: { lambda: 0.192, mu: 3.069, sigma: 0.145 },
  1: { lambda: 0.131, mu: 3.623, sigma: 0.144 },
  2: { lambda: 0.071, mu: 4.171, sigma: 0.143 },
  3: { lambda: 0.012, mu: 4.706, sigma: 0.143 },
  4: { lambda: -0.045, mu: 5.22, sigma: 0.142 },
  5: { lambda: -0.1, mu: 5.706, sigma: 0.141 },
  6: { lambda: -0.152, mu: 6.161, sigma: 0.141 },
  7: { lambda: -0.201, mu: 6.582, sigma: 0.141 },
  8: { lambda: -0.248, mu: 6.968, sigma: 0.14 },
  9: { lambda: -0.291, mu: 7.323, sigma: 0.14 },
  10: { lambda: -0.331, mu: 7.648, sigma: 0.14 },
  11: { lambda: -0.368, mu: 7.947, sigma: 0.14 },
  12: { lambda: -0.402, mu: 8.223, sigma: 0.14 },
  13: { lambda: -0.434, mu: 8.478, sigma: 0.14 },
  14: { lambda: -0.463, mu: 8.716, sigma: 0.14 },
  15: { lambda: -0.49, mu: 8.939, sigma: 0.14 },
  16: { lambda: -0.515, mu: 9.148, sigma: 0.14 },
  17: { lambda: -0.539, mu: 9.346, sigma: 0.14 },
  18: { lambda: -0.561, mu: 9.535, sigma: 0.14 },
  19: { lambda: -0.582, mu: 9.716, sigma: 0.14 },
  20: { lambda: -0.601, mu: 9.889, sigma: 0.141 },
  21: { lambda: -0.62, mu: 10.056, sigma: 0.141 },
  22: { lambda: -0.637, mu: 10.217, sigma: 0.141 },
  23: { lambda: -0.654, mu: 10.374, sigma: 0.141 },
  24: { lambda: -0.67, mu: 10.526, sigma: 0.141 },
  25: { lambda: -0.686, mu: 10.674, sigma: 0.142 },
  26: { lambda: -0.7, mu: 10.819, sigma: 0.142 },
  27: { lambda: -0.715, mu: 10.96, sigma: 0.142 },
  28: { lambda: -0.729, mu: 11.098, sigma: 0.142 },
  29: { lambda: -0.742, mu: 11.235, sigma: 0.143 },
  30: { lambda: -0.755, mu: 11.37, sigma: 0.143 },
  31: { lambda: -0.768, mu: 11.504, sigma: 0.143 },
  32: { lambda: -0.781, mu: 11.639, sigma: 0.143 },
  33: { lambda: -0.794, mu: 11.774, sigma: 0.144 },
  34: { lambda: -0.807, mu: 11.911, sigma: 0.144 },
  35: { lambda: -0.819, mu: 12.049, sigma: 0.144 },
  36: { lambda: -0.832, mu: 12.19, sigma: 0.145 },
};

export const getDownSyndromeInfantWeightPercentile = (
  gender: string,
  ageInMonths: number,
  weight: number,
) => {
  const dsWeightLMSParams =
    gender === 'Female'
      ? femaleDownSyndromeInfantWeightLMSParams
      : maleDownSyndromeInfantWeightLMSParams;

  return zScoreFromMeasurement(weight, ageInMonths, dsWeightLMSParams);
};
