import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { CommentsState, selectCommentsState } from './comments.reducer';
import {
  selectAllOrderComments,
  selectOrderCommentsError,
  selectOrderCommentsPending,
} from './order-comments.selectors';
import {
  selectAllPostComments,
  selectPostCommentsError,
  selectPostCommentsPending,
} from './post-comments.selectors';
import {
  selectAllProcedureInteractionComments,
  selectProcedureInteractionCommentsError,
  selectProcedureInteractionCommentsPending,
} from './procedure-interaction-comments.selectors';
import {
  selectAllRenewalComments,
  selectRenewalCommentsError,
  selectRenewalCommentsPending,
} from './renewal-comments.selectors';
import {
  selectAllSummaryComments,
  selectSummaryCommentsError,
  selectSummaryCommentsPending,
} from './summary-comments.selectors';
import {
  selectAllChangeRxComments,
  selectChangeRxCommentsError,
  selectChangeRxCommentsPending,
} from './change-rx-comments.selectors';

/* This service should handle the selectors for all the types of comments. */
@Injectable()
export class CommentsSelectors {
  constructor(private store: Store<CommentsState>) {}

  private get state() {
    return this.store.pipe(select(selectCommentsState));
  }

  get summaryAllComments() {
    return this.state.pipe(select(selectAllSummaryComments));
  }

  get summaryCommentsError() {
    return this.state.pipe(select(selectSummaryCommentsError));
  }

  get summaryCommentsPending() {
    return this.state.pipe(select(selectSummaryCommentsPending));
  }

  get postAllComments() {
    return this.state.pipe(select(selectAllPostComments));
  }

  get postCommentsError() {
    return this.state.pipe(select(selectPostCommentsError));
  }

  get postCommentsPending() {
    return this.state.pipe(select(selectPostCommentsPending));
  }

  get allOrderComments() {
    return this.state.pipe(select(selectAllOrderComments));
  }

  get orderCommentsError() {
    return this.state.pipe(select(selectOrderCommentsError));
  }

  get orderCommentsPending() {
    return this.state.pipe(select(selectOrderCommentsPending));
  }

  get renewalAllComments() {
    return this.state.pipe(select(selectAllRenewalComments));
  }

  get renewalCommentsPending() {
    return this.state.pipe(select(selectRenewalCommentsPending));
  }

  get renewalCommentsError() {
    return this.state.pipe(select(selectRenewalCommentsError));
  }

  get changeRxAllComments() {
    return this.state.pipe(select(selectAllChangeRxComments));
  }

  get changeRxCommentsPending() {
    return this.state.pipe(select(selectChangeRxCommentsPending));
  }

  get changeRxCommentsError() {
    return this.state.pipe(select(selectChangeRxCommentsError));
  }

  get procedureInteractionAllComments() {
    return this.state.pipe(select(selectAllProcedureInteractionComments));
  }

  get procedureInteractionCommentsError() {
    return this.state.pipe(select(selectProcedureInteractionCommentsError));
  }

  get procedureInteractionCommentsPending() {
    return this.state.pipe(select(selectProcedureInteractionCommentsPending));
  }
}
