<label>Signed on behalf of</label>
<omg-auto-complete class="om-ui-select -limited-choice"
                   placeholder="Select a provider..."
                   *ngIf="validPrescribers.length > 1"
                   [formControl]="signedOnBehalfOfId"
                   bindValue="id"
                   bindLabel="name"
                   dropdownPosition="top"
                   [items]="validPrescribers"
                   [hideDropdownArrow]="true"
                   [resetItemsOnClear]="false"
                   [hideClearAll]="true"></omg-auto-complete>
<div *ngIf="validPrescribers.length === 1">
  {{ validPrescribers[0].name }}
</div>
