// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';

import { DenialReason } from '@app/features/renewals/shared/renewals.type';

import { MedForDisplay, RefillText } from '../../shared-rx.type';

@Component({
  selector: 'omg-checkout-list-item-medication-display',
  templateUrl: './checkout-list-item-medication-display.component.html',
  styleUrls: ['./checkout-list-item-medication-display.component.css'],
})
export class CheckoutListItemMedicationDisplayComponent implements OnInit {
  @Input() medForDisplay: MedForDisplay;
  @Input() deaSchedule: number;
  @Input() earliestFillDate: string;
  @Input() refillText: RefillText = 'Refills';
  @Input() denialReason: DenialReason;
  @Input() originalMedForDisplay: MedForDisplay;
  isDenial: boolean;
  medication: MedForDisplay;

  constructor() {}

  ngOnInit() {
    this.isDenial = !!(this.denialReason && this.denialReason.description);
    this.medication =
      (this.isDenial && this.originalMedForDisplay) || this.medForDisplay;
  }
}
