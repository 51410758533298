// @ts-strict-ignore
import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/analytics.type';

interface DirectiveProps {
  event: string;
  action: AnalyticsEvent;
  props: TrackEventProperties;
}
@Directive({ selector: '[omgTrack]' })
export class AnalyticsDirective implements AfterContentInit {
  @Input('omgTrack')
  data: DirectiveProps;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private analytics: AnalyticsService,
  ) {}

  ngAfterContentInit(): void {
    this.renderer.listen(
      this.element.nativeElement,
      this.data.event || 'click',
      (event: Event) => this.track(event),
    );
  }

  track(event: Event): void {
    this.analytics.track(this.data.action, this.data.props);
  }
}
