import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'omg-renewal-item-fills',
  templateUrl: './renewal-item-fills.component.html',
  styleUrls: ['./renewal-item-fills.component.css'],
})
export class RenewalItemFillsComponent {
  @Input() fills: UntypedFormControl;
  @Input() tooltipText =
    'Total Fills equals 1 + all additional refills for this prescription.';

  constructor() {}
}
