import { gql } from '@apollo/client/core';
import { Observable, Subscribable } from 'rxjs';

export const notifyMutation = gql`
  mutation Notify(
    $resource: String!
    $payload: AWSJSON!
    $action: Action!
    $scope: String
  ) {
    notify(
      payload: $payload
      resource: $resource
      action: $action
      scope: $scope
    ) {
      action
      payload
      resource
      sent_at
      scope
    }
  }
`;

export const notificationSubscription = gql`
  subscription Notification(
    $resource: String!
    $action: Action
    $scope: String
  ) {
    notification(resource: $resource, action: $action, scope: $scope) {
      action
      payload
      resource
      sent_at
      scope
    }
  }
`;

export const toRxObservable = <T>(
  subscribable: Subscribable<T>,
): Observable<T> =>
  new Observable<T>(observer => subscribable.subscribe(observer));
