import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'omg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @ContentChild('loadedRef') loadedRef: TemplateRef<any>;
  @ContentChild('loadingRef') loadingRef: TemplateRef<any>;
  @ContentChild('emptyRef') emptyRef: TemplateRef<any>;

  @Input() loading: boolean;
  @Input() empty: boolean;

  constructor() {}
}
