// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { RecategorizeDocumentService } from '../../shared/recategorize-document.service';
import { FilingTemplate } from '../../shared/recategorize-document.type';

@Component({
  selector: 'omg-recategorize-document-action-bar',
  templateUrl: './recategorize-document-action-bar.component.html',
})
export class RecategorizeDocumentActionBarComponent implements OnInit {
  @Input() index: string;
  @Output() apply = new EventEmitter<FilingTemplate>();
  @Output() cancel = new EventEmitter<void>();

  template: FilingTemplate;
  recategorizeApplyFocusOnKey = 'recategorizeDocument-apply';
  recategorizeSearchFocusOnKey = 'recategorizeDocument-search';
  templateItems: Observable<FilingTemplate[]>;
  formGroup: UntypedFormGroup;

  constructor(
    private recategorizeDocumentService: RecategorizeDocumentService,
  ) {}

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      documentCategory: new UntypedFormControl(null),
    });
  }

  onRecategorizeDocument() {
    this.apply.emit(this.template);
  }

  onCancel() {
    this.template = null;
    this.templateItems = of(null);
    this.formGroup.get('documentCategory').patchValue(null);
    this.cancel.emit();
  }

  onSetTemplate(template: FilingTemplate) {
    this.template = template;
  }

  onSearchForTemplate(text: string) {
    if (text.length > 1) {
      this.templateItems = this.recategorizeDocumentService.searchForTemplate(
        text,
        this.index,
      );
    }
  }

  templateSearchFn(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }
}
