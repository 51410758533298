import { DOCUMENT, formatDate } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PatientSelectors } from '../patient/store/patient.selectors';

@Injectable()
export class DocumentTitleSetterService implements OnDestroy {
  private unsubscribe = new Subject<void>();

  constructor(
    private patient: PatientSelectors,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.patient.fullName
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(fullName => {
        if (fullName) {
          this.document.title += ` | ${fullName}`;
        } else {
          this.document.title = '1Life';
        }
      });
    this.patient.dateOfBirth
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dateOfBirth => {
        if (dateOfBirth) {
          this.document.title += ` - ${formatDate(
            dateOfBirth,
            'MM/dd/yyyy',
            'en-US',
          )}`;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
