// @ts-strict-ignore
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';

import { DialogRef } from './dialog-ref';
import { DialogService } from './dialog.service';

// eslint-disable  @angular-eslint/component-class-suffix
// eslint-disable
// eslint-disable  @angular-eslint/directive-class-suffix

/**
 * Dialog directive content helpers used for common dialog content elements.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog-config.ts
 */

/** Counter used to generate unique IDs for dialog elements. */
let dialogElementUid = 0;

/**
 * Button that will close the current dialog.
 */
@Directive({
  selector: `button[omg-dialog-close], button[omgDialogCloseDirective]`,
  exportAs: 'omgDialogCloseDirective',
  host: {
    '(click)': 'dialogRef.close(dialogResult)',
    '[attr.aria-label]': 'ariaLabel',
    type: 'button', // Prevents accidental form submits.
  },
})
export class DialogCloseDirective implements OnInit, OnChanges {
  /** Screenreader label for the button. */
  @Input('aria-label') ariaLabel = 'Close dialog';

  /** Dialog close input. */
  // eslint-disable-next-line  @angular-eslint/no-input-rename
  @Input('omg-dialog-close') dialogResult: any;

  /** Dialog close input. */
  // eslint-disable-next-line  @angular-eslint/no-input-rename
  @Input('omgDialogCloseDirective') _omgDialogCloseDirective: any;

  constructor(@Optional() public dialogRef: DialogRef<any>) {}

  ngOnInit() {
    if (!this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const proxiedChange =
      changes._omgDialogCloseDirective ||
      changes._omgDialogCloseDirectiveResult;

    if (proxiedChange) {
      this.dialogResult = proxiedChange.currentValue;
    }
  }
}

/**
 * Title of a dialog element. Stays fixed to the top of the dialog when scrolling.
 */
@Directive({
  selector: '[omg-dialog-title], [omgDialogTitleDirective]',
  exportAs: 'omgDialogTitleDirective',
  host: {
    class: 'om-dialog-title',
    '[id]': 'id',
  },
})
export class DialogTitleDirective implements OnInit {
  @Input() id = `omg-dialog-title-${dialogElementUid++}`;

  constructor(@Optional() private _dialogRef: DialogRef<any>) {}

  ngOnInit() {
    if (this._dialogRef) {
      Promise.resolve().then(() => {
        const container = this._dialogRef._containerInstance;

        if (container && !container._ariaLabelledBy) {
          container._ariaLabelledBy = this.id;
        }
      });
    }
  }
}

/**
 * Scrollable content container of a dialog.
 */
@Directive({
  selector: `[omg-dialog-content], omg-dialog-content, [omgDialogContentDirective]`,
  host: { class: 'om-dialog-content' },
})
export class DialogContentDirective {}

/**
 * Container for the bottom action buttons in a dialog.
 * Stays fixed to the bottom when scrolling.
 */
@Directive({
  selector: `[omg-dialog-actions], omg-dialog-actions, [omgDialogActionsDirective]`,
  host: { class: 'om-dialog-actions' },
})
export class DialogActionsDirective {}
