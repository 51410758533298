<div class="padding-normal"
     om-layout-wrap
     om-layout>
  <omg-change-rx-status-approval></omg-change-rx-status-approval>
  <div om-flex="50"
       *ngFor='let change of form.changeRx.rxChangeRequestPrescriberAuthorizations'
       class="padding-normal-vertical">
    <div><strong>{{prescriberAuthorizationSubcodes[change.subcode]}}</strong></div>
    <div>{{ form.controls.get(controlsByPrescriberAuthorizationSubcode[change.subcode].name)?.value || 'N/A' }} </div>
  </div>
</div>
