import { CommentsAction, CommentsActionTypes } from './comments.actions';

export function deleteCommentReducer(
  state = <any>{ error: null, pending: false },
  action: CommentsAction,
  adapter: any,
): unknown {
  switch (action.type) {
    case CommentsActionTypes.DELETE_COMMENT: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case CommentsActionTypes.DELETE_COMMENT_SUCCESS: {
      return adapter.removeOne(action.payload, {
        ...state,
        pending: false,
        error: null,
      });
    }

    case CommentsActionTypes.DELETE_COMMENT_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return { ...state };
    }
  }
}
