<div class="padding-normal"
     om-layout="vertical"
     *ngIf="!completed">
  <strong>Chronic care management time</strong>

  <div>
    <span class="chronic-care-management-time-explain-text">Only include time spent on chronic care management</span>
    <div>
      <input class="chronic-care-management-time-input"
             type="number"
             name="chronicCareManagementTime"
             [formControl]="chronicCareManagementTimeControl"
             (blur)="onChronicCareManagmentTimeBlur()"
             omgInputText /> minutes
    </div>
    <div *ngIf="showErrorForControl(chronicCareManagementTimeControl)">
      <div class="om-messages"
           *ngIf="chronicCareManagementTimeControl.errors.min || chronicCareManagementTimeControl.errors.required">
        Add time spent
      </div>
    </div>
  </div>
</div>
<div *ngIf="!completed"
     class="padding-normal chronic-care-management-summary-container"
     om-layout="vertical">
  <strong>Chronic care management summary</strong>
  <omg-chart-text-box quillFormat="text"
                      [control]="summaryControl"
                      (blurTextBox)="onSummaryBlur()"></omg-chart-text-box>
  <div *ngIf="showErrorForControl(summaryControl)">
    <div class="om-messages"
         *ngIf="summaryControl.errors.required">
      Add a summary
    </div>
  </div>
</div>
<div *ngIf="completed"
     class="padding-normal"
     om-layout="vertical">
  <strong>Chronic care management summary</strong>
  <span>{{ programVisit?.formData["time"] }} minutes</span>
  <p [innerHTML]="programVisit?.summaryOfVisit | safe:'html'"></p>
</div>
