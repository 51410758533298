import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackgroundImageDirective } from './background-image.directive';
import { ProfileImageBubbleComponent } from './profile-image-bubble.component';

const declarations = [BackgroundImageDirective, ProfileImageBubbleComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class ProfileImageBubbleModule {}
