import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import { PopoverContent, PopoverPlacement } from './popover-config';
import { PopoverRef } from './popover-ref';

@Component({
  selector: 'omg-popover-portal',
  templateUrl: './popover-portal.component.html',
  styleUrls: ['./popover-portal.component.scss'],
})
export class PopoverPortalComponent implements OnInit {
  @Input() title?: string | null;
  @Input() placement?: PopoverPlacement;
  width?: string | number | null;

  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: PopoverContent;
  context;

  constructor(private popoverRef: PopoverRef) {
    this.setProps();
  }

  ngOnInit() {
    this.render();
  }

  private setProps() {
    const config = this.popoverRef.config;
    this.width = config.width;
    this.content = config.content;
    this.title = config.title;
    this.placement = config.placement;
  }

  private render() {
    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
