// @ts-strict-ignore
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FocusService } from '@app/shared/directives/focus/focus.service';

@Component({
  selector: 'omg-editable-label',
  templateUrl: './editable-label.component.html',
  styleUrls: ['./editable-label.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableLabelComponent),
      multi: true,
    },
  ],
})
export class EditableLabelComponent
  implements AfterViewInit, ControlValueAccessor {
  private _value = '';

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onChange(value);
    }
  }

  @Input() required = true;
  @Input() placeholder = '';
  @Input() name = 'inPlaceTitle';
  @Input() disabled = false;

  @Output() textUpdated = new EventEmitter<string>();
  @Output() focusOn = new EventEmitter();
  @Output() focusOut = new EventEmitter();

  editingSubject: boolean;
  focusOnKey = 'editableLabel-input';

  constructor(private focusService: FocusService) {}

  ngAfterViewInit() {
    this.setupLabel();
  }

  setupLabel() {
    this.editingSubject = !this.value;
    this.focusOnInput();
  }

  refreshLabel() {
    this.toggleEditingSubject();
    this.focusOnInput();
  }

  toggleEditingSubject() {
    this.editingSubject = !this.value || !this.editingSubject;
  }

  focusOnInput() {
    setTimeout(() => this.focusService.setFocus(this.focusOnKey));
  }

  focusedOut(event: FocusEvent) {
    this.focusOut.emit(event);
  }

  focused(event: FocusEvent) {
    this.focusOn.emit(event);
  }

  update(event: any) {
    if (this._value?.length > 0) {
      this.focusedOut(event);
    }
    this.toggleEditingSubject();
    this.textUpdated.emit(this._value);
  }

  /* istanbul ignore next */
  onChange = (value: any) => {};

  /* istanbul ignore next */
  onTouched = () => {};

  /* istanbul ignore next */
  writeValue(value: any) {
    this._value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn: (value: any) => {}) {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
