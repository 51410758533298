// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RenewalPharmacy } from '@app/modules/pharmacy-picker/shared/pharmacy.type';

import { Renewal, RenewalCartState } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-completed-renewals',
  templateUrl: './completed-renewals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedRenewalsComponent implements OnInit {
  renewalsByPharmacy$: Observable<Renewal[][]>;

  constructor(private renewalSelectors: RenewalSelectors) {}

  ngOnInit() {
    this.renewalsByPharmacy$ = this.renewalSelectors.renewalsByPharmacy;
  }

  handwrittenRenewals(renewals: Renewal[] = []) {
    return renewals.filter(this.isHandwrittenAndNotDenied);
  }

  electronicRenewals(renewals: Renewal[] = []) {
    return renewals.filter(renewal => !this.isHandwrittenAndNotDenied(renewal));
  }

  private isHandwrittenAndNotDenied = (renewal: Renewal) => {
    return (
      renewal.mustHandwrite && renewal.cartState !== RenewalCartState.denied
    );
  };
}
