// @ts-strict-ignore
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { VitalsDataSelectors } from '@app/modules/vitals-data/store/vitals-data.selectors';

import {
  getExpiredVitalsWarning,
  getExpiryRequirements,
  getMostRecentVitals,
  isMissingVitals,
  PediatricVital,
  PediatricVitalWarning,
} from '../../utils/pediatric-vitals-util';

@Component({
  selector: 'omg-pediatric-vitals',
  templateUrl: './pediatric-vitals.component.html',
})
export class PediatricVitalsComponent implements OnInit {
  @Output() vitalsStatus = new EventEmitter<boolean>();

  mostRecentVitals$: Observable<PediatricVital[]>;
  warnings$: Observable<PediatricVitalWarning>;

  constructor(
    private patientSelectors: PatientSelectors,
    private vitalsDataSelectors: VitalsDataSelectors,
  ) {}

  ngOnInit() {
    this.mostRecentVitals$ = combineLatest([
      this.vitalsDataSelectors.entities,
      this.patientSelectors.ageInMonths,
    ]).pipe(
      map(([vitalsData, ageInMonths]) =>
        getMostRecentVitals(vitalsData, ageInMonths),
      ),
    );

    this.warnings$ = combineLatest([
      this.mostRecentVitals$,
      this.patientSelectors.ageInMonths,
    ]).pipe(
      map(([vitals, ageInMonths]) => {
        const expiredVitals = getExpiredVitalsWarning(vitals);
        const expiryRequirements = getExpiryRequirements(ageInMonths);
        const isError = isMissingVitals(vitals);
        return expiredVitals && { expiredVitals, expiryRequirements, isError };
      }),
      tap(({ isError }) => this.vitalsStatus.emit(!isError)),
    );
  }
}
