<div om-layout
     om-layout-align="space-between center">
  <div om-flex="65">
    <omg-auto-complete om-layout-fill
                       placeholder="Reason for denial"
                       #denialReasonSearch
                       (focusOnRequest)="denialReasonSearch.focus()"
                       [items]="denialReasons$ | ngrxPush"
                       bindLabel="description"
                       omgStopEvent="click"
                       [formControl]="denialReason"
                       [customItem]="addCustomDenialReason"
                       [selectOnTab]="true"
                       addItemText="Create custom denial reason"
                       [hideClearAll]="true"
                       data-cy="rx-deny-reason">
    </omg-auto-complete>
  </div>
  <div om-layout
       om-flex="35"
       om-layout-gutter
       om-layout-align="end center">
    <button omgButton
            class="padding-normal-horizontal"
            variant="secondary"
            type="button"
            (click)="onDeny()"
            [disabled]="disabled"
            data-cy="rx-deny-done-btn">
      Done
    </button>
    <button omgButton
            class="padding-normal-horizontal"
            type="button"
            variant="link"
            (click)="onCancel()"
            data-cy="rx-deny-cancel-btn">
      Cancel
    </button>
  </div>
</div>
