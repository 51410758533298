// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Patient } from '@app/core';
import { filterTruthy } from '@app/utils';

import { ErrorHandlerService } from '../../errors/error-handler.service';
import { PatientApiService } from '../shared/patient-api.service';
import {
  GetPatient,
  GetPatientError,
  GetPatientSuccess,
  QueryPatient,
  QueryPatientError,
  QueryPatientSuccess,
  UpdatePatient,
  UpdatePatientError,
  UpdatePatientSuccess,
} from './patient.actions';
import { PatientSelectors } from './patient.selectors';

export interface GetPatientQueryVariables {
  id: number;
}

export interface GetPatientData {
  patient: {
    id: string;
    connectedDevicesAuthorized: boolean;

    licensingBody?: {
      id: string;
    };

    membership: {
      customNetworks?: {
        id: string;
        name: string;
      }[];
    };
  };
}

const PatientQuery = gql`
  query GetPatient($id: ID!) {
    patient(id: $id) {
      id
      connectedDevicesAuthorized
      licensingBody {
        id
      }
      pendingAdmissions {
        id
        admittedAt
        admitReason
        status
        subject
        author
        patient {
          id
        }
      }
      careTeam(type: LONGITUDINAL, first: 10) {
        total
        edges {
          label
          node {
            ... on InternalUser {
              id
              displayName
            }
          }
        }
      }
      membership {
        customNetworks {
          id
          name
        }
      }
    }
  }
`;

export type PatientWithoutId = Omit<Patient, 'id'>;

@Injectable()
export class PatientEffects {
  constructor(
    private actions$: Actions,
    private patientApi: PatientApiService,
    private patientSelectors: PatientSelectors,
    private errorHandler: ErrorHandlerService,
    private apollo: Apollo,
  ) {}

  getPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPatient),
      withLatestFrom(this.patientSelectors.patientId),
      filterTruthy(),
      filter(
        ([{ payload }, currentPatientId]) =>
          payload.id !== currentPatientId || payload.forceGet,
      ),
      switchMap(([{ payload }]) =>
        this.patientApi.get(payload.id).pipe(
          map(patient => GetPatientSuccess(patient)),
          catchError(error =>
            of(
              GetPatientError({
                payload: this.errorHandler.handleErrorSafe(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updatePatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePatient),
      switchMap(({ payload }) =>
        this.patientApi.update(payload.id, payload.data).pipe(
          map(patient => UpdatePatientSuccess(patient)),
          catchError(error =>
            of(
              UpdatePatientError({
                payload: this.errorHandler.handleErrorSafe(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  queryPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QueryPatient),
      withLatestFrom(this.patientSelectors.patientId),
      filter(
        ([{ payload }, currentPatientId]) =>
          payload.id !== currentPatientId || payload.forceGet,
      ),
      mergeMap(
        ([action, _id]) =>
          this.apollo
            .use('onelife')
            .watchQuery<GetPatientData, GetPatientQueryVariables>({
              query: PatientQuery,
              variables: { id: action.payload.id },
            }).valueChanges,
      ),
      map(({ data }) => {
        const { id, ...patientWithoutId } = data.patient;
        return <PatientWithoutId>patientWithoutId;
      }),
      map(patientWithoutId => QueryPatientSuccess(patientWithoutId)),
      catchError((error: String[]) => of(QueryPatientError({ error: error }))),
    ),
  );
}
