<div omgTheme
     om-flex
     om-layout
     om-layout-align="center center">
  <h5 class="schedule-title">
    Clinical UX notes can be started from your
    <a omgLegacyUiHref="/admin"
       class="schedule-link">schedule</a> by clicking the clock icon for an
    appointment
  </h5>
</div>
