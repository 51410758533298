import { PrescriptionHistory } from './prescription-history.type';

export const lookupDestinationText = (rx: PrescriptionHistory): string => {
  const { sentAs, pharmacy } = rx;

  switch (sentAs) {
    case 'print':
      return 'Printer';
    case 'handwritten':
      return pharmacy
        ? `Handwritten for: ${pharmacy.displayName}`
        : 'Handwritten';
    default:
      return pharmacy ? pharmacy.displayName : 'Unknown Pharmacy';
  }
};
