import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { sortProblemsByClinicalDescription } from '../../shared/summaries-utils';
import { SummaryProblem } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-assessment-plan',
  templateUrl: './linked-assessment-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedAssessmentPlanComponent {
  @Input() signed: boolean;

  @Input() get assessedProblems(): SummaryProblem[] {
    return this._assessedProblems;
  }

  set assessedProblems(assessedProblems: SummaryProblem[]) {
    this._assessedProblems = sortProblemsByClinicalDescription([
      ...assessedProblems,
    ]);
  }

  @Output() unlink = new EventEmitter<SummaryProblem>();

  private _assessedProblems: SummaryProblem[];

  trackByFn = (index, summaryProblem: SummaryProblem) =>
    summaryProblem.id || index;

  deleteFromNote(problem: SummaryProblem) {
    this.unlink.emit(problem);
  }
}
