import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LaunchDarklyService } from './launchdarkly.service';

/**
 * Angular resolver that ensures the launchdarkly sdk is initialized before rendering the chart ui components.
 */
@Injectable()
export class LaunchDarklyResolver {
  constructor(private service: LaunchDarklyService) {}

  resolve(): Observable<boolean> {
    return this.service.initialized$;
  }
}
