// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';

import { MedicationsRootState } from '../store/medications-store.reducer';
import { selectMedicationsSectionLinkPayload } from '../store/medications-store.selectors';

@Injectable({
  providedIn: 'root',
})
export class MedicationsService {
  constructor(
    private store: Store<MedicationsRootState>,
    private patientSelectors: PatientSelectors,
    private summarySelectors: SummariesSelectors,
    private summaryActions: SummariesActions,
  ) {}

  allowAddToNote() {
    return this.summarySelectors.hasActiveSummary;
  }

  addToNote() {
    this.store
      .pipe(
        select(selectMedicationsSectionLinkPayload),
        withLatestFrom(this.patientSelectors.patientId),
        take(1),
      )
      .subscribe(([payload, patientId]) => {
        this.summaryActions.linkMedications({
          patientId,
          medications: payload,
        });
      });
  }
}
