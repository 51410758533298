// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { SearchIndex } from '../search';

@Injectable()
export class ConfigService {
  environment = environment;

  searchIndex(indexName: SearchIndex) {
    return this.environment.search.indexes[indexName];
  }
}
