import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api';
import { mapProfileResponseToEntity } from './profile-api-mappers';
import { ProfileResponse } from './profile-response.type';

@Injectable()
export class ProfileApiService {
  private profilePath = '/v2/admin/profile';

  constructor(private apiService: ApiService) {}

  get() {
    return this.apiService
      .get<ProfileResponse>(this.profilePath, null, {
        // we need to pass credentials so that onelife can read the JWT from the session
        // When we start using the JWT as the bearer token this will no longer be needed
        // Ticket for removal: https://www.pivotaltracker.com/story/show/174805294
        withCredentials: true,
      })
      .pipe(map(mapProfileResponseToEntity));
  }
}
