// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { RxCartMeasurement } from '@app/modules/shared-rx/shared-rx.type';

import { VitalsDataSelectors } from '../../../vitals-data/store/vitals-data.selectors';
import {
  getMostRecentVitals,
  PediatricVital,
} from '../../utils/pediatric-vitals-util';

@Component({
  selector: 'omg-checkout-patient-info',
  templateUrl: './checkout-patient-info.component.html',
})
export class CheckoutPatientInfoComponent implements OnInit {
  @Input() patient: Patient;
  @Input() rxCartMeasurements: RxCartMeasurement[];
  @Input() pediatricVitalsEnabled: boolean;

  mostRecentVitals$: Observable<PediatricVital[]>;

  constructor(
    private patientSelectors: PatientSelectors,
    private vitalsDataSelectors: VitalsDataSelectors,
  ) {}

  ngOnInit() {
    this.mostRecentVitals$ = combineLatest([
      this.patientSelectors.isMinor,
      this.vitalsDataSelectors.entities,
      this.patientSelectors.ageInMonths,
    ]).pipe(
      map(([isMinor, vitalsData, ageInMonths]) =>
        isMinor ? getMostRecentVitals(vitalsData, ageInMonths) : [],
      ),
      map(vitals => vitals.filter(vital => vital.value)),
    );
  }
}
