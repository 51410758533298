import {
  Directive,
  Host,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';

import { CollapseDirective } from './collapse.directive';

@Directive({
  selector: 'omg-collapse, [omgCollapse], [omgCollapseProvider]',
  exportAs: 'omgCollapseProvider',
})
export class CollapseProviderDirective implements OnChanges {
  @Input() collapseProvider: CollapseDirective;

  collapse: CollapseDirective = this.collapseDirective;

  constructor(
    @Optional() @Host() private collapseDirective: CollapseDirective,
  ) {}

  get expanded() {
    return this.collapse.expanded;
  }

  get expandedChange() {
    return this.collapse.expandedChange;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { collapseProvider } = changes;
    if (collapseProvider) {
      this.collapse = this.collapseProvider || this.collapseDirective;
    }
  }
}
