<omg-card class="workspace-card"
          [loading]="loadingProcedureInteraction | ngrxPush">
  <ng-template #loadedRef>
    <ng-container *ngIf="procedureInteraction">
      <omg-card-title heading="Perform Procedure">
        <div class="om-tab-subheading"
             omgCollapse
             omgTheme
             [expanded]="commentsVisible"
             om-layout-gutter
             om-layout-align="end center">
          <omg-collapse-toggle om-layout
                               om-layout-align="space-between center"
                               om-layout-gutter-mini
                               class="collapse-with-flex"
                               (click)="toggleComment()">
            <omg-collapsed disableAnimation="true">
              <span class="om-icon icon-comment clickable"
                    data-cy="order-comment-btn"></span>
            </omg-collapsed>
            <omg-expanded disableAnimation="true">
              <span class="om-icon icon-comment-filled clickable"
                    data-cy="order-comment-btn-expand"></span>
            </omg-expanded>
            <span>{{ procedureInteractionCommentable?.totalComments }}</span>
          </omg-collapse-toggle>
        </div>
      </omg-card-title>

      <omg-card-body omgScrollingContainer>
        <omg-comments *ngIf="commentsVisible"
                      [commentable]="procedureInteractionCommentable"
                      (commentAdded)="updateCommentCount('add')"
                      (commentRemoved)="updateCommentCount('remove')"
                      data-cy="order-comments"></omg-comments>

        <!--        INDICATIONS -->
        <omg-procedure-interaction-indications [procedureInteraction]="procedureInteraction">
        </omg-procedure-interaction-indications>

        <!--        PROCEDURE TYPE -->
        <omg-procedure-interaction-procedure-type [procedureInteraction]="procedureInteraction">
        </omg-procedure-interaction-procedure-type>

        <omg-procedure-interaction-dynamic-form *ngIf="featureDynamicFormsEnabled && procedureInteraction.dynamicForm"
                                                [procedureInteraction]="procedureInteraction">
        </omg-procedure-interaction-dynamic-form>

        <omg-procedure-interaction-common-procedures *ngIf="!procedureInteraction?.procedureType">
        </omg-procedure-interaction-common-procedures>

        <!--        CLINICAL GUIDANCE -->
        <omg-procedure-interaction-clinical-guidance *ngIf="procedureInteraction?.procedureType?.clinicalGuidance"
                                                     [clinicalGuidanceText]="procedureInteraction?.procedureType?.clinicalGuidance">
        </omg-procedure-interaction-clinical-guidance>

        <!--        ADMINISTRATION GUIDANCE -->
        <omg-procedure-interaction-administration-guidance *ngIf="procedureInteraction?.procedureType?.administrationGuidance"
                                                     [administrationGuidanceText]="procedureInteraction?.procedureType?.administrationGuidance">
        </omg-procedure-interaction-administration-guidance>

        <!--        PROCEDURE SUMMARY -->
        <omg-procedure-interaction-summary *ngIf="procedureInteraction?.procedureType"
                                           [procedureInteraction]="procedureInteraction"
                                           [procedureSummaryControl]="formState.get('procedureSummary')">
        </omg-procedure-interaction-summary>

        <!--        RESULTS -->
        <omg-procedure-interaction-results *ngIf="showResults"
                                           [procedureInteraction]="procedureInteraction"
                                           [measurementControls]="formState.get('measurements')">
        </omg-procedure-interaction-results>

        <!--        RESULTS INTERPRETATION -->
        <omg-procedure-interaction-results-interpretation *ngIf="showResultsInterpretation"
                                                          [procedureInteraction]="procedureInteraction"
                                                          [resultsInterpretationControl]="formState.get('resultsInterpretation')">
        </omg-procedure-interaction-results-interpretation>

        <omg-errors *ngIf="actionBarErrors.length > 0"
                    [errors]="actionBarErrors"
                    class="padding-normal"></omg-errors>
      </omg-card-body>

      <omg-card-footer>
        <omg-procedure-interaction-action-bar [procedureInteraction]="procedureInteraction"
                                              (actionBarComplete)="onActionBarComplete()">
        </omg-procedure-interaction-action-bar>
      </omg-card-footer>
    </ng-container>

    <div class="load-status"
         *ngIf="!procedureInteraction">This procedure no longer exists.</div>
  </ng-template>

  <ng-template #loadingRef>
    <div class="load-status">Loading procedure...</div>
  </ng-template>
</omg-card>
