<cdk-virtual-scroll-viewport omgDetectResize
                             [style.height.px]="fixedScrollHeight"
                             [itemSize]="itemHeight"
                             [minBufferPx]="minBufferPx"
                             [maxBufferPx]="maxBufferPx"
                             (resizeEvent)="checkVirtualScrollSize()">
  <div #staticTemplateRef
       *ngIf="staticTemplate">
    <ng-content *ngTemplateOutlet="staticTemplate"></ng-content>
  </div>
  <div *ngIf="loading && !isListScrolled">
    <ng-content *ngTemplateOutlet="loadingTemplate"></ng-content>
  </div>
  <div *cdkVirtualFor="let item of items; index as i; trackBy: trackBy; templateCacheSize: templateCacheSize;"
       [style.height.px]="itemHeight"
       class="infinite-scroll-item">
    <ng-content *ngTemplateOutlet="itemTemplate; context: { $implicit: item, index: i }"></ng-content>
  </div>
  <div *ngIf="lazyLoading">
    <ng-content *ngTemplateOutlet="lazyLoadingTemplate"></ng-content>
  </div>
</cdk-virtual-scroll-viewport>
