<ng-container *ngIf="isVisible">
  <omg-auto-complete #optionsDropdown
                     [items]="items"
                     hideInput="true"
                     autoWidth="true"
                     trackByKey="id"
                     resetItemsOnClear="false"
                     bindLabel="name"
                     bindValue="id"
                     (changeAutocomplete)="onItemClick($event)"
                     dropdownPosition="top"
                     [fillLayout]="false">
  </omg-auto-complete>
  <button omgButton
          variant="secondary"
          (click)="toggleOptions()"
          id="moreMessagingActionsMenu">
    <span>More </span><i class="fa fa-caret-up"></i>
  </button>
</ng-container>
