// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { messagingPath } from '@app/features/workspace/shared/workspace-utils';

import { LaunchDarklyService } from '../../../../core/launch-darkly/launchdarkly.service';
import { LabDataService } from '../../shared/lab-data.service';
import { LabData } from '../../shared/lab-data.type';

@Component({
  selector: 'omg-lab-result',
  templateUrl: './lab-result.component.html',
  styleUrls: ['./lab-result.component.scss'],
})
export class LabResultComponent implements OnInit {
  @Input() awsKey: string;
  @Input() awsBucket: string;
  @Input() labDataId: number;
  @Input() patientId: number;

  labData$: Observable<LabData>;
  displayAutoreportabilityRulesEnabled$: Observable<boolean>;
  failedAutoSend = false;
  successfulAutoSend = false;
  reportedWithAbnormals = false;

  constructor(
    private labDataService: LabDataService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.labData$ = this.labDataService.get(this.labDataId).pipe(
      tap(labData => {
        this.failedAutoSend =
          !!labData.conversationId && !labData.patientTimelinePost;
        this.successfulAutoSend = labData.autoReported;
        this.reportedWithAbnormals =
          labData.autoReportedReason === 'auto_report_abnormals_flag_on';
      }),
    );

    this.displayAutoreportabilityRulesEnabled$ = this.launchDarklyService.variation$<
      boolean
    >(FeatureFlagNames.medsDisplayLabAutoreportabilityRules, false);
  }

  getTimelinePostRoute(id: number) {
    return messagingPath(this.patientId, id, 'edit');
  }
}
