<ng-container *ngIf="state !== 'dismissed'">
  <omg-ml-suggestion *ngIf="state === 'suggesting'"
                     [text]="name"
                     [subtext]="procedureSuggestion.problemHistory.problemType.clinicalDescription"
                     [buttonText]="'Record'"
                     (accept)="createProcedureInteraction()"
                     (closeSuggestion)="dismiss()">
    <omg-procedure-suggestion-explainability [predictiveWords]="procedureSuggestion.predictiveWords"
                                             [confidence]="procedureSuggestion.probability | formatConfidence">
    </omg-procedure-suggestion-explainability>
  </omg-ml-suggestion>

  <omg-procedure-suggestion-procedure-order-form *ngIf="state === 'ordered' && procedureSuggestion.itemType === 'ProcedureOrder'"
                                                 [procedureOrderId]="procedureSuggestion.itemId"
                                                 [procedureSuggestion]="procedureSuggestion"
                                                 [patientId]="patientId"
                                                 (dismiss)="dismiss()">
  </omg-procedure-suggestion-procedure-order-form>

  <omg-procedure-suggestion-procedure-interaction-form *ngIf="state === 'ordered' && procedureSuggestion.itemType === 'ProcedureInteraction'"
                                                       [procedureInteractionId]="procedureSuggestion.itemId"
                                                       [procedureSuggestion]="procedureSuggestion"
                                                       [patientId]="patientId"
                                                       (dismiss)="dismiss()">
  </omg-procedure-suggestion-procedure-interaction-form>

  <omg-ml-suggestion-dismissed *ngIf="state === 'dismissing'"
                               [text]="name"
                               (giveFeedback)="giveFeedback()">
  </omg-ml-suggestion-dismissed>

  <omg-procedure-suggestion-feedback *ngIf="state === 'feedback'"
                                     [feedbackCategories]="feedbackCategories"
                                     [procedureDescription]="name"
                                     [procedureSuggestion]="procedureSuggestion"
                                     (closeFeedback)="closeFeedback()">
  </omg-procedure-suggestion-feedback>
</ng-container>
