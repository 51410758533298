export const setElementHeight = (el: any, container: any) => {
  if (!container) {
    return;
  }
  const omgCardTitle = container.querySelector('.card-title');
  const omgCardFooter = container.querySelector('.card-footer');

  const containerBounds = container.getBoundingClientRect();
  const containerHeight = containerBounds.height;

  const footerHeight = omgCardFooter ? omgCardFooter.offsetHeight : 0;
  const titleHeight = omgCardTitle ? omgCardTitle.offsetHeight : 0;
  let titleOffsetInContainer = omgCardTitle
    ? omgCardTitle.offsetTop - containerBounds.top
    : 0;

  if (titleOffsetInContainer < 0) {
    titleOffsetInContainer = 0;
  }

  const borders = (omgCardTitle ? 2 : 0) + (omgCardFooter ? 2 : 0);
  const otherContent = Math.floor(
    footerHeight + titleHeight + titleOffsetInContainer + borders,
  );

  const cardBodyAvailableHeight = Math.floor(containerHeight - otherContent);
  el.style.height = '100%';

  return container.hasAttribute('docked')
    ? adjustDockedCardBody(el, container, otherContent, cardBodyAvailableHeight)
    : adjustCardBody(el, cardBodyAvailableHeight);
};

function adjustCardBody(el: any, cardBodyAvailableHeight: number) {
  el.style['max-height'] = `${cardBodyAvailableHeight}px`;
}

function adjustDockedCardBody(
  el: any,
  container: any,
  otherContent: number,
  cardBodyAvailableHeight: number,
) {
  const elementContentHeight = +window
    .getComputedStyle(el.children[0])
    .height.replace('px', '');
  const minContainerHeight = +window
    .getComputedStyle(container)
    .minHeight.replace('px', '');
  const maxContainerHeight = +window
    .getComputedStyle(container)
    .maxHeight.replace('px', '');

  const minCardBodyHeight = Math.floor(minContainerHeight - otherContent);
  const maxCardBodyHeight = Math.floor(maxContainerHeight - otherContent);

  if (elementContentHeight < minCardBodyHeight) {
    const minHeight =
      elementContentHeight > minCardBodyHeight
        ? elementContentHeight
        : minCardBodyHeight;
    el.style['min-height'] = `${minHeight}px`;
  } else if (elementContentHeight > maxCardBodyHeight) {
    const maxHeight =
      elementContentHeight < cardBodyAvailableHeight
        ? elementContentHeight
        : maxCardBodyHeight;
    el.style['max-height'] = `${maxHeight}px`;
  }
}
