import { GetObjectOutput } from 'aws-sdk/clients/s3';
import { v4 } from 'uuid';

export const createAttachmentKey = (
  patientId: number,
  basename: string,
): string => {
  return `patients/${patientId}/${v4()}/${basename}`;
};

export const getObjectPdfUrl = (data: GetObjectOutput): string => {
  const blob = new Blob([data.Body as BlobPart], {
    type: 'application/pdf',
  });
  return URL.createObjectURL(blob);
};
