import { ErrorResult } from './errors.type';

export interface DetailedError extends ErrorResult {
  location: ErrorLocation;
  title: string;
  severity: string;
  key: string;
}

export type ErrorLocation = ErrorSource | string;

// allow multiple error sources to be mapped
// to a display area determined by components
export interface ErrorComponentLocationMap {
  [displayKey: string]: ErrorLocation[];
}

export enum ErrorSource {
  Generic = 'generic',
  Base = 'base',
}

export type MappedReturnAndDefaultErrors<
  T extends ErrorComponentLocationMap
> = {
  [key in keyof T]: DetailedError[];
} & { default: DetailedError[] };

export type MappedReturnAndDefualtMessages<
  T extends ErrorComponentLocationMap
> = {
  [key in keyof T]: string[];
} & { default: string[] };
