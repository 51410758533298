import { convertAgeToHalfYearInMonths } from '../vitals-utils';
import { POUND_PER_KG } from './constants';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeChildWeightLMSParams: LMSParamsMap = {
  2: { lambda: 0.357, mu: 11.227, sigma: 0.129 },
  2.5: { lambda: 0.335, mu: 12.053, sigma: 0.129 },
  3: { lambda: 0.316, mu: 12.794, sigma: 0.13 },
  3.5: { lambda: 0.296, mu: 13.54, sigma: 0.132 },
  4: { lambda: 0.272, mu: 14.421, sigma: 0.134 },
  4.5: { lambda: 0.244, mu: 15.519, sigma: 0.138 },
  5: { lambda: 0.211, mu: 16.788, sigma: 0.143 },
  5.5: { lambda: 0.177, mu: 18.144, sigma: 0.149 },
  6: { lambda: 0.141, mu: 19.51, sigma: 0.157 },
  6.5: { lambda: 0.105, mu: 20.845, sigma: 0.165 },
  7: { lambda: 0.069, mu: 22.176, sigma: 0.174 },
  7.5: { lambda: 0.031, mu: 23.598, sigma: 0.184 },
  8: { lambda: -0.009, mu: 25.116, sigma: 0.194 },
  8.5: { lambda: -0.051, mu: 26.684, sigma: 0.204 },
  9: { lambda: -0.094, mu: 28.231, sigma: 0.213 },
  9.5: { lambda: -0.138, mu: 29.758, sigma: 0.222 },
  10: { lambda: -0.184, mu: 31.328, sigma: 0.23 },
  10.5: { lambda: -0.233, mu: 32.985, sigma: 0.237 },
  11: { lambda: -0.286, mu: 34.775, sigma: 0.243 },
  11.5: { lambda: -0.343, mu: 36.77, sigma: 0.247 },
  12: { lambda: -0.404, mu: 39.001, sigma: 0.25 },
  12.5: { lambda: -0.468, mu: 41.506, sigma: 0.25 },
  13: { lambda: -0.535, mu: 44.301, sigma: 0.247 },
  13.5: { lambda: -0.602, mu: 47.266, sigma: 0.242 },
  14: { lambda: -0.669, mu: 50.198, sigma: 0.235 },
  14.5: { lambda: -0.734, mu: 52.932, sigma: 0.227 },
  15: { lambda: -0.795, mu: 55.349, sigma: 0.218 },
  15.5: { lambda: -0.851, mu: 57.423, sigma: 0.21 },
  16: { lambda: -0.901, mu: 59.186, sigma: 0.203 },
  16.5: { lambda: -0.945, mu: 60.664, sigma: 0.198 },
  17: { lambda: -0.983, mu: 61.875, sigma: 0.193 },
  17.5: { lambda: -0.014, mu: 62.827, sigma: 0.19 },
  18: { lambda: -0.039, mu: 63.548, sigma: 0.187 },
  18.5: { lambda: -0.057, mu: 64.075, sigma: 0.185 },
  19: { lambda: -0.07, mu: 64.446, sigma: 0.183 },
  19.5: { lambda: -0.079, mu: 64.693, sigma: 0.183 },
  20: { lambda: -0.084, mu: 64.846, sigma: 0.182 },
};

const femaleDownSyndromeChildWeightLMSParams: LMSParamsMap = {
  2: { lambda: -0.67, mu: 10.526, sigma: 0.141 },
  2.5: { lambda: -0.755, mu: 11.37, sigma: 0.143 },
  3: { lambda: -0.832, mu: 12.19, sigma: 0.145 },
  3.5: { lambda: -0.909, mu: 13.083, sigma: 0.147 },
  4: { lambda: -0.984, mu: 14.039, sigma: 0.15 },
  4.5: { lambda: -0.055, mu: 15.067, sigma: 0.154 },
  5: { lambda: -0.12, mu: 16.12, sigma: 0.158 },
  5.5: { lambda: -0.175, mu: 17.199, sigma: 0.163 },
  6: { lambda: -0.22, mu: 18.342, sigma: 0.168 },
  6.5: { lambda: -0.253, mu: 19.577, sigma: 0.174 },
  7: { lambda: -0.272, mu: 20.927, sigma: 0.18 },
  7.5: { lambda: -0.274, mu: 22.397, sigma: 0.187 },
  8: { lambda: -0.258, mu: 23.999, sigma: 0.195 },
  8.5: { lambda: -0.226, mu: 25.746, sigma: 0.202 },
  9: { lambda: -0.176, mu: 27.598, sigma: 0.21 },
  9.5: { lambda: -0.112, mu: 29.527, sigma: 0.217 },
  10: { lambda: -0.038, mu: 31.485, sigma: 0.224 },
  10.5: { lambda: -0.957, mu: 33.413, sigma: 0.23 },
  11: { lambda: -0.874, mu: 35.309, sigma: 0.235 },
  11.5: { lambda: -0.791, mu: 37.187, sigma: 0.239 },
  12: { lambda: -0.712, mu: 39.053, sigma: 0.241 },
  12.5: { lambda: -0.636, mu: 40.922, sigma: 0.242 },
  13: { lambda: -0.561, mu: 42.808, sigma: 0.243 },
  13.5: { lambda: -0.486, mu: 44.712, sigma: 0.242 },
  14: { lambda: -0.41, mu: 46.614, sigma: 0.241 },
  14.5: { lambda: -0.33, mu: 48.501, sigma: 0.24 },
  15: { lambda: -0.246, mu: 50.347, sigma: 0.238 },
  15.5: { lambda: -0.158, mu: 52.149, sigma: 0.236 },
  16: { lambda: -0.066, mu: 53.895, sigma: 0.234 },
  16.5: { lambda: 0.028, mu: 55.571, sigma: 0.232 },
  17: { lambda: 0.123, mu: 57.172, sigma: 0.231 },
  17.5: { lambda: 0.219, mu: 58.694, sigma: 0.229 },
  18: { lambda: 0.315, mu: 60.128, sigma: 0.228 },
  18.5: { lambda: 0.408, mu: 61.472, sigma: 0.227 },
  19: { lambda: 0.499, mu: 62.73, sigma: 0.226 },
  19.5: { lambda: 0.587, mu: 63.922, sigma: 0.225 },
  20: { lambda: 0.674, mu: 65.063, sigma: 0.224 },
};
export const getWeightInKg = (weightInLb: number) => weightInLb / POUND_PER_KG;

export const getDownSyndromeChildWeightPercentile = (
  gender: string,
  ageInMonths: number,
  weight: number,
) => {
  const weightInKg = getWeightInKg(weight);
  const ageInHalfYears = convertAgeToHalfYearInMonths(ageInMonths);

  const childWeightLMSParams =
    gender === 'Female'
      ? femaleDownSyndromeChildWeightLMSParams
      : maleDownSyndromeChildWeightLMSParams;

  return zScoreFromMeasurement(
    weightInKg,
    ageInHalfYears,
    childWeightLMSParams,
  );
};
