import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'omgScrubText', pure: false })
export class ScrubTextPipe implements PipeTransform {
  transform(value: string | null = ''): string | null {
    const pattern = /[\n\r<>!?[\]}{=)(*&^%$#~@]/g;
    const scrubbedValue = value ? value.replace(pattern, '') : value;

    return scrubbedValue;
  }
}
