<form [formGroup]="unsignedSummaryForm"
      [omgForm]="unsignedSummaryFormModel"
      *ngIf="unsignedSummaryForm"
      class="om-form">
  <div om-layout>
    <omg-card-body omgScrollingContainer
                   om-flex>
      <ng-content *ngTemplateOutlet="summaryIsWalkinVisit ? walkinSummary : defaultSummary"></ng-content>
    </omg-card-body>
    <omg-card-body class="border-left"
                   *ngIf="workspaceExpanded$ | ngrxPush"
                   om-flex="50">
      <omg-service-ticket [visitProcedureId]="summary.visitProcedureId"></omg-service-ticket>
    </omg-card-body>
  </div>
  <omg-card-footer>
    <div class="action-bar"
         om-layout="vertical"
         om-layout-align="center"
         omgCollapse
         #coSignCollapseRef="omgCollapse">
      <omg-collapsed>
        <div om-layout
             om-layout-align="space-between center">
          <omg-form-state>
            <ng-template [hidden]="showButtonChoice"
                         let-change>
              <ng-container *ngIf="showStartVisitButton;  then appointmentStateArrived; else normalFlow">
              </ng-container>
              <ng-template #appointmentStateArrived
                           om-layout
                           om-layout-gutter>
                <button omgButton
                        variant="primary"
                        (click)="startVisitFromNote(summary.appointment?.id)"
                        [disabled]="startVisitButtonPressed"
                        data-cy="start-visit-from-note">
                  Start Visit
                </button>
              </ng-template>
              <ng-template #normalFlow>
                <div om-layout
                     om-layout-gutter>
                  <omg-split-button type="submit"
                                    *ngIf="!((visitProcedure$ | ngrxPush)?.visitProcedureType?.cosignatureRequired || summary.appointment?.cosignatureRequired)"
                                    variant="primary"
                                    [items]="signNoteMenuItems"
                                    [label]="change.state === 'saving' ? 'Saving...' : 'Sign Note'"
                                    [disabled]="change.state === 'saving'"
                                    id="signNoteActionsMenu"
                                    data-cy="sign-save-note-btn"
                                    [menuStyle]="{ width: 'auto' }"
                                    (labelClick)="onSignSummary()"></omg-split-button>
                  <button omgButton
                          *ngIf="(visitProcedure$ | ngrxPush)?.visitProcedureType?.cosignatureRequired || summary.appointment?.cosignatureRequired"
                          variant="primary"
                          (click)="openCosignRequestForm()"
                          [disabled]="change.state === 'saving'"
                          data-cy="request-cosign">Sign and Request Co-signature</button>
                  <button omgButton
                          *ngIf="summary.noteType.tags.includes('triage')"
                          variant="link"
                          (click)="openVMTHelpModal()"
                          [disabled]="change.state === 'saving'"
                          [omgTrack]="{
                            event: 'click',
                            action: 'Create HelpOut Request'
                          }"
                          data-cy="create-vmt-help-request-link">Create HelpOut Request</button>
                  <button omgButton
                          *ngIf="summary.visitProcedureId"
                          variant="secondary"
                          (click)="toggleServiceTicket()"
                          [omgTrack]="{
                            event: 'click',
                            action: 'Toggle Service Ticket'
                          }"
                          data-cy="toggle-service-ticket-btn">
                    Edit Service Ticket
                  </button>
                </div>
              </ng-template>
            </ng-template>
          </omg-form-state>
          <button omgButton
                  variant="critical-link"
                  data-cy="delete-note-btn"
                  *ngIf="enableNoteDeletion"
                  type="button"
                  (click)="onDeleteSummary()">
            Delete note
          </button>
        </div>
      </omg-collapsed>

      <omg-expanded om-layout="vertical"
                    om-layout-align="center">
        <b *ngIf="!showConfirmation">Request Co-Signature From</b>
        <div om-layout
             om-layout-align="space-between">
          <b *ngIf="showConfirmation">Are you sure?</b>
          <div om-flex="55"
               *ngIf="!showConfirmation">
            <omg-auto-complete placeholder="Select a provider"
                               [items]="coSignSearchItems | ngrxPush"
                               (search)="searchForCoSignNames($event)"
                               [searchFn]="filterTaskAssignees"
                               (changeAutocomplete)="setCoSignAssignee($event)"
                               omgStopEvent
                               trackByKey="id"
                               bindLabel="label"
                               bindValue="id"
                               [hideDropdownArrow]="true"
                               resetItemsOnClear="false"></omg-auto-complete>
          </div>
          <span om-layout
                om-layout-gutter>
            <button omgButton
                    omgCollapseToggle
                    variant="flat"
                    data-cy="never-mind-btn">Never mind</button>
            <button omgButton
                    variant="primary"
                    (click)="signSummary()"
                    [disabled]="(!coSignAssignee && !showConfirmation) || (loading$ | ngrxPush)"
                    data-cy="confirm-sign-note-btn">
              Sign Note
            </button>
          </span>
        </div>
      </omg-expanded>
    </div>
  </omg-card-footer>

  <ng-template #defaultSummary>
    <div>
      <!-- Comments -->
      <omg-expanded [collapseProvider]="commentsCollapseRef">
        <omg-comments [commentable]="commentable"
                      (commentAdded)="onCommentUpdate('add')"
                      (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
      </omg-expanded>

      <div *ngIf="summary.appointment && !summary.hasProgramVisit"
           class="banner -flush">CC: "{{ summary?.appointment?.reason }}"</div>

      <omg-task-assignment [todo]="todo"
                           (focusOn)="trackFieldSelected('Assigned To')"
                           (focusOut)="trackFieldUnselected('Assigned To')"
                           (clicked)="trackHighPriorityClicked($event)"></omg-task-assignment>

      <div om-layout="vertical"
           om-layout-gutter>
        <div class="program-visit-container padding-normal"
             *ngIf="summary.hasProgramVisit">
          <omg-program-visit [summaryId]="summary.id"
                             [chronicCareBillingPrograms]="summary.chronicCareBillingPrograms"></omg-program-visit>
        </div>
        <div om-layout="vertical"
             class="padding-normal-horizontal"
             om-layout-gutter>
          <label>
            Note Title
            <input omgInputText
                   type="text"
                   om-layout-fill
                   formControlName="subject"
                   data-cy="note-title-input"
                   (focus)="trackFieldSelected('Title')"
                   (focusout)="trackFieldUnselected('Title')" />
          </label>
          <div class="om-messages"
               [hidden]="!subjectError">Cannot be blank</div>
          <label *ngIf="!summary.hasProgramVisit">
            Subjective
            <omg-chart-text-box [control]="unsignedSummaryForm.get('subjective')"
                                [insertionEventProps]="{
                                  component: summary.noteType.name,
                                  subcomponent: 'Subjective'
                                }"
                                dataCy="note-subjective-input"
                                omgShortcutClick="s"
                                placeholderText="HPI and ROS go here..."
                                (focusTextBox)="trackFieldSelected('Subjective')"
                                (focusoutTextbox)="trackFieldUnselected('Subjective')">
            </omg-chart-text-box>
          </label>
        </div>
        <omg-chronic-care-management *ngIf="summary.hasProgramVisit"
                                     [completed]="false"
                                     [summaryId]="summary.id"></omg-chronic-care-management>

        <omg-linked-problems *ngIf="summary.medicalHistory"
                             [activeProblems]="summary.activeProblems"
                             [resolvedProblems]="summary.resolvedProblems"
                             (unlink)="onUnlinkSection('Problems')"></omg-linked-problems>
        <omg-linked-allergies *ngIf="summary.patientAllergies?.length > 0 || summary.noKnownAllergies"
                              [signed]="summary.signed"
                              [noKnownAllergies]="summary.noKnownAllergies"
                              [patientAllergies]="summary.patientAllergies"
                              (unlink)="onUnlinkSection('Allergies')"></omg-linked-allergies>
        <omg-linked-medications *ngIf="summary.medications?.length > 0 || summary.noMedications"
                                [signed]="summary.signed"
                                [noMedications]="summary.noMedications"
                                [medications]="summary.medications"
                                (unlink)="onUnlinkSection('Medications')"></omg-linked-medications>
        <omg-linked-vaccines *ngIf="summary.vaccinations?.length > 0"
                             [signed]="summary.signed"
                             [vaccinations]="summary.vaccinations"
                             (unlink)="onUnlinkSection('Vaccines')"></omg-linked-vaccines>
        <omg-linked-health-background *ngIf="showHealthBackground"
                                      [signed]="summary.signed"
                                      [healthBackground]="summary.healthBackground"
                                      [smokingStatus]="summary.smokingStatus"
                                      [implantableDevices]="summary.implantableDevices"
                                      (unlink)="onUnlinkSection('Health-Background')"></omg-linked-health-background>
        <omg-linked-family-health-history *ngIf="summary.familyHealthHistory"
                                          [familyHealthHistory]="summary.familyHealthHistory"
                                          [signed]="summary.signed"
                                          [patientId]="patientId">
        </omg-linked-family-health-history>

        <!-- Vitals and Exam -->
        <div class="padding-normal"
             *ngIf="!summary.hasProgramVisit"
             data-cy="note-vitals-and-exam-form-container">
          <div class="label">Vitals</div>
          <div *ngIf="(patient$ | ngrxPush) as patient">
            <omg-vital-set-form [form]="unsignedSummaryForm.get('vitalsForm')"
                                [validationRules]="validationRules"
                                [patient]="patient"
                                (focusTextBox)="trackFieldSelected($event)"
                                (focusoutTextBox)="trackFieldUnselected($event)"></omg-vital-set-form>
          </div>

          <!-- Exam -->
          <label>
            Exam
            <omg-chart-text-box [control]="unsignedSummaryForm.get('objective')"
                                [insertionEventProps]="{
                                  component: summary.noteType.name,
                                  subcomponent: 'Exam'
                                }"
                                dataCy="note-exam-input"
                                (focusTextBox)="trackFieldSelected('Exam')"
                                (focusoutTextbox)="trackFieldUnselected('Exam')">
            </omg-chart-text-box>
          </label>
        </div>

        <omg-linked-assessment-plan *ngIf="summary.assessedProblems?.length > 0"
                                    [signed]="summary.signed"
                                    [assessedProblems]="summary.assessedProblems"
                                    (unlink)="onUnlinkSection('Assessment-Plan', $event)"></omg-linked-assessment-plan>

        <omg-after-visit-guidance *ngIf="showAvsGuidanceComponent | ngrxPush"
                                  [summaryProviderRecommendation]="summary.summaryProviderRecommendation"
                                  [afterVisitGuidance]="unsignedSummaryForm.get('afterVisitGuidance')"
                                  (attachmentAdded)="onAddAttachment($event)"
                                  (attachmentDeleted)="onDeleteAttachment($event)"
                                  [isAttachable]="isAttachable"></omg-after-visit-guidance>

        <omg-linked-health-maintenance *ngIf="summary.healthGoals?.length > 0 || summary.healthMaintenanceNote?.content"
                                       [signed]="summary.signed"
                                       [healthMaintenanceNote]="summary.healthMaintenanceNote"
                                       [healthGoals]="summary.healthGoals"
                                       (unlink)="onUnlinkSection('Health-Maintenance')"></omg-linked-health-maintenance>

        <omg-procedures-and-services [serviceTicketItems]="summary.serviceTicketItems"
                                     [showServiceTicketDeleteError]="showServiceTicketDeleteError"
                                     (unlink)="removeServiceTicketItems($event)"
                                     [procedureSuggestions]="procedureSuggestions$ | ngrxPush"
                                     [patientId]="patientId"></omg-procedures-and-services>

        <!-- Attachments -->
        <div *ngIf="summary.attachableFiles?.length > 0">
          <div class="label padding-normal-horizontal">Attachments</div>
          <div class="om-list">
            <div class="om-item"
                 *ngFor="let attachment of summary?.attachableFiles; trackBy: attachmentTrackBy">
              <b>
                <i class="fa fa-file-o"></i>
                <a [href]="attachment.viewingPath"
                   class="attachment-link"
                   target="_blank">{{ attachment.name }}</a>
              </b>
            </div>
          </div>
        </div>

        <!-- Time Based Visit -->
        <div *ngIf="!summary.visitProcedureId && summary?.noteType?.timeBasedVisitBillable && !summary.hasProgramVisit"
             class="padding-normal-horizontal">
          <omg-checkbox [label]="timeBasedVisitLabel"
                        formControlName="timeBasedVisit"></omg-checkbox>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #walkinSummary>
    <div>
      <!-- Comments -->
      <omg-expanded [collapseProvider]="commentsCollapseRef">
        <omg-comments [commentable]="commentable"
                      (commentAdded)="onCommentUpdate('add')"
                      (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
      </omg-expanded>

      <div om-layout="vertical">
        <div om-layout="vertical"
             class="padding-normal-horizontal"
             om-layout-gutter>
          <label>Note Title</label>
          <input omgInputText
                 type="text"
                 om-layout-fill
                 formControlName="subject"
                 data-cy="note-title-input" />
          <div class="om-messages"
               [hidden]="!subjectError">Cannot be blank</div>
        </div>

        <!-- Vitals and Exam -->
        <div class="padding-normal">
          <div class="label">Vitals</div>
          <div *ngIf="(patient$ | ngrxPush) as patient">
            <omg-vital-set-form [form]="unsignedSummaryForm.get('vitalsForm')"
                                [validationRules]="validationRules"
                                [patient]="patient"></omg-vital-set-form>
          </div>
        </div>

        <omg-linked-assessment-plan *ngIf="summary.assessedProblems?.length > 0"
                                    [signed]="summary.signed"
                                    [assessedProblems]="summary.assessedProblems"
                                    (unlink)="onUnlinkSection('Assessment-Plan', $event)"></omg-linked-assessment-plan>

        <omg-procedures-and-services [serviceTicketItems]="summary.serviceTicketItems"
                                     [showServiceTicketDeleteError]="showServiceTicketDeleteError"
                                     (unlink)="removeServiceTicketItems($event)"></omg-procedures-and-services>

        <!-- Attachments -->
        <div *ngIf="summary.attachableFiles?.length > 0">
          <div class="label padding-normal-horizontal">Attachments</div>
          <div class="om-list">
            <div class="om-item"
                 *ngFor="let attachment of summary?.attachableFiles; trackBy: attachmentTrackBy">
              <b>
                <i class="fa fa-file-o"></i>
                <a [href]="attachment.viewingPath"
                   class="attachment-link"
                   target="_blank">{{ attachment.name }}</a>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</form>
