// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';

import { formatSuggestionConfidence } from '@app/utils';

@Pipe({
  name: 'formatConfidence',
})
export class FormatConfidencePipe implements PipeTransform {
  transform(confidence: number): any {
    return formatSuggestionConfidence(confidence);
  }
}
