// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { NoteActions, NoteSelectors } from '@app/modules/note/store';
import {
  StateValidator,
  StateValidatorOptions,
} from '@app/shared/services/state-validator.service';

@Injectable()
export class NotesGuard {
  constructor(
    private noteActions: NoteActions,
    private noteSelectors: NoteSelectors,
    private stateValidator: StateValidator,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const noteId = +route.paramMap.get('id');
    const patientId = this.stateValidator.getPatientId(route);
    const options = this.getStateValidatorOptions(noteId);

    return this.stateValidator.canActivate(
      options,
      `patients/${patientId}/chart/summaries/new`,
    );
  }

  private getStateValidatorOptions(noteId: number): StateValidatorOptions {
    const noteSelector = this.noteSelectors.noteById(noteId);
    return {
      loadFn: () => this.noteActions.loadNoteWithTodo(noteId),
      selectors: [noteSelector],
      possibleErrorStates: [this.noteStateLoadError()],
    };
  }

  private noteStateLoadError = () => ({
    errorState: this.noteSelectors.error,
  });
}
