<div *ngIf="label"
     class="label"
     (click)=focus()>{{label}}</div>
<p-calendar [minDate]="minDate"
            [maxDate]="maxDate"
            [yearNavigator]="yearNavigator"
            [monthNavigator]="monthNavigator"
            [yearRange]="yearRange"
            [dateFormat]="dateFormatString"
            [inputStyleClass]="inputStyleClass"
            [showTime]="showTime"
            [hourFormat]="hourFormat"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [required]="required"
            [appendTo]="appendTo"
            [tabindex]="tabindex"
            [readonlyInput]="readonlyInput"
            [name]="name"
            [showButtonBar]="showButtonBar"
            [keepInvalid]="keepInvalid"
            (onSelect)="selectDate.emit($event)"
            (onFocus)="focusDatePicker.emit()"
            (onBlur)="onBlur($event)"
            (onClearClick)="clearClick.emit($event)">
</p-calendar>
