import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';

import { InternalUser } from '../internal-user.type';

export const addInternalUsers = createAction(
  '[InternalUsers] Add Internal Users',
  props<{ internalUsers: { [key: string]: InternalUser } }>(),
);

export interface InternalUsersState {
  internalUsers: {
    [key: string]: InternalUser;
  };
}

@Injectable({
  providedIn: 'root',
})
export class InternalUsersActions {
  constructor(private store: Store<InternalUsersState>) {}

  addUsers(users: InternalUser[]) {
    const payload = {};
    users.forEach(user => (payload[user.id] = user));
    this.store.dispatch(addInternalUsers({ internalUsers: payload }));
  }
}
