// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { CorrelationId, uuid } from '@app/utils';

import { PendingNewRx, PendingNewRxCreateEntity } from '../shared/rx-cart.type';
import { NewRxCartState } from './rx-cart.reducer';

export const ADD_EXISTING_PENDING_RX_CART_ITEMS =
  '[PendingNewRx] Add all existing Cart Items';
export const CREATE_PENDING_RX_CART_ITEM = '[PendingNewRx] Create Cart item';
export const CREATE_PENDING_RX_CART_ITEM_SUCCESS =
  '[PendingNewRx] Create Cart item Success';
export const CREATE_PENDING_RX_CART_ITEM_ERROR =
  '[PendingNewRx] Create Cart item Error';
export const REMOVE_PENDING_RX_CART_ITEM = '[PendingNewRx] Remove Cart item';
export const REMOVE_PENDING_RX_CART_ITEM_SUCCESS =
  '[PendingNewRx] Remove Cart item Success';
export const REMOVE_PENDING_RX_CART_ITEM_ERROR =
  '[PendingNewRx] Remove Cart item Error';
export const UPDATE_PENDING_RX_CART_ITEM = '[PendingNewRx] Update Cart item';
export const UPDATE_PENDING_RX_CART_ITEM_SUCCESS =
  '[PendingNewRx] Update Cart item Success';
export const UPDATE_PENDING_RX_CART_ITEM_ERROR =
  '[PendingNewRx] Update Cart item Error';
export const MARK_UNREADY_TO_SIGN = '[PendingNewRx] Mark Unready To Sign';
export const MARK_UNREADY_TO_SIGN_SUCCESS =
  '[PendingNewRx] Mark Unready To Sign Success';
export const MARK_READY_TO_SIGN = '[PendingNewRx] Mark Ready To Sign';
export const MARK_READY_TO_SIGN_SUCCESS =
  '[PendingNewRx] Mark Ready To Sign Success';
export const MARK_READY_TO_SIGN_ERROR =
  '[PendingNewRx] Mark Ready To Sign Error';
export const MARK_HANDWRITTEN_CONFIRMED =
  '[PendingNewRx] Mark Handwritten Confirmed';
export const MARK_HANDWRITTEN_UNCONFIRMED =
  '[PendingNewRx] Mark Handwritten Unconfirmed';

interface CartErrorPayload {
  errors: { [key: string]: string[] };
}

interface RemovePendingRxCartItemSuccessPayload {
  cartId: number;
  cartItemId: number;
}

export class AddExistingPendingRxCartItems
  implements FsaAction<PendingNewRx[]> {
  readonly type = ADD_EXISTING_PENDING_RX_CART_ITEMS;

  constructor(public payload: PendingNewRx[]) {}
}

export class CreatePendingRxCartItem
  implements FsaAction<PendingNewRxCreateEntity> {
  readonly type = CREATE_PENDING_RX_CART_ITEM;

  constructor(
    public payload: PendingNewRxCreateEntity,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class CreatePendingRxCartItemSuccess implements FsaAction<PendingNewRx> {
  readonly type = CREATE_PENDING_RX_CART_ITEM_SUCCESS;
  error = false;

  constructor(
    public payload: PendingNewRx,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class CreatePendingRxCartItemError implements FsaAction<any> {
  readonly type = CREATE_PENDING_RX_CART_ITEM_ERROR;
  error = true;

  constructor(
    public payload: CartErrorPayload,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class RemovePendingRxCartItem implements FsaAction<number> {
  readonly type = REMOVE_PENDING_RX_CART_ITEM;

  constructor(public payload: number) {}
}

export class RemovePendingRxCartItemSuccess
  implements FsaAction<RemovePendingRxCartItemSuccessPayload> {
  readonly type = REMOVE_PENDING_RX_CART_ITEM_SUCCESS;
  error = false;

  constructor(public payload: RemovePendingRxCartItemSuccessPayload) {}
}

export class RemovePendingRxCartItemError implements FsaAction<any> {
  readonly type = REMOVE_PENDING_RX_CART_ITEM_ERROR;
  error = true;

  constructor(public payload: CartErrorPayload) {}
}

export class UpdatePendingRxCartItem
  implements FsaAction<Update<PendingNewRx>> {
  readonly type = UPDATE_PENDING_RX_CART_ITEM;

  constructor(public payload: Update<PendingNewRx>) {}
}

export class UpdatePendingRxCartItemSuccess
  implements FsaAction<Update<PendingNewRx>> {
  readonly type = UPDATE_PENDING_RX_CART_ITEM_SUCCESS;
  error = false;

  constructor(public payload: Update<PendingNewRx>) {}
}

export class UpdatePendingRxCartItemError implements FsaAction<any> {
  readonly type = UPDATE_PENDING_RX_CART_ITEM_ERROR;
  error = true;

  constructor(public payload: CartErrorPayload, public meta: { id: number }) {}
}

export class MarkUnreadyToSign implements FsaAction<number> {
  readonly type = MARK_UNREADY_TO_SIGN;

  constructor(public payload: number) {}
}

export class MarkUnreadyToSignSuccess implements FsaAction<boolean> {
  readonly type = MARK_UNREADY_TO_SIGN_SUCCESS;

  constructor(public payload: boolean, public meta: { id: number }) {}
}

export class MarkReadyToSign implements FsaAction<number> {
  readonly type = MARK_READY_TO_SIGN;

  constructor(public payload: number) {}
}

export class MarkReadyToSignSuccess implements FsaAction<boolean> {
  readonly type = MARK_READY_TO_SIGN_SUCCESS;

  constructor(public payload: boolean, public meta: { id: number }) {}
}

export class MarkReadyToSignError implements FsaAction<CartErrorPayload> {
  readonly type = MARK_READY_TO_SIGN_ERROR;

  constructor(public payload: CartErrorPayload) {}
}

export class MarkHandwrittenConfirmed implements FsaAction<number> {
  readonly type = MARK_HANDWRITTEN_CONFIRMED;

  constructor(public payload: number) {}
}

export class MarkHandwrittenUnconfirmed implements FsaAction<number> {
  readonly type = MARK_HANDWRITTEN_UNCONFIRMED;

  constructor(public payload: number) {}
}

export type PendingNewRxAction =
  | AddExistingPendingRxCartItems
  | CreatePendingRxCartItem
  | CreatePendingRxCartItemSuccess
  | CreatePendingRxCartItemError
  | RemovePendingRxCartItem
  | RemovePendingRxCartItemSuccess
  | RemovePendingRxCartItemError
  | UpdatePendingRxCartItem
  | UpdatePendingRxCartItemSuccess
  | UpdatePendingRxCartItemError
  | MarkUnreadyToSign
  | MarkUnreadyToSignSuccess
  | MarkReadyToSign
  | MarkReadyToSignSuccess
  | MarkReadyToSignError
  | MarkHandwrittenConfirmed
  | MarkHandwrittenUnconfirmed;

@Injectable({ providedIn: 'root' })
export class PendingNewRxActions {
  constructor(private store: Store<NewRxCartState>) {}

  addExistingPendingRxCartItems(items: PendingNewRx[]) {
    this.store.dispatch(new AddExistingPendingRxCartItems(items));
  }

  createPendingRxCartItem(
    item: PendingNewRxCreateEntity,
    correlationId?: CorrelationId,
  ) {
    correlationId = correlationId || uuid();
    this.store.dispatch(new CreatePendingRxCartItem(item, { correlationId }));
    return correlationId;
  }

  removePendingRxCartItem(itemId: number) {
    this.store.dispatch(new RemovePendingRxCartItem(itemId));
  }

  updatePendingRxCartItem(item: Update<PendingNewRx>) {
    this.store.dispatch(new UpdatePendingRxCartItem(item));
  }

  markReadyToSign(id: number) {
    this.store.dispatch(new MarkReadyToSign(id));
  }

  markUnreadyToSign(id: number) {
    this.store.dispatch(new MarkUnreadyToSign(id));
  }

  markHandwrittenConfirmed(id: number) {
    this.store.dispatch(new MarkHandwrittenConfirmed(id));
  }

  toggleReadyToSign(id: number, isReadyToSign: boolean) {
    this.store.dispatch(
      isReadyToSign
        ? new MarkReadyToSignSuccess(isReadyToSign, { id })
        : new MarkUnreadyToSignSuccess(isReadyToSign, { id }),
    );
  }

  markHandwrittenUnconfirmed(id: number) {
    this.store.dispatch(new MarkHandwrittenUnconfirmed(id));
  }
}
