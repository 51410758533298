<div class="om-tabs"
     omgTheme
     om-layout-fill>
  <ul class="om-tabs-nav"
      [ngClass]="{'alt-tab-height': altTabHeight}">
    <ng-container *ngFor="let tab of tabs; trackBy: trackByFn">
      <li (click)="selectTab(tab)"
          [routerLink]="tab.route"
          [attr.data-cy]="tab.dataCy"
          [class.active]="tab.isActive"
          [class.space-equally]="spaceEqually">
        <button>
          <strong class="tab-title"
                  [ngClass]="{'no-text-transform': tab.noTextTransformHeading}">{{ tab.heading }}</strong>
          <strong *ngIf="(tab.badgeCount$ | ngrxPush) > 0"
                  class="om-badge -warning">{{ tab.badgeCount$ | ngrxPush }}</strong>
        </button>
      </li>
    </ng-container>
  </ul>
  <div *ngIf="!buttonMode">
    <ng-content></ng-content>
  </div>
</div>
