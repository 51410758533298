import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[omgStopEvent]',
})
export class StopEventDirective implements OnInit {
  @Input() omgStopEvent = 'click';

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    this.renderer.listen(
      this.elementRef.nativeElement,
      this.omgStopEvent,
      (event: any) => event.stopPropagation(),
    );
  }
}
