// @ts-strict-ignore
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, NgZone } from '@angular/core';

import { getOriginOverlayPositions } from '../utils/cdk-overlay-utils';
import { applyConfigDefaults, PopoverConfig } from './popover-config';
import { PopoverPortalComponent } from './popover-portal.component';
import { PopoverRef } from './popover-ref';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  private openPopoverRef: PopoverRef<any>;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private zone: NgZone,
  ) {}

  open<T>(config: PopoverConfig<T>): PopoverRef<T> {
    if (this.openPopoverRef) {
      this.openPopoverRef.close();
    }
    config = applyConfigDefaults(config);

    const overlayRef = this.overlay.create(this.getOverlayConfig(config));
    const popoverRef = new PopoverRef<T>(overlayRef, config, this.zone);
    const injector = this.createInjector(popoverRef, this.injector);

    popoverRef.attach(PopoverPortalComponent, injector);

    this.openPopoverRef = popoverRef;

    return popoverRef;
  }

  private getOverlayConfig(config: PopoverConfig) {
    return new OverlayConfig({
      hasBackdrop: false,
      width: config.width || config.minWidth,
      height: config.height,
      minWidth: config.minWidth,
      minHeight: config.minHeight,
      maxWidth: config.maxWidth,
      maxHeight: config.maxHeight,
      backdropClass: '',
      panelClass: '',
      positionStrategy: this.getOverlayPosition(config),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(config: PopoverConfig): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(config.origin)
      .withPositions(
        this.getPositions(config).map(position => ({
          ...position,
          offsetX: config.offsetX,
          offsetY: config.offsetY,
        })),
      )
      .withFlexibleDimensions(config.withFlexibleDimensions)
      .withPush(false);

    return positionStrategy;
  }

  private createInjector(popoverRef: PopoverRef, injector: Injector) {
    return Injector.create({
      providers: [{ provide: PopoverRef, useValue: popoverRef }],
      parent: injector,
    });
  }

  private getPositions(config: PopoverConfig): ConnectionPositionPair[] {
    return getOriginOverlayPositions(config.placement);
  }
}
