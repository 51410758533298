<label>DEA License Number</label>
<div *ngIf="loading$ | ngrxPush">
  <i class="fa fa-lg fa-spinner fa-pulse"></i>
</div>
<ng-container *ngIf="!(loading$ | ngrxPush) && credentialDropdownItems?.length > 0">
  <omg-dropdown om-focus
                om-layout-fill
                *ngIf="showDropDown"
                [formControl]="selectedPrescribingCredentialId"
                [options]="credentialDropdownItems">
  </omg-dropdown>
  <span *ngIf="!showDropDown">
    {{ credentialDropdownItems[0].label }}
  </span>
</ng-container>
