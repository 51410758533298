// @ts-strict-ignore
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { CollapseProviderDirective } from '@app/shared/components/collapse';

import { Note } from '../../shared/note.type';

@Component({
  selector: 'omg-note-header',
  templateUrl: './note-header.component.html',
  styleUrls: ['./note-header.component.scss'],
})
export class NoteHeaderComponent {
  @Input() editable: boolean;
  @Input() note: Note;
  @Input() todo: Todo;
  @Input() form: UntypedFormGroup;
  @Input() commentsCollapseRef: CollapseProviderDirective;

  @Output() closeNoteHeader = new EventEmitter<void>();

  constructor(private analytics: AnalyticsService) {}

  onClose() {
    this.closeNoteHeader.emit();
  }

  trackTitleUnselected(): void {
    this.analytics.track(AnalyticsEvent.FieldUnselected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Title',
      noteId: this.note.id,
    });
  }

  trackTitleSelected(): void {
    this.analytics.track(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Title',
      noteId: this.note.id,
    });
  }

  trackCommentClicked(): void {
    this.analytics.track(AnalyticsEvent.AddCommentClicked, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Add Comment Button',
      noteId: this.note.id,
      method: this.commentsCollapseRef.expanded ? 'Expand' : 'Collapse',
    });
  }
}
