import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import { deleteCommentReducer } from './comments.reducer.utils';
import {
  SummaryCommentsAction,
  SummaryCommentsActionTypes,
} from './summary-comments.actions';

export const summaryCommentsStatePath = 'summaryComments';

export interface SummaryCommentsState extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

export function selectSummaryCommentsId(comments: Comment): number {
  return comments.id;
}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectSummaryCommentsId,
});

export const summaryCommentsInitialState: SummaryCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function summaryCommentsReducer(
  state = summaryCommentsInitialState,
  action: SummaryCommentsAction,
): SummaryCommentsState {
  switch (action.type) {
    case SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS: {
      return {
        ...state,
        pending: true,
      };
    }

    case SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS_SUCCESS: {
      state = { ...state, error: null, pending: false };
      return adapter.setAll(action.payload, state);
    }

    case SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT: {
      return { ...state, error: null, pending: true };
    }

    case SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, action, adapter);
    }
  }
}
