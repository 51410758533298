<div om-layout
     om-layout-align="space-between center">
  <div>
    <label for="freeTextInstructions">Instructions</label> (
    <button omgButton
            type="button"
            variant="link"
            class="-plain-text"
            (click)="onUseStructuredDataClick()"
            data-cy="regimen-editor-use-structured-data-btn">
      use structured data
    </button> )
  </div>
  <label for="freeTextInstructions">
    {{ instructionsText.value | omgRemainingCharacters:maxLength }} characters
    remaining
  </label>
</div>
<textarea omgTextarea
          autosize
          rows="2"
          class="-flush-bottom"
          id="freeTextInstructions"
          #freeTextInstructions
          [formControl]="instructionsText"
          (change)="onInstructionsTextChange($event)"
          omgStopEvent
          omgScrubText
          placeholder="special characters and line breaks not allowed"
          om-layout-fill
          data-cy="regimen-editor-free-text-textarea"></textarea>
