import { Component, Input } from '@angular/core';

import { ProcedureCapture } from '../../shared/historical-procedure-capture.type';

@Component({
  selector: 'omg-procedure-capture-view',
  templateUrl: './procedure-capture-view.component.html',
  styleUrls: ['./procedure-capture-view.component.scss'],
})
export class ProcedureCaptureViewComponent {
  @Input() procedureCapture: ProcedureCapture;
}
