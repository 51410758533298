<div class="padding-half"
     om-layout
     om-layout-gutter
     om-layout-align="space-between center">
  <div om-layout
       om-layout-gutter
       om-layout-align="start center"
       class="padding-half-horizontal">
    <strong>{{text}}</strong>
    <span #dataInfoIcon
          *ngIf="tooltip.childNodes.length > 0"
          class="om-icon icon-alert-info clickable"
          (click)="showInfoPopover()"></span>
  </div>
</div>
<omg-popover #infoPopover
             placement="top"
             width="430">
  <div #tooltip
       (click)="closeInfoPopover()">
    <ng-content></ng-content>
  </div>
</omg-popover>
