import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent {
  @Input() altBackgroundColor = false;
  @Input() largeFontSize = false;
}
