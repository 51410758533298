// @ts-strict-ignore
import { mapFormToDrugInteractionOverride } from '@app/modules/medications/shared/patient-medications-api-mappers';
import { findAPharmacyOption } from '@app/modules/pharmacy-picker/shared/patient-pharmacy.type';
import {
  CancelPrescriber,
  CancelPrescriberResponse,
  CancelPrescribers,
  CancelPrescribersResponse,
  Credential,
  CredentialResponse,
  Prescriber,
  PrescriberCredentials,
  PrescriberCredentialsResponse,
} from '@app/modules/shared-rx/prescriber-credential.type';
import {
  MedForDisplay,
  RxPackageSize,
} from '@app/modules/shared-rx/shared-rx.type';
import { camelCase } from '@app/utils';
import { head } from '@app/utils';

import {
  MedForDisplayResponse,
  MedicationPrescriptionItemResponse,
  PendingNewRxCreateData,
  PendingNewRxResponse,
  PrescriberResponse,
  RxCartResponse,
  RxMedicationDispensableResponse,
  RxPackageSizeResponse,
} from './rx-cart-api.type';
import { findValidPrescriber } from './rx-cart-utils';
import {
  ErxParams,
  MedicationPrescriptionItem,
  PendingNewRx,
  PendingNewRxCreateEntity,
  PendingNewRxUpdateErrorResponse,
  RxCart,
  RxMedicationDispensable,
  UpdateRxCartErrorResponse,
} from './rx-cart.type';

/* istanbul ignore next */
export const mapRxPackageSizeResponseToEntity = (
  data: RxPackageSizeResponse,
): RxPackageSize => ({
  id: data.id,
  desc: data.desc,
  descPlural: data.desc_plural,
});

/* istanbul ignore next */
export const mapRxMedicationDispensableResponseToEntity = (
  data: RxMedicationDispensableResponse,
): RxMedicationDispensable => ({
  textDesc: data.text_desc,
  packageSizes:
    data.package_sizes &&
    data.package_sizes.map(mapRxPackageSizeResponseToEntity),
});

/* istanbul ignore next */
export const mapMedicationPrescriptionItemResponseToEntity = (
  data: MedicationPrescriptionItemResponse,
): MedicationPrescriptionItem => ({
  id: data.id,
  layInstructionsForUse: data.lay_instructions_for_use,
  medicationDispensable: mapRxMedicationDispensableResponseToEntity(
    data.medication_dispensable,
  ),
});

/* istanbul ignore next */
export const mapMedForDisplayResponseToEntity = (
  medForDisplay: MedForDisplayResponse,
): MedForDisplay => ({
  dispensableTextDesc: medForDisplay.dispensable_text_desc,
  instructions: medForDisplay.instructions,
  name: medForDisplay.name,
  quantityDescription: medForDisplay.quantity_description,
  refills: medForDisplay.refills,
});

/* istanbul ignore next */
export const mapPendingNewRxResponseToEntity = (
  data: PendingNewRxResponse,
): PendingNewRx => ({
  id: data.id,
  dispensableControlledSubstance: data.dispensable_controlled_substance,
  dispensableDeaCode: data.dispensable_dea_code,
  dispensableMaxRefills: data.dispensable_max_refills,
  dispensableRestrictedControlledSubstance:
    data.dispensable_restricted_controlled_substance,
  dispenseAsWritten: data.dispense_as_written,
  displayEarliestFillDate: data.display_earliest_fill_date,
  earliestFillDate: data.earliest_fill_date,
  earliestFillDateInputName: data.earliest_fill_date_input_name,
  maxEarliestFillDate: data.max_earliest_fill_date,
  maxQuantity: data.max_quantity,
  medForDisplay: mapMedForDisplayResponseToEntity(data.med_for_display),
  medicationPackageSizeId: data.medication_package_size_id,
  medicationPrescriptionItem: mapMedicationPrescriptionItemResponseToEntity(
    data.medication_prescription_item,
  ),
  medicationPrnId: data.medication_prn_id,
  medicationRegimenId: data.medication_regimen_id,
  medicationRoute: data.medication_route,
  minEarliestFillDate: data.min_earliest_fill_date,
  mustHandwrite: data.must_handwrite,
  handwrittenConfirmed: data.handwrittenConfirmed,
  noteToPharmacistMaxCharacters: data.note_to_pharmacist_max_characters,
  notesLegalAddendum: data.notes_legal_addendum,
  notesToPharmacist: data.notes_to_pharmacist,
  patientId: data.patient_id,
  patientMedicationId: data.patient_medication_id,
  patientMedicationRegimenId: data.patient_medication_regimen_id,
  pharmacyId: data.pharmacy_id,
  prescriberId: data.prescriber_id,
  prescribingCredentialId: data.prescribing_credential_id,
  quantity: data.quantity,
  refills: data.refills,
  require2Fa: data.require_2fa,
  requiresNoteOfMedicalNeed: data.requires_note_of_medical_need,
});

/* istanbul ignore next */
export const mapMedForDisplayEntityToUpdateData = (
  data: MedForDisplay,
): MedForDisplayResponse => ({
  dispensable_text_desc: data.dispensableTextDesc,
  instructions: data.instructions,
  name: data.name,
  quantity_description: data.quantityDescription,
  refills: data.refills,
});

/* istanbul ignore next */
export const mapEntityToPendingNewRxUpdateData = (
  data: PendingNewRx,
): PendingNewRxResponse => ({
  id: data.id,
  dispensable_controlled_substance: data.dispensableControlledSubstance,
  dispensable_dea_code: data.dispensableDeaCode,
  dispensable_max_refills: data.dispensableMaxRefills,
  dispensable_restricted_controlled_substance:
    data.dispensableRestrictedControlledSubstance,
  dispense_as_written: data.dispenseAsWritten,
  display_earliest_fill_date: data.displayEarliestFillDate,
  earliest_fill_date: data.earliestFillDate,
  earliest_fill_date_input_name: data.earliestFillDateInputName,
  max_earliest_fill_date: data.maxEarliestFillDate,
  max_quantity: data.maxQuantity,
  med_for_display: mapMedForDisplayEntityToUpdateData(data.medForDisplay),
  medication_package_size_id: data.medicationPackageSizeId,
  medication_prescription_item: data.medicationPrescriptionItem,
  medication_prn_id: data.medicationPrnId,
  medication_regimen_id: data.medicationRegimenId,
  medication_route: data.medicationRoute,
  min_earliest_fill_date: data.minEarliestFillDate,
  must_handwrite: data.mustHandwrite,
  handwrittenConfirmed: data.handwrittenConfirmed,
  note_to_pharmacist_max_characters: data.noteToPharmacistMaxCharacters,
  notes_legal_addendum: data.notesLegalAddendum,
  notes_to_pharmacist: data.notesToPharmacist,
  patient_id: data.patientId,
  patient_medication_id: data.patientMedicationId,
  patient_medication_regimen_id: data.patientMedicationRegimenId,
  pharmacy_id: data.pharmacyId,
  prescriber_id: data.prescriberId,
  prescribing_credential_id: data.prescribingCredentialId,
  quantity: data.quantity,
  refills: data.refills,
  require_2fa: data.require2Fa,
  readyToSign: data.readyToSign,
  requires_note_of_medical_need: data.requiresNoteOfMedicalNeed,
});

/* istanbul ignore next */
export const mapCredentialsResponseToEntity = (
  data: CredentialResponse,
): Credential => ({
  deaLicenseNumber: data.dea_license_number,
  id: data.id,
  serviceArea: data.service_area,
  stateOfIssue: data.state_of_issue,
  supervisingPhysicianId: data.supervising_physician_id,
  supervisingPhysician: data.supervising_physician || null,
});

/* istanbul ignore next */
export const mapCancelPrescriberToEntity = (
  data: CancelPrescriberResponse,
): CancelPrescriber => ({
  id: data.id,
  name: data.name,
  prescribingCredentials: data.prescribing_credentials.map(
    mapCredentialsResponseToEntity,
  ),
});

/* istanbul ignore next */
export const mapPrescriberCredentialsResponseToEntity = (
  data: PrescriberCredentialsResponse,
): PrescriberCredentials => ({
  id: data.id,
  prescribingCredentials: (data.prescribing_credentials || []).map(
    mapCredentialsResponseToEntity,
  ),
});

/* istanbul ignore next */
export const mapCancelPrescribersResponseToEntity = (
  data: CancelPrescribersResponse,
): CancelPrescribers => {
  return {
    id: data.id,
    cancelPrescribers: (data.cancel_prescribers || []).map(
      mapCancelPrescriberToEntity,
    ),
  };
};

/* istanbul ignore next */
export const mapCancelPrescriberToPrescriber = (
  data: CancelPrescriber,
): Prescriber => ({
  id: data.id,
  name: data.name,
  address: '',
});

/* istanbul ignore next */
export const mapValidPrescribersToEntity = (
  data: PrescriberResponse,
): Prescriber => ({
  id: data.id,
  name: data.name,
  address: data.address,
});

const mapPrescriberAndCredentialIds = (cart: RxCartResponse) => {
  // Ensure the signed on behalf of is a valid prescriber
  const validPrescribers = cart.valid_prescribers || [];
  const prescriber = findValidPrescriber(
    validPrescribers,
    cart.signed_on_behalf_of_id,
  );
  const signedOnBehalfOfId = (prescriber && prescriber.id) || null;

  // Get the selectedPrescribingCredentialId from the cart items
  // only if the signedOnBehalfOf user is valid
  // NOTE: This value is never returned in the response so this seems
  //       to be the only way to do this based on the current API calls.
  const cartItem = head(cart.cart_items);
  const selectedPrescribingCredentialId =
    signedOnBehalfOfId && cartItem && cartItem.prescribing_credential_id;

  // The cleansed prescriber and credentials
  return { signedOnBehalfOfId, selectedPrescribingCredentialId };
};

/* istanbul ignore next */
export const mapRxCartResponseToEntity = (data: RxCartResponse): RxCart => ({
  cartCompleteAt: data.cart_complete_at,
  cartItems: data.cart_items.map(mapPendingNewRxResponseToEntity),
  createdAt: data.created_at,
  id: data.id,
  notifyPatient: data.notify_patient,
  patientId: data.patient_id,
  pharmacyId: data.pharmacy_id,
  require2Fa: data.require_2fa,
  signedById: data.signed_by_id,
  signedByName: data.signed_by_name,
  updatedAt: data.updated_at,
  rxCartMeasurements: (data.rx_cart_measurements || []).map(camelCase),
  validPrescribers: (data.valid_prescribers || []).map(
    mapValidPrescribersToEntity,
  ),
  ...mapPrescriberAndCredentialIds(data),
});

/* istanbul ignore next */
export const mapEntityToRxCartUpdateData = (data: RxCart) => ({
  cart_complete_at: data.cartCompleteAt,
  cart_items: data.cartItems.map(mapEntityToPendingNewRxUpdateData),
  id: data.id,
  notify_patient: data.notifyPatient,
  patient_id: data.patientId,
  pharmacy_id: data.pharmacyId && data.pharmacyId > -1 ? data.pharmacyId : null,
  require_2fa: data.require2Fa,
  signed_by_id: data.signedById,
  signed_by_name: data.signedByName,
  signed_on_behalf_of_id: data.signedOnBehalfOfId,
  selected_prescribing_credential_id: data.selectedPrescribingCredentialId,
});

export const mapEntityToPendingNewRxCreateData = (
  data: PendingNewRxCreateEntity,
): PendingNewRxCreateData => {
  const overriddenInteractions = mapFormToDrugInteractionOverride(data);
  return {
    medication_regimen_id: data.medicationRegimenId,
    patient_medication_regimen_id: data.patientMedicationRegimenId,
    patient_id: data.patientId,
    has_drug_interaction_override: data.hasDrugInteractionOverride,
    interacting_medication_ids: overriddenInteractions.interactingMedicationIds,
    interacting_allergy_ids: overriddenInteractions.interactingAllergyIds,
  };
};

/* istanbul ignore next */
export const mapErxParamsToRequestData = (params: ErxParams) => ({
  password: params.password,
  rx_cart_id: params.rxCartId,
  cart_push_token: params.cartPushToken,
  print_rx: params.printRx,
  cart: mapEntityToRxCartUpdateData(params.cart),
});

/* istanbul ignore next */
export const mapPendingNewRxUpdateErrorResponse = (
  error: PendingNewRxUpdateErrorResponse,
): any => ({
  errors: { ...error.errors },
});

/* istanbul ignore next */
export const mapEntityToRxCartUpdateErrorResponse = (
  error: UpdateRxCartErrorResponse,
): any => ({
  errors: { ...error.errors },
});
