import { Injectable } from '@angular/core';
import { CookieOptions, CookieService as CookiesService } from 'ngx-cookie';

@Injectable()
export class CookieService {
  constructor(private cookie: CookiesService) {}

  get() {
    return this.cookie.get('token');
  }

  set(token: string, options: CookieOptions) {
    this.cookie.put('token', token, options);
  }

  delete() {
    this.cookie.remove('token');
  }

  isPresent() {
    return !!this.cookie.get('token');
  }
}
