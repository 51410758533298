<div class="caption"
     *ngIf="numberOfPages > 1"
     om-layout
     om-layout-align="space-between center"
     om-layout-gutter>
  <span>Page {{currentPage}} of {{numberOfPages}}</span>
  <button *ngIf="currentPage !== 1"
          omgButton
          variant="flat-icon"
          (click)="onPreviousPage()">
    <span class="om-icon clickable icon-page-left"></span>
  </button>
  <button omgButton
          variant="flat-icon"
          *ngIf="currentPage < numberOfPages"
          (click)="onNextPage()">
    <span class="om-icon clickable icon-page-right"></span>
  </button>
</div>
