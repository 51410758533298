<omg-ml-suggestion *ngIf="!feedbackVisible"
                   text="This message was auto-reassigned"
                   buttonText="Give Feedback"
                   (accept)="showFeedback()"
                   (tooltipOpened)="trackTooltipOpened()"
                   [hideCloseButton]="true">
  <div class="padding-normal">
    <div om-layout
         om-layout-align="space-between">
      <strong>How message assignment works</strong>
      <span class="om-icon clickable icon-dismiss -gray"></span>
    </div>
    {{ tooltipMainMessage }} Read more on
    <a target="_blank"
       class="-plain-text"
       href="https://sites.google.com/onemedical.com/technology-knowledge-base/home/product-teams/machine-learning-team/message-routing">our
      wiki</a>.
  </div>
</omg-ml-suggestion>
<omg-todo-reassignment-feedback *ngIf="feedbackVisible"
                                [reassignmentId]=reassignmentId
                                (cancel)="hideFeedback()"></omg-todo-reassignment-feedback>
