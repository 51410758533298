<div omgTheme>
  <span om-layout
        om-layout-align="start center"
        om-layout-gutter
        *ngIf="!editingSubject"
        (click)="refreshLabel()">
    <span class="editable-label">{{value | slice:0:90}}</span>
    <span om-flex="5"
          class="om-icon icon-edit"></span>
  </span>
  <input *ngIf="editingSubject"
         data-cy="editable-title"
         omgInputText
         class="editable-label-input -edit-in-place"
         [name]="name"
         [placeholder]="placeholder"
         [required]="required"
         [(ngModel)]="value"
         (blur)="update($event)"
         (keyup.enter)="update($event)"
         [omgFocusOn]="focusOnKey"
         [disabled]="disabled"
         (focus)="focused($event)"
         (focusout)="focusedOut($event)"
         om-layout-fill />
</div>
