<div omgTheme
     class="full-height"
     om-layout
     om-layout-align="center center">

  <button omgButton
          variant="link"
          (click)="login()">
    <h5 class="login-title">Login with 1Life</h5>
  </button>

</div>
