import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SummaryProblem } from './../../shared/summaries.type';
import { hasNoSignificantMedicalHistory } from '../../shared/summaries-utils';

@Component({
  selector: 'omg-linked-problems',
  templateUrl: './linked-problems.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedProblemsComponent implements OnChanges {
  @Input() signed: boolean;
  @Input() activeProblems: SummaryProblem[];
  @Input() resolvedProblems: SummaryProblem[];
  @Output() unlink = new EventEmitter();

  noSignificantMedicalHistory: boolean;

  trackByFn = (index, problem: SummaryProblem) => problem.id || index;

  ngOnChanges(changes: SimpleChanges) {
    const { activeProblems, resolvedProblems } = changes;
    if (
      activeProblems.previousValue !== activeProblems.currentValue ||
      resolvedProblems.previousValue !== resolvedProblems.currentValue
    ) {
      this.noSignificantMedicalHistory = hasNoSignificantMedicalHistory(
        activeProblems.currentValue.length,
        resolvedProblems.currentValue.length,
      );
    }
  }

  deleteFromNote() {
    this.unlink.emit();
  }
}
