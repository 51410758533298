// @ts-strict-ignore
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ExtendedKeyboardEvent,
  Hotkey,
  HotkeysService,
} from 'angular2-hotkeys';

import { CommandPaletteService } from '@app/modules/command-palette/command-palette.service';

import { createHotkeyHandler } from './shortcut-click-handlers';
import { HotkeyHandler, HotkeyHandlerType } from './shortcut-click.type';

/** For more information on possible hotkeys, please see https://craig.is/killing/mice. */
@Directive({
  selector: '[omgShortcutClick]',
})
export class ShortcutClickDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('omgShortcutClick') key: string;
  @Input() handlerType: HotkeyHandlerType = 'focusClick';

  private registeredKeys: Hotkey[] = [];
  private originalStopCallback: (
    e: ExtendedKeyboardEvent,
    element: Element,
    combo: string,
  ) => boolean;

  constructor(
    private el: ElementRef,
    private hotkeyService: HotkeysService,
    private commandPaletteService: CommandPaletteService,
  ) {}

  ngOnInit() {
    this.registerHotkey(this.key);
    // the type signature suggests that `mousetrap` is always defined,
    // but in tests it is not.
    if (this.hotkeyService.mousetrap) {
      this.originalStopCallback = this.hotkeyService.mousetrap.stopCallback;
      this.hotkeyService.mousetrap.stopCallback = this.stopCallback;
    }
  }

  stopCallback = (
    e: ExtendedKeyboardEvent,
    element: Element,
    combo: string,
  ) => {
    if (this.commandPaletteService.isElementCommandPaletteElement(element)) {
      return true;
    }
    return this.originalStopCallback(e, element, combo);
  };

  ngOnDestroy() {
    this.registeredKeys.forEach(registeredKey =>
      this.deregisterHotkey(registeredKey),
    );
  }

  deregisterHotkey(hotkey: Hotkey): Hotkey {
    this.registeredKeys = this.registeredKeys.filter(
      registeredKeys => registeredKeys !== hotkey,
    );
    this.hotkeyService.remove(hotkey);

    return hotkey;
  }

  registerHotkey(key: string, handler?: HotkeyHandler): Hotkey {
    const focusElementHandler = createHotkeyHandler('focusClick', this.el);
    const hotkey = new Hotkey(key, handler || focusElementHandler);

    this.registeredKeys = [...this.registeredKeys, hotkey];
    this.hotkeyService.add(hotkey);

    return hotkey;
  }
}
