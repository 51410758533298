// @ts-strict-ignore
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import {
  PopoverComponent,
  PopoverRef,
  PopoverService,
} from '@app/shared/components/popover';

@Component({
  selector: 'omg-automatic-process-explanation',
  templateUrl: './automatic-process-explanation.component.html',
  styleUrls: ['./automatic-process-explanation.component.scss'],
})
export class AutomaticProcessExplanationComponent {
  @Input() text: string;
  @ViewChild('dataInfoIcon') dataInfo: ElementRef;
  @ViewChild('infoPopover', { static: true }) infoPopover: PopoverComponent;

  private infoPopoverRef: PopoverRef;

  constructor(private popoverService: PopoverService) {}

  showInfoPopover() {
    this.infoPopoverRef = this.popoverService.open({
      ...this.infoPopover,
      origin: this.dataInfo,
    });
  }

  closeInfoPopover() {
    if (this.infoPopoverRef) {
      this.infoPopoverRef.close();
    }
  }
}
