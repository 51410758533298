// @ts-strict-ignore
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { parseHotkeyStringToEventProperties } from '@app/shared/directives/shortcut-click/shortcut-click-handlers';

declare var Quill: any;

/**
 * Originally based on PrimeNG's p-editor
 * See their sourcecode for reference:
 * https://github.com/primefaces/primeng/blob/master/src/app/components/editor/editor.ts
 */
@Component({
  selector: 'omg-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RichTextEditorComponent),
    },
  ],
})
export class RichTextEditorComponent
  implements AfterViewInit, ControlValueAccessor {
  @Input() style: any;
  @Input() styleClass: string;
  @Input() triggerShortcutClickOn: string;
  @Input() placeholderText: string;
  @Input() bounds = 'self';
  @Input() useMarkdownStyling = true;
  @Input() scrollingContainer = null;
  @Input() allowHyperlinks = true;

  @Output() init = new EventEmitter<any>();
  @Output() textChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();

  private _value = '';
  private _readonly = false;

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onModelChange(value);
    }
  }

  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(val: boolean) {
    this._readonly = val;

    if (this.quill) {
      if (this._readonly) {
        this.quill.disable();
      } else {
        this.quill.enable();
      }
    }
  }

  readonly editorSelector = 'div.p-editor-content';
  readonly toolbarSelector = 'div.p-editor-toolbar';

  quill: any;
  editorElement: any;
  toolbarElement: any;

  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(public el: ElementRef, private hotkeyService: HotkeysService) {}

  ngAfterViewInit() {
    this.editorElement = this.selectElement(this.el, this.editorSelector);
    this.toolbarElement = this.selectElement(this.el, this.toolbarSelector);

    this.setupQuillEditor();
  }

  insertText(index = 0, html: string, source: string = 'user') {
    this.quill.clipboard.dangerouslyPasteHTML(index, html, source);
  }

  onSelectionChange(range, oldRange, source) {
    this.selectionChange.emit({
      range: range,
      oldRange: oldRange,
      source: source,
    });
  }

  onTextChange(delta, oldContents, source) {
    if (source === 'user') {
      let html = this.editorElement.children[0].innerHTML;
      const text = this.quill.getText().trim();
      if (html === '<p><br></p>') {
        html = null;
      }

      this.textChange.emit({
        htmlValue: html,
        textValue: text,
        delta: delta,
        source: source,
      });

      this.onModelChange(html);
      this.onModelTouched();
    }
  }

  /* istanbul ignore next */
  registerShortcutClick() {
    if (!this.triggerShortcutClickOn) {
      return;
    }

    if (this.quill) {
      this.quill.keyboard.addBinding(
        parseHotkeyStringToEventProperties(this.triggerShortcutClickOn),
        () => {
          const hotkey = <Hotkey>(
            this.hotkeyService.get(this.triggerShortcutClickOn)
          );
          hotkey.callback(null, this.triggerShortcutClickOn);
        },
      );
    }
  }

  /* istanbul ignore next */
  focus() {
    if (this.quill) {
      this.quill.focus();
    }
  }

  /* istanbul ignore next */
  writeValue(value: any): void {
    this.value = value;

    if (this.quill) {
      if (value) {
        this.quill.pasteHTML(value);
      } else {
        this.quill.setText('');
      }
    }
  }

  /* istanbul ignore next */
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  /* istanbul ignore next */
  private selectElement(el: ElementRef, selector: string) {
    return el.nativeElement.querySelector(selector);
  }

  /* istanbul ignore next */
  private setupQuillEditor() {
    this.quill = new Quill(this.editorElement, {
      modules: { toolbar: this.toolbarElement },
      placeholder: this.placeholderText,
      readOnly: this.readonly,
      theme: 'snow',
      bounds: this.bounds === 'self' ? this.editorElement : this.bounds,
      scrollingContainer: this.scrollingContainer,
    });

    if (this.value) {
      this.quill.pasteHTML(this.value);
    }

    this.quill.on('text-change', this.onTextChange.bind(this));

    this.quill.on('selection-change', this.onSelectionChange.bind(this));

    this.init.emit({
      editor: this.quill,
    });

    this.registerShortcutClick();
  }
}
