import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

type LogArgument = any;

@Injectable()
export class LoggerService {
  info(...args: LogArgument[]) {
    const [first, ...rest] = args;
    /* eslint-disable-next-line no-console */
    console.info(first, rest);
  }

  log(...args: LogArgument[]) {
    const [first, ...rest] = args;
    /* eslint-disable-next-line no-console */
    console.log(first, rest);
  }

  warning(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.warn(first, rest);
  }

  error(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.error(first, rest);
    if (first instanceof Error) Sentry.captureException(first);
  }
}
