import { CM_PER_INCH } from './constants';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// WHO data
// https://www.cdc.gov/growthcharts/who/boys_weight_head_circumference.htm
const maleWeightForLengthLMSParams: LMSParamsMap = {
  45: { lambda: -0.3521, mu: 2.441, sigma: 0.09182 },
  45.5: { lambda: -0.3521, mu: 2.5244, sigma: 0.09153 },
  46: { lambda: -0.3521, mu: 2.6077, sigma: 0.09124 },
  46.5: { lambda: -0.3521, mu: 2.6913, sigma: 0.09094 },
  47: { lambda: -0.3521, mu: 2.7755, sigma: 0.09065 },
  47.5: { lambda: -0.3521, mu: 2.8609, sigma: 0.09036 },
  48: { lambda: -0.3521, mu: 2.948, sigma: 0.09007 },
  48.5: { lambda: -0.3521, mu: 3.0377, sigma: 0.08977 },
  49: { lambda: -0.3521, mu: 3.1308, sigma: 0.08948 },
  49.5: { lambda: -0.3521, mu: 3.2276, sigma: 0.08919 },
  50: { lambda: -0.3521, mu: 3.3278, sigma: 0.0889 },
  50.5: { lambda: -0.3521, mu: 3.4311, sigma: 0.08861 },
  51: { lambda: -0.3521, mu: 3.5376, sigma: 0.08831 },
  51.5: { lambda: -0.3521, mu: 3.6477, sigma: 0.08801 },
  52: { lambda: -0.3521, mu: 3.762, sigma: 0.08771 },
  52.5: { lambda: -0.3521, mu: 3.8814, sigma: 0.08741 },
  53: { lambda: -0.3521, mu: 4.006, sigma: 0.08711 },
  53.5: { lambda: -0.3521, mu: 4.1354, sigma: 0.08681 },
  54: { lambda: -0.3521, mu: 4.2693, sigma: 0.08651 },
  54.5: { lambda: -0.3521, mu: 4.4066, sigma: 0.08621 },
  55: { lambda: -0.3521, mu: 4.5467, sigma: 0.08592 },
  55.5: { lambda: -0.3521, mu: 4.6892, sigma: 0.08563 },
  56: { lambda: -0.3521, mu: 4.8338, sigma: 0.08535 },
  56.5: { lambda: -0.3521, mu: 4.9796, sigma: 0.08507 },
  57: { lambda: -0.3521, mu: 5.1259, sigma: 0.08481 },
  57.5: { lambda: -0.3521, mu: 5.2721, sigma: 0.08455 },
  58: { lambda: -0.3521, mu: 5.418, sigma: 0.0843 },
  58.5: { lambda: -0.3521, mu: 5.5632, sigma: 0.08406 },
  59: { lambda: -0.3521, mu: 5.7074, sigma: 0.08383 },
  59.5: { lambda: -0.3521, mu: 5.8501, sigma: 0.08362 },
  60: { lambda: -0.3521, mu: 5.9907, sigma: 0.08342 },
  60.5: { lambda: -0.3521, mu: 6.1284, sigma: 0.08324 },
  61: { lambda: -0.3521, mu: 6.2632, sigma: 0.08308 },
  61.5: { lambda: -0.3521, mu: 6.3954, sigma: 0.08292 },
  62: { lambda: -0.3521, mu: 6.5251, sigma: 0.08279 },
  62.5: { lambda: -0.3521, mu: 6.6527, sigma: 0.08266 },
  63: { lambda: -0.3521, mu: 6.7786, sigma: 0.08255 },
  63.5: { lambda: -0.3521, mu: 6.9028, sigma: 0.08245 },
  64: { lambda: -0.3521, mu: 7.0255, sigma: 0.08236 },
  64.5: { lambda: -0.3521, mu: 7.1467, sigma: 0.08229 },
  65: { lambda: -0.3521, mu: 7.2666, sigma: 0.08223 },
  65.5: { lambda: -0.3521, mu: 7.3854, sigma: 0.08218 },
  66: { lambda: -0.3521, mu: 7.5034, sigma: 0.08215 },
  66.5: { lambda: -0.3521, mu: 7.6206, sigma: 0.08213 },
  67: { lambda: -0.3521, mu: 7.737, sigma: 0.08212 },
  67.5: { lambda: -0.3521, mu: 7.8526, sigma: 0.08212 },
  68: { lambda: -0.3521, mu: 7.9674, sigma: 0.08214 },
  68.5: { lambda: -0.3521, mu: 8.0816, sigma: 0.08216 },
  69: { lambda: -0.3521, mu: 8.1955, sigma: 0.08219 },
  69.5: { lambda: -0.3521, mu: 8.3092, sigma: 0.08224 },
  70: { lambda: -0.3521, mu: 8.4227, sigma: 0.08229 },
  70.5: { lambda: -0.3521, mu: 8.5358, sigma: 0.08235 },
  71: { lambda: -0.3521, mu: 8.648, sigma: 0.08241 },
  71.5: { lambda: -0.3521, mu: 8.7594, sigma: 0.08248 },
  72: { lambda: -0.3521, mu: 8.8697, sigma: 0.08254 },
  72.5: { lambda: -0.3521, mu: 8.9788, sigma: 0.08262 },
  73: { lambda: -0.3521, mu: 9.0865, sigma: 0.08269 },
  73.5: { lambda: -0.3521, mu: 9.1927, sigma: 0.08276 },
  74: { lambda: -0.3521, mu: 9.2974, sigma: 0.08283 },
  74.5: { lambda: -0.3521, mu: 9.401, sigma: 0.08289 },
  75: { lambda: -0.3521, mu: 9.5032, sigma: 0.08295 },
  75.5: { lambda: -0.3521, mu: 9.6041, sigma: 0.08301 },
  76: { lambda: -0.3521, mu: 9.7033, sigma: 0.08307 },
  76.5: { lambda: -0.3521, mu: 9.8007, sigma: 0.08311 },
  77: { lambda: -0.3521, mu: 9.8963, sigma: 0.08314 },
  77.5: { lambda: -0.3521, mu: 9.9902, sigma: 0.08317 },
  78: { lambda: -0.3521, mu: 10.0827, sigma: 0.08318 },
  78.5: { lambda: -0.3521, mu: 10.1741, sigma: 0.08318 },
  79: { lambda: -0.3521, mu: 10.2649, sigma: 0.08316 },
  79.5: { lambda: -0.3521, mu: 10.3558, sigma: 0.08313 },
  80: { lambda: -0.3521, mu: 10.4475, sigma: 0.08308 },
  80.5: { lambda: -0.3521, mu: 10.5405, sigma: 0.08301 },
  81: { lambda: -0.3521, mu: 10.6352, sigma: 0.08293 },
  81.5: { lambda: -0.3521, mu: 10.7322, sigma: 0.08284 },
  82: { lambda: -0.3521, mu: 10.8321, sigma: 0.08273 },
  82.5: { lambda: -0.3521, mu: 10.935, sigma: 0.0826 },
  83: { lambda: -0.3521, mu: 11.0415, sigma: 0.08246 },
  83.5: { lambda: -0.3521, mu: 11.1516, sigma: 0.08231 },
  84: { lambda: -0.3521, mu: 11.2651, sigma: 0.08215 },
  84.5: { lambda: -0.3521, mu: 11.3817, sigma: 0.08198 },
  85: { lambda: -0.3521, mu: 11.5007, sigma: 0.08181 },
  85.5: { lambda: -0.3521, mu: 11.6218, sigma: 0.08163 },
  86: { lambda: -0.3521, mu: 11.7444, sigma: 0.08145 },
  86.5: { lambda: -0.3521, mu: 11.8678, sigma: 0.08128 },
  87: { lambda: -0.3521, mu: 11.9916, sigma: 0.08111 },
  87.5: { lambda: -0.3521, mu: 12.1152, sigma: 0.08096 },
  88: { lambda: -0.3521, mu: 12.2382, sigma: 0.08082 },
  88.5: { lambda: -0.3521, mu: 12.3603, sigma: 0.08069 },
  89: { lambda: -0.3521, mu: 12.4815, sigma: 0.08058 },
  89.5: { lambda: -0.3521, mu: 12.6017, sigma: 0.08048 },
  90: { lambda: -0.3521, mu: 12.7209, sigma: 0.08041 },
  90.5: { lambda: -0.3521, mu: 12.8392, sigma: 0.08034 },
  91: { lambda: -0.3521, mu: 12.9569, sigma: 0.0803 },
  91.5: { lambda: -0.3521, mu: 13.0742, sigma: 0.08026 },
  92: { lambda: -0.3521, mu: 13.191, sigma: 0.08025 },
  92.5: { lambda: -0.3521, mu: 13.3075, sigma: 0.08025 },
  93: { lambda: -0.3521, mu: 13.4239, sigma: 0.08026 },
  93.5: { lambda: -0.3521, mu: 13.5404, sigma: 0.08029 },
  94: { lambda: -0.3521, mu: 13.6572, sigma: 0.08034 },
  94.5: { lambda: -0.3521, mu: 13.7746, sigma: 0.0804 },
  95: { lambda: -0.3521, mu: 13.8928, sigma: 0.08047 },
  95.5: { lambda: -0.3521, mu: 14.012, sigma: 0.08056 },
  96: { lambda: -0.3521, mu: 14.1325, sigma: 0.08067 },
  96.5: { lambda: -0.3521, mu: 14.2544, sigma: 0.08078 },
  97: { lambda: -0.3521, mu: 14.3782, sigma: 0.08092 },
  97.5: { lambda: -0.3521, mu: 14.5038, sigma: 0.08106 },
  98: { lambda: -0.3521, mu: 14.6316, sigma: 0.08122 },
  98.5: { lambda: -0.3521, mu: 14.7614, sigma: 0.08139 },
  99: { lambda: -0.3521, mu: 14.8934, sigma: 0.08157 },
  99.5: { lambda: -0.3521, mu: 15.0275, sigma: 0.08177 },
  100: { lambda: -0.3521, mu: 15.1637, sigma: 0.08198 },
  100.5: { lambda: -0.3521, mu: 15.3018, sigma: 0.0822 },
  101: { lambda: -0.3521, mu: 15.4419, sigma: 0.08243 },
  101.5: { lambda: -0.3521, mu: 15.5838, sigma: 0.08267 },
  102: { lambda: -0.3521, mu: 15.7276, sigma: 0.08292 },
  102.5: { lambda: -0.3521, mu: 15.8732, sigma: 0.08317 },
  103: { lambda: -0.3521, mu: 16.0206, sigma: 0.08343 },
  103.5: { lambda: -0.3521, mu: 16.1697, sigma: 0.0837 },
  104: { lambda: -0.3521, mu: 16.3204, sigma: 0.08397 },
  104.5: { lambda: -0.3521, mu: 16.4728, sigma: 0.08425 },
  105: { lambda: -0.3521, mu: 16.6268, sigma: 0.08453 },
  105.5: { lambda: -0.3521, mu: 16.7826, sigma: 0.08481 },
  106: { lambda: -0.3521, mu: 16.9401, sigma: 0.0851 },
  106.5: { lambda: -0.3521, mu: 17.0995, sigma: 0.08539 },
  107: { lambda: -0.3521, mu: 17.2607, sigma: 0.08568 },
  107.5: { lambda: -0.3521, mu: 17.4237, sigma: 0.08599 },
  108: { lambda: -0.3521, mu: 17.5885, sigma: 0.08629 },
  108.5: { lambda: -0.3521, mu: 17.7553, sigma: 0.0866 },
  109: { lambda: -0.3521, mu: 17.9242, sigma: 0.08691 },
  109.5: { lambda: -0.3521, mu: 18.0954, sigma: 0.08723 },
  110: { lambda: -0.3521, mu: 18.2689, sigma: 0.08755 },
};

// https://www.cdc.gov/growthcharts/who/girls_weight_head_circumference.htm
const femaleWeightForLengthLMSParams: LMSParamsMap = {
  45: { lambda: -0.3833, mu: 2.4607, sigma: 0.09029 },
  45.5: { lambda: -0.3833, mu: 2.5457, sigma: 0.09033 },
  46: { lambda: -0.3833, mu: 2.6306, sigma: 0.09037 },
  46.5: { lambda: -0.3833, mu: 2.7155, sigma: 0.0904 },
  47: { lambda: -0.3833, mu: 2.8007, sigma: 0.09044 },
  47.5: { lambda: -0.3833, mu: 2.8867, sigma: 0.09048 },
  48: { lambda: -0.3833, mu: 2.9741, sigma: 0.09052 },
  48.5: { lambda: -0.3833, mu: 3.0636, sigma: 0.09056 },
  49: { lambda: -0.3833, mu: 3.156, sigma: 0.0906 },
  49.5: { lambda: -0.3833, mu: 3.252, sigma: 0.09064 },
  50: { lambda: -0.3833, mu: 3.3518, sigma: 0.09068 },
  50.5: { lambda: -0.3833, mu: 3.4557, sigma: 0.09072 },
  51: { lambda: -0.3833, mu: 3.5636, sigma: 0.09076 },
  51.5: { lambda: -0.3833, mu: 3.6754, sigma: 0.0908 },
  52: { lambda: -0.3833, mu: 3.7911, sigma: 0.09085 },
  52.5: { lambda: -0.3833, mu: 3.9105, sigma: 0.09089 },
  53: { lambda: -0.3833, mu: 4.0332, sigma: 0.09093 },
  53.5: { lambda: -0.3833, mu: 4.1591, sigma: 0.09098 },
  54: { lambda: -0.3833, mu: 4.2875, sigma: 0.09102 },
  54.5: { lambda: -0.3833, mu: 4.4179, sigma: 0.09106 },
  55: { lambda: -0.3833, mu: 4.5498, sigma: 0.0911 },
  55.5: { lambda: -0.3833, mu: 4.6827, sigma: 0.09114 },
  56: { lambda: -0.3833, mu: 4.8162, sigma: 0.09118 },
  56.5: { lambda: -0.3833, mu: 4.95, sigma: 0.09121 },
  57: { lambda: -0.3833, mu: 5.0837, sigma: 0.09125 },
  57.5: { lambda: -0.3833, mu: 5.2173, sigma: 0.09128 },
  58: { lambda: -0.3833, mu: 5.3507, sigma: 0.0913 },
  58.5: { lambda: -0.3833, mu: 5.4834, sigma: 0.09132 },
  59: { lambda: -0.3833, mu: 5.6151, sigma: 0.09134 },
  59.5: { lambda: -0.3833, mu: 5.7454, sigma: 0.09135 },
  60: { lambda: -0.3833, mu: 5.8742, sigma: 0.09136 },
  60.5: { lambda: -0.3833, mu: 6.0014, sigma: 0.09137 },
  61: { lambda: -0.3833, mu: 6.127, sigma: 0.09137 },
  61.5: { lambda: -0.3833, mu: 6.2511, sigma: 0.09136 },
  62: { lambda: -0.3833, mu: 6.3738, sigma: 0.09135 },
  62.5: { lambda: -0.3833, mu: 6.4948, sigma: 0.09133 },
  63: { lambda: -0.3833, mu: 6.6144, sigma: 0.09131 },
  63.5: { lambda: -0.3833, mu: 6.7328, sigma: 0.09129 },
  64: { lambda: -0.3833, mu: 6.8501, sigma: 0.09126 },
  64.5: { lambda: -0.3833, mu: 6.9662, sigma: 0.09123 },
  65: { lambda: -0.3833, mu: 7.0812, sigma: 0.09119 },
  65.5: { lambda: -0.3833, mu: 7.195, sigma: 0.09115 },
  66: { lambda: -0.3833, mu: 7.3076, sigma: 0.0911 },
  66.5: { lambda: -0.3833, mu: 7.4189, sigma: 0.09106 },
  67: { lambda: -0.3833, mu: 7.5288, sigma: 0.09101 },
  67.5: { lambda: -0.3833, mu: 7.6375, sigma: 0.09096 },
  68: { lambda: -0.3833, mu: 7.7448, sigma: 0.0909 },
  68.5: { lambda: -0.3833, mu: 7.8509, sigma: 0.09085 },
  69: { lambda: -0.3833, mu: 7.9559, sigma: 0.09079 },
  69.5: { lambda: -0.3833, mu: 8.0599, sigma: 0.09074 },
  70: { lambda: -0.3833, mu: 8.163, sigma: 0.09068 },
  70.5: { lambda: -0.3833, mu: 8.2651, sigma: 0.09062 },
  71: { lambda: -0.3833, mu: 8.3666, sigma: 0.09056 },
  71.5: { lambda: -0.3833, mu: 8.4676, sigma: 0.0905 },
  72: { lambda: -0.3833, mu: 8.5679, sigma: 0.09043 },
  72.5: { lambda: -0.3833, mu: 8.6674, sigma: 0.09037 },
  73: { lambda: -0.3833, mu: 8.7661, sigma: 0.09031 },
  73.5: { lambda: -0.3833, mu: 8.8638, sigma: 0.09025 },
  74: { lambda: -0.3833, mu: 8.9601, sigma: 0.09018 },
  74.5: { lambda: -0.3833, mu: 9.0552, sigma: 0.09012 },
  75: { lambda: -0.3833, mu: 9.149, sigma: 0.09005 },
  75.5: { lambda: -0.3833, mu: 9.2418, sigma: 0.08999 },
  76: { lambda: -0.3833, mu: 9.3337, sigma: 0.08992 },
  76.5: { lambda: -0.3833, mu: 9.4252, sigma: 0.08985 },
  77: { lambda: -0.3833, mu: 9.5166, sigma: 0.08979 },
  77.5: { lambda: -0.3833, mu: 9.6086, sigma: 0.08972 },
  78: { lambda: -0.3833, mu: 9.7015, sigma: 0.08965 },
  78.5: { lambda: -0.3833, mu: 9.7957, sigma: 0.08959 },
  79: { lambda: -0.3833, mu: 9.8915, sigma: 0.08952 },
  79.5: { lambda: -0.3833, mu: 9.9892, sigma: 0.08946 },
  80: { lambda: -0.3833, mu: 10.0891, sigma: 0.0894 },
  80.5: { lambda: -0.3833, mu: 10.1916, sigma: 0.08934 },
  81: { lambda: -0.3833, mu: 10.2965, sigma: 0.08928 },
  81.5: { lambda: -0.3833, mu: 10.4041, sigma: 0.08923 },
  82: { lambda: -0.3833, mu: 10.514, sigma: 0.08918 },
  82.5: { lambda: -0.3833, mu: 10.6263, sigma: 0.08914 },
  83: { lambda: -0.3833, mu: 10.741, sigma: 0.0891 },
  83.5: { lambda: -0.3833, mu: 10.8578, sigma: 0.08906 },
  84: { lambda: -0.3833, mu: 10.9767, sigma: 0.08903 },
  84.5: { lambda: -0.3833, mu: 11.0974, sigma: 0.089 },
  85: { lambda: -0.3833, mu: 11.2198, sigma: 0.08898 },
  85.5: { lambda: -0.3833, mu: 11.3435, sigma: 0.08897 },
  86: { lambda: -0.3833, mu: 11.4684, sigma: 0.08895 },
  86.5: { lambda: -0.3833, mu: 11.594, sigma: 0.08895 },
  87: { lambda: -0.3833, mu: 11.7201, sigma: 0.08895 },
  87.5: { lambda: -0.3833, mu: 11.8461, sigma: 0.08895 },
  88: { lambda: -0.3833, mu: 11.972, sigma: 0.08896 },
  88.5: { lambda: -0.3833, mu: 12.0976, sigma: 0.08898 },
  89: { lambda: -0.3833, mu: 12.2229, sigma: 0.089 },
  89.5: { lambda: -0.3833, mu: 12.3477, sigma: 0.08903 },
  90: { lambda: -0.3833, mu: 12.4723, sigma: 0.08906 },
  90.5: { lambda: -0.3833, mu: 12.5965, sigma: 0.08909 },
  91: { lambda: -0.3833, mu: 12.7205, sigma: 0.08913 },
  91.5: { lambda: -0.3833, mu: 12.8443, sigma: 0.08918 },
  92: { lambda: -0.3833, mu: 12.9681, sigma: 0.08923 },
  92.5: { lambda: -0.3833, mu: 13.092, sigma: 0.08928 },
  93: { lambda: -0.3833, mu: 13.2158, sigma: 0.08934 },
  93.5: { lambda: -0.3833, mu: 13.3399, sigma: 0.08941 },
  94: { lambda: -0.3833, mu: 13.4643, sigma: 0.08948 },
  94.5: { lambda: -0.3833, mu: 13.5892, sigma: 0.08955 },
  95: { lambda: -0.3833, mu: 13.7146, sigma: 0.08963 },
  95.5: { lambda: -0.3833, mu: 13.8408, sigma: 0.08972 },
  96: { lambda: -0.3833, mu: 13.9676, sigma: 0.08981 },
  96.5: { lambda: -0.3833, mu: 14.0953, sigma: 0.0899 },
  97: { lambda: -0.3833, mu: 14.2239, sigma: 0.09 },
  97.5: { lambda: -0.3833, mu: 14.3537, sigma: 0.0901 },
  98: { lambda: -0.3833, mu: 14.4848, sigma: 0.09021 },
  98.5: { lambda: -0.3833, mu: 14.6174, sigma: 0.09033 },
  99: { lambda: -0.3833, mu: 14.7519, sigma: 0.09044 },
  99.5: { lambda: -0.3833, mu: 14.8882, sigma: 0.09057 },
  100: { lambda: -0.3833, mu: 15.0267, sigma: 0.09069 },
  100.5: { lambda: -0.3833, mu: 15.1676, sigma: 0.09083 },
  101: { lambda: -0.3833, mu: 15.3108, sigma: 0.09096 },
  101.5: { lambda: -0.3833, mu: 15.4564, sigma: 0.0911 },
  102: { lambda: -0.3833, mu: 15.6046, sigma: 0.09125 },
  102.5: { lambda: -0.3833, mu: 15.7553, sigma: 0.09139 },
  103: { lambda: -0.3833, mu: 15.9087, sigma: 0.09155 },
  103.5: { lambda: -0.3833, mu: 16.0645, sigma: 0.0917 },
  104: { lambda: -0.3833, mu: 16.2229, sigma: 0.09186 },
  104.5: { lambda: -0.3833, mu: 16.3837, sigma: 0.09203 },
  105: { lambda: -0.3833, mu: 16.547, sigma: 0.09219 },
  105.5: { lambda: -0.3833, mu: 16.7129, sigma: 0.09236 },
  106: { lambda: -0.3833, mu: 16.8814, sigma: 0.09254 },
  106.5: { lambda: -0.3833, mu: 17.0527, sigma: 0.09271 },
  107: { lambda: -0.3833, mu: 17.2269, sigma: 0.09289 },
  107.5: { lambda: -0.3833, mu: 17.4039, sigma: 0.09307 },
  108: { lambda: -0.3833, mu: 17.5839, sigma: 0.09326 },
  108.5: { lambda: -0.3833, mu: 17.7668, sigma: 0.09344 },
  109: { lambda: -0.3833, mu: 17.9526, sigma: 0.09363 },
  109.5: { lambda: -0.3833, mu: 18.1412, sigma: 0.09382 },
  110: { lambda: -0.3833, mu: 18.3324, sigma: 0.09401 },
};

export const getInfWeightForLengthPercentile = (
  gender: string,
  length: number, // in inches
  weight: number, // in KG
) => {
  const lengthInCm = length * CM_PER_INCH;
  const weightForLengthLMSDataTable =
    gender === 'Female'
      ? femaleWeightForLengthLMSParams
      : maleWeightForLengthLMSParams;

  return zScoreFromMeasurement(
    weight,
    lengthInCm,
    weightForLengthLMSDataTable,
    'half',
  );
};
