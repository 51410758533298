import { Component, Input, OnInit } from '@angular/core';

import { AutoReportableCheck } from '../../shared/lab-data.type';

@Component({
  selector: 'omg-lab-autoreportability',
  templateUrl: './lab-autoreportability.component.html',
  styleUrls: ['./lab-autoreportability.component.scss'],
})
export class LabAutoreportabilityComponent implements OnInit {
  @Input() autoReportableChecks: AutoReportableCheck[];

  numFailedChecks: number;

  ngOnInit() {
    this.numFailedChecks = this.autoReportableChecks.filter(
      check => check.evaluation === false,
    ).length;
  }
}
