import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { ActiveRoutesState } from './active-routes.reducer';

const getState = state => <ActiveRoutesState>state.activeRoutes;

export const selectActiveOrders = createSelector(
  getState,
  state => state.orders,
);

export const selectActiveWorkspace = createSelector(
  getState,
  state => state.workspace,
);

/* istanbul ignore next */
@Injectable()
export class ActiveRoutesSelectors {
  constructor(private store: Store<ActiveRoutesState>) {}

  get activeOrders() {
    return this.store.pipe(select(selectActiveOrders));
  }

  get activeWorkspace() {
    return this.store.pipe(select(selectActiveWorkspace));
  }

  get activeItemId() {
    return merge(
      this.activeOrders.pipe(skipWhile(val => !val)),
      this.activeWorkspace.pipe(skipWhile(val => !val)),
    ).pipe(
      map(url => {
        const activeItemId =
          url && (+url.path.split('/')[1] || +url.path.split('/')[2]);
        return activeItemId || null;
      }),
    );
  }
}
