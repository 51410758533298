// @ts-strict-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiConfigOptions } from '@app/core/api/api.type';
import { camelCase, snakeCase } from '@app/utils';

const baseUrl = environment.api.baseUrl;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    endpoint: string,
    params?: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ): Observable<T> {
    const response$ = this.http.get<T>(`${baseUrl}${endpoint}`, {
      ...config,
      params,
    });
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  save<T>(
    endpoint: string,
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const response$ = this.http.post<T>(`${baseUrl}${endpoint}`, data, config);
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  update<T>(
    endpoint: string,
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const response$ = this.http.put<T>(`${baseUrl}${endpoint}`, data, config);
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  delete<T>(endpoint: string, config?: ApiConfigOptions) {
    return this.http.delete<T>(`${baseUrl}${endpoint}`, config);
  }

  private camelize<T>(response$: Observable<any>) {
    return response$.pipe(map(response => <T>camelCase(response)));
  }
}
