// @ts-strict-ignore
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  map,
  mergeMap,
  shareReplay,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { TimelineActions } from '@app/features/timeline/store/timeline.actions';
import { MessagingService } from '@app/modules/messaging/shared/messaging.service';
import { Post } from '@app/modules/messaging/shared/messaging.type';
import { NoteApiService } from '@app/modules/note/shared/note-api.service';
import { Note } from '@app/modules/note/shared/note.type';
import { filterTruthy, waitFor } from '@app/utils';

import { NoteTypesService } from '../../shared/note-types.service';
import { NoteType, NoteTypes } from '../../shared/note-types.type';
import {
  lettersPath,
  manualResultEntryPath,
  messagingPath,
  notesPath,
  summariesPath,
} from '../../shared/workspace-utils';

@Component({
  selector: 'omg-new-workspace-item',
  templateUrl: './new-workspace-item.component.html',
  styleUrls: ['./new-workspace-item.component.scss'],
})
export class NewWorkspaceItemComponent implements OnInit, OnDestroy {
  noteTypes: Observable<NoteTypes>;
  patient: Patient;
  patientAcceptsDigitalCommunications: Observable<boolean>;
  patientWarnings: Observable<string>;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private patientSelectors: PatientSelectors,
    private noteTypesService: NoteTypesService,
    private summariesActions: SummariesActions,
    private summariesSelectors: SummariesSelectors,
    private messagingService: MessagingService,
    private noteService: NoteApiService,
    private timelineActions: TimelineActions,
  ) {}

  ngOnInit() {
    this.setupObservables();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  newSummary(type: NoteType) {
    this.summariesActions.saveSummary({
      patientId: this.patient.id,
      noteTypeId: type.id,
    });

    waitFor(this.summariesSelectors.loading, loading => !loading)
      .pipe(withLatestFrom(this.summariesSelectors.currentSummary))
      .subscribe(([_, currentSummary]) => {
        this.router.navigateByUrl(
          summariesPath(this.patient.id, currentSummary.id, 'edit'),
        );
        this.trackNewSummary(currentSummary);
        this.refreshTimeline();
      });
  }

  newMessage() {
    this.messagingService
      .createPost()
      .pipe(take(1))
      .subscribe(post => {
        this.router.navigateByUrl(
          messagingPath(this.patient.id, post.id, 'edit'),
        );
        this.trackNewPatientTimelinePost(post);
        this.refreshTimeline();
      });
  }

  newManualResultEntry() {
    this.router.navigateByUrl(manualResultEntryPath(this.patient.id));
  }

  newMiscTask(type: NoteType) {
    // Create new MiscTask and route to workspace.notes with new id
    this.noteService
      .save(this.patient.id, type.id)
      .pipe(take(1))
      .subscribe(note => {
        this.router.navigateByUrl(notesPath(this.patient.id, note.id, 'edit'));
        this.refreshTimeline();
        this.trackNewMiscTask(note);
      });
  }

  newLetter(type: NoteType) {
    this.noteService
      .save(this.patient.id, type.id)
      .pipe(take(1))
      .subscribe(letter => {
        this.router.navigateByUrl(lettersPath(this.patient.id, letter.id));
        this.refreshTimeline();
        this.trackNewLetter(letter);
      });
  }

  private setupObservables() {
    this.noteTypes = this.patientSelectors.patient.pipe(
      mergeMap(patient => this.noteTypesService.getAll(patient.id)),
      shareReplay(1),
      takeUntil(this.unsubscribe),
    );

    this.patientAcceptsDigitalCommunications = this.patientSelectors.acceptsDigitalCommunications.pipe(
      filterTruthy(),
      takeUntil(this.unsubscribe),
    );

    this.patientWarnings = this.patientSelectors.patientWarnings.pipe(
      filterTruthy(),
      map(warnings => Object.values(warnings).join(' ')),
      takeUntil(this.unsubscribe),
    );

    this.patientSelectors.patient
      .pipe(filterTruthy(), takeUntil(this.unsubscribe))
      .subscribe(patient => (this.patient = patient));
  }

  private refreshTimeline() {
    this.timelineActions.refreshTimeline();
  }

  private trackNewSummary(summary: Summary) {
    this.analytics.track(AnalyticsEvent.SummaryAdded, {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: summary.noteType.name,
      summaryId: summary.id,
    });
  }

  private trackNewMiscTask(note: Note) {
    this.analytics.track(AnalyticsEvent.MiscNoteCreated, {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: note.noteType.name,
      noteId: note.id,
    });
  }

  private trackNewPatientTimelinePost(patientTimelinePost: Post) {
    this.analytics.track(AnalyticsEvent.PatientTimelinePostCreated, {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: 'Patient Timeline Post',
      summaryId: patientTimelinePost.id,
    });
  }

  private trackNewLetter(letter) {
    this.analytics.track(AnalyticsEvent.LetterCreated, {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: letter.noteType.name,
      letterId: letter.id,
    });
  }
}
