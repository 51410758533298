<ng-container>
  <omg-section-title>
    Patient Information
  </omg-section-title>

  <div class="padding-normal"
       om-layout="vertical"
       om-layout-gutter>
    <div *ngFor="let vital of mostRecentVitals$ | ngrxPush">
      <strong class="capitalize">{{ vital.label }}:</strong>

      <span *ngIf="vital.value; else warnNoneRecorded">
        {{ vital.value }} on {{ vital.collectedAt | omgDate: '4y' }}
        <i *ngIf="vital.isExpired"
           class="om-icon icon-alert-warning -small -black"></i>
      </span>

      <ng-template #warnNoneRecorded>
        <strong class="alert"> None recorded </strong>
        <i class="om-icon icon-alert-critical -critical"></i>
      </ng-template>
    </div>

    <div *ngIf="warnings$ | ngrxPush as warnings"
         [ngClass]="warnings.isError ? '-error' : '-warning'"
         class="banner padding-half"
         om-layout>
      <span class="padding-half">
        <i [ngClass]="warnings.isError ? 'icon-alert-critical' : 'icon-alert-warning'"
           class="om-icon -black">
        </i>
      </span>
      <span class="padding-half-horizontal">
        <span *ngIf="warnings.isError; else warningMessage"
              om-layout="vertical">
          <strong>
            Missing Measurements - Checkout Disabled
          </strong>
          <span>
            Patients
            {{ warnings.expiryRequirements.ageRange }}
            need to have weight and height recorded within the last
            {{ warnings.expiryRequirements.expiryPeriod }}
            to checkout this prescription.
          </span>
        </span>

        <ng-template #warningMessage>
          Measurements should be recorded within the last
          {{ warnings.expiryRequirements.expiryPeriod }}
          for patients
          {{ warnings.expiryRequirements.ageRange }}.
          If available, please add a recent
          {{ warnings.expiredVitals }}.
        </ng-template>
      </span>
    </div>
  </div>
</ng-container>
