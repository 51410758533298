<ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="!loading && !empty">
    <ng-template [ngTemplateOutlet]="loadedRef"></ng-template>
  </ng-container>
  <ng-container *ngIf="loading">
    <div>
      <ng-template [ngTemplateOutlet]="loadingRef"></ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && empty">
    <div class="empty-message">
      <ng-template [ngTemplateOutlet]="emptyRef"></ng-template>
    </div>
  </ng-container>
</ng-container>
