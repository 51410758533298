<label om-layout
       om-layout-gutter
       [tooltipText]="tooltipText"
       tooltipPosition="top"
       omgTooltip>
  <div om-layout
       om-layout-gutter
       om-layout-align="center center">
    <span>Total Fills</span>
    <i class="om-icon icon-alert-info"></i>
  </div>
  <input omgInputNumber
         omgTrimTrailingZeros
         [formControl]="fills"
         type="number"
         class="-small"
         data-cy="rx-renewal-max-total-fills" />
</label>
