<form [formGroup]="form.controls">
  <div *ngFor="let option of form.controlOptions; trackBy: trackByName">
    <div *ngIf="form.controls.get(option.name)">
      <div *ngIf="option.handlers.searchFn">
        <omg-auto-complete [placeholder]="option.autoCompleteConfig.placeholder"
                           om-layout-fill
                           omgStopEvent="click"
                           #control
                           [omgFocusOn]="option.name"
                           (focusOnRequest)="control.focus()"
                           [formControlName]="option.name"
                           [bindLabel]="option.autoCompleteConfig.bindLabel"
                           [bindValue]="option.autoCompleteConfig.bindValue"
                           [trackByKey]="option.autoCompleteConfig.trackByKey"
                           [items]="option.items$ | ngrxPush"
                           [searchFn]=" option.handlers.searchFn"
                           (search)=" option.handlers.search($event)"
                           (changeAutocomplete)="option.handlers.onChange($event)"
                           (blurAutocomplete)="option.handlers.blur($event)"
                           [hideDropdownArrow]="true"
                           [hideClearAll]="true"
                           (selectFocus)="focus()"
                           (focusout)="focusout()">
          <ng-template let-optionData="optionData">{{ optionData['label'] }}</ng-template>
          <ng-template #noResultsTemplate
                       let-searchTerm="searchTerm">
            <div *ngIf="searchTerm && searchTerm.length > 0">
              <div class="ng-option disabled">No matches found for "{{ searchTerm }}"</div>
              <div class="ng-option"
                   *ngIf='form.searchableHandlers.addCustomItem && option.addCustomItemLabel'
                   (click)="form.searchableHandlers.addCustomItem()">+ Custom {{option.addCustomItemLabel}}</div>
            </div>
          </ng-template>
        </omg-auto-complete>
      </div>
      <div *ngIf="!option.handlers.searchFn">
        <omg-auto-complete [placeholder]="option.autoCompleteConfig.placeholder"
                           om-layout-fill
                           omgStopEvent="click"
                           #secondary
                           [omgFocusOn]="option.name"
                           (focusOnRequest)="secondary.focus()"
                           [formControlName]="option.name"
                           [bindLabel]="option.autoCompleteConfig.bindLabel"
                           [bindValue]="option.autoCompleteConfig.bindValue"
                           [trackByKey]="option.autoCompleteConfig.trackByKey"
                           [items]="option.items$ | ngrxPush"
                           (changeAutocomplete)="option.handlers.onChange($event)"
                           [hideDropdownArrow]="true"
                           [hideClearAll]="true">
        </omg-auto-complete>
      </div>
    </div>
  </div>
</form>
