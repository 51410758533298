import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[omgSwapIcons]',
})
export class SwapIconsDirective implements OnInit {
  @Input() originalIcon: string;
  @Input() iconToSwap: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.addClass(this.el.nativeElement, this.originalIcon);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.swapIcon(this.originalIcon, this.iconToSwap);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.swapIcon(this.iconToSwap, this.originalIcon);
  }

  private swapIcon(oldIcon: string, newIcon: string) {
    this.removeClass(this.el.nativeElement, oldIcon);
    this.addClass(this.el.nativeElement, newIcon);
  }

  private addClass(el: ElementRef, classes: string) {
    classes
      .split(' ')
      .forEach(singleClass => this.renderer.addClass(el, singleClass));
  }

  private removeClass(el: ElementRef, classes: string) {
    classes
      .split(' ')
      .forEach(singleClass => this.renderer.removeClass(el, singleClass));
  }
}
