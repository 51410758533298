import { Component, Input } from '@angular/core';

import { SummaryProviderRecommendation } from '../../shared/summaries.type';

@Component({
  selector: 'omg-signed-after-visit-guidance',
  templateUrl: './signed-after-visit-guidance.component.html',
  styleUrls: ['./signed-after-visit-guidance.component.scss'],
})
export class SignedAfterVisitGuidanceComponent {
  @Input() summaryProviderRecommendation: SummaryProviderRecommendation;

  constructor() {}
}
