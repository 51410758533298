import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IsMiscNoteTypeService } from './is-misc-note-type.service';
import { NoteIconComponent } from './note-icon.component';
import { SwapIconsDirective } from './swap-icons.directive';

const declarations = [NoteIconComponent, SwapIconsDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...declarations],
  exports: [...declarations],
  providers: [IsMiscNoteTypeService],
})
export class NoteIconModule {}
