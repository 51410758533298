import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PatientState } from '../patient/store/patient.reducer';
import {
  CloseSummary,
  LoadSummary,
  UpdateSectionsLinkable,
} from './store/encounter.actions';
import { selectSectionsLinkable } from './store/encounter.selectors';

@Injectable()
export class EncounterService {
  sectionsLinkable$: Observable<boolean> = this.store.pipe(
    select(selectSectionsLinkable),
  );

  updateSectionsLinkable(isSectionsLinkable: boolean) {
    this.store.dispatch(new UpdateSectionsLinkable(isSectionsLinkable));
  }

  loadSummary(summary: any) {
    this.store.dispatch(new LoadSummary(summary));
  }

  closeSummary(summary: boolean) {
    this.store.dispatch(new CloseSummary(summary));
  }

  constructor(private store: Store<PatientState>) {}
}
