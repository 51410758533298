// @ts-strict-ignore
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

import { ApiService } from '@app/core/api';
import { GraphQLService } from '@app/core/graphql/graphql.service';

import { ErrorInterceptorService } from './error-interceptor.service';
import { UnauthorizedInterceptorService } from './unauthorized-interceptor.service';

/*
  Order of interceptors matters here.
  Putting ErrorInterceptor as the last
  make it get invoked before the unauthorized interceptor.
*/
export const apiProviders: Provider[] = [
  ApiService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true,
  },
  GraphQLService,
];
