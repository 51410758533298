// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import {
  GetPrescriberCredentialsPayload,
  PrescriberCredentials,
  PrescriberCredentialsErrorPayload,
} from '@app/modules/shared-rx/prescriber-credential.type';

import { PrescriberCredentialsState } from './prescriber-credentials.reducer';

export enum PrescriberCredentialsActionTypes {
  GetPrescriberCredentials = '[RxCart] Get Prescriber Credentials',
  GetPrescriberCredentialsError = '[RxCart] Get Prescriber Credentials Error',
  GetPrescriberCrentialsSuccess = '[RxCart] Get Prescriber Credentials Success',
}

export class GetPrescriberCredentials
  implements FsaAction<GetPrescriberCredentialsPayload> {
  readonly type = PrescriberCredentialsActionTypes.GetPrescriberCredentials;

  constructor(public payload: GetPrescriberCredentialsPayload) {}
}

export class GetPrescriberCredentialsSuccess
  implements FsaAction<PrescriberCredentials> {
  readonly type =
    PrescriberCredentialsActionTypes.GetPrescriberCrentialsSuccess;
  error = false;

  constructor(public payload: PrescriberCredentials) {}
}

export class GetPrescriberCredentialsError
  implements FsaAction<PrescriberCredentialsErrorPayload> {
  readonly type =
    PrescriberCredentialsActionTypes.GetPrescriberCredentialsError;
  error = true;

  constructor(public payload: PrescriberCredentialsErrorPayload) {}
}

export type PrescriberCredentialsAction =
  | GetPrescriberCredentials
  | GetPrescriberCredentialsSuccess
  | GetPrescriberCredentialsError;

@Injectable({
  providedIn: 'root',
})
export class PrescriberCredentialsActions {
  constructor(private store: Store<PrescriberCredentialsState>) {}

  getPrescriberCredentials(payload: GetPrescriberCredentialsPayload) {
    this.store.dispatch(new GetPrescriberCredentials(payload));
  }
}
