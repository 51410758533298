// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';

import { getHttpStatusCodeText, isError, isHttpError } from '@app/utils';

export const getHttpErrorMessage = (error: HttpErrorResponse) => {
  let message = '';

  if (error.error && typeof error.error === 'string') {
    message = error.error;
  } else if (
    error.error &&
    typeof error.error === 'object' &&
    error.error.message
  ) {
    message = error.error.message;
  } else if (error.message) {
    message = error.message;
  } else if (error.statusText) {
    message = error.statusText;
  } else if (error.status === 0) {
    message = `CORS error with url`;
  } else {
    message = getHttpStatusCodeText(error.status);
  }

  return message;
};

export const getErrorMessage = (err: any) => {
  if (isHttpError(err)) {
    return getHttpErrorMessage(err);
  } else if (isError(err)) {
    return `${err.name}: ${err.message}`;
  } else if (err) {
    return `${err}`;
  } else {
    return null;
  }
};

const scrubPattern = new RegExp('^/patients/(\\d+)/chart(.*)?', 'i');
const obfuscated = '*****';

function scrubReplacer(match: string, patientId: string, trailing = '') {
  return `/patients/${obfuscated}/chart${trailing}`;
}

export function scrubURL(url: URL): string {
  const path = url.pathname.replace(scrubPattern, scrubReplacer);
  const scrubbed = `${url.origin}${path}${url.search || ''}`;
  return scrubbed;
}
