<div om-layout
     om-layout-gutter>
  <omg-signed-on-behalf-of om-flex
                           om-layout="vertical"
                           [signedOnBehalfOfId]="prescriberId"
                           [validPrescribers]="validPrescribers"
                           data-cy="rx-item-sign-on-behalf-of">
  </omg-signed-on-behalf-of>
  <omg-dea-license-number om-flex
                          om-layout="vertical"
                          [credentialDropdownItems$]="credentialItems$"
                          [loading$]="loading$"
                          [validPrescriber]="prescriber"
                          [selectedPrescribingCredentialId]="prescribingCredentialId"
                          data-cy="rx-item-prescribing-credential-dropdown">
  </omg-dea-license-number>
</div>
