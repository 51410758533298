// @ts-strict-ignore
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import { flow, get, join } from '@app/utils';

export type ApiErrors = any;
export type ApiError = any | any[];

export const registerFormControls = (
  form: UntypedFormGroup,
  controls: { [k: string]: UntypedFormControl } = {},
) => {
  for (const key in controls) {
    if (key) {
      const control = controls[key];
      if (control && !form.controls[key]) {
        form.registerControl(key, control);
      }
    }
  }
};

export const setFormErrors = (
  form: UntypedFormGroup,
  errors: ApiErrors,
  errorKey = 'apiError',
) => {
  const mapApiError = (error: ApiError) =>
    Array.isArray(error) ? error.join(' ') : error;

  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key];
      const control = form.controls[key];
      if (error && control) {
        control.setErrors({ [errorKey]: mapApiError(error) });
      }
    }
  }
};

export const updateFormArray = <T>(
  array: UntypedFormArray,
  items: T[],
  comparisonField: string,
  itemMapper: (i: T) => AbstractControl,
) => {
  if (!array) {
    return;
  }

  if (array.controls.length === 0) {
    items.map(item => array.push(itemMapper(item)));
  }

  const inFormArray = array.controls.map(
    ctrl => ctrl.get(comparisonField).value,
  );
  const inItems = items.map(ctrl => ctrl[comparisonField]);

  const toRemove = [inFormArray, inItems].reduce((a, b) =>
    a.filter(c => !b.includes(c)),
  );
  const toAdd = [inItems, inFormArray].reduce((a, b) =>
    a.filter(c => !b.includes(c)),
  );

  toRemove.forEach(value =>
    array.removeAt(
      array.value.findIndex(item => item[comparisonField] === value),
    ),
  );
  toAdd.forEach(value =>
    array.push(itemMapper(items.find(item => item[comparisonField] === value))),
  );
};

export const mapApiResponseError = (error: any): any =>
  flow(get('data.errors'), join(' '))(error);
