<omg-section-title>
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Medications</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgStopEvent
          omgTooltip
          tooltipText="Delete from Note"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li *ngIf="noMedications">None</li>
  <li class="om-animate"
      *ngFor="let medication of medications; trackBy: trackByFn">
    <strong>{{ medication.name }}</strong>, {{ medication.regimen }}
  </li>
</ul>
