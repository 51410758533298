<omg-expanded [collapseProvider]="collapseRef"
              [formGroup]="formGroup">
  <div class="action-bar -with-scrollable-sibling"
       om-layout
       om-layout-align="space-between center">
    <div om-flex>
      <omg-auto-complete placeholder="Select a template"
                         [items]="templateItems | ngrxPush"
                         (search)="searchForTemplate($event)"
                         (changeAutocomplete)="onSetTemplate($event)"
                         [searchFn]="templateSearchFn"
                         omgFocusOn="templateInsertion-search"
                         #templateSearch
                         (focusOnRequest)="templateSearch.focus()"
                         formControlName="messageTemplate"
                         omgStopEvent
                         trackByKey="id"
                         bindLabel="name"
                         bindValue="id"
                         dropdownPosition="top">
      </omg-auto-complete>
    </div>
    <span om-layout
          om-layout-gutter>
      <button omgButton
              variant="flat"
              (click)="onCancel()">
        Never mind
      </button>
      <button omgButton
              variant="primary"
              [omgFocusOn]="templateInsertionFocusOnKey"
              (click)="onApplyTemplate()">
        Apply Template
      </button>
    </span>
  </div>
</omg-expanded>
