import { createFeatureSelector } from '@ngrx/store';

import {
  MedicationsRootState,
  medicationsRootStatePath,
} from './medications-store.reducer';

export const selectMedicationsRootState = createFeatureSelector<
  MedicationsRootState
>(medicationsRootStatePath);
