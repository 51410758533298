<ng-container>
  <p-skeleton [shape]="shape"
              [size]="size"
              [height]="height"
              [width]="width"
              [borderRadius]="borderRadius"
              [styleClass]="styleClass"
              [style]="style">
  </p-skeleton>
</ng-container>
