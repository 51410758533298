<div class="data-table">
  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Specimen Number:</b> {{labData.specimenId}}</span>
    <span>
      <b>Patient ID:</b> {{labData.patient.id}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span>
      <b>Patient Last Name:</b> {{labData.patient.lastName}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Patient First Name:</b> {{labData.patient.firstName}}</span>
    <span>
      <b>Patient Middle Name:</b> {{labData.patient.middleName}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Date of Birth:</b> {{labData.patient.dateOfBirth}}</span>
    <span>
      <b>Sex:</b> {{labData.patient.sex}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Patient SS#:</b> {{labData.patient.ssn}}</span>
    <span>
      <b>Patient Phone:</b> {{labData.patient.phoneNumber}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <b om-flex="45">Patient Address:</b>
    <div>
      <div>{{labData.patient.streetAddress}}</div>
      <div>{{labData.patient.city}}</div>
    </div>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Control Number:</b> {{labData.controlId}}</span>
    <span>
      <b>Account Number:</b> {{labData.clientId}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span>
      <b>Account Address:</b>
    </span>
    <span>
      <b>One Medical Group</b>
      <div>{{labData.originatingOffice.address1}}</div>
      <div>{{labData.originatingOffice.address2}}</div>
      <div>{{labData.originatingOffice.addressCsz}}</div>
    </span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Date Collected:</b> {{labData.observedAt | omgDate: 'withTime2yTz'}}</span>
    <span>
      <b>Date Entered:</b> {{labData.specimenReceivedAt | omgDate: 'withTime2yTz'}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span><b>Date Reported:</b> {{labData.reportedAt | omgDate: 'withTime2yTz'}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Total Volume:</b> {{labData.collectionVolume}}</span>
    <span>
      <b>Fasting:</b> {{labData.fasting}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Additional Information:</b> {{labData.additionalInformation}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Physician Name:</b> {{labData.orderingPhysicianName}}</span>
    <span>
      <b>NPI:</b> {{labData.npi}}</span>
  </div>

  <div class="row"
       om-layout-gutter>
    <span om-flex="45">
      <b>Physician ID:</b> {{labData.ordererId}}</span>
  </div>
  <div class="row"
       om-layout-gutter>
    <b>Tests Ordered</b>
    <div>{{labData.testsOrdered}}</div>
  </div>
</div>
