import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';

import { FsaAction } from '../shared/fsa-action';
import { AppState } from '../store/app-store.reducer';

export const ROUTER_GO = '[Router] Go';
export const ROUTER_BACK = '[Router] Back';
export const ROUTER_FORWARD = '[Router] Forward';

export interface RouterGoPayload {
  path: any[];
  query?: object;
  extras?: NavigationExtras;
}

export class RouterGo implements FsaAction<RouterGoPayload> {
  readonly type = ROUTER_GO;

  constructor(public payload: RouterGoPayload) {}
}

export class RouterBack implements FsaAction<void> {
  readonly type = ROUTER_BACK;
}

export class RouterForward implements FsaAction<void> {
  readonly type = ROUTER_FORWARD;
}

export type RouterAction = RouterGo | RouterBack | RouterForward;

@Injectable()
export class RouterActions {
  constructor(private store: Store<AppState>) {}

  go(path: string | any[], query?: object, extras?: NavigationExtras) {
    if (typeof path === 'string') {
      path = [path];
    }
    this.store.dispatch(new RouterGo({ path, query, extras }));
  }

  back() {
    this.store.dispatch(new RouterBack());
  }

  forward() {
    this.store.dispatch(new RouterForward());
  }
}
