import * as _ from 'lodash';

import { UntypedFormGroup } from '@angular/forms';
import { Validator } from 'jsonschema';

const validator = new Validator();
/**
 * Recursively validates an AbstractControl against a `jsonschema` definition.
 * Unlike `valid()`, this will also add errors to the appropriate sub control
 * based on the `jsonschema` definition.
 */
export function validateWithJsonSchema(
  formState: UntypedFormGroup,
  jsonSchema: object,
): boolean {
  if (!jsonSchema) {
    console.warn(
      'Attempting to validate a FormGroup without a json-schema - considered valid',
    );
    return true;
  }

  const schema = camelizeSchema(jsonSchema);
  const value = _.cloneDeep(formState.value);
  const { valid, errors } = validator.validate(value, schema, {
    allowUnknownAttributes: false,
  });

  if (valid) {
    formState.setValue(value);
    return true;
  }

  errors.forEach(e => {
    if (e.name === 'exclusiveMinimum' || e.name === 'exclusiveMaximum') {
      // these errors are meaningless for a draft4 json schema, ignore them
      return;
    }

    let erroredControl = e.path.length ? formState.get(e.path) : formState;
    if (!erroredControl) {
      formState.setErrors({
        jsonSchema: `Validation failed (${e.name}: ${e.message})`,
      });
      return;
    }

    if (e.name === 'required') {
      // `required` errors are reported on the containing object
      const erroredFormGroup = erroredControl as UntypedFormGroup;
      const missingProp = _.camelCase(e.argument);
      erroredControl = erroredFormGroup.contains(missingProp)
        ? erroredFormGroup.get(missingProp)
        : erroredFormGroup;
    }

    erroredControl?.setErrors({
      ...(erroredControl.errors ?? {}),
      [e.name]: e,
    });
  });
  return false;
}

/**
 * After returning from the backend, the jsonschema value already has its
 * keys camelized, however, the `required` field will still have snake case
 * references to keys that are now camelCase.
 *
 * This functions camelizes the `required` field defined in jsonschema.
 *
 * JSON#parse/stringify is used here for a succinct way to edit a hash's entries
 * It is safe to do so because the jsonschema value is necessarily serializable
 * having been returned from the backend.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#using_the_reviver_parameter
 */
function camelizeSchema(schema: object): object {
  return JSON.parse(JSON.stringify(schema), (key, val) => {
    if (key === 'required') {
      return val.map(v => _.camelCase(v));
    }
    return val;
  });
}
