<omg-action-bar #actionBar>
  <ng-container left-buttons>
    <button [disabled]="loading$ | ngrxPush"
            [hidden]="loadingPDF"
            type="button"
            omgButton
            variant="primary"
            (click)="signLetter()"
            data-cy="generate-letter-sign-btn">
      {{ (loading$ | ngrxPush) ? 'Saving...' : 'Sign Letter' }}
    </button>
  </ng-container>
  <ng-container right-buttons>
    <button [hidden]="loadingPDF"
            omgButton
            type="button"
            variant="secondary"
            (click)="confirmDelete()"
            data-cy="generate-letter-delete-order-btn">
      Delete Draft
    </button>
  </ng-container>
  <omg-confirm-drawer (cancel)="cancelDelete()"
                      (confirm)="delete()"
                      [confirmationQuestion]="deleteConfirmationQuestion"
                      [confirmButtonText]="deleteConfirmationButtonText"
                      om-layout-fill
                      data-cy="generate-letter-confirm-delete">
  </omg-confirm-drawer>
</omg-action-bar>
