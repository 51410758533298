import {
  AwsCredentials,
  AwsCredentialsResponse,
  AwsSession,
  AwsSessionResponse,
  S3Pointer,
  S3PointerResponse,
} from './aws-session.type';

/* istanbul ignore next */
export const mapAwsCredentialsResponseToEntity = (
  data: AwsCredentialsResponse,
): AwsCredentials => ({
  accessKeyId: data.access_key_id,
  secretAccessKey: data.secret_access_key,
  sessionToken: data.session_token,
});

/* istanbul ignore next */
export const mapAwsResponseToEntity = (
  data: AwsSessionResponse,
): AwsSession => ({
  bucket: data.bucket,
  expiresAt: data.expires_at,
  userArn: data.user_arn,
  userId: data.user_id,
  credentials: mapAwsCredentialsResponseToEntity(data.credentials),
});

/* istanbul ignore next */
export const mapS3PointerResponseToEntity = (
  data: S3PointerResponse,
): S3Pointer => ({
  id: data.id || null,
  bucket: data.bucket || null,
  contentLength: data.content_length || null,
  contentType: data.content_type || null,
  key: data.key || null,
  title: data.title || null,
  destroy: data._destroy || null,
});
