import { Directive, HostListener } from '@angular/core';

import { BaseInput } from '../base-input/base-input';

@Directive({
  selector: 'input[omgInputNumber][type=number]',
  // eslint-disable-next-line
  host: {
    '[class.om-input]': 'true',
  },
})
export class InputNumberDirective extends BaseInput {
  @HostListener('wheel', ['$event'])
  onWheel(event: Event) {
    event.preventDefault();
  }
}
