import { createSelector } from '@ngrx/store';

import {
  selectActivePatientMedicationRegimenIds,
  selectHasPatientMedication,
} from './patient-medication.selectors';

// selects all the medication data for section linking (add to note)
export const selectMedicationsSectionLinkPayload = createSelector(
  selectHasPatientMedication,
  selectActivePatientMedicationRegimenIds,
  (hasPatientMedication, regimenIds) => ({
    summaryData: {
      noMedications: !hasPatientMedication,
      patientMedicationRegimenIds: regimenIds,
    },
  }),
);
