import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omgRemainingCharacters',
  pure: false,
})
export class RemainingCharactersPipe implements PipeTransform {
  transform(text: string = '', maxLength: number = 140) {
    const length = text ? text.length : 0;
    return maxLength - length < 0 ? 0 : maxLength - length;
  }
}
