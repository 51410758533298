import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EncounterService } from './encounter.service';
import { encounterReducer } from './store/encounter.reducer';

@NgModule({
  imports: [StoreModule.forFeature('encounter', encounterReducer)],
  providers: [EncounterService],
})
export class EncounterModule {
  constructor() {}
}
