// @ts-strict-ignore
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { pickBy } from '@app/utils';

@Directive({
  selector: '[omgSyncInput][control]',
})
export class SyncInputDirective implements OnInit, OnChanges {
  constructor(private el: ElementRef) {}
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('control') formControl: UntypedFormControl;

  ngOnInit(): void {
    this.el.nativeElement.value = this.formControl.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Not the first load, i.e. there was a previous value that is being replaced{
    if (
      !changes.formControl.isFirstChange() &&
      changes.formControl.currentValue
    ) {
      this.el.nativeElement.value = changes.formControl.currentValue.value;
    }
  }

  @HostListener('input', ['$event'])
  updateFormControl(): void {
    this.formControl.setErrors(null);

    if (this.el.nativeElement.validity.valid) {
      this.formControl.setValue(this.el.nativeElement.value);
    } else {
      const errors = pickBy(value => value, this.el.nativeElement.validity);
      this.formControl.setErrors(errors);
    }
  }
}
