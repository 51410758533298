// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';

import { mapFilingTemplateResponseToEntity } from './recategorize-document-api-mapper';
import { FilingTemplate } from './recategorize-document.type';

@Injectable({
  providedIn: 'root',
})
export class RecategorizeDocumentService {
  constructor(
    private searchService: SearchService,
    private config: ConfigService,
  ) {}

  searchForTemplate(
    text: string,
    index = this.config.searchIndex('filing_templates'),
  ): Observable<FilingTemplate[]> {
    return this.searchService.search(this.buildSearchQuery(index, text)).pipe(
      map((response: any) => {
        const hits = response.hits || {};
        const items = hits.hits || [];

        return items.map(hit => mapFilingTemplateResponseToEntity(hit._source));
      }),
    );
  }

  private buildSearchQuery(index: string, text: string): QueryBuilder {
    const query = new QueryBuilder('multi_match_with_fields_v6_strategy').build(
      text,
      {
        size: '200',
        fields: ['description'],
        sort: ['_score', 'description.keyword'],
        index: [index],
        filter: {
          term: { system_template: false },
        },
        operator: 'and',
      },
    );
    return query;
  }
}
