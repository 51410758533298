import { ElementRef, TemplateRef, Type } from '@angular/core';

export type PopoverContent = TemplateRef<any> | Type<any> | string;

export type PopoverPlacement = 'top' | 'right' | 'bottom' | 'left';

export type PopoverTriggerEvent = 'click' | 'hover' | 'none';

export interface PopoverOptions<T = any> {
  content: PopoverContent;
  title?: string | null;
  data?: T;
  placement?: PopoverPlacement;
  triggerOn?: PopoverTriggerEvent;
  enabled?: boolean;
  offsetX?: number;
  offsetY?: number;
  withFlexibleDimensions?: boolean;
  // size
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  // delays
  enterDelay?: number;
  leaveDelay?: number;
}

export class PopoverConfig<T = any> implements PopoverOptions<T> {
  origin: ElementRef | HTMLElement;
  content: PopoverContent;
  title?: string | null;
  data?: T;
  placement?: PopoverPlacement = 'top';
  triggerOn?: PopoverTriggerEvent = 'click';
  enabled? = true;
  offsetX?: number;
  offsetY?: number;
  withFlexibleDimensions?: boolean;
  // size
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string = 430;
  maxHeight?: number | string;
}

const buildDefaultConfig = (): PopoverConfig => ({
  ...new PopoverConfig(),
});

export const applyConfigDefaults = (
  config?: PopoverConfig,
  defaultOptions?: PopoverConfig,
): PopoverConfig => ({
  ...(defaultOptions || buildDefaultConfig()),
  ...config,
});
