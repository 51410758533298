// @ts-strict-ignore
import {
  DenialReason,
  PackageOptions,
  Task,
} from '@app/features/renewals/shared/renewals.type';
import {
  MedicationRegimen,
  MedicationRoute,
  PatientMedicationPrescriptionHistoryItem,
} from '@app/modules/medications/shared';
import { RenewalPharmacy } from '@app/modules/pharmacy-picker/shared/pharmacy.type';
import {
  Credential,
  Prescriber,
} from '@app/modules/shared-rx/prescriber-credential.type';
import { MedForDisplay } from '@app/modules/shared-rx/shared-rx.type';

export interface ChangeRx extends ChangeRxUpdateProperties, ChangeRxProperties {
  id: number;
  medicationRoute: MedicationRoute;
  createdAt: string;
  updatedAt: string;
  originalMedicationPrescription: PatientMedicationPrescriptionHistoryItem;
  task: Task;
  totalComments: number;
  className?: 'RxChangeRequest';
}

export interface NUCCProviderSpecialty {
  id: number;
  code: string;
  displayName: string;
}

export enum ChangeRxTypes {
  P = 'Prior Authorization',
  U = 'Prescriber Authorization',
  D = 'DUE Drug Use Evaluation',
  G = 'Generic Substitution',
  S = 'Script Clarification',
  T = 'Therapeutic Interchange/Substitution',
  OS = 'Out of Stock',
}

export enum PrescriberAuthorizationSubcodes {
  A = 'State license',
  B = 'DEA',
  C = 'DEA',
  D = 'State control substance number',
  E = 'State control substance number',
  F = 'NADEA number',
  G = 'NPI',
  H = 'Prescriber enrollment date in prescription benefit plan',
  I = 'Specialty',
  J = 'REMS enrollment date',
  L = 'Supervising provider information',
  M = 'Certificate to prescribe number',
}

export interface RxChangeCart {
  id: number;
}

export enum ChangeRxCartState {
  pending = 'new',
  approved = 'prescribe',
  denied = 'do_not_prescribe',
}

type ChangeRxType = keyof typeof ChangeRxTypes;
type PrescriberAuthorizationSubcode = keyof typeof PrescriberAuthorizationSubcodes;
export const rxChangeClass = 'RxChange';

interface ChangeRxProperties {
  changeRequestType: ChangeRxType;
  rxChangeRequest?: RxChangeRequest;
  rxChangeRequestPriorAuthorization?: RxChangeRequestPriorAuthorization;
  rxChangeRequestPrescriberAuthorizations?: RxChangeRequestPrescriberAuthorization[];
}

interface ChangeRxUpdateProperties {
  password?: string;
  cartPushToken?: string;
  denialReason?: DenialReason;
  rxChangeRequestPriorAuthorizationAttributes?: {
    id: number;
    submitWithoutApproval: boolean;
    approvedValue: string;
  };
  rxChangeRequestPrescriberAuthorizationsAttributes?: {
    id: number;
    value: any;
  }[];
  rxChangeRequestAttributes?: {
    id?: number;
    cartState?: ChangeRxCartState;
    approvedFills?: string;
    approvedMedicationPackageSizeId?: number;
    dispenseAsWritten?: string;
    notesToPharmacist?: string;
    earliestFillDate?: string;
    approvedQuantity?: string;
    medicationRegimenId?: number;
    prescriberId?: number;
    prescribingCredentialId?: number;
    selectedRxChangeRequestOptionId?: number;
  };
  customMedicationRegimen?: MedicationRegimen;
}

export interface RxChangeRequest {
  id: number;
  approvedFills?: number;
  approvedQuantity?: number;
  approvedMedicationPackageSizeId?: number;
  selectedRxChangeRequestOptionId?: number;
  rxChangeRequestOptions: RxChangeRequestOption[];

  // Below is Copied from Renewals
  createdAt: string;
  updatedAt: string;
  cartState: ChangeRxCartState;
  patientId: number;
  dispenseAsWritten: boolean;
  notesToPharmacist: string;
  dispensableMaxTotalFills: number;
  dispensableDeaCode: string;
  earliestFillDate: Date;
  maxEarliestFillDate: string;
  maxApprovedQuantity: number;
  noteToPharmacistMaxCharacters: number;
  notesLegalAddendum: string;
  earliestFillDateInputName: string;
  className: string;
  requestedBy: string;
  rxReferenceNumber: string;
  commentFromPatient: string;
  cancellationReason: string;
  createdByLabel: string;
  notesFromPharmacist: string;
  require2Fa: boolean;
  patientOfficeState: string;
  pharmacy: RenewalPharmacy;
  medForDisplay: MedForDisplay;
  medicationRoute: MedicationRoute;
  validPrescribers: Prescriber[];
  mustHandwrite: boolean;
  requiresNoteOfMedicalNeed: boolean;
  dispensableControlledSubstance: boolean;
  dispensableRestrictedControlledSubstance: boolean;
  denialReason: DenialReason;
  task: Task;
  packageOptions: PackageOptions;
  medicationRegimen: MedicationRegimen;
  totalComments: number;
  prescriber: Prescriber;
  prescriberId?: number;
  prescribingCredential: Credential;
  medicationRouteId?: number;
  rxCart: RxChangeCart;
}

export interface RxChangeRequestOption {
  id: number;
  dispenseAsWritten: boolean;
  earliestFillDate: Date;
  fills: number;
  preferred?: boolean;
  custom?: boolean;
  quantity: number;
  medForDisplay: MedForDisplay;
  medicationRegimen: MedicationRegimen;
  packageOptions: PackageOptions;
  medicationRoute: MedicationRoute;
  dispensableDeaCode: string;
  className?: string;
}

export interface RxChangeRequestPriorAuthorization {
  id: number;
  submitWithoutApproval: boolean;
  approvedValue?: string;
  pharmacyId: number;
}

export interface RxChangeRequestPrescriberAuthorization {
  id: number;
  subcode: PrescriberAuthorizationSubcode;
  value?: string;
  pharmacyId: number;
}
