<div *ngIf="{ results: results$ | ngrxPush, insertionState: insertionState$ | ngrxPush, currentIndex: currentIndex$ | ngrxPush } as data"
     (mousedown)="$event.preventDefault()">
  <div class="insertion-menu">
    <div *ngIf="!data.results?.length && data.insertionState?.searchTerm?.length < 2 && !isSearching"
         class="insertion-message">Type to search templates...</div>
    <div *ngIf="!data.results?.length && data.insertionState?.searchTerm?.length >= 2 && !isSearching"
         class="insertion-message">No templates found for '{{data.insertionState?.searchTerm}}'</div>
    <div *ngIf="isSearching && data.insertionState?.searchTerm?.length >= 2"
         class="insertion-message"
         om-layout
         om-layout-align="space-between center">
      Searching...
      <span class="spinner"
            om-flex>
        <i class="fa fa-lg fa-spinner fa-pulse"></i>
      </span>
    </div>
    <ng-container *ngIf="data.results?.length && !isSearching">
      <div class="insertion-results">
        <omg-infinite-scroll [itemTemplate]="itemTemplate"
                             [items]="data.results"
                             [itemHeight]="30"
                             [fixedScrollHeight]="getFixedScrollHeight(data.results)">
          <ng-template #itemTemplate
                       let-item
                       let-index="index">
            <li class="insertion-results-item"
                [class.selected]="index === data.currentIndex"
                (click)="itemSelect.emit(item)"
                (mousemove)="onItemMouseMove(index)"
                om-layout
                om-layout-align="space-between center"
                #insertionResults>
              <span om-flex-shrink
                    [innerHTML]="item.highlightedTitle | safe:'html'"
                    class="insertion-result-title"></span>
              <span om-flex
                    *ngIf="item.purpose === 'personal'"
                    class="insertion-result-flag">Personal</span>
            </li>
          </ng-template>
        </omg-infinite-scroll>
      </div>
      <div class="insertion-shortcuts"
           om-layout
           om-layout-align="space-between center">
        <span>
          <span class="insertion-icon-container">
            <span class="om-icon -small insertion-icon icon-key-up-arrow"></span>
          </span>
          <span class="insertion-icon-container">
            <span class="om-icon -small insertion-icon icon-key-down-arrow"></span>
          </span> to navigate
        </span>
        <span om-flex-shrink>
          <span class="insertion-icon-container">
            <span class="om-icon -small insertion-icon icon-key-enter"></span>
          </span>
          to insert
        </span>
      </div>
    </ng-container>
  </div>
  <omg-inline-insertion-preview *ngIf="data.results?.length && !isSearching"
                                [alignment]="previewAlignment"
                                [template]="data.results[data.currentIndex]">
  </omg-inline-insertion-preview>
</div>
