import {
  buildFunctionScoreV6Strategy,
  buildFunctionScoreV6StrategyWithFilters,
  buildMultiMatchWithFieldsV6Strategy,
  buildQueryStringWithFieldsV6Strategy,
  StrategyOptions,
} from './strategies';

export type StrategyName =
  | 'query_string_with_fields_v6'
  | 'function_score_v6'
  | 'multi_match_with_fields_v6_strategy'
  | 'function_score_v6_with_filters';

export class QueryBuilder {
  private strategyName: string;
  private strategyNameMap = {
    query_string_with_fields_v6: buildQueryStringWithFieldsV6Strategy,
    function_score_v6: buildFunctionScoreV6Strategy,
    multi_match_with_fields_v6_strategy: buildMultiMatchWithFieldsV6Strategy,
    function_score_v6_with_filters: buildFunctionScoreV6StrategyWithFilters,
  };

  constructor(strategyName: StrategyName) {
    this.strategyName = strategyName;
  }

  build(query: string, options?: Partial<StrategyOptions>) {
    return this.buildStrategy(query, options);
  }

  private buildStrategy(query: string, options?: Partial<StrategyOptions>) {
    return this.strategyNameMap[this.strategyName](query, options);
  }
}
