import * as encounterActions from './encounter.actions';

export interface EncounterState {
  sectionsLinkable: boolean;
}

export const encounterInitialState: EncounterState = {
  sectionsLinkable: false,
};

export function encounterReducer(
  state = encounterInitialState,
  action: encounterActions.EncounterAction,
): EncounterState {
  switch (action.type) {
    case encounterActions.updateSectionsLinkable: {
      return {
        ...state,
        sectionsLinkable: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}
