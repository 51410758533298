// @ts-strict-ignore
import { Component, Input, ViewChild } from '@angular/core';
import { first, map, shareReplay, take, withLatestFrom } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { Note } from '@app/modules/note/shared/note.type';
import { NoteActions, NoteSelectors } from '@app/modules/note/store';
import { ActionBarComponent } from '@app/shared/components/action-bar/action-bar.component';

@Component({
  selector: 'omg-letter-action-bar',
  templateUrl: './letter-action-bar.component.html',
  styleUrls: ['./letter-action-bar.component.scss'],
})
export class LetterActionBarComponent {
  @Input() loadingPDF = false;

  @ViewChild(ActionBarComponent, { static: true })
  actionBar: ActionBarComponent;

  loading$ = this.noteSelectors.isPending.pipe(shareReplay(1));

  readonly deleteConfirmationQuestion = 'Permanently delete this letter draft?';
  readonly deleteConfirmationButtonText = 'Delete';

  constructor(
    private noteActions: NoteActions,
    private noteSelectors: NoteSelectors,
    private analytics: AnalyticsService,
  ) {}

  signLetter(): void {
    this.noteSelectors.isPending
      .pipe(
        first(pending => !pending),
        withLatestFrom(this.noteSelectors.currentNote),
        map(([_, letter]: [boolean, Note]) => letter),
      )
      .subscribe(letter => this.noteActions.generateLetter(letter.id));
  }

  delete(): void {
    this.noteSelectors.currentNote.pipe(take(1)).subscribe(letter => {
      this.noteActions.deleteLetter(letter.id);
      this.analytics.track(AnalyticsEvent.DeleteDraftClicked, {
        workflow: 'Charting',
        component: 'Official Letter',
        subcomponent: 'Delete Draft Button',
        activeCharting: true,
        noteId: letter.id,
      });
    });
  }

  cancelDelete(): void {
    this.actionBar.hideConfirmDrawer();
  }

  confirmDelete(): void {
    this.actionBar.showConfirmDrawer();
  }
}
