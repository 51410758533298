// @ts-strict-ignore
import { SearchAfterCursor } from '@app/core/search/open-search/types/search-after-cursor';
import { StateActions } from '@app/core/store/shared/state';
import {
  TimelineFilterTypes,
  TimelineItem,
} from '@app/features/timeline/shared/timeline.type';

export enum TimelineActionTypes {
  CloseItem = '[Timeline] Close Item',
  SelectItem = '[Timeline] Select Item',
  SelectSearchFilter = '[Timeline] Select Search Filter',
  SelectSearchTerm = '[Timeline] Select Search Term',
  SelectScrollId = '[Timeline] Select Scroll Id',
  SelectTotalItems = '[Timeline] Select Total Items',
  RefreshTimeline = '[Timeline] Refresh Timeline',
  ScrollQuery = '[Timeline] Scroll Query',
  ScrollQuerySuccess = '[Timeline] Scroll Query Success',
  ScrollQueryError = '[Timeline] Scroll Query Error',
  OpenAttachedItem = '[Timeline] Open Attached Item',
  OpenAttachedItemSuccess = '[Timeline] Open Attached Item Success',
  OpenAttachedItemError = '[Timeline] Open Attached Item Error',
  OpenProcedureInteraction = '[Timeline] Open Procedure Interaction',
  OpenProcedureInteractionSuccess = '[Timeline] Open Procedure Interaction Success',
  OpenProcedureInteractionError = '[Timeline] Open Procedure Interaction Error',
}

export class CloseItem {
  readonly type = TimelineActionTypes.CloseItem;
}

export class SelectItem {
  readonly type = TimelineActionTypes.SelectItem;

  constructor(public payload: number) {}
}

export class SelectSearchFilter {
  readonly type = TimelineActionTypes.SelectSearchFilter;

  constructor(public payload: TimelineFilterTypes) {}
}

export class SelectSearchTerm {
  readonly type = TimelineActionTypes.SelectSearchTerm;

  constructor(public payload: string) {}
}

export class SelectScrollId {
  readonly type = TimelineActionTypes.SelectScrollId;

  constructor(public cursor: SearchAfterCursor) {}
}

export class SelectTotalItems {
  readonly type = TimelineActionTypes.SelectTotalItems;

  constructor(public payload: number) {}
}

export class RefreshTimeline {
  readonly type = TimelineActionTypes.RefreshTimeline;
}

export class ScrollQuery {
  readonly type = TimelineActionTypes.ScrollQuery;
}

export class ScrollQuerySuccess {
  readonly type = TimelineActionTypes.ScrollQuerySuccess;

  constructor(public payload: TimelineItem[]) {}
}

export class ScrollQueryError {
  readonly type = TimelineActionTypes.ScrollQueryError;

  constructor(public payload: any) {}
}

export class OpenAttachedItem {
  readonly type = TimelineActionTypes.OpenAttachedItem;

  constructor(public payload: number) {}
}

export class OpenAttachedItemSuccess {
  readonly type = TimelineActionTypes.OpenAttachedItemSuccess;

  constructor(public payload: TimelineItem) {}
}

export class OpenAttachedItemError {
  readonly type = TimelineActionTypes.OpenAttachedItemError;

  constructor(public payload: any) {}
}

export class OpenProcedureInteraction {
  readonly type = TimelineActionTypes.OpenProcedureInteraction;

  constructor(public payload: number) {}
}

export class OpenProcedureInteractionSuccess {
  readonly type = TimelineActionTypes.OpenProcedureInteractionSuccess;

  constructor(public payload: TimelineItem) {}
}

export class OpenProcedureInteractionError {
  readonly type = TimelineActionTypes.OpenProcedureInteractionError;

  constructor(public payload: any) {}
}

export type TimelineAction =
  | CloseItem
  | SelectItem
  | SelectSearchFilter
  | SelectSearchTerm
  | SelectScrollId
  | SelectTotalItems
  | RefreshTimeline
  | ScrollQuery
  | ScrollQuerySuccess
  | ScrollQueryError
  | OpenAttachedItem
  | OpenAttachedItemSuccess
  | OpenAttachedItemError
  | OpenProcedureInteraction
  | OpenProcedureInteractionSuccess
  | OpenProcedureInteractionError;

export class TimelineActions extends StateActions<TimelineItem> {
  refreshTimeline() {
    return this.store.dispatch(new RefreshTimeline());
  }

  closeItem() {
    return this.store.dispatch(new CloseItem());
  }

  selectItem(timelineItemId: number) {
    return this.store.dispatch(new SelectItem(timelineItemId));
  }

  selectSearchFilter(timelineFilterType: TimelineFilterTypes) {
    return this.store.dispatch(new SelectSearchFilter(timelineFilterType));
  }

  selectSearchTerm(term: string = '*') {
    return this.store.dispatch(new SelectSearchTerm(term));
  }

  scrollQuery() {
    return this.store.dispatch(new ScrollQuery());
  }

  openAttachedItem(timelineItemId: number) {
    return this.store.dispatch(new OpenAttachedItem(timelineItemId));
  }

  openProcedureInteraction(procedureInteractionId: number) {
    return this.store.dispatch(
      new OpenProcedureInteraction(procedureInteractionId),
    );
  }
}
