// @ts-strict-ignore
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import {
  ChangeRxForm,
  priorAuthNumberMaxLength,
} from '../../shared/change-rx-form';

@Component({
  selector: 'omg-change-rx-prior-auth',
  templateUrl: './change-rx-prior-auth.component.html',
  styleUrls: ['./change-rx-prior-auth.component.scss'],
})
export class ChangeRxPriorAuthComponent implements OnInit, OnDestroy {
  @Input() form: ChangeRxForm;

  priorAuthNumberMaxLength = priorAuthNumberMaxLength;

  unsubscribe$ = new Subject<void>();

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.listenToFormControls();
  }

  listenToFormControls() {
    this.form.controls
      .get('submitWithoutAuthNumber')
      .valueChanges.pipe(
        startWith(this.form.controls.get('submitWithoutAuthNumber').value),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(submitWithoutAuth => {
        const authNumber = this.form.controls.get('authNumber');
        if (submitWithoutAuth) {
          authNumber.setValue('');
          authNumber.disable();
        } else {
          authNumber.enable();
        }
      });
  }
}
