<div class="patient-demographics">
  <table>
    <tr>
      <th>Patient:</th>
      <td>{{ patient.firstName }} {{ patient.lastName }}</td>
    </tr>
    <tr>
      <th>DOB:</th>
      <td>{{ patient.dateOfBirth | omgDate: '4y' }}</td>
    </tr>
    <tr>
      <th>Sex:</th>
      <td>{{ patient.gender }}</td>
    </tr>
    <tr>
      <th>PCP:</th>
      <td>{{ patient.pcp }}</td>
    </tr>
    <tr>
      <th>Patient ID:</th>
      <td>{{ patient.id }}</td>
    </tr>
    <tr>
      <th>Address:</th>
      <td>
        <span>{{patient.primaryAddress?.street}}</span><br>
        <div *ngIf="patient?.primaryAddress?.address2 && patient?.primaryAddress?.address2?.length > 0">
          <span>{{patient.primaryAddress?.address2}}</span><br>
        </div>
        <span>
          {{patient.primaryAddress?.city}},
          {{patient.primaryAddress?.state?.shortName || patient.primaryAddress?.state?.name }}
          {{patient.primaryAddress?.zipCode}}
        </span>
      </td>
    </tr>
  </table>
  <table>
    <tr>
      <th>Chief Complaint:</th>
      <td>{{ note.subject }}</td>
    </tr>
    <tr>
      <th>Note Type:</th>
      <td>{{ note.noteType.name }}</td>
    </tr>
    <tr>
      <th>Date of Service:</th>
      <td>{{ dateOfService | omgDate: 'withTime4y' }}</td>
    </tr>
    <tr>
      <th>Date Signed:</th>
      <td>{{ note.signedAt | omgDate: 'withTime4y' }}</td>
    </tr>
    <tr>
      <th>Created By:</th>
      <td>{{ note.createdByWithSuffix }}</td>
    </tr>
    <tr>
      <th>Signed By:</th>
      <td>{{ note.signedByWithSuffix }}</td>
    </tr>
  </table>
</div>
