import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.cdc.gov/growthcharts/data/zscore/hcageinf.csv
const maleChildHeadCircumferenceLMSParams: LMSParamsMap = {
  23.5: { lambda: 1.276691223, mu: 48.60011223, sigma: 0.028989089 },
  24.5: { lambda: 1.424084853, mu: 48.72064621, sigma: 0.029242207 },
  25.5: { lambda: 1.570621291, mu: 48.83366629, sigma: 0.029505723 },
  26.5: { lambda: 1.715393998, mu: 48.93976089, sigma: 0.029778323 },
  27.5: { lambda: 1.857652984, mu: 49.03945383, sigma: 0.030058871 },
  28.5: { lambda: 1.996810563, mu: 49.13321432, sigma: 0.030346384 },
  29.5: { lambda: 2.132411346, mu: 49.22146409, sigma: 0.030640006 },
  30.5: { lambda: 2.264111009, mu: 49.30458348, sigma: 0.030938992 },
  31.5: { lambda: 2.391658052, mu: 49.38291658, sigma: 0.031242693 },
  32.5: { lambda: 2.514878222, mu: 49.45677569, sigma: 0.031550537 },
  33.5: { lambda: 2.633661226, mu: 49.526445, sigma: 0.031862026 },
  34.5: { lambda: 2.747949445, mu: 49.59218385, sigma: 0.03217672 },
  35.5: { lambda: 2.857728375, mu: 49.65422952, sigma: 0.032494231 },
  36: { lambda: 2.910932095, mu: 49.68393611, sigma: 0.032653934 },
};

const femaleChildHeadCircumferenceLMSParams: LMSParamsMap = {
  23.5: { lambda: 1.624475262, mu: 47.40515585, sigma: 0.029299426 },
  24.5: { lambda: 1.71165803, mu: 47.53687649, sigma: 0.029478937 },
  25.5: { lambda: 1.792551616, mu: 47.66118396, sigma: 0.029666406 },
  26.5: { lambda: 1.867550375, mu: 47.77865186, sigma: 0.02986096 },
  27.5: { lambda: 1.93703258, mu: 47.8897923, sigma: 0.030061839 },
  28.5: { lambda: 2.001358669, mu: 47.99506422, sigma: 0.030268375 },
  29.5: { lambda: 2.060870301, mu: 48.09488048, sigma: 0.030479985 },
  30.5: { lambda: 2.115889982, mu: 48.18961365, sigma: 0.03069615 },
  31.5: { lambda: 2.16672113, mu: 48.2796011, sigma: 0.030916413 },
  32.5: { lambda: 2.21364844, mu: 48.36514917, sigma: 0.031140368 },
  33.5: { lambda: 2.256943216, mu: 48.44653703, sigma: 0.031367651 },
  34.5: { lambda: 2.296844024, mu: 48.52401894, sigma: 0.031597939 },
  35.5: { lambda: 2.333589434, mu: 48.59782828, sigma: 0.031830942 },
  36: { lambda: 2.350847202, mu: 48.63342328, sigma: 0.031948378 },
};

export const getChildHeadCircumferencePercentile = (
  gender: string,
  ageInMonths: number,
  headCirc: number,
) => {
  const childHeadCircumferenceLMSDataTable =
    gender === 'Female'
      ? femaleChildHeadCircumferenceLMSParams
      : maleChildHeadCircumferenceLMSParams;

  return zScoreFromMeasurement(
    headCirc,
    ageInMonths,
    childHeadCircumferenceLMSDataTable,
    'wholeMidpoint',
  );
};
