import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[omgScrollingContainer]',
})
export class ScrollingContainerDirective {
  public nativeElement: HTMLElement;

  constructor(el: ElementRef) {
    this.nativeElement = el.nativeElement;
  }
}
