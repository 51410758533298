<div om-layout
     om-layout-gutter
     om-layout-fill
     om-layout-align="space-between"
     class="list-item"
     (click)="routeToDetail()">
  <span om-flex>{{ changeRx.originalMedicationPrescription.medForDisplay?.name}}
    {{changeRx.originalMedicationPrescription.medForDisplay?.dispensableTextDesc}}</span>
  <span om-flex>{{ changeRx.task?.assignee.name || 'Virtual Medical Team / VMT'}}</span>
  <span om-flex>
    {{ changeRxTypes[changeRx.changeRequestType] }} - {{ changeRx.createdAt | omgDateAgo}}
  </span>
</div>
