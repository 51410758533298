// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { PatientSelectors } from '@app/core';

@Component({
  selector: 'omg-patient-incomplete-demographics',
  templateUrl: './patient-incomplete-demographics.component.html',
})
export class PatientIncompleteDemographicsComponent implements OnInit {
  patientInfo$: Observable<any>;

  constructor(private patientSelectors: PatientSelectors) {}

  ngOnInit() {
    this.patientInfo$ = this.patientSelectors.patientInfo;
  }
}
