<omg-section-title>
  Addenda
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate"
      *ngFor="let addendum of addenda; trackBy: trackByFn">
    <p class="break-words">{{ addendum.content }}</p>
    <div om-layout
         om-layout-align="space-between">
      <b>Signed by {{ addendum.signedByName }}</b>
      <span>{{ addendum.signedAt | omgDate: '4y' }} at {{ addendum.signedAt | omgDate: '12Time' }}</span>
    </div>
  </li>
</ul>
