import { CM_PER_INCH, POUND_PER_KG } from './constants';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Taken from CDC dataset to use for children 24-36 months
// https://www.cdc.gov/growthcharts/percentile_data_files.htm

const maleWeightForLengthLMSParams: LMSParamsMap = {
  45: { lambda: 1.44903689, mu: 2.289757735, sigma: 0.149236691 },
  45.5: { lambda: 1.31794165, mu: 2.38617219, sigma: 0.144790131 },
  46.5: { lambda: 1.041730589, mu: 2.587097922, sigma: 0.1365472 },
  47.5: { lambda: 0.756615683, mu: 2.797952593, sigma: 0.129156077 },
  48.5: { lambda: 0.472617587, mu: 3.017679791, sigma: 0.122589498 },
  49.5: { lambda: 0.197455933, mu: 3.245225583, sigma: 0.116802688 },
  50.5: { lambda: -0.063272822, mu: 3.479567767, sigma: 0.111734963 },
  51.5: { lambda: -0.305663778, mu: 3.719739648, sigma: 0.107316407 },
  52.5: { lambda: -0.527210764, mu: 3.964838222, sigma: 0.10347453 },
  53.5: { lambda: -0.726356263, mu: 4.214033476, sigma: 0.100139369 },
  54.5: { lambda: -0.902380499, mu: 4.466562625, sigma: 0.097246097 },
  55.5: { lambda: -1.055126826, mu: 4.721730669, sigma: 0.09473644 },
  56.5: { lambda: -1.184933443, mu: 4.978903744, sigma: 0.092558749 },
  57.5: { lambda: -1.292531809, mu: 5.237504753, sigma: 0.09066765 },
  58.5: { lambda: -1.378973111, mu: 5.497008915, sigma: 0.089023438 },
  59.5: { lambda: -1.445563111, mu: 5.756939907, sigma: 0.087591418 },
  60.5: { lambda: -1.49380121, mu: 6.016866693, sigma: 0.086341291 },
  61.5: { lambda: -1.525332827, mu: 6.276400575, sigma: 0.085246598 },
  62.5: { lambda: -1.541839648, mu: 6.535195541, sigma: 0.084284401 },
  63.5: { lambda: -1.545098045, mu: 6.792942366, sigma: 0.083434649 },
  64.5: { lambda: -1.536863318, mu: 7.049370425, sigma: 0.08268004 },
  65.5: { lambda: -1.518786093, mu: 7.304248994, sigma: 0.082005843 },
  66.5: { lambda: -1.49249029, mu: 7.557381995, sigma: 0.081399411 },
  67.5: { lambda: -1.459487925, mu: 7.808610136, sigma: 0.080850107 },
  68.5: { lambda: -1.421167427, mu: 8.057810266, sigma: 0.08034908 },
  69.5: { lambda: -1.378835366, mu: 8.304892397, sigma: 0.079888977 },
  70.5: { lambda: -1.333634661, mu: 8.549802669, sigma: 0.079463915 },
  71.5: { lambda: -1.286605147, mu: 8.792519752, sigma: 0.079069193 },
  72.5: { lambda: -1.238665517, mu: 9.033054944, sigma: 0.07870118 },
  73.5: { lambda: -1.19066716, mu: 9.271448675, sigma: 0.078357096 },
  74.5: { lambda: -1.143316882, mu: 9.507773605, sigma: 0.078035021 },
  75.5: { lambda: -1.097263403, mu: 9.742129356, sigma: 0.077733651 },
  76.5: { lambda: -1.053083813, mu: 9.974642178, sigma: 0.077452242 },
  77.5: { lambda: -1.011294273, mu: 10.20546331, sigma: 0.077190512 },
  78.5: { lambda: -0.972360231, mu: 10.43476723, sigma: 0.076948562 },
  79.5: { lambda: -0.936705887, mu: 10.66274993, sigma: 0.076726804 },
  80.5: { lambda: -0.904722736, mu: 10.88962699, sigma: 0.076525901 },
  81.5: { lambda: -0.876777097, mu: 11.11563177, sigma: 0.076346711 },
  82.5: { lambda: -0.853216568, mu: 11.34101346, sigma: 0.076190236 },
  83.5: { lambda: -0.834375406, mu: 11.56603512, sigma: 0.076057579 },
  84.5: { lambda: -0.820578855, mu: 11.79097176, sigma: 0.075949901 },
  85.5: { lambda: -0.81214646, mu: 12.01610828, sigma: 0.075868383 },
  86.5: { lambda: -0.809394398, mu: 12.24173753, sigma: 0.075814185 },
  87.5: { lambda: -0.812636889, mu: 12.46815824, sigma: 0.075788413 },
  88.5: { lambda: -0.822186712, mu: 12.69567298, sigma: 0.075792075 },
  89.5: { lambda: -0.838354876, mu: 12.92458613, sigma: 0.075826044 },
  90.5: { lambda: -0.861449493, mu: 13.15520182, sigma: 0.075891019 },
  91.5: { lambda: -0.891773904, mu: 13.38782185, sigma: 0.075987476 },
  92.5: { lambda: -0.929617736, mu: 13.6227442, sigma: 0.076115636 },
  93.5: { lambda: -0.975268944, mu: 13.86025986, sigma: 0.076275395 },
  94.5: { lambda: -1.028990493, mu: 14.10065234, sigma: 0.076466299 },
  95.5: { lambda: -1.091024455, mu: 14.34419522, sigma: 0.076687482 },
  96.5: { lambda: -1.161574946, mu: 14.59115139, sigma: 0.076937631 },
  97.5: { lambda: -1.240820737, mu: 14.84177007, sigma: 0.077214912 },
  98.5: { lambda: -1.328879402, mu: 15.0962879, sigma: 0.077516968 },
  99.5: { lambda: -1.425809463, mu: 15.35492729, sigma: 0.077840877 },
  100.5: { lambda: -1.531575592, mu: 15.61789822, sigma: 0.078183177 },
  101.5: { lambda: -1.646081976, mu: 15.88539464, sigma: 0.078539804 },
  102.5: { lambda: -1.769082483, mu: 16.15760201, sigma: 0.078906277 },
  103.5: { lambda: -1.900221246, mu: 16.43469418, sigma: 0.079277694 },
};

const femaleWeightForLengthLMSParams: LMSParamsMap = {
  45: { lambda: 0.666839915, mu: 2.305396985, sigma: 0.168969897 },
  45.5: { lambda: 0.699616404, mu: 2.403256702, sigma: 0.157654766 },
  46.5: { lambda: 0.747915684, mu: 2.606020484, sigma: 0.139389663 },
  47.5: { lambda: 0.751754737, mu: 2.817114082, sigma: 0.125837223 },
  48.5: { lambda: 0.691329975, mu: 3.035356101, sigma: 0.115888948 },
  49.5: { lambda: 0.559107556, mu: 3.259693318, sigma: 0.108648608 },
  50.5: { lambda: 0.361549127, mu: 3.48922017, sigma: 0.103402703 },
  51.5: { lambda: 0.116436203, mu: 3.723195489, sigma: 0.099599651 },
  52.5: { lambda: -0.152509094, mu: 3.961034945, sigma: 0.096830356 },
  53.5: { lambda: -0.421478627, mu: 4.202270022, sigma: 0.09480477 },
  54.5: { lambda: -0.671388289, mu: 4.446476028, sigma: 0.093323068 },
  55.5: { lambda: -0.889973526, mu: 4.693220151, sigma: 0.092246459 },
  56.5: { lambda: -1.071844454, mu: 4.942029343, sigma: 0.091473166 },
  57.5: { lambda: -1.216671445, mu: 5.192403337, sigma: 0.090923715 },
  58.5: { lambda: -1.327360462, mu: 5.443830096, sigma: 0.090532906 },
  59.5: { lambda: -1.408261687, mu: 5.69581328, sigma: 0.090246768 },
  60.5: { lambda: -1.464051065, mu: 5.947889759, sigma: 0.090021128 },
  61.5: { lambda: -1.499105627, mu: 6.199640267, sigma: 0.089820688 },
  62.5: { lambda: -1.517197913, mu: 6.450695818, sigma: 0.089618171 },
  63.5: { lambda: -1.521479703, mu: 6.700736725, sigma: 0.089393174 },
  64.5: { lambda: -1.514481331, mu: 6.949493534, sigma: 0.089131254 },
  65.5: { lambda: -1.498204976, mu: 7.196744733, sigma: 0.088822943 },
  66.5: { lambda: -1.474231858, mu: 7.442313819, sigma: 0.088462854 },
  67.5: { lambda: -1.443808911, mu: 7.686067039, sigma: 0.088048963 },
  68.5: { lambda: -1.407959107, mu: 7.92790936, sigma: 0.087581916 },
  69.5: { lambda: -1.367521025, mu: 8.167783677, sigma: 0.087064605 },
  70.5: { lambda: -1.32324327, mu: 8.405666621, sigma: 0.086501667 },
  71.5: { lambda: -1.275834578, mu: 8.641566305, sigma: 0.085899159 },
  72.5: { lambda: -1.226014257, mu: 8.875519723, sigma: 0.085264271 },
  73.5: { lambda: -1.174555804, mu: 9.107590221, sigma: 0.084605096 },
  74.5: { lambda: -1.122323639, mu: 9.337865054, sigma: 0.083930435 },
  75.5: { lambda: -1.070302348, mu: 9.566453061, sigma: 0.083249631 },
  76.5: { lambda: -1.019617172, mu: 9.793482492, sigma: 0.082572421 },
  77.5: { lambda: -0.971544123, mu: 10.01909902, sigma: 0.081908788 },
  78.5: { lambda: -0.927495981, mu: 10.24346467, sigma: 0.081268832 },
  79.5: { lambda: -0.889046221, mu: 10.46675386, sigma: 0.080662561 },
  80.5: { lambda: -0.857844783, mu: 10.6891553, sigma: 0.080099785 },
  81.5: { lambda: -0.835600041, mu: 10.91086924, sigma: 0.079589888 },
  82.5: { lambda: -0.824007806, mu: 11.13210717, sigma: 0.079141623 },
  83.5: { lambda: -0.824673085, mu: 11.35309164, sigma: 0.078762888 },
  84.5: { lambda: -0.839021353, mu: 11.57405623, sigma: 0.078460511 },
  85.5: { lambda: -0.868191531, mu: 11.79524697, sigma: 0.078240047 },
  86.5: { lambda: -0.912987527, mu: 12.0169203, sigma: 0.078105554 },
  87.5: { lambda: -0.973732843, mu: 12.23934838, sigma: 0.078059544 },
  88.5: { lambda: -1.050238631, mu: 12.46281861, sigma: 0.078102898 },
  89.5: { lambda: -1.141750538, mu: 12.68763627, sigma: 0.078234935 },
  90.5: { lambda: -1.246935039, mu: 12.9141268, sigma: 0.078453576 },
  91.5: { lambda: -1.363881842, mu: 13.1426393, sigma: 0.078755652 },
  92.5: { lambda: -1.490235591, mu: 13.37354263, sigma: 0.079137144 },
  93.5: { lambda: -1.623204367, mu: 13.60723197, sigma: 0.079593737 },
  94.5: { lambda: -1.759750536, mu: 13.84412275, sigma: 0.080121122 },
  95.5: { lambda: -1.896722704, mu: 14.08464853, sigma: 0.080715361 },
  96.5: { lambda: -2.031079769, mu: 14.32925018, sigma: 0.081372938 },
  97.5: { lambda: -2.159985258, mu: 14.57837334, sigma: 0.082090922 },
  98.5: { lambda: -2.280992946, mu: 14.8324557, sigma: 0.082866693 },
  99.5: { lambda: -2.392125361, mu: 15.09192012, sigma: 0.083697706 },
  100.5: { lambda: -2.491985117, mu: 15.35716167, sigma: 0.08458092 },
  101.5: { lambda: -2.579688446, mu: 15.62854849, sigma: 0.085512655 },
  102.5: { lambda: -2.654922113, mu: 15.90640903, sigma: 0.086487929 },
  103.5: { lambda: -2.717782155, mu: 16.19103966, sigma: 0.087500575 },
};

export const getChildWeightForLengthPercentile = (
  gender: string,
  length: number, // in inches
  weight: number, // in lb
) => {
  const lengthInCm = length * CM_PER_INCH;
  const weightInKg = weight / POUND_PER_KG;
  const weightForLengthLMSDataTable =
    gender === 'Female'
      ? femaleWeightForLengthLMSParams
      : maleWeightForLengthLMSParams;

  return zScoreFromMeasurement(
    weightInKg,
    lengthInCm,
    weightForLengthLMSDataTable,
    'wholeMidpoint',
  );
};
