// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, groupBy, map, mergeMap } from 'rxjs/operators';

import { ErrorHandlerService } from '@app/core';

import { ReferenceDataApiService } from '../shared/reference-data-api.service';
import {
  LoadReferenceData,
  LoadReferenceDataError,
  LoadReferenceDataSuccess,
  ReferenceDataActionTypes,
} from './reference-data.actions';

@Injectable()
export class ReferenceDataEffects {
  constructor(
    private actions$: Actions,
    protected referenceDataApi: ReferenceDataApiService,
    protected errorHandler: ErrorHandlerService,
  ) {}

  loadReferenceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferenceDataActionTypes.LoadReferenceDataActionType),
      groupBy((action: LoadReferenceData) => action.referenceDataKey),
      mergeMap((actionsGroupedByDataKey$: Observable<LoadReferenceData>) => {
        return actionsGroupedByDataKey$.pipe(
          exhaustMap((action: LoadReferenceData) => {
            return this.referenceDataApi
              .getReferenceData(action.referenceDataKey)
              .pipe(
                map(
                  payload =>
                    new LoadReferenceDataSuccess(
                      payload,
                      action.referenceDataKey,
                    ),
                ),
                catchError(error =>
                  of(
                    new LoadReferenceDataError(
                      this.errorHandler.handleErrorSafe(error),
                      action.referenceDataKey,
                    ),
                  ),
                ),
              );
          }),
        );
      }),
    ),
  );
}
