// @ts-strict-ignore
import { ActionReducer } from '@ngrx/store';

import { PatientActionTypes } from '@app/core/patient/store/patient.actions';
import { profileStatePath } from '@app/core/profile/store/profile.reducer';
import { referenceDataPath } from '@app/modules/reference-data/store/reference-data.reducer';

const nonPatientStores = [
  profileStatePath,
  referenceDataPath,
];
export function patientResetReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === PatientActionTypes.ResetPatient) {
      const cleanState = Object.keys(state).reduce(
        (acc, curr) => ({
          ...acc,
          ...(nonPatientStores.includes(curr) ? { [curr]: state[curr] } : {}),
        }),
        {},
      );
      return reducer(cleanState, action);
    }
    return reducer(state, action);
  };
}
