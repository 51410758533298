<ng-container>
  <div omgTheme
       class="section-title-container"
       [attr.altBackgroundColor]="altBackgroundColor"
       om-flex>
    <strong class="section-title"
            [attr.largeFontSize]="largeFontSize"
            om-layout
            om-flex>
      <ng-content></ng-content>
    </strong>
  </div>
</ng-container>
