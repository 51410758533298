import { createSelector } from '@ngrx/store';

export interface StoreMetadataProps<T = {}> {
  pending: boolean;
  error: any;
  data?: T;
}

export interface StoreMetadataMap<T = {}> {
  [k: string]: StoreMetadataProps<T>;
}

export interface StoreMetadataState<T = {}> {
  [k: string]: StoreMetadataMap<T>;
}

const initialItemState = { pending: false, error: null };

export const createStoreMetadataAdapter = <T = {}>(
  statePath: string = 'metadata',
) => {
  const getInitialState = <V>(state: V): StoreMetadataState<T> => ({
    ...state,
    [statePath]: {},
  });

  const getSelectors = <V>(selectParentState: (state: V) => any) => {
    const selectMap = createSelector(
      selectParentState,
      (state: any): StoreMetadataMap<T> => state[statePath],
    );

    const selectItemByKey = createSelector(
      selectMap,
      (meta: StoreMetadataMap<T>, { key }): StoreMetadataProps<T> => meta[key],
    );

    return {
      selectMap,
      selectItemByKey,
    };
  };

  const upsertOne = (
    key: string | number,
    changes: Partial<StoreMetadataProps<T>>,
    state: any,
  ): StoreMetadataState<T> => ({
    [statePath]: {
      ...state[statePath],
      [key]: {
        ...initialItemState,
        ...state[key],
        ...changes,
      },
    },
  });

  const removeOne = (
    key: string | number,
    state: any,
  ): StoreMetadataState<T> => {
    const { [key]: item, ...rest } = state[statePath];
    return {
      [statePath]: {
        ...rest,
      },
    };
  };

  return {
    getInitialState,
    getSelectors,
    upsertOne,
    removeOne,
  };
};
