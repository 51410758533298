<omg-action-bar #actionBar
                *ngIf="isListView; else checkoutTemplate">
  <ng-container left-buttons>
    <ng-container *ngTemplateOutlet="checkoutTemplate"></ng-container>
  </ng-container>
</omg-action-bar>

<ng-template #savingTemplate>
  Saving...
</ng-template>

<ng-template #checkoutTemplate>
  <div om-layout-gutter
       [class.om-item]="!isListView">
    <button omgButton
            type="button"
            variant="primary"
            (click)="openCheckoutModal()"
            [disabled]="checkoutDisabled"
            data-cy="renewals-checkout-action-btn">
      <span omgTooltip
            tooltipText="No credentials. Email creds@onemedical.com"
            tooltipPosition="right"
            [showTooltip]="!isProvider">
        <span *ngIf="!loading; else savingTemplate">
          Checkout
        </span>
      </span>
    </button>
  </div>
</ng-template>
