import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';

import {
  PatientChartRootState,
  patientChartStatePath,
  TimelineState,
  WorkspaceState,
} from './patient-chart.reducer';

export const selectPatientChartState = createFeatureSelector<
  PatientChartRootState
>(patientChartStatePath);

export const selectTimelineState = createSelector(
  selectPatientChartState,
  (state: PatientChartRootState) => state.timeline,
);

export const selectWorkspaceState = createSelector(
  selectPatientChartState,
  (state: PatientChartRootState) => state.workspace,
);

export const selectTimelineExpanded = createSelector(
  selectTimelineState,
  (state: TimelineState) => (state.entity && state.entity.expanded) || false,
);

export const selectWorkspaceExpanded = createSelector(
  selectWorkspaceState,
  (state: WorkspaceState) => (state.entity && state.entity.expanded) || false,
);
/* istanbul ignore next */
@Injectable({
  providedIn: 'root',
})
export class PatientChartSelectors {
  constructor(private store: Store<PatientChartRootState>) {}

  get timelineExpanded() {
    return this.store.pipe(select(selectTimelineExpanded));
  }

  get workspaceExpanded() {
    return this.store.pipe(select(selectWorkspaceExpanded));
  }
}
