// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { ToastMessageService } from '@app/shared/components/toast';

const labelXMLString = `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips">
        <PaperOrientation>Landscape</PaperOrientation>
        <Id>ReturnAddress</Id>
        <PaperName>30330 Return Address</PaperName>
        <DrawCommands/>
        <ObjectInfo>
            <TextObject>
                <Name>Text</Name>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                <LinkedObjectName></LinkedObjectName>
                <Rotation>Rotation0</Rotation>
                <IsMirrored>False</IsMirrored>
                <IsVariable>True</IsVariable>
                <HorizontalAlignment>Left</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <TextFitMode>ShrinkToFit</TextFitMode>
                <UseFullFontHeight>True</UseFullFontHeight>
                <Verticalized>False</Verticalized>
                <StyledText/>
            </TextObject>
            <Bounds X="326" Y="165" Width="2242" Height="795" />
        </ObjectInfo>
    </DieCutLabel>`;

@Injectable({
  providedIn: 'root',
})
export class LabelPrinterService {
  constructor(private toast: ToastMessageService) {}

  get printers() {
    return dymo.label.framework.getPrinters();
  }

  get labelPrinter() {
    return (
      this.printers &&
      this.printers.find(
        printer => printer.printerType === 'LabelWriterPrinter',
      )
    );
  }

  get printerLoaded(): boolean {
    return this.printers && this.printers.length > 0 && !!this.labelPrinter;
  }

  makeLabel(text: string): dymo.label.framework.ILabel {
    const label = dymo.label.framework.openLabelXml(labelXMLString);
    label.setObjectText('Text', text);
    return label;
  }

  print(text: string, count = 1): void {
    if (!this.printerLoaded) {
      return this.toast.add({
        severity: 'warn',
        summary:
          'Dymo printer could not be found. Please reconnect and try again.',
      });
    }

    this.makeLabel(text).print(
      this.labelPrinter.name,
      dymo.label.framework.createLabelWriterPrintParamsXml({
        copies: count,
      }),
      '',
    );

    this.toast.add({
      severity: 'success',
      summary: `Printing ${count} label${count > 1 ? 's' : ''}.`,
    });
  }
}
