// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Comment } from '../shared/comments.type';
import { PostCommentsState } from './post-comments.reducer';

export enum PostCommentsActionTypes {
  GET_POST_COMMENTS = '[Comments] Get Post Comments',
  GET_POST_COMMENTS_SUCCESS = '[Comments] Get Post Comments Success',
  GET_POST_COMMENTS_ERROR = '[Comments] Get Post Comments Error',
  CREATE_POST_COMMENT = '[Comments] Create Post Comments',
  CREATE_POST_COMMENT_SUCCESS = '[Comments] Create Post Comments Success',
  CREATE_POST_COMMENT_ERROR = '[Comments] Create Post Comments Error',
}

type PostCommentsErrorData = any;

interface CreatePostCommentData {
  commentableId: number;
  commentBody: string;
}

export class GetPostComments implements FsaAction<number> {
  readonly type = PostCommentsActionTypes.GET_POST_COMMENTS;
  error = false;

  constructor(public payload: number) {}
}

export class GetPostCommentsSucccess implements FsaAction<Comment[]> {
  readonly type = PostCommentsActionTypes.GET_POST_COMMENTS_SUCCESS;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetPostCommentsError implements FsaAction<PostCommentsErrorData> {
  readonly type = PostCommentsActionTypes.GET_POST_COMMENTS_ERROR;
  error = true;

  constructor(public payload: PostCommentsErrorData) {}
}

export class CreatePostComment implements FsaAction<CreatePostCommentData> {
  readonly type = PostCommentsActionTypes.CREATE_POST_COMMENT;
  error = false;

  constructor(public payload: CreatePostCommentData) {}
}

export class CreatePostCommentSuccess implements FsaAction<Comment> {
  readonly type = PostCommentsActionTypes.CREATE_POST_COMMENT_SUCCESS;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreatePostCommentError
  implements FsaAction<PostCommentsErrorData> {
  readonly type = PostCommentsActionTypes.CREATE_POST_COMMENT_ERROR;
  error = true;

  constructor(public payload: PostCommentsErrorData) {}
}

export type PostCommentsAction =
  | GetPostComments
  | GetPostCommentsSucccess
  | GetPostCommentsError
  | CreatePostComment
  | CreatePostCommentSuccess
  | CreatePostCommentError;

@Injectable()
export class PostCommentsActions {
  constructor(private store: Store<PostCommentsState>) {}

  getPostComments(id: number) {
    this.store.dispatch(new GetPostComments(id));
  }

  createPostComment(commentableId: number, commentBody: string) {
    this.store.dispatch(new CreatePostComment({ commentableId, commentBody }));
  }
}
