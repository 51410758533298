import { Component, Input, OnInit } from '@angular/core';

import { MismatchedPatientDetails } from '../../shared/renewals.type';

@Component({
  selector: 'omg-mismatched-patient-detail-row',
  templateUrl: './mismatched-patient-detail-row.component.html',
})
export class MismatchedPatientDetailRowComponent implements OnInit {
  @Input() details: Partial<MismatchedPatientDetails>;
  detailKeys: string[];

  constructor() {}

  ngOnInit() {
    this.detailKeys = Object.keys(this.details);
  }
}
