// @ts-strict-ignore
import { Component, Input, ViewChild } from '@angular/core';

import { PopoverPlacement } from '@app/shared/components/popover/popover-config';

import { PopoverTriggerDirective } from '../popover';

/**
 * This component adds an onHover effect to its children's content which provides a popover of a card
 * displaying an internal user's details. These details include the internal user's name, location, work schedule,
 * and links to additional details. This component manages fetching the internal user's data via services.
 */
@Component({
  selector: 'omg-internal-user-info',
  templateUrl: './internal-user-info.component.html',
})
export class InternalUserInfoComponent {
  /** Internal User Id */
  @Input() providerId: number;

  /** Direction the popover should appear */
  @Input() placement: PopoverPlacement = 'right';

  /** The offset in pixels for the overlay connection point on the x-axis */
  @Input() offsetX = 0;

  /** The offset in pixels for the overlay connection point on the y-axix */
  @Input() offsetY = 0;

  @ViewChild(PopoverTriggerDirective, { static: true })
  triggerDirective: PopoverTriggerDirective;

  resize() {
    this.triggerDirective.toggle();
    this.triggerDirective.toggle();
  }
}
