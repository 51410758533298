import { Component, Input, OnInit } from '@angular/core';

import { Renewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-item-requested-by',
  templateUrl: './renewal-item-requested-by.component.html',
})
export class RenewalItemRequestedByComponent implements OnInit {
  @Input() renewal: Renewal;
  requestedByPatient: boolean;

  ngOnInit() {
    this.requestedByPatient = this.renewal.className === 'PatientRxRequest';
  }
}
