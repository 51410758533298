import { Action } from '@ngrx/store';

import { SectionLinkedItem } from './section-linked-queue.reducer';

export const addSectionLink = '[SectionLinkedQueue] Add Section Link';
export const removeSectionLink = '[SectionLinkedQueue] Remove Section Link';

export class AddSectionLink implements Action {
  readonly type = addSectionLink;

  constructor(public payload: SectionLinkedItem) {}
}

export class RemoveSectionLink implements Action {
  readonly type = removeSectionLink;

  constructor() {}
}

export type SectionLinkedQueueAction = AddSectionLink | RemoveSectionLink;
