// @ts-strict-ignore
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { map, take, tap } from 'rxjs/operators';

import { Patient } from '@app/core';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { getObjectPdfUrl } from '@app/modules/aws/shared/s3-utils';
import { S3Service } from '@app/modules/aws/shared/s3.service';
import { filterTruthy } from '@app/utils';

import { Note } from '../../shared/note.type';

@Component({
  selector: 'omg-printable-note',
  templateUrl: './printable-note.component.html',
  styleUrls: ['./printable-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableNoteComponent {
  @Input() patient: Patient;
  @Input() delay = 100;
  @Input() hasDocuments = false;
  @Input() summary: Summary;
  @Input() note: Note;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private s3Service: S3Service,
  ) {}

  openPrintDialog() {
    if (!this.hasDocuments) {
      this.writeHtmlToiFrame();
    }
    this.openPrintDialogWithDelay();
  }

  private getiFrameWindow(): HTMLIFrameElement {
    const iFrameSelector = !!this.hasDocuments
      ? 'omg-pdf-viewer iframe'
      : '.printable-document-iframe';

    const iFrame = this.document.querySelector(
      iFrameSelector,
    ) as HTMLIFrameElement;

    return iFrame;
  }

  private buildPrintHtmlContent() {
    let stylingTags = '';
    let bodyContents = '';

    if (this.document) {
      this.document
        .querySelectorAll('link[rel], style[type="text/css"]')
        .forEach(element => {
          stylingTags += element.outerHTML;
        });
      bodyContents = this.document.querySelector('.printable-document-contents')
        .innerHTML;
    }

    return `
      <html>
      <head>
        ${stylingTags}
      </head>
      <body>
        ${bodyContents}
      </body>
      </html>
    `;
  }

  private writeHtmlToiFrame() {
    const printDocument = this.getiFrameWindow().contentWindow.document.open();

    printDocument.write(this.buildPrintHtmlContent());
    printDocument.close();
  }

  private openPrintDialogWithDelay = () => {
    const iframeWindow = this.getiFrameWindow();
    iframeWindow
      ? setTimeout(() => iframeWindow.contentWindow.print(), this.delay)
      : this.openPdfFromS3();
  };

  private openPdfFromS3(): void {
    const document = this.note.documents[0];
    if (!document) {
      return;
    }

    const iFrame = this.document.querySelector(
      '.printable-document-iframe',
    ) as HTMLIFrameElement;
    iFrame.contentWindow.document.open();

    this.s3Service
      .getObject(document.pdfKey, document.pdfBucket)
      .pipe(
        filterTruthy(),
        map(getObjectPdfUrl),
        tap(url => {
          iFrame.onload = () =>
            setTimeout(() => {
              iFrame.focus();
              iFrame.contentWindow.print();
            }, this.delay);
          iFrame.src = url;
        }),
        take(1),
      )
      .subscribe();
  }
}
