import { Injectable } from '@angular/core';
import { flatten } from 'lodash';

import {
  DetailedError,
  ErrorComponentLocationMap,
  ErrorLocation,
  ErrorSource,
  MappedReturnAndDefaultErrors,
  MappedReturnAndDefualtMessages,
} from './detailed-errors.type';

/*
  This service is meant to handle detailed_errors returned from server requests, and is
  also compatible with `(string | Error)[]` . It's purpose is to allow server errors
  to be rendered with more flexibility, such as at a specific location or with a given severity.

  Components using DetailedErrorService to render errors at various locations must define an
  ErrorComponentLocationMap.
  The service will map the errors of the provided locations to their respective keys.
  Any errors with locations not defined in the errorLocationMap will be added under the `default` key.
  For example, given the following ErrorComponentLocationMap:

                var errorLocationMap = {
                  completion: ["submission", "invalidUser"],
                  measurements: ["measurements"]
                }

  and the following errors:

              var detailedErrors = [
                { ..., location: 'submission', message: 'Submission could not be completed' },
                { ..., location: 'invalidUser',  message: 'The user is invalid' },
                { ..., location: 'measurements', message: 'The measurement is invalid' }
                { ..., location: 'base', message: 'Something totally else' }
              ]

  the service will return:

              {
                completion: ['Submission could not be completed', 'The user is invalid'],
                measurements: ['The measurement is invalid'],
                default: ['Something totally else']
              }

  The component can then use the same keys defined in their errorLocationMap to select the
  returned errors, and render them where it chooses:

            this.actionErrors = mappedErrors.completion;
            this.measurementErrors = mappedErrors.measurements;
            toastErrors = mappedErrors.default;

*/
@Injectable({
  providedIn: 'root',
})
export class DetailedErrorService {
  // returns the detailedError *messages* mapped to the defined keys
  getMappedMessagesByLocation<T extends ErrorComponentLocationMap>(
    errors: any[],
    map: T,
  ): MappedReturnAndDefualtMessages<T> {
    const mappedErrors = this.getMappedErrorsByLocation(errors, map);
    return this.getMessagesFromMappedDetailedErrors(mappedErrors);
  }

  // returns an array of ALL error *messages*
  getDefaultMessages(errors: any[]): string[] {
    return this.getMappedMessagesByLocation(errors, {}).default;
  }

  // returns the full detailedErrors mapped to the defined keys
  getMappedErrorsByLocation<T extends ErrorComponentLocationMap>(
    errors: any[],
    map: T,
  ): MappedReturnAndDefaultErrors<T> {
    const detailedErrors = this.getAsDetailedErrors(errors);
    const mapped = {
      default: this.getDefaultErrors(detailedErrors, map),
    };
    Object.keys(map).forEach(k => {
      mapped[k] = this.getErrorsByLocation(detailedErrors, map[k]);
    });
    return mapped as MappedReturnAndDefaultErrors<T>;
  }

  private getMessagesFromMappedDetailedErrors<
    T extends ErrorComponentLocationMap
  >(
    mappedDetailedErrors: MappedReturnAndDefaultErrors<T>,
  ): MappedReturnAndDefualtMessages<T> {
    const obj = {};
    Object.keys(mappedDetailedErrors).forEach(k => {
      obj[k] = mappedDetailedErrors[k].map(v => v.message);
    });
    return obj as MappedReturnAndDefualtMessages<T>;
  }

  private getErrorsByLocation = (
    errors: DetailedError[],
    locations: ErrorLocation[],
  ) => {
    const set = new Set(locations);
    return errors.filter(e => set.has(e.location));
  };

  private getDefaultErrors(
    errors: DetailedError[],
    errorLocationMap: ErrorComponentLocationMap,
  ) {
    const set = new Set(flatten(Object.values(errorLocationMap)));
    return errors.filter(e => !set.has(e.location));
  }

  private getAsDetailedErrors = (errors: any[]) => {
    const errorMessages = errors.map((error: any) => {
      const errorInfo: DetailedError = {
        location: this.getDisplayLocation(error),
        message: error.message || '',
        title: error.title || '',
        severity: error.severity || '',
        key: error.key || '',
      };

      if (typeof error === 'string') {
        errorInfo.message = error;
      } else if (typeof error === 'object' && 'message' in error) {
        errorInfo.message = error.message;
      }
      return errorInfo;
    });
    return errorMessages;
  };

  private getDisplayLocation = (error: any) => {
    if (typeof error === 'object' && error.location) {
      return error.location;
    }
    return ErrorSource.Generic;
  };
}
