import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SummaryMedication } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-medications',
  templateUrl: './linked-medications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedMedicationsComponent {
  @Input() signed: boolean;
  @Input() noMedications: boolean;
  @Input() medications: SummaryMedication[];
  @Output() unlink = new EventEmitter();

  trackByFn = (index, medication: SummaryMedication) => medication.id || index;

  deleteFromNote() {
    this.unlink.emit();
  }
}
