// @ts-strict-ignore
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { mapVitalsFormToApiParams } from '@app/modules/vitals-data/shared/vitals-data-api.service';

import {
  SummaryProviderRecommendationUpdateData,
  SummaryUpdateData,
} from './summaries-update.type';
import { Summary } from './summaries.type';

const summaryProviderRecommendation = (summary: Summary): any => {
  const hasProviderRecommendation = summary.summaryProviderRecommendation;
  return {
    body: (hasProviderRecommendation && hasProviderRecommendation.body) || '',
    s3Pointers:
      (hasProviderRecommendation && hasProviderRecommendation.s3Pointers) || [],
  };
};

/* istanbul ignore next */
export const mapSummaryToUnsignedForm = (
  summary: Summary,
  vitalSetForm: UntypedFormGroup,
): UntypedFormGroup => {
  const recommendation = summaryProviderRecommendation(summary);
  return new UntypedFormGroup({
    subject: new UntypedFormControl(summary.subject || ''),
    subjective: new UntypedFormControl(summary.subjective || ''),
    objective: new UntypedFormControl(summary.objective || ''),
    afterVisitGuidance: new UntypedFormControl(recommendation.body),
    timeBasedVisit: new UntypedFormControl(summary.timeBasedVisit || false),
    vitalsForm: vitalSetForm,
  });
};

export const mapSummaryProviderRecommendationS3PointerToSaveData = attachmentData => {
  return {
    id: attachmentData.id,
    bucket: attachmentData.bucket,
    key: attachmentData.key,
    content_length: attachmentData.contentLength,
    content_type: attachmentData.contentType,
    title: attachmentData.title,
    _destroy: attachmentData.destroy,
  };
};

export const mapAfterVisitGuidanceToSummaryProviderRecommendation = (
  formValues,
  summary: Summary,
): SummaryProviderRecommendationUpdateData => {
  return {
    id:
      summary.summaryProviderRecommendation &&
      summary.summaryProviderRecommendation.id,
    body: formValues.afterVisitGuidance,
  };
};

/* istanbul ignore next */
export const mapUnsignedFormToSummaryUpdate = (
  formValues,
  summary: Summary,
): SummaryUpdateData => {
  const providerRecommendation = mapAfterVisitGuidanceToSummaryProviderRecommendation(
    formValues,
    summary,
  );

  return {
    subject: formValues.subject || '',
    subjective: formValues.subjective,
    objective: formValues.objective,
    measurements: mapVitalsFormToApiParams(formValues.vitalsForm),
    time_based_visit: formValues.timeBasedVisit,
    summary_provider_recommendation_attributes: providerRecommendation,
  };
};

export const mapSummaryToSummaryProviderRecommendationUpdateData = (
  summary: Summary,
  attachments: any[],
): Partial<SummaryUpdateData> => {
  return {
    summary_provider_recommendation_attributes: {
      id: summary.summaryProviderRecommendation.id,
      body: summary.summaryProviderRecommendation.body,
      s3_pointers_attributes: attachments.map(
        mapSummaryProviderRecommendationS3PointerToSaveData,
      ),
    },
  };
};
