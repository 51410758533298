// @ts-strict-ignore
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { AppState } from '@app/core/store/store/app-store.reducer';
import { SummaryChronicCareBillingPrograms } from '@app/features/summaries/shared/summaries.type';
import { DropdownItem } from '@app/shared';

import { ProgramEnrollment } from './../../shared/program-visit.type';
import {
  updateProgramForAppointment,
  updateProgramVisitCarePlan,
} from './../../store/program-visit.actions';
import { ProgramVisit } from '../../shared/program-visit.type';

@Component({
  selector: 'omg-program-care-plan',
  templateUrl: './program-care-plan.component.html',
  styleUrls: ['./program-care-plan.component.scss'],
})
export class ProgramCarePlanComponent implements OnInit, OnChanges {
  @Input() programVisit: ProgramVisit;
  @Input() chronicCareBillingPrograms: SummaryChronicCareBillingPrograms[];

  editingPlan = false;
  carePlanControl = new UntypedFormControl();
  programControl = new UntypedFormControl();
  dropdownItems: DropdownItem[];

  // Handles cleaning up subscriptions when the component is destroyed
  private unsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // Set up a subscription to automatically send the update action whenever
    // the program care plan text changes, debounced by 2 seconds and only when
    // the text is different than before.
    this.carePlanControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(2000),
        distinctUntilChanged(),
      )
      .subscribe((carePlan: string) => {
        this.dispatchUpdateCarePlan(carePlan);
      });

    // Set up a subscription to automatically send the update action whenever
    // the program changes, only when the text is different than before.
    this.programControl.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((programId: string) => {
        this.dispatchUpdateProgramForAppointment(programId);
      });
    this.mapDropdownItems();
  }

  // When program visit first loads is loaded, update the text field for the care plan
  // with the retrieved value.  Updating the textbox on any other changes will mess up
  // the cursor position as updates to the care plan would trigger a program visit update
  //
  // Note that if the component is instantiated before the program visit input is defined.
  // the form will not be populated correctly
  ngOnChanges({ programVisit }: SimpleChanges) {
    if (programVisit?.isFirstChange()) {
      this.carePlanControl.setValue(this.programCarePlan?.carePlan, {
        emitEvent: false,
      });
      this.programControl.setValue(
        this.programVisit.summary.appointment.programEnrollment.program.id,
        { emitEvent: false },
      );
    }
  }

  // When the textbox loses focus, we send an update request to capture any
  // changes that might have been missed. See the html template for where this
  // is called.
  carePlanOnBlur() {
    this.dispatchUpdateCarePlan(this.carePlanControl.value);
  }

  dispatchUpdateCarePlan(carePlan: string) {
    carePlan = carePlan?.trim() || null;

    if (carePlan === this.programCarePlan.carePlan) {
      return;
    }

    const programCarePlanId = this.programVisit.summary.appointment
      .programEnrollment.programCarePlan.id;

    this.store.dispatch(
      updateProgramVisitCarePlan({
        programCarePlanId,
        carePlan,
      }),
    );
  }

  dispatchUpdateProgramForAppointment(programId: string) {
    if (
      programId ===
      this.programVisit.summary.appointment.programEnrollment.program.id
    ) {
      return;
    }

    this.store.dispatch(
      updateProgramForAppointment({
        appointmentId: this.programVisit.summary.appointment.id,
        programId: programId,
      }),
    );
  }

  toggleEditingPlan(): void {
    this.editingPlan = !this.editingPlan;
  }

  get appointmentProgramEnrollment(): ProgramEnrollment {
    return this.programVisit.summary.appointment.programEnrollment;
  }

  get programCarePlan() {
    return this.appointmentProgramEnrollment.programCarePlan;
  }

  mapDropdownItems(): void {
    this.dropdownItems = this.chronicCareBillingPrograms.map(program => {
      return {
        value: program.id.toString(),
        label: program.displayName,
      };
    });
  }
}
