// @ts-strict-ignore
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Patient } from '@app/core';
import {
  RxCartStatus,
  RxCartTypes,
} from '@app/features/rx-verification/shared/rx-verification.type';

import { RenewalCheckoutForm } from '../../shared/renewal-checkout-form';
import { RenewalCart } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-signature',
  templateUrl: './renewal-signature.component.html',
  styleUrls: ['./renewal-signature.component.css'],
})
export class RenewalSignatureComponent {
  readonly cartType: RxCartTypes = RxCartTypes.renewal;

  @Input() patient: Patient;
  @Input() renewalCheckoutForm: RenewalCheckoutForm;
  @Input() cartState: RenewalCart;
  @Output() cancel = new EventEmitter();

  constructor() {}

  onCancel() {
    this.cancel.emit();
  }

  get cartId(): number {
    return this.renewalCheckoutForm.controls.get('rxCartId').value;
  }

  get cartStatus(): RxCartStatus {
    return {
      allReadyToSignItemsConfirmed: this.renewalCheckoutForm
        .allSubstancesConfirmed,
      allHandwrittenItemsConfirmed: this.renewalCheckoutForm
        .allHandwrittenItemsConfirmed,
      requireTwoFactorCheckout: this.renewalCheckoutForm
        .requiresTwoFactorCheckout,
    };
  }

  onVerificationComplete({ password, token: cartPushToken }) {
    this.renewalCheckoutForm.controls.patchValue({ password, cartPushToken });
    this.renewalCheckoutForm.checkout();
  }
}
