import * as sectionLinkedQueueActions from './section-linked-queue.actions';

export interface SectionLinkedItem {
  eventKey: string;
  sectionKey: string;
  payload: any;
}

export interface SectionLinkedQueueState extends Array<SectionLinkedItem> {}

export const sectionLinkedQueueInitialState: SectionLinkedQueueState = [];

export function sectionLinkedQueueReducer(
  state = sectionLinkedQueueInitialState,
  action: sectionLinkedQueueActions.SectionLinkedQueueAction,
): SectionLinkedQueueState {
  switch (action.type) {
    case sectionLinkedQueueActions.addSectionLink: {
      return [action.payload];
    }

    case sectionLinkedQueueActions.removeSectionLink: {
      return [];
    }

    default: {
      return state;
    }
  }
}
