import { TrackEventProperties } from '@app/core/analytics/analytics.type';

export const letterAnalyticsBaseAttributes = (
  letterId: number,
): Partial<TrackEventProperties> => ({
  workflow: 'Charting',
  component: 'Official Letter',
  noteId: letterId,
});

export const letterSignButtonAnalyticsEvent = (
  letterId: number,
): Partial<TrackEventProperties> => ({
  ...letterAnalyticsBaseAttributes(letterId),
  subcomponent: 'Sign Button',
});
