import { convertAgeToHalfYearInMonths } from '../vitals-utils';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeChildBMILMSParams: LMSParamsMap = {
  2: { lambda: -1.435, mu: 16.777, sigma: 0.098 },
  2.5: { lambda: -1.435, mu: 16.784, sigma: 0.098 },
  3: { lambda: -1.435, mu: 16.792, sigma: 0.099 },
  3.5: { lambda: -1.435, mu: 16.802, sigma: 0.099 },
  4: { lambda: -1.435, mu: 16.82, sigma: 0.1 },
  4.5: { lambda: -1.435, mu: 16.85, sigma: 0.101 },
  5: { lambda: -1.435, mu: 16.892, sigma: 0.102 },
  5.5: { lambda: -1.435, mu: 16.949, sigma: 0.104 },
  6: { lambda: -1.435, mu: 17.023, sigma: 0.107 },
  6.5: { lambda: -1.435, mu: 17.114, sigma: 0.11 },
  7: { lambda: -1.435, mu: 17.229, sigma: 0.113 },
  7.5: { lambda: -1.435, mu: 17.371, sigma: 0.118 },
  8: { lambda: -1.435, mu: 17.545, sigma: 0.123 },
  8.5: { lambda: -1.435, mu: 17.753, sigma: 0.129 },
  9: { lambda: -1.435, mu: 17.996, sigma: 0.135 },
  9.5: { lambda: -1.435, mu: 18.27, sigma: 0.141 },
  10: { lambda: -1.435, mu: 18.577, sigma: 0.147 },
  10.5: { lambda: -1.435, mu: 18.915, sigma: 0.154 },
  11: { lambda: -1.435, mu: 19.284, sigma: 0.16 },
  11.5: { lambda: -1.435, mu: 19.686, sigma: 0.165 },
  12: { lambda: -1.435, mu: 20.122, sigma: 0.17 },
  12.5: { lambda: -1.435, mu: 20.596, sigma: 0.175 },
  13: { lambda: -1.435, mu: 21.107, sigma: 0.178 },
  13.5: { lambda: -1.435, mu: 21.651, sigma: 0.181 },
  14: { lambda: -1.435, mu: 22.212, sigma: 0.182 },
  14.5: { lambda: -1.435, mu: 22.774, sigma: 0.183 },
  15: { lambda: -1.435, mu: 23.32, sigma: 0.184 },
  15.5: { lambda: -1.435, mu: 23.836, sigma: 0.184 },
  16: { lambda: -1.435, mu: 24.319, sigma: 0.184 },
  16.5: { lambda: -1.435, mu: 24.767, sigma: 0.183 },
  17: { lambda: -1.435, mu: 25.175, sigma: 0.183 },
  17.5: { lambda: -1.435, mu: 25.539, sigma: 0.182 },
  18: { lambda: -1.435, mu: 25.859, sigma: 0.182 },
  18.5: { lambda: -1.435, mu: 26.14, sigma: 0.181 },
  19: { lambda: -1.435, mu: 26.388, sigma: 0.181 },
  19.5: { lambda: -1.435, mu: 26.611, sigma: 0.181 },
  20: { lambda: -1.435, mu: 26.814, sigma: 0.18 },
};

const femaleDownSyndromeChildBMILMSParams: LMSParamsMap = {
  2: { lambda: -1.934, mu: 16.626, sigma: 0.095 },
  2.5: { lambda: -1.922, mu: 16.689, sigma: 0.096 },
  3: { lambda: -1.908, mu: 16.762, sigma: 0.099 },
  3.5: { lambda: -1.894, mu: 16.837, sigma: 0.101 },
  4: { lambda: -1.878, mu: 16.921, sigma: 0.103 },
  4.5: { lambda: -1.86, mu: 17.018, sigma: 0.106 },
  5: { lambda: -1.839, mu: 17.13, sigma: 0.109 },
  5.5: { lambda: -1.815, mu: 17.257, sigma: 0.113 },
  6: { lambda: -1.789, mu: 17.402, sigma: 0.117 },
  6.5: { lambda: -1.759, mu: 17.568, sigma: 0.122 },
  7: { lambda: -1.725, mu: 17.756, sigma: 0.127 },
  7.5: { lambda: -1.687, mu: 17.967, sigma: 0.132 },
  8: { lambda: -1.646, mu: 18.2, sigma: 0.138 },
  8.5: { lambda: -1.602, mu: 18.456, sigma: 0.144 },
  9: { lambda: -1.554, mu: 18.735, sigma: 0.151 },
  9.5: { lambda: -1.502, mu: 19.038, sigma: 0.157 },
  10: { lambda: -1.447, mu: 19.365, sigma: 0.164 },
  10.5: { lambda: -1.388, mu: 19.717, sigma: 0.17 },
  11: { lambda: -1.325, mu: 20.099, sigma: 0.177 },
  11.5: { lambda: -1.259, mu: 20.515, sigma: 0.183 },
  12: { lambda: -1.188, mu: 20.967, sigma: 0.189 },
  12.5: { lambda: -1.113, mu: 21.456, sigma: 0.194 },
  13: { lambda: -1.035, mu: 21.986, sigma: 0.199 },
  13.5: { lambda: -0.953, mu: 22.555, sigma: 0.203 },
  14: { lambda: -0.868, mu: 23.16, sigma: 0.207 },
  14.5: { lambda: -0.78, mu: 23.796, sigma: 0.21 },
  15: { lambda: -0.69, mu: 24.455, sigma: 0.213 },
  15.5: { lambda: -0.598, mu: 25.134, sigma: 0.216 },
  16: { lambda: -0.504, mu: 25.827, sigma: 0.218 },
  16.5: { lambda: -0.409, mu: 26.525, sigma: 0.22 },
  17: { lambda: -0.314, mu: 27.221, sigma: 0.223 },
  17.5: { lambda: -0.218, mu: 27.911, sigma: 0.225 },
  18: { lambda: -0.122, mu: 28.59, sigma: 0.227 },
  18.5: { lambda: -0.026, mu: 29.252, sigma: 0.229 },
  19: { lambda: 0.07, mu: 29.898, sigma: 0.231 },
  19.5: { lambda: 0.165, mu: 30.527, sigma: 0.233 },
  20: { lambda: 0.26, mu: 31.141, sigma: 0.235 },
};

export const getDownSyndromeChildBMIPercentile = (
  gender: string,
  ageInMonths: number,
  bmi: number,
) => {
  const dsBMILMSParams =
    gender === 'Female'
      ? femaleDownSyndromeChildBMILMSParams
      : maleDownSyndromeChildBMILMSParams;
  const ageInHalfYears = convertAgeToHalfYearInMonths(ageInMonths);

  return zScoreFromMeasurement(bmi, ageInHalfYears, dsBMILMSParams);
};
