import {
  Component,
  ContentChild,
  Directive,
  Input,
  TemplateRef,
} from '@angular/core';

export type InlineBannerColorVariant = 'primary' | 'secondary';

@Directive({
  selector: '[omgInlineBannerPopoverContent]',
})
export class InlineBannerPopoverContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgInlineBannerAction]',
})
export class InlineBannerActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omg-inline-banner',
  templateUrl: './inline-banner.component.html',
  styleUrls: ['./inline-banner.component.scss'],
})
export class InlineBannerComponent {
  @Input() variant: InlineBannerColorVariant;
  @Input() additionalClasses: string;
  @Input() primaryIconName: string;

  @ContentChild(InlineBannerPopoverContentDirective)
  infoPopoverContent: InlineBannerPopoverContentDirective;
  @ContentChild(InlineBannerActionDirective)
  actionContent: InlineBannerActionDirective;

  constructor() {}
}
