<div *ngIf="note">
  <div om-layout
       om-layout-align="space-between">
    <label om-layout="vertical">Note to {{recipient}}</label>
    <span *ngIf="!note.disabled && maxLength"
          [ngClass]="{counter: true, error: exceedsMaxLength()}">
      {{charsRemaining()}} Characters Remaining
    </span>
  </div>
  <omg-chart-text-box om-layout-fill
                      [control]="note"
                      quillFormat="text"
                      [placeholderText]="placeholder"
                      [maxLength]="maxLength"
                      recipient="patient"
                      [required]="required"
                      (click)="setNoteValueIfBlank(placeholder, autofillPlaceholder)"
                      (focus)="noteFocus.emit()"
                      (focusout)="noteFocusout.emit()"></omg-chart-text-box>
  <span class="om-messages"
        *ngIf="!note.valid && charsRemaining() === maxLength">
    Cannot be blank
  </span>
</div>
