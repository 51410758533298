// @ts-strict-ignore
import { FsaAction } from '@app/core';
import { StateActions } from '@app/core/store/shared/state';

import {
  VisitProcedure,
  VisitProcedureError,
} from '../shared/visit-procedure.type';

export enum VisitProcedureActionTypes {
  SignVisitProcedureError = '[VisitProcedure] Sign Visit Procedure Error',
  ResetSignVisitProcedureError = '[VisitProcedure] Sign Visit Procedure Error',
}

export class SignVisitProcedureError implements FsaAction<VisitProcedureError> {
  readonly type = VisitProcedureActionTypes.SignVisitProcedureError;
  error = true;

  constructor(public payload: VisitProcedureError) {}
}

export class ResetSignVisitProcedureError implements FsaAction {
  readonly type = VisitProcedureActionTypes.ResetSignVisitProcedureError;
  error = false;

  constructor() {}
}

export class VisitProcedureActions extends StateActions<VisitProcedure> {
  resetSigningError() {
    this.store.dispatch(new ResetSignVisitProcedureError());
  }
}

export type VisitProcedureAction =
  | SignVisitProcedureError
  | ResetSignVisitProcedureError;
