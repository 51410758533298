<div class="padding-half"
     om-layout
     om-layout-gutter
     om-layout-align="space-between center">
  <div om-layout
       om-layout-gutter
       om-layout-align="start center">
    <i class="om-icon icon-magic"></i>
    <strong>{{text}}</strong>
    <div class="muted">{{subtext}}</div>
    <span #suggestionInfoIcon
          *ngIf="tooltip.childNodes.length > 0"
          class="om-icon icon-alert-info clickable"
          (click)="showInfoPopover()"></span>
  </div>
  <button *ngIf="buttonText"
          class="accept-button"
          omgButton
          [variant]="buttonVariant"
          (click)="accept.emit()"
          data-cy="ml-suggestion-accept">
    {{buttonText}}
  </button>
  <button *ngIf="!hideCloseButton"
          omgButton
          type="button"
          variant="none"
          class="clickable om-icon icon-close"
          (click)="closeSuggestion.emit()"
          data-cy="ml-suggestion-dismiss">
  </button>
</div>

<omg-popover #infoPopover
             placement="top"
             width="430">
  <div #tooltip
       (click)="closeInfoPopover()">
    <ng-content></ng-content>
  </div>
</omg-popover>
