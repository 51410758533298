// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import {
  CorrelationId,
  createEntityMetadataSelectors,
  EntityMetadataMap,
} from '@app/utils/store';

import { PendingNewRx } from '../shared/rx-cart.type';
import {
  adapter,
  metadataAdapter,
  PendingRxCartItemsState,
} from './pending-new-rx.reducer';
import { selectRxCartState } from './rx-cart-store-shared';
import { NewRxCartState } from './rx-cart.reducer';

export const selectCartItemsState = createSelector(
  selectRxCartState,
  (state: NewRxCartState) => state && state.pendingRxCartItems,
);

const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = adapter.getSelectors();

// selects array of cart item ids
export const selectCartItemIds = createSelector(
  selectCartItemsState,
  selectIds,
);

// selects the dictionary of cart items
export const selectCartItemEntities = createSelector(
  selectCartItemsState,
  selectEntities,
);

// selects the array of cart items
export const selectAllCartItems = createSelector(
  selectCartItemsState,
  selectAll,
);

export const selectCartItemById = createSelector(
  selectCartItemsState,
  (state: PendingRxCartItemsState, { id }) =>
    state && state.entities && state.entities[id],
);

// selects the total number of cart items
export const selectTotalCartItems = createSelector(
  selectCartItemsState,
  selectTotal,
);

// selects is loading
const isLoading = (state: PendingRxCartItemsState) => state && state.loading;
export const selectIsLoading = createSelector(selectCartItemsState, isLoading);

// metadata selectors for tracking async operations, etc.
const metadataSelectors = metadataAdapter.getSelectors(selectCartItemsState);
const {
  selectEntityMetadata,
  selectEntityWithMetadata,
  selectEntityError,
} = createEntityMetadataSelectors(selectCartItemsState, selectCartItemById);
export const selectCartMetadata = selectEntityMetadata;
export const selectCartError = selectEntityError;
export const selectCartWithMetadata = selectEntityWithMetadata;

export const selectCartItemsMetadata = createSelector(
  selectCartItemsState,
  (state: PendingRxCartItemsState) => state && state.metadata,
);

export const selectCartItemMetadataById = createSelector(
  selectCartItemsMetadata,
  (metadata, { id }) => metadata && metadata[id],
);

export const selectCartItemIsMarkReadyToSignPending = createSelector(
  selectCartItemMetadataById,
  metadata => metadata && !!metadata.markReadyToSignPending,
);

export const selectAllItemsWithMetadata = createSelector(
  selectAllCartItems,
  selectCartItemsMetadata,
  (items: PendingNewRx[], metadataMap: EntityMetadataMap<PendingNewRx>) => [
    ...(items &&
      items.map(item => ({
        item,
        metadata: metadataMap[item.id],
      }))),
  ],
);

export const selectErrors = createSelector(
  selectCartError,
  metadataError =>
    metadataError &&
    metadataError.errors &&
    Object.keys(metadataError.errors).map(key => [
      ...metadataError.errors[key],
    ]),
);

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class PendingNewRxSelectors {
  constructor(private store: Store<NewRxCartState>) {}

  get itemIds() {
    return this.store.pipe(select(selectCartItemIds));
  }

  get entities() {
    return this.store.pipe(select(selectCartItemEntities));
  }

  get cartItems() {
    return this.store.pipe(select(selectAllCartItems));
  }

  item(id: number) {
    return this.store.pipe(select(selectCartItemById, { id }));
  }

  itemMetadata(id: number) {
    return this.store.pipe(select(selectCartItemMetadataById, { id }));
  }

  itemIsMarkReadyToSignPending(id: number) {
    return this.store.pipe(
      select(selectCartItemIsMarkReadyToSignPending, { id }),
    );
  }

  get totalCartItems() {
    return this.store.pipe(select(selectTotalCartItems));
  }

  get isLoading() {
    return this.store.pipe(select(selectIsLoading));
  }

  pendingOperation(key: CorrelationId) {
    return this.store.pipe(select(metadataSelectors.selectItemByKey, { key }));
  }

  cartMetadata(id: number) {
    return this.store.pipe(select(selectCartWithMetadata, { id }));
  }

  itemErrors(id: number) {
    return this.store.pipe(select(selectErrors, { id }));
  }
}
