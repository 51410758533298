// @ts-strict-ignore
import { FsaAction } from '@app/core';
import { StateActions } from '@app/core/store/shared/state';
import {
  AddDocumentError,
  AddTimelineItemResultDocumentPayload,
  DeleteDocumentError,
  ProcedureOrder,
  ProcedureOrderAddResultDocumentsPayload,
  ProcedureOrderAdministerOnBehalfOf,
  ProcedureOrderDeleteResultDocumentPayload,
  ProcedureOrderRecordMeasurementPayload,
  ProcedureOrderRecordMeasurementSuccessPayload,
} from '@app/features/procedure-order/shared/procedure-order.type';
import { TimelineItem } from '@app/features/timeline/shared/timeline.type';
import { CloneOrder } from '@app/modules/orders/shared/actions/clone-order.type';
import { SupportingDocument } from '@app/modules/orders/shared/order.type';

export enum ProcedureOrderActionTypes {
  AddProcedureOrderSupportingDocument = '[ProcedureOrder] Add Supporting Document',
  AddTimelineItemResultDocument = '[ProcedureOrder] Add Timeline Item Result Document',
  AddTimelineItemResultDocumentError = '[ProcedureOrder] Add Timeline Item Result Document Error',
  ProcedureOrderStatusChange = '[ProcedureOrder] Status Change',
  AdministerOnBehalfOf = '[ProcedureOrder] Adminster On Behalf Of',
  AddResultDocuments = '[ProcedureOrder] Add Result Documents',
  AddResultDocumentsError = '[ProcedureOrder] Add Result Documents Error',
  AddResultDocumentsSuccess = '[ProcedureOrder] Add Result Documents Success',
  DeleteResultDocuments = '[ProcedureOrder] Delete Result Document',
  DeleteResultDocumentsError = '[ProcedureOrder] Delete Result Document Error',
  RecordMeasurement = '[ProcedureOrder] Record Measurement',
  RecordMeasurementSuccess = '[ProcedureOrder] Record Measurement Success',
  RecordMeasurementError = '[ProcedureOrder] Record Measurement Error',
}

export class AddProcedureOrderSupportingDocument
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureOrderActionTypes.AddProcedureOrderSupportingDocument;

  constructor(public payload: { id: number; document: SupportingDocument }) {}
}

export class ProcedureOrderStatusChange implements FsaAction<ProcedureOrder> {
  readonly type = ProcedureOrderActionTypes.ProcedureOrderStatusChange;

  constructor(public payload: ProcedureOrder) {}
}

export class AdministerOnBehalfOf
  implements FsaAction<ProcedureOrderAdministerOnBehalfOf> {
  readonly type = ProcedureOrderActionTypes.AdministerOnBehalfOf;

  constructor(public payload: ProcedureOrderAdministerOnBehalfOf) {}
}

export class AddResultDocuments
  implements FsaAction<ProcedureOrderAddResultDocumentsPayload> {
  readonly type = ProcedureOrderActionTypes.AddResultDocuments;

  constructor(public payload: ProcedureOrderAddResultDocumentsPayload) {}
}

export class AddResultDocumentsError implements FsaAction<AddDocumentError> {
  readonly type = ProcedureOrderActionTypes.AddResultDocumentsError;
  error = true;

  constructor(public payload: AddDocumentError) {}
}

export class AddResultDocumentsSuccess implements FsaAction<ProcedureOrder> {
  readonly type = ProcedureOrderActionTypes.AddResultDocumentsSuccess;

  error = false;

  constructor(public payload: ProcedureOrder) {}
}

export class AddTimelineItemResultDocument
  implements FsaAction<AddTimelineItemResultDocumentPayload> {
  readonly type = ProcedureOrderActionTypes.AddTimelineItemResultDocument;

  constructor(public payload: AddTimelineItemResultDocumentPayload) {}
}

export class AddTimelineItemResultDocumentError implements FsaAction<any> {
  readonly type = ProcedureOrderActionTypes.AddTimelineItemResultDocumentError;
  error = true;

  constructor(public payload: any) {}
}

export class DeleteResultDocuments
  implements FsaAction<ProcedureOrderDeleteResultDocumentPayload> {
  readonly type = ProcedureOrderActionTypes.DeleteResultDocuments;

  constructor(public payload: ProcedureOrderDeleteResultDocumentPayload) {}
}

export class DeleteResultDocumentsError
  implements FsaAction<DeleteDocumentError> {
  readonly type = ProcedureOrderActionTypes.DeleteResultDocumentsError;
  error = true;

  constructor(public payload: any) {}
}

export class RecordMeasurement
  implements FsaAction<ProcedureOrderRecordMeasurementPayload> {
  readonly type = ProcedureOrderActionTypes.RecordMeasurement;

  constructor(public payload: ProcedureOrderRecordMeasurementPayload) {}
}

export class RecordMeasurementError implements FsaAction<any> {
  readonly type = ProcedureOrderActionTypes.RecordMeasurementError;
  error = true;

  constructor(public payload: any) {}
}

export class RecordMeasurementSuccess
  implements FsaAction<ProcedureOrderRecordMeasurementSuccessPayload> {
  readonly type = ProcedureOrderActionTypes.RecordMeasurementSuccess;

  error = false;

  constructor(public payload: ProcedureOrderRecordMeasurementSuccessPayload) {}
}

export class ProcedureOrderActions extends StateActions<ProcedureOrder> {
  recordMeasurement(payload: ProcedureOrderRecordMeasurementPayload) {
    this.store.dispatch(new RecordMeasurement(payload));
  }

  clone(order: ProcedureOrder): any {
    this.store.dispatch(new CloneOrder(order));
  }

  statusChanged(order: ProcedureOrder) {
    this.store.dispatch(new ProcedureOrderStatusChange(order));
  }

  administerOnBehalfOf(id: number, administeredOnBehalfOf: boolean) {
    this.store.dispatch(
      new AdministerOnBehalfOf({
        id: id,
        administeredOnBehalfOf: administeredOnBehalfOf,
      }),
    );
  }

  addResultDocuments(id: number, resultDocuments: File[]) {
    this.store.dispatch(
      new AddResultDocuments({
        id: id,
        resultDocuments,
      }),
    );
  }

  deleteResultDocuments(documentId: number, procedureOrderId: number) {
    this.store.dispatch(
      new DeleteResultDocuments({
        documentId: documentId,
        procedureOrderId: procedureOrderId,
      }),
    );
  }
}

export type ProcedureOrderRecordMeasurementAction =
  | RecordMeasurement
  | RecordMeasurementSuccess
  | RecordMeasurementError;
