<div *ngIf="label"
     class="label"
     (click)=focus()>{{label}}</div>
<div class="radio-button-group"
     om-layout>
  <div *ngFor="let option of options"
       om-layout-fill>
    <input #buttonGroupOption
           type="radio"
           (click)="buttonGroupClick.emit($event)"
           [value]="option.value"
           [formControl]="buttonGroupControl"
           [name]="radioGroupName"
           [id]="radioGroupName + '-' + option.value" />
    <label [for]="radioGroupName + '-' + option.value"
           [innerHtml]="option.label"
           om-layout="vertical"
           om-layout-align="center center"
           om-layout-fill
           (click)="option.click && option.click()"></label>
  </div>
</div>
