import { Component, Input, OnInit } from '@angular/core';

import { sortRenewalsByPharmacy } from '../../shared/renewal-utils';
import { CheckoutRenewal, Renewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-checkout-prescriptions',
  templateUrl: './renewal-checkout-prescriptions.component.html',
  styleUrls: ['./renewal-checkout-prescriptions.component.scss'],
})
export class RenewalCheckoutPrescriptionsComponent implements OnInit {
  @Input() checkoutRenewals: CheckoutRenewal[];
  renewalsbyPharmacy: Renewal[][];

  constructor() {}

  ngOnInit() {
    this.renewalsbyPharmacy = sortRenewalsByPharmacy(this.checkoutRenewals);
  }
}
