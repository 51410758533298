// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Comment } from '../shared/comments.type';
import { SummaryCommentsState } from './summary-comments.reducer';

export enum SummaryCommentsActionTypes {
  GET_SUMMARY_COMMENTS = '[Comments] Get Summary Comments',
  GET_SUMMARY_COMMENTS_SUCCESS = '[Comments] Get Summary Comments Success',
  GET_SUMMARY_COMMENTS_ERROR = '[Comments] Get Summary Comments Error',
  CREATE_SUMMARY_COMMENT = '[Comments] Create Summary Comments',
  CREATE_SUMMARY_COMMENT_SUCCESS = '[Comments] Create Summary Comments Success',
  CREATE_SUMMARY_COMMENT_ERROR = '[Comments] Create Summary Comments Error',
}

type SummaryCommentsErrorPayload = any;

interface CreateSummaryCommentPayload {
  commentableId: number;
  commentBody: string;
}

export class GetSummaryComments implements FsaAction<number> {
  readonly type = SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS;
  error = false;

  constructor(public payload: number) {}
}

export class GetSummaryCommentsSucccess implements FsaAction<Comment[]> {
  readonly type = SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS_SUCCESS;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetSummaryCommentsError
  implements FsaAction<SummaryCommentsErrorPayload> {
  readonly type = SummaryCommentsActionTypes.GET_SUMMARY_COMMENTS_ERROR;
  error = true;

  constructor(public payload: SummaryCommentsErrorPayload) {}
}

export class CreateSummaryComment
  implements FsaAction<CreateSummaryCommentPayload> {
  readonly type = SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT;
  error = false;

  constructor(public payload: CreateSummaryCommentPayload) {}
}

export class CreateSummaryCommentSuccess implements FsaAction<Comment> {
  readonly type = SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT_SUCCESS;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreateSummaryCommentError
  implements FsaAction<SummaryCommentsErrorPayload> {
  readonly type = SummaryCommentsActionTypes.CREATE_SUMMARY_COMMENT_ERROR;
  error = true;

  constructor(public payload: SummaryCommentsErrorPayload) {}
}

export type SummaryCommentsAction =
  | GetSummaryComments
  | GetSummaryCommentsSucccess
  | GetSummaryCommentsError
  | CreateSummaryComment
  | CreateSummaryCommentSuccess
  | CreateSummaryCommentError;

@Injectable()
export class SummaryCommentsActions {
  constructor(private store: Store<SummaryCommentsState>) {}

  getSummaryComments(id: number) {
    this.store.dispatch(new GetSummaryComments(id));
  }

  createSummaryComment(commentableId: number, commentBody: string) {
    this.store.dispatch(
      new CreateSummaryComment({ commentableId, commentBody }),
    );
  }
}
