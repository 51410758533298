import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omgPhoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(number: string): string {
    if (!number) {
      return number;
    }
    if (number.match(/[^0-9]/)) {
      return number;
    }
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
  }
}
