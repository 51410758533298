<div [ngClass]="'p-component p-editor-container'"
     [class]="styleClass">
  <div class="p-editor-toolbar">
    <span class="ql-formats">
      <button class="ql-bold"
              title="Bold"
              tabindex="-1"></button>
      <button *ngIf="allowHyperlinks"
              class="ql-link"
              title="Link"
              tabindex="-1"></button>
      <button class="ql-list"
              value="ordered"
              title="Ordered List"
              tabindex="-1"></button>
      <button class="ql-list"
              value="bullet"
              title="Bullet List"
              tabindex="-1"></button>
    </span>
  </div>
  <div class="p-editor-content"
       [ngClass]="{'marked': useMarkdownStyling}"
       [ngStyle]="style"></div>
</div>
