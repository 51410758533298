// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';

import { random } from '@app/utils';

@Component({
  selector: 'omg-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {
  @Input() shape: 'rectangle' | 'circle' = 'rectangle';
  @Input() size: string;
  @Input() height: string;
  @Input() width: string;
  @Input() borderRadius: string;
  @Input() styleClass = '';
  @Input() style: any;
  @Input() randomWidthPercentage = false;
  @Input() minRandomWidth = 0;
  @Input() maxRandomWidth = 100;

  ngOnInit() {
    if (this.randomWidthPercentage) {
      const widthPercentage = Math.floor(
        random(this.minRandomWidth, this.maxRandomWidth),
      );
      this.width = `${widthPercentage}%`;
    }
  }
}
