<div [formGroup]="form.controls">
  <span><span class="label">Instructions</span> (
    <button omgButton
            type="button"
            class="-plain-text"
            variant="link"
            (click)="onUseFreeTextClick()"
            data-cy="regimen-editor-use-free-text-btn">
      use free text
    </button> )</span>
  <div om-layout
       om-layout-align="start center"
       om-layout-gutter>
    <label om-layout
           class="plain-text"
           om-layout-align="space-between center"
           om-layout-gutter>
      <input omgInputText
             class="-small -flush-bottom"
             type="text"
             (change)="onDoseChange($event)"
             [formControl]="form.controls.get('dose')"
             data-cy="regimen-editor-dosage-input" />
      <span om-flex>
        {{ dosingUnitOfMeasure }} {{ dispensable?.clinicalRoute?.description }}
      </span>
    </label>

    <omg-auto-complete #frequencyIntervalInput
                       class="-flush-bottom"
                       omgFocusOn="frequencyIntervalId"
                       (focusOnRequest)="frequencyIntervalInput.focus()"
                       [autoWidth]="true"
                       (changeAutocomplete)="onFrequencyIntervalIdChange($event)"
                       [formControl]="form.controls.get('frequencyIntervalId')"
                       [items]="frequencyIntervals"
                       placeholder="Select frequency"
                       [clearable]="false"
                       bindValue="id"
                       bindLabel="textShort"
                       trackByKey="value"
                       data-cy="regimen-editor-frequency-interval-dropdown">
    </omg-auto-complete>

    <button omgButton
            type="button"
            variant="link"
            (click)="specifyDuration()"
            *ngIf="!editingDuration"
            data-cy="regimen-editor-specify-duration-btn">
      Specify Duration
    </button>

    <label *ngIf="editingDuration"
           class="plain-text"> for
      <input #durationDaysInput
             omgFocusOn="durationDays"
             (focusOnRequest)="durationDaysInput.focus()"
             type="number"
             omgInputNumber
             min="0"
             class="-small -flush-bottom"
             placeholder="days"
             (change)="onDurationDaysChange($event)"
             [formControl]="form.controls.get('durationDays')"
             data-cy="regimen-editor-duration-days-input" />
      <ng-container [ngPlural]="form.controls.get('durationDays').value || ''">
        <ng-template ngPluralCase="=1"> day</ng-template>
        <ng-template ngPluralCase="other"> days</ng-template>
      </ng-container>
    </label>
  </div>
</div>
