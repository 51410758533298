import { Component } from '@angular/core';

@Component({
  selector: 'omg-app-shell',
  templateUrl: 'app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent {
  constructor() {}
}
