<div *ngIf="chartViewers$ | ngrxPush as visitors"
     om-layout>
  <div *ngFor="let visitor of visitors; let i = index; trackBy: trackByFn">
    <div *ngIf="i < 3"
         [ngStyle]="{ 'z-index': 1004 - i, position: 'relative' }">
      <omg-internal-user-info [providerId]="visitor.internalUserId"
                              placement="bottom"
                              offsetX="14">
        <omg-profile-image-bubble [url]="visitor.profileImageURL"
                                  [initials]="visitor.initials"
                                  [colorCombo]="visitor.internalUserId"
                                  [overlay]="true"></omg-profile-image-bubble>
      </omg-internal-user-info>
    </div>
  </div>
  <div *ngIf="visitors.length > 3"
       [ngStyle]="{ 'z-index': 1000, position: 'relative' }">
    <omg-profile-image-bubble [overlay]="true"
                              initials="+{{ visitors.length - 3 }}"></omg-profile-image-bubble>
  </div>
</div>
