import { Component, Input } from '@angular/core';

export type ToastPositions =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'top-center'
  | 'center';

@Component({
  selector: 'omg-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() position: ToastPositions = 'top-center';
}
