<div class="data-table">
  <omg-section-title [altBackgroundColor]="true">
    <!-- Infant Growth Measurements -->
    <span om-flex="10"
          *ngIf="showInfantVitals">wt</span>
    <span om-flex="10"
          *ngIf="showInfantVitals">ht</span>
    <span om-flex="10"
          *ngIf="showInfantVitals">head</span>

    <span om-flex="10">sys</span>
    <span om-flex="10">dia</span>
    <span om-flex="10">HR</span>
    <span om-flex="8">RR</span>
    <span om-flex="12">temp</span>

    <!-- Adult Measurements -->
    <span om-flex="10"
          *ngIf="!showInfantVitals">wt</span>
    <span om-flex="10"
          *ngIf="!showInfantVitals">ht</span>
    <span om-flex="10"
          *ngIf="!showInfantVitals">BMI</span>

    <span om-flex="10">SpO₂</span>
    <span om-flex="10">FiO₂</span>
  </omg-section-title>
  <div class="row">
    <!-- Infant Growth Measurements -->
    <span om-flex="10"
          *ngIf="showInfantVitals">
      {{ vitals.infantWeight?.value }} {{ vitals.infantWeight?.value ? 'kg' : '' }}
    </span>
    <span om-flex="10"
          *ngIf="showInfantVitals">
      {{ vitals.height?.value }} {{ vitals.height?.value ? 'in': '' }}
    </span>
    <span om-flex="10"
          *ngIf="showInfantVitals">
      {{ vitals.headCircumference.value }} {{ vitals.head?.value ? 'cm': '' }}
    </span>

    <span om-flex="10">{{ vitals.systolicPressure?.value }}</span>
    <span om-flex="10">{{ vitals.diastolicPressure?.value }}</span>
    <span om-flex="10">{{ vitals.heartRate?.value }}</span>
    <span om-flex="8">{{ vitals.respiratoryRate?.value }}</span>
    <span om-flex="12">{{ vitals.temperature?.value }} {{ vitals.temperature?.value ? '°F' : '' }}</span>

    <!-- Adult BMI Measurements -->
    <span om-flex="10"
          *ngIf="!showInfantVitals">
      {{ vitals.weight?.value }} {{ vitals.weight?.value ? 'lb' : '' }}
    </span>
    <span om-flex="10"
          *ngIf="!showInfantVitals">
      {{ vitals.height?.value }} {{ vitals.height?.value ? 'in': '' }}
    </span>
    <span om-flex="10"
          *ngIf="!showInfantVitals">
      {{ bmi | number: '1.0-1' }}
    </span>

    <span om-flex="10">{{ vitals.pulseOximetry?.value }}</span>
    <span om-flex="10">{{ vitals.oxygenConcentration?.value }}</span>
  </div>
</div>
