<div class="omg-popover"
     [ngClass]="placement"
     [ngStyle]="{ width: width + 'px' }">
  <div class="arrow"></div>

  <omg-section-title *ngIf="title">
    {{ title }}
  </omg-section-title>
  <div class="popover-content"
       omgTheme>
    <ng-container [ngSwitch]="renderMethod">
      <div *ngSwitchCase="'text'"
           class="popover-text-content"
           [innerHTML]="content"></div>
      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
