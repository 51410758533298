// @ts-strict-ignore
import { ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject, throwError } from 'rxjs';
import {
  distinct,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { mapDecimalValidationErrors } from '@app/modules/rx-cart/shared/rx-cart-utils';
import { FormModel, wholeNumberValidator } from '@app/shared';
import { DynamicFormGroup } from '@app/utils/forms/base';

import { RenewalActions, RenewalSelectors } from '../store';
import { calculateEarliestFillDate, isValidPrescriber } from './renewal-utils';
import { Renewal, RenewalCartState } from './renewals.type';

const mapSaveError = error => (!!error ? Object.values(error) : null);

export class RenewalForm extends DynamicFormGroup {
  model: FormModel;
  unsubscribe$ = new Subject<void>();
  updateErrors: any[];

  packageSizeIdRequiredValidator: ValidatorFn = value => {
    if (
      this.renewal &&
      this.renewal.packageOptions &&
      this.renewal.packageOptions.defaultOption &&
      this.renewal.packageOptions.defaultOption.matchedOption &&
      !value
    ) {
      return { required: true };
    }

    return null;
  };

  notesToPharmacistRequiredValidator: ValidatorFn = value => {
    if (this.renewal.requiresNoteOfMedicalNeed && !value) {
      return {
        required: true,
      };
    }
    return null;
  };

  constructor(
    private actions: RenewalActions,
    private selectors: RenewalSelectors,
    public renewal: Renewal,
    private profileId?: number,
  ) {
    super();
    this.addControls();
    this.buildFormModel();
    this.setPrescriberId();
    this.setupFormListeners();
  }

  /* istanbul ignore next */
  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  update(changes: Partial<Renewal>): Observable<Renewal> {
    this.actions.update({ id: changes.id, changes });

    return this.selectors.loading.pipe(
      filter(loading => !loading),
      switchMap(() => this.selectors.error),
      map(state => (!!state && state.error && state.error.errors) || null),
      tap(error => this.setUpdateErrors(error)),
      switchMap(stateError =>
        !!stateError
          ? throwError(stateError)
          : this.selectors.getById(changes.id),
      ),
    );
  }

  private setUpdateErrors(error) {
    const formErrors = this.getFormErrors();
    this.updateErrors = formErrors.concat(mapSaveError(error) || []);
  }

  private getFormErrors = (): any[] =>
    mapDecimalValidationErrors([
      { label: 'Quantity', control: this.controls.get('approvedQuantity') },
      { label: 'Total Fills', control: this.controls.get('approvedFills') },
    ]);

  private buildFormModel() {
    this.model = new FormModel(this.controls, {
      saveFunction: () => this.update(this.value),
      saveInvalid: true,
      autosaveDelay: 200,
      mapSaveError,
    });
  }

  private addControls() {
    const controls: {
      name: string;
      validators?: ValidatorFn[];
      defaultValue?: any;
    }[] = [
      { name: 'id' },
      {
        name: 'approvedQuantity',
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(this.renewal.maxApprovedQuantity),
          wholeNumberValidator(),
        ],
      },
      {
        name: 'approvedFills',
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(this.renewal.dispensableMaxTotalFills),
          wholeNumberValidator(),
        ],
      },
      {
        name: 'approvedMedicationPackageSizeId',

        validators: [this.packageSizeIdRequiredValidator],
      },
      {
        name: 'notesToPharmacist',
        validators: [this.notesToPharmacistRequiredValidator],
      },
      { name: 'earliestFillDate' },
      { name: 'dispenseAsWritten' },
      { name: 'className' },
      { name: 'cartState' },
      { name: 'denialReason' },
      { name: 'notesToPharmacist' },
      {
        name: 'prescriberId',
        validators: [Validators.required],
        defaultValue: null,
      },
      ,
    ];

    controls.forEach(control =>
      this.addControl({
        name: control.name,
        defaultValue: control.defaultValue || this.renewal[control.name],
        validators: control.validators,
      }),
    );

    this.addControl({
      name: 'prescribingCredentialId',
      validators: [Validators.required],
      defaultValue: null,
    });
  }

  private setPrescriberId() {
    const control = this.controls.get('prescriberId');

    if (isValidPrescriber(this.profileId, this.renewal.validPrescribers)) {
      control.patchValue(this.profileId, { emitEvent: false });
    } else if (
      this.renewal.validPrescribers &&
      this.renewal.validPrescribers[0]
    ) {
      control.patchValue(this.renewal.validPrescribers[0].id, {
        emitEvent: false,
      });
    }

    this.update({
      prescriberId: control.value,
      id: this.renewal.id,
      className: this.renewal.className,
    });

    this.selectors.loading
      .pipe(
        filter(loading => !loading),
        take(1),
      )
      .subscribe(() => this.actions.loadPrescriberCredentials(this.renewal));
  }

  private listenToCartState() {
    this.controls
      .get('cartState')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter(state => state === RenewalCartState.approved),
        withLatestFrom(
          this.selectors.lastApprovedSiblingFillDate(
            this.renewal.medicationRouteId,
          ),
        ),
      )
      .subscribe(([state, sibling]: [RenewalCartState, Date]) => {
        if (this.renewal.dispensableRestrictedControlledSubstance) {
          this.controls.get('earliestFillDate').enable();
          this.controls
            .get('earliestFillDate')
            .patchValue(
              calculateEarliestFillDate(
                sibling,
                this.controls.get('earliestFillDate').value,
              ),
            );
        }
      });
  }

  private setupFormListeners() {
    this.listenToCartState();
    this.getPrescriberCredentialsWhenPrescriberChanges();
  }

  private getPrescriberCredentialsWhenPrescriberChanges() {
    this.selectors.currentRenewal
      .pipe(
        filter(renewal => !!renewal?.prescriber),
        takeUntil(this.unsubscribe$),
        distinct(renewal => renewal.prescriber.id),
      )
      .subscribe(renewal => this.actions.loadPrescriberCredentials(renewal));

    this.selectors
      .prescribingCredentialsByRenewalId(this.renewal.id)
      .pipe(
        filter(credentials => !!credentials && credentials.id),
        takeUntil(this.unsubscribe$),
        distinct(credentials => credentials.id),
      )
      .subscribe(credentials =>
        this.controls.get('prescribingCredentialId').patchValue(credentials.id),
      );
  }
}
