// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { StateActions } from '@app/core/store/shared/state';

import {
  Procedure,
  ProcedureCapture,
  ProcedureCaptureFormValue,
} from '../shared/historical-procedure-capture.type';

export enum HistoricalProcedureCaptureActionTypes {
  GetByDocumentId = '[HistoricalProcedureCapture] Get By Document Id',
  GetByDocumentIdSuccess = '[HistoricalProcedureCapture] Get By Document Id Success',
  GetByDocumentIdError = '[HistoricalProcedureCapture] Get By Document Id Error',
  ExpandForm = '[HistoricalProcedureCapture] Expand Form',
  CollapseForm = '[HistoricalProcedureCapture] Collapse Form',
  ToggleForm = '[HistoricalProcedureCapture] Toggle Form',
  VerifyProcedure = '[HistoricalProcedureCapture] Verify Procedure',
  VerifyProcedureSuccess = '[HistoricalProcedureCapture] Verify Procedure Success',
  VerifyProcedureError = '[HistoricalProcedureCapture] Verify Procedure Error',
}

export class GetByDocumentId {
  readonly type = HistoricalProcedureCaptureActionTypes.GetByDocumentId;

  constructor(public documentId: number) {}
}

export class GetByDocumentIdSuccess {
  readonly type = HistoricalProcedureCaptureActionTypes.GetByDocumentIdSuccess;

  constructor(public procedure: Procedure, public documentId: number) {}
}

export class GetByDocumentIdError {
  readonly type = HistoricalProcedureCaptureActionTypes.GetByDocumentIdError;

  constructor(public payload: any, public documentId: number) {}
}

export class VerifyProcedure {
  readonly type = HistoricalProcedureCaptureActionTypes.VerifyProcedure;

  constructor(
    public payload: ProcedureCaptureFormValue,
    public documentId: number,
  ) {}
}

export class VerifyProcedureSuccess {
  readonly type = HistoricalProcedureCaptureActionTypes.VerifyProcedureSuccess;

  constructor(public procedure: Procedure, public documentId: number) {}
}

export class VerifyProcedureError {
  readonly type = HistoricalProcedureCaptureActionTypes.VerifyProcedureError;

  constructor(public payload: any, public documentId: number) {}
}

export class ExpandForm {
  readonly type = HistoricalProcedureCaptureActionTypes.ExpandForm;

  constructor(public documentId: number) {}
}

export class CollapseForm {
  readonly type = HistoricalProcedureCaptureActionTypes.CollapseForm;

  constructor(public documentId: number) {}
}

export class ToggleForm {
  readonly type = HistoricalProcedureCaptureActionTypes.ToggleForm;

  constructor(public documentId: number) {}
}

export type HistoricalProcedureCaptureAction =
  | GetByDocumentId
  | GetByDocumentIdSuccess
  | GetByDocumentIdError
  | ExpandForm
  | CollapseForm
  | ToggleForm
  | VerifyProcedure
  | VerifyProcedureSuccess
  | VerifyProcedureError;

export class HistoricalProcedureCaptureActions extends StateActions<Procedure> {
  getByDocumentId(documentId: number) {
    this.store.dispatch(new GetByDocumentId(documentId));
  }

  expandForm(documentId: number) {
    this.store.dispatch(new ExpandForm(documentId));
  }

  collapseForm(documentId: number) {
    this.store.dispatch(new CollapseForm(documentId));
  }

  toggleForm(documentId: number) {
    this.store.dispatch(new ToggleForm(documentId));
  }

  verifyProcedure(form: ProcedureCaptureFormValue, documentId: number): void {
    this.store.dispatch(new VerifyProcedure(form, documentId));
  }
}
