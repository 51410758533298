import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { initializeSentry } from '@app/core/errors/sentry';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

initializeSentry();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    Sentry.captureException(err);
    console.error(err);
  });
