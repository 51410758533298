// @ts-strict-ignore
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { HelpRequestService } from '@app/modules/help-request/help-request.service';
import { DropdownItem } from '@app/shared';
import { DynamicFormGroup } from '@app/utils/forms/base';

export class OrderHelpRequestForm extends DynamicFormGroup {
  maxDetailsLength = 500;
  helpFlowTypes: DropdownItem[];
  urgencyLevels: DropdownItem[] = [
    {
      label: 'Within 24 hours 💚',
      value: 'Within 24 hours :green_heart:',
    },
    { label: 'Within an hour 🚨', value: 'Within an hour :rotating_light:' },
    {
      label: 'Within 3-4 hours 💛',
      value: 'Within 3-4 hours :yellow_heart:',
    },
  ];

  constructor(
    private helpRequestService: HelpRequestService,
    private unsubscribe$: Subject<void>,
  ) {
    super();
    this.addControls();
    this.subscribeToStreams();
  }

  private subscribeToStreams() {
    this.helpRequestService
      .getHelpFlowTypes()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(types => (this.helpFlowTypes = types)),
        tap(_types => this.updateHelpflowType('request_order_results')),
      )
      .subscribe();
    this.helpRequestService
      .getLicensingBodyAndStates()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ licensingBodyId }) => {
          this.updateLicensingBody(licensingBodyId);
        }),
      )
      .subscribe();
  }

  private updateLicensingBody(id: string) {
    this.controls.get('licensingBodyId').patchValue(id);
  }

  private updateHelpflowType(value: string) {
    this.controls.get('helpflowType').patchValue(value);
  }

  private addControls() {
    const controls = [
      { name: 'helpflowType', validators: [Validators.required] },
      { name: 'urgency' },
      { name: 'details' },
      { name: 'callerOnTheLine', defaultValue: true },
      { name: 'facility' },
      { name: 'licensingBodyId' },
    ];
    controls.forEach(control => this.addControl(control));
    this.controls.get('urgency').patchValue(this.urgencyLevels[0].value);
  }
}
