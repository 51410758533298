<div om-layout
     om-layout-gutter
     om-layout="vertical"
     class="padding-normal">
  <span class="clickable om-icon icon-close"></span>
  <div>
    <b>Why didn't this lab autoreport?</b>
    <p> {{ numFailedChecks }} of {{ autoReportableChecks.length }} autoreport checks failed</p>
  </div>
  <div>
    <b>Rules for autoreporting</b>
    <div om-layout
         om-layout="vertical">
      <div om-layout
           om-layout-gutter
           class="auto-reportability-check"
           [ngClass]="check.evaluation ? '-valid' : '-error'"
           *ngFor="let check of autoReportableChecks">
        <span om-flex="5">
          <i class="fa"
             [ngClass]="check.evaluation ? 'fa-check' : 'fa-times'"></i>
        </span>
        <span> {{ check.description }}</span>
      </div>
    </div>
  </div>
</div>
