import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omgPharmacyAddress',
})
export class PharmacyAddressPipe implements PipeTransform {
  /**
   * @TODO: add address type in once newRx store/types are created
   */
  transform(address) {
    const displayAddress =
      address.displayAddressStreet && address.displayAddressStreet !== 'Unknown'
        ? `${address.displayAddressStreet} `
        : '';
    return `${displayAddress}${address.city}, ${address.state} ${address.zip}`;
  }
}
