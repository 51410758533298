import { formatDate } from '../../../utils/dates';
import { VisitProcedure } from './visit-procedure.type';

export function visitProcedureTimeBasedLabel(visitProcedure: VisitProcedure) {
  if (
    visitProcedure.requiredParams.includes('total_time') &&
    visitProcedure.timeBasedMinutes
  ) {
    return `${visitProcedure.timeBasedMinutes} minutes spent on ${
      visitProcedure.timeBasedServiceName
    } on ${formatDate(
      visitProcedure.serviceTicket.appointment.startAt,
      'MM/dd/yyyy',
    )}`;
  } else if (
    visitProcedure.requiredParams.includes('counseling_threshold_met')
  ) {
    const visitProcedureRule = visitProcedure.options.find(
      o => o.id === visitProcedure.visitProcedureRuleId,
    );
    return `>50% of this ${visitProcedureRule?.lengthOfVisit} min visit was spent on counseling & care coordination`;
  } else {
    return '';
  }
}
