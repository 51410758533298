import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { VitalsByType } from '@app/modules/vitals-data/shared/vitals-data.type';
import { getBmi } from '@app/modules/vitals-data/shared/vitals-utils';

@Component({
  selector: 'omg-summary-measurements-table',
  templateUrl: './summary-measurements-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryMeasurementsTableComponent implements OnChanges {
  @Input() vitals: VitalsByType;
  @Input() showInfantVitals: boolean;

  bmi: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vitals) {
      const weight = this.vitals.weight || { value: null };
      const height = this.vitals.height || { value: null };
      const weightValue = weight.value || 0;
      const heightValue = height.value || 0;

      this.bmi = getBmi(weightValue, heightValue);
    }
  }
}
