<hr class="-spaced" />
<div om-layout="vertical"
     *ngIf="address">
  <strong>Destination</strong>
  <strong>{{ pharmacy.name }}</strong>
  <div *ngIf="displayAddressStreet">
    {{ address.displayAddressStreet }}
  </div>
  <div>
    {{ [ address.city, address.state].join(', ') }}
    <span *ngIf="showZip">
      {{address.zip}}
    </span>
  </div>
  <div *ngIf="pharmacy.workPhoneNumber">
    ph: {{ pharmacy.workPhoneNumber | omgPhoneNumber }}
  </div>
</div>
