import { HttpErrorResponse } from '@angular/common/http';
// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@app/core/api/api.service';

import { MLFeedbackDTO } from './note-api.type';
import { MLNoteRetitleFeedback, Note } from './note.type';

@Injectable({
  providedIn: 'root',
})
export class MLNoteRetitleFeedbackApi {
  private readonly base = '/v2/admin/ml_feedbacks';

  constructor(private api: ApiService) {}

  saveFormFeedback(feedback: MLNoteRetitleFeedback): Observable<MLFeedbackDTO> {
    const payload: Partial<MLFeedbackDTO> = {
      associated_type: 'MlNoteSuggestion',
      associated_id: feedback.mlSuggestionId,
      feedback_type: 'note_retitling',
      feedback_text: feedback.otherFeedback,
      metadata: {
        specialty: feedback.specialty,
        other_specialty: feedback.otherSpecialty,
        note_id: feedback.noteId,
      },
    };
    return this.api
      .save<MLFeedbackDTO>(this.base, payload, {}, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  saveDismissedFeedback(note: Note): Observable<MLFeedbackDTO> {
    const payload: Partial<MLFeedbackDTO> = {
      associated_type: 'MlNoteSuggestion',
      associated_id: note.mlNoteSuggestion.id,
      feedback_type: 'note_retitling',
      metadata: {
        note_id: note.id,
        action: 'dismissed',
      },
    };
    return this.api
      .save<MLFeedbackDTO>(this.base, payload, {}, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
}
