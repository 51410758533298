import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'omg-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
})
export class FileUploadButtonComponent {
  @Output() fileChosen = new EventEmitter<File[]>();

  onFileSelected(fileList: FileList) {
    this.fileChosen.emit(Array.from(fileList));
  }
}
