// @ts-strict-ignore
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { httpStatus } from '@app/utils';

import { getHttpErrorMessage } from '../errors/error-utils';
import { LoggerService } from '../logger';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private logger: LoggerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    this.logError(error);
    return throwError(error);
  }

  private logError(error: HttpErrorResponse) {
    if (error.status >= httpStatus.INTERNAL_SERVER_ERROR) {
      this.logger.error(error);
    } else if (error.status !== httpStatus.NOT_FOUND) {
      this.logger.log(getHttpErrorMessage(error));
    }
  }
}
