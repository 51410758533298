import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'omg-prescription-pdf-link',
  templateUrl: './prescription-pdf-link.component.html',
  styleUrls: ['./prescription-pdf-link.component.css'],
})
export class PrescriptionPdfLinkComponent implements OnInit {
  @Input() transmissionId: number;
  pdfLink: string;

  constructor() {}

  ngOnInit() {
    this.setPdfLink();
  }

  setPdfLink() {
    this.pdfLink = `/api/v2/admin/medication_prescription_transmissions/${this.transmissionId}/pdf`;
  }
}
