<div class="procedure-suggestion-explainability">
  <div class="procedure-suggestion-explainability-header">How procedure suggestions work <span class="om-icon clickable icon-dismiss -gray procedure-suggestion-explainability-dismiss"></span></div>
  <div class="procedure-suggestion-explainability-body">Procedure suggestions use machine learning to compare
    your A&P
    text with historical A&Ps. Your feedback helps
    improve the accuracy of this tool. Read more on <a class="procedure-suggestion-explainability-link"
       href="https://sites.google.com/onemedical.com/electronic-service-tickets/faqs/suggestion-faq"
       target="_blank">our
      wiki</a>.
  </div>
  <div class="predictive-text">
    <div>Most predicitive words: {{ predictiveWords }}</div>
    <div>{{ confidence }}</div>
  </div>
</div>
