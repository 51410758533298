import { createSelector } from '@ngrx/store';

// NOTE: This should could be refactored to use the generic StoreMetadata
// via the createStoreMetadataAdapter factory function.

export interface EntityMetadataProps<T> {
  pending: boolean;
  markReadyToSignPending?: boolean;
  error: any;
}

export interface EntityMetadataMap<T> {
  [k: string]: EntityMetadataProps<T>;
}

export interface EntityMetadataState<T> {
  metadata: EntityMetadataMap<T>;
}

export interface EntityWithMetadata<T> extends EntityMetadataProps<T> {
  entity: T;
}

const metadataStatePath = 'metadata';
const initialEntityState = { pending: false, error: null };

/**
 * Gets the initial state for the entity metadata state slice
 *
 * @param state The EntityState<T> + metadata state slice
 */
export const getEntityMetadataInitialState = <T>(
  state,
): EntityMetadataState<T> => ({
  ...state,
  metadata: {},
});

/**
 * Updates a particular entities metadata state
 *
 * @param id The entity key
 * @param changes The changes to reflect in the entities metadata
 * @param state The EntityState<T> + metadata state slice
 */
export const updateEntityMetadata = <T>(
  id: string | number,
  changes: Partial<EntityMetadataProps<T>>,
  state: EntityMetadataState<T>,
) => ({
  [metadataStatePath]: {
    ...state.metadata,
    [id]: {
      ...state[id],
      ...initialEntityState,
      ...changes,
    },
  },
});

/**
 * Resets a particular entities metadata state
 *
 * @param id The entity key
 * @param state The EntityState<T> + metadata state slice
 */
export const resetEntityMetadata = <T>(
  id: string | number,
  state: EntityMetadataState<T>,
) => ({
  [metadataStatePath]: {
    ...state.metadata,
    [id]: {
      pending: false,
      error: null,
    },
  },
});

/**
 * Creates a series of helpers to select entity metadata
 *
 * @param selectEntityState The EntityState<T> base selector
 * @param selectEntityById The selector that selects an entity by it's key
 */
export const createEntityMetadataSelectors = <T>(
  selectEntityState: (state: any) => EntityMetadataState<T>,
  selectEntityById: (state: any, props: { id: any }) => T,
) => {
  const selectEntityMetadataState = createSelector(
    selectEntityState,
    state => state.metadata,
  );

  const selectEntityMetadata = createSelector(
    selectEntityMetadataState,
    (state, { id }) => state[id] as EntityMetadataProps<T>,
  );

  const selectEntityWithMetadata = createSelector(
    selectEntityById,
    selectEntityMetadata,
    (entity, status): EntityWithMetadata<T> => ({ entity, ...status }),
  );

  const selectEntityError = createSelector(
    selectEntityMetadata,
    status => status && status.error,
  );

  return {
    selectEntityMetadataState,
    selectEntityMetadata,
    selectEntityWithMetadata,
    selectEntityError,
  };
};
