<omg-section-title>
  Transmission
</omg-section-title>
<div class="padding-normal-vertical"
     om-layout="vertical"
     om-layout-gutter>
  <div class="padding-normal-horizontal"
       *ngFor="let event of history; trackBy: trackByFn"
       om-layout
       om-layout-fill>
    <span om-flex="50">
      <span *ngIf="event.type === 'email'">
        Secure Message sent to {{emailRecipient(event)}}
      </span>
      <span *ngIf="event.type === 'dm'">
        Direct Message sent to {{event.recipient[0].displayName}}
      </span>
      <span *ngIf="event.type === 'redox'">
        Electronic Interface Order sent to {{event.recipient.displayName}}
      </span>
      <span *ngIf="event.type !== 'email' && event.type !== 'dm' && event.type !== 'redox'">
        Fax sent to
        <span *ngIf="event.recipientPhoneNumber">{{event.recipientPhoneNumber | omgPhoneNumber}}</span>
        <span *ngIf="!event.recipientPhoneNumber"><i>unknown number</i></span>
      </span>
    </span>
    <span om-flex="20">
      <span *ngIf="event.type === 'email'"
            class="ng-binding capitalize">Succeeded</span>
      <span *ngIf="event.type !== 'email'"
            class="ng-binding capitalize">{{event.state}}</span>
    </span>
    <span om-flex="15"
          class="caption right-align">{{event.createdAt | omgDate: '12Time'}}</span>
    <span om-flex="15"
          class="caption right-align">{{event.createdAt | omgDate: '2y'}}</span>
  </div>
</div>
