import { Directive, HostListener } from '@angular/core';

import { InputNumberDirective } from './input-number.directive';

@Directive({
  selector: 'input[omgInputNumber][omgTrimTrailingZeros][type=number]',
})
export class TrimTrailingZerosDirective extends InputNumberDirective {
  @HostListener('blur', ['$event']) onBlur($event: any) {
    if ($event.target.value) {
      $event.target.value = +$event.target.value;
    }
  }
}
