<div om-layout
     om-layout-gutter-large>
  <div>
    <div class="label">Issued On</div>
    <div>{{ today | omgDate: '2y' }}</div>
  </div>
  <omg-checkbox label="Notify patient"
                [formControl]="notifyPatient"
                *ngIf="!!notifyPatient"
                omgTooltip
                tooltipText="Patient has opted out of receiving clinical email."
                [showTooltip]="disabled"
                tooltipPosition="top"
                data-cy="notify-patient-checkbox"></omg-checkbox>

</div>
