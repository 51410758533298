<div *ngIf="safeUrl$ | ngrxPush as safeUrl; else loading">
  <embed [src]="safeUrl"
         type="application/pdf">
  <iframe height="100%"
          type="application/pdf"
          hidden
          [src]="safeUrl"></iframe>
</div>
<ng-template #loading>
  <div class="empty-tab-message">Loading document..</div>
</ng-template>
