<omg-card class="workspace-card"
          [loading]="false"
          [empty]="false">
  <omg-card-title heading="Rx Change Request">
  </omg-card-title>
  <ng-template #loadedRef>
    <omg-card-body>
      <router-outlet></router-outlet>
    </omg-card-body>
    <omg-card-footer>
    </omg-card-footer>
  </ng-template>
  <ng-template #loadingRef>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
  <ng-template #emptyRef>
    No Pending Rx Change Requests
  </ng-template>
</omg-card>
