import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'omg-pager',
  templateUrl: './pager.component.html',
})
export class PagerComponent implements OnInit {
  @Input() currentPage = 1;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;

  @Output() pageChanged = new EventEmitter<number>();

  numberOfPages = 1;

  ngOnInit() {
    if (this.itemsPerPage && this.totalItems) {
      this.numberOfPages = Math.ceil(this.totalItems / this.itemsPerPage);
    }
  }

  onPreviousPage() {
    this.currentPage -= 1;
    this.pageChanged.emit(this.currentPage);
  }

  onNextPage() {
    this.currentPage += 1;
    this.pageChanged.emit(this.currentPage);
  }
}
