// @ts-strict-ignore
import { Component, Input } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';

export interface TodoReassignmentInput {
  id: string;
  newAssigneeName: string;
  originalAssigneeName: string;
  labels: string[];
}

@Component({
  selector: 'omg-todo-reassignment',
  templateUrl: './todo-reassignment.component.html',
  styleUrls: ['./todo-reassignment.component.scss'],
})
export class TodoReassignmentComponent {
  @Input() set reassignment({
    id,
    labels,
    newAssigneeName,
    originalAssigneeName,
  }: TodoReassignmentInput) {
    this.tooltipMainMessage =
      `This was routed from ${originalAssigneeName} to ${newAssigneeName}` +
      ` because we found the label${
        labels.length > 1 ? 's' : ''
      }: ${labels.join(', ')}.`;
    this.reassignmentId = id;
  }

  tooltipMainMessage: string;
  feedbackVisible = false;
  reassignmentId: string;

  constructor(private analytics: AnalyticsService) {}

  showFeedback() {
    this.feedbackVisible = true;

    this.analytics.track(AnalyticsEvent.GiveFeedbackClicked, {
      workflow: 'Messages',
      component: 'Message',
      subcomponent: 'Give Feedback button',
    });
  }

  hideFeedback() {
    this.feedbackVisible = false;
  }

  trackTooltipOpened() {
    this.analytics.track(AnalyticsEvent.ToolTipClicked, {
      workflow: 'Messages',
      component: 'Message',
      subcomponent: 'Tool Tip Icon',
    });
  }
}
