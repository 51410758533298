import { MeasurementType } from './measurement-types.type';

export interface VitalsDataMeasurement {
  id: number;
  measurementType: MeasurementType;
  unit: string;
  value: number;
  asMetric?: number;
  metricUnit?: string;
  asImperial?: number;
  imperialUnit?: string;
}

export interface VitalsData {
  id: number;
  collectedAt: string;
  patientAgeAtCollectionInMonths: number;
  comment: string;
  internalUser: string;
  measurements: VitalsDataMeasurement[];
  withings: boolean;
  unsigned: boolean;
  readonly: boolean;
}

export interface VitalsByType {
  [key: string]: VitalsDataMeasurement;
}

export const loincCodes = {
  weight: '29463-7',
  height: '8302-2',
  bloodPressure: '85354-9',
  systolicPressure: '8480-6',
  diastolicPressure: '8462-4',
  respiratoryRate: '9279-1',
  heartRate: '8867-4',
  temperature: '8310-5',
  minutesAsleep: '93832-4',
  dailyActiveMinutes: '74009-2',
  dailySteps: '41950-7',
  bodyMassIndex: '39156-5',
  heightPercentile: '8303-0',
  weightPercentile: '8336-0',
  bmiPercentile: '59576-9',
  headCircumference: '8287-5',
  headCircumferencePercentile: '8289-1',
  infantWeight: '8339-4',
  weightForLengthPercentile: '77606-2',
  pulseOximetry: '59408-5',
  oxygenConcentration: '3150-0',
};

function createVitalTypesByLoincCode(codes) {
  return Object.keys(codes).reduce((result, type) => {
    result[loincCodes[type]] = type;
    return result;
  }, {});
}

export const vitalTypesByLoincCode = createVitalTypesByLoincCode(loincCodes);

function createVitalTypesFromLoincCodes(codes: typeof loincCodes) {
  const types = Object.assign({}, codes);
  Object.keys(types).forEach(type => (types[type] = type));
  return types;
}

export const vitalTypes = createVitalTypesFromLoincCodes(loincCodes);
