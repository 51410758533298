import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { PrivacyConsentStorageService } from '@app/shared/services/privacy-consent-storage.service';

import { loginPath } from './auth-constants';

@Injectable()
export class LogoutGuard {
  constructor(
    private auth: AuthService,
    private privacyConsentService: PrivacyConsentStorageService,
  ) {}

  canActivate() {
    const returnTo = `${window.location.origin}/${loginPath}`;
    this.privacyConsentService.clearConsentCookies();
    this.auth.logout({ logoutParams: { returnTo: returnTo } });
    return true;
  }
}
