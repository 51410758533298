import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'omg-confirm-drawer',
  templateUrl: './confirm-drawer.component.html',
})
export class ConfirmDrawerComponent {
  @Input() confirmationQuestion: string;
  @Input() confirmationQuestionSubheader: string;
  @Input() confirmButtonsFlex = '40';
  @Input() confirmButtonText: string;
  @Input() disabled: boolean;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }
}
