<form name="form"
      [formGroup]="form"
      om-flex="100"
      class="min-modal-width"
      omgTheme>
  <div omgDialogTitleDirective>
    <h5 class="fax-document-title">Fax Document</h5>
    <button type="button"
            class="om-icon icon-close clickable"
            (click)="onClose()"></button>
  </div>
  <div omgDialogContentDirective>
    <div class="padding-normal"
         om-layout="vertical"
         om-layout-gutter>
      <label>Fax Number</label>
      <input autofocus
             type="text"
             class="om-input"
             name="faxNumber"
             placeholder="(111) 111-1111"
             maxlength="14"
             formControlName="faxNumber"
             om-flex="25"
             omgStopEvent="click">
      <div class="alert"
           *ngIf="form.get('faxNumber').errors && form.get('faxNumber').dirty">
        {{ faxNumberControlError }}
      </div>

      <label>
        Cover Page Text (optional)
        <textarea omgTextarea
                  autosize
                  rows="3"
                  formControlName="coverPageText"
                  om-layout-fill></textarea>
      </label>
    </div>
    <div class="action-bar">
      <button omgButton
              variant="primary"
              [disabled]="form.invalid || formSubmitted"
              type="submit"
              (click)="fax()">
        Fax Document
      </button>
    </div>
  </div>
</form>
