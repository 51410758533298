<omg-collapse om-collapse>
  <omg-section-title [altBackgroundColor]="true">
    <div om-layout
         om-layout-fill
         om-layout-align="space-between center">
      {{renewalPositionPhrase$ | ngrxPush}}
      <omg-collapse-toggle om-layout
                           om-layout-align="space-between center"
                           om-layout-gutter-mini
                           class="collapse-with-flex"
                           (click)="toggleComments()">
        <omg-collapsed class="icon-container"
                       disableAnimation="true">
          <span class="om-icon icon-comment clickable"
                data-cy="renewal-comment-btn">
          </span>
        </omg-collapsed>
        <omg-expanded class="icon-container"
                      disableAnimation="true">
          <span class="om-icon icon-comment-filled clickable"
                data-cy="renewal-comment-btn-expand"></span>
        </omg-expanded>
        <span>{{ renewal.totalComments }}</span>
      </omg-collapse-toggle>
    </div>
  </omg-section-title>
  <omg-comments *ngIf="showComments"
                [commentable]="commentable"
                (commentAdded)="updateCommentCount('add')"
                (commentRemoved)="updateCommentCount('remove')"
                data-cy="renewal-comments"></omg-comments>
</omg-collapse>
