<div class="padding-normal"
     om-layout-wrap
     om-layout>
  <omg-change-rx-status-approval></omg-change-rx-status-approval>
  <div om-flex="50"
       class="padding-normal-vertical">
    <div><strong>Authorization Number</strong></div>
    <div>{{ form.controls.get('authNumber')?.value || 'N/A' }} </div>
  </div>
</div>
