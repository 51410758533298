import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InternalUser } from '../internal-user.type';
import { InternalUsersState } from './internal-users.actions';
import { internalUsersStatePath } from './internal-users.reducer';

@Injectable({
  providedIn: 'root',
})
export class InternalUsersSelectors {
  constructor(private store: Store<InternalUsersState>) {}

  getAll(): Observable<{ [key: string]: InternalUser }> {
    return this.store.pipe(select(internalUsersStatePath));
  }

  get(id: string): Observable<InternalUser> {
    return this.store.pipe(
      select(internalUsersStatePath),
      select(users => users[id]),
    );
  }
}
