<ng-container>
  <div #cardTitle
       class="card-title"
       omgTheme
       om-layout
       om-layout-align="space-between center">
    <div omgTheme
         om-layout
         [ngClass]="{'card-expand-chevron' : isExpandable}"
         om-layout-align="center center">
      <button *ngIf="isExpandable"
              omgButton
              variant="invisible"
              omgTooltip
              [tooltipText]="this.tooltipText"
              tooltipPosition="top"
              [ariaLabel]="this.tooltipText"
              (click)="this.collapseProvider.toggle()">
        <span *ngIf="this.collapseProvider.expanded"
              class="om-icon clickable icon-chevron-up"></span>
        <span *ngIf="!this.collapseProvider.expanded"
              class="om-icon clickable icon-chevron-down"></span>
      </button>
      <strong class="text"
              [attr.smallFontSize]="smallFontSize"
              *ngIf="heading">{{ heading }}</strong>
      <strong *ngIf="(badgeCount$ | ngrxPush) > 0"
              class="om-label badge-count">{{ badgeCount$ | ngrxPush }}</strong>
    </div>
    <div om-layout
         om-layout-align="start stretch"
         class="card-title-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
