<omg-section-title>
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Vaccinations</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgStopEvent="click"
          omgTooltip
          tooltipText="DeleteFromNote"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate"
      *ngFor="let vaccination of vaccinations; trackBy: trackByFn">
    <b>{{ vaccination.name }} {{ vaccination.step }}</b> on {{ vaccination.givenOnWithPrecision }}
  </li>
</ul>
