import {
  ConnectionPositionPair,
  HorizontalConnectionPos,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';

export type OverlayPlacement = 'top' | 'left' | 'right' | 'bottom';

export const inverseHorizontalPos = (pos: HorizontalConnectionPos) =>
  pos === 'end' ? 'start' : pos === 'start' ? 'end' : 'center';

export const inverseVerticalPos = (pos: VerticalConnectionPos) =>
  pos === 'top' ? 'bottom' : pos === 'bottom' ? 'top' : 'center';

export const getOriginOverlayPositions = (
  placement: OverlayPlacement,
): ConnectionPositionPair[] => {
  const originX: HorizontalConnectionPos =
    placement === 'left' ? 'start' : placement === 'right' ? 'end' : 'center';
  const originY: VerticalConnectionPos =
    placement === 'top' ? 'top' : placement === 'bottom' ? 'bottom' : 'center';
  const overlayX: HorizontalConnectionPos =
    placement === 'left' ? 'end' : placement === 'right' ? 'start' : 'center';
  const overlayY: VerticalConnectionPos =
    placement === 'top' ? 'bottom' : placement === 'bottom' ? 'top' : 'center';

  const originFallbackX = inverseHorizontalPos(originX);
  const originFallbackY = inverseVerticalPos(originY);
  const overlayFallbackX = inverseHorizontalPos(overlayX);
  const overlayFallbackY = inverseVerticalPos(overlayY);

  return [
    {
      originX,
      originY,
      overlayX,
      overlayY,
    },
    {
      originX: originFallbackX,
      originY: originFallbackY,
      overlayX: overlayFallbackX,
      overlayY: overlayFallbackY,
    },
  ];
};
