// @ts-strict-ignore
/// <reference types="aws-sdk" />
/**
 * Code above is used to get typings working
 * See: https://www.npmjs.com/package/aws-sdk#in-the-browser-1
 */

import * as S3 from 'aws-sdk/clients/s3';

import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GetObjectOutput } from 'aws-sdk/clients/s3';
import { Observable, of, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AwsSessionService } from './aws-session.service';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  private readonly SERVER_SIDE_ENCRYPTION = 'AES256';
  private s3: S3;

  constructor(private awsSession: AwsSessionService) {}

  getURL(key: string, bucket = environment.awsBucket): Observable<string> {
    const getURLObservable = new Subject<string>();

    this.getS3Client().subscribe(client => {
      client.getSignedUrl(
        'getObject',
        { Bucket: bucket, Key: key },
        (err, url) => this.handleError(err, url, getURLObservable),
      );
    });

    return getURLObservable.asObservable();
  }

  getObject(
    key: string,
    bucket = environment.awsBucket,
  ): Observable<GetObjectOutput> {
    const getObjectObservable = new Subject<GetObjectOutput>();

    this.getS3Client().subscribe(client => {
      client.getObject({ Bucket: bucket, Key: key }, (error, data) =>
        this.handleError(error, data, getObjectObservable),
      );
    });

    return getObjectObservable.asObservable();
  }

  copy(
    sourceKey: string,
    sourceBucket: string,
    destKey: string,
  ): Observable<{ sourceBucket: string; destinationKey: string }> {
    const copySubject = new Subject<{
      sourceBucket: string;
      destinationKey: string;
    }>();

    this.getS3Client().subscribe(client => {
      client.copyObject(
        {
          Bucket: environment.awsBucket,
          Key: destKey,
          CopySource: `/${sourceBucket}/${sourceKey}`,
          ServerSideEncryption: this.SERVER_SIDE_ENCRYPTION,
        },
        error =>
          this.handleError(
            error,
            {
              sourceBucket: environment.awsBucket,
              destinationKey: destKey,
            },
            copySubject,
          ),
      );
    });

    return copySubject.asObservable();
  }

  delete(key: string, bucket: string): Observable<S3.DeleteObjectOutput> {
    const deleteSubject = new Subject<S3.DeleteObjectOutput>();

    this.getS3Client().subscribe(client => {
      client.deleteObject({ Bucket: bucket, Key: key }, (error, data) =>
        this.handleError(error, data, deleteSubject),
      );
    });

    return deleteSubject.asObservable();
  }

  upload(key: string, file: File): Observable<S3.ManagedUpload.SendData> {
    const uploadSubject = new Subject<S3.ManagedUpload.SendData>();

    this.getS3Client().subscribe(client => {
      client.upload(
        {
          Bucket: environment.awsBucket,
          Key: key,
          ContentType: file.type,
          ServerSideEncryption: this.SERVER_SIDE_ENCRYPTION,
          Body: file,
        },
        {},
        (error, data) => this.handleError(error, data, uploadSubject),
      );
    });

    return uploadSubject.asObservable();
  }

  getS3Client(): Observable<S3> {
    if (this.s3) {
      return of(this.s3);
    }

    return this.awsSession.get().pipe(
      take(1),
      map(session => {
        this.s3 = new S3({
          accessKeyId: session.credentials.accessKeyId,
          secretAccessKey: session.credentials.secretAccessKey,
          sessionToken: session.credentials.sessionToken,
        });
        return this.s3;
      }),
    );
  }

  private handleError(error, data: any, subscription: Subject<any>): void {
    if (error) {
      subscription.error(error);
    } else {
      subscription.next(data);
      subscription.complete();
    }
  }
}
