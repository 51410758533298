// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PatientMedicationPrescriptionHistoryItem } from '@app/modules/medications/shared/patient-medications.type';

@Component({
  selector: 'omg-change-rx-medication-item',
  templateUrl: './change-rx-medication-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationItemComponent {
  @Input()
  medication: PatientMedicationPrescriptionHistoryItem;
}
