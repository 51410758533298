import { NoteTypeResponse, NoteTypesResponse } from './note-types-api.type';
import { NoteType, NoteTypes, NoteTypeTags } from './note-types.type';

/* istanbul ignore next */
export const mapNoteTypeResponseToEntity = (
  noteType: NoteTypeResponse,
): NoteType => ({
  id: noteType.id,
  name: noteType.name,
  systemName: noteType.system_name,
  tags: noteType.tags as NoteTypeTags[],
});

/* istanbul ignore next */
export const mapNoteTypesResponseToEntity = (
  noteTypes: NoteTypesResponse,
): NoteTypes => ({
  summaryNoteTypes: noteTypes.summary_note_types.map(
    mapNoteTypeResponseToEntity,
  ),
  miscTaskNoteTypes: (noteTypes.misc_task_note_types || []).map(
    mapNoteTypeResponseToEntity,
  ),
  letterNoteTypes: (noteTypes.letter_note_type || []).map(
    mapNoteTypeResponseToEntity,
  ),
});
