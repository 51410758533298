import { Directive } from '@angular/core';

import { FocusService } from './focus.service';

@Directive({
  selector: '[omgFocusProvider]',
  exportAs: 'omgFocusProvider',
})
export class FocusProviderDirective {
  constructor(private focusService: FocusService) {}

  setFocus(key: string) {
    this.focusService.setFocus(key);
  }
}
