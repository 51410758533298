// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/analytics.type';
import { ConfigService } from '@app/core/config';
import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';
import { MessageTemplatesService } from '@app/modules/messaging/shared/message-templates.service';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';
import { RichTextEditorComponent } from '@app/shared/components/rich-text-editor/rich-text-editor.component';

import { SummaryProviderRecommendation } from '../../shared/summaries.type';

@Component({
  selector: 'omg-after-visit-guidance',
  templateUrl: './after-visit-guidance.component.html',
  styleUrls: ['./after-visit-guidance.component.scss'],
})
export class AfterVisitGuidanceComponent {
  @Input() afterVisitGuidance: UntypedFormControl;
  @Input() isAttachable: boolean;
  @Input() summaryProviderRecommendation: SummaryProviderRecommendation;
  @Output() attachmentAdded = new EventEmitter<File>();
  @Output() attachmentDeleted = new EventEmitter<S3Pointer>();

  @ViewChild(RichTextEditorComponent, { static: false })
  editor: RichTextEditorComponent;

  insertTemplateEventProperties: Partial<TrackEventProperties>;
  characterCount: number;
  currentCursorIndex = 0;

  constructor(
    private configService: ConfigService,
    private analytics: AnalyticsService,
    private messagingTemplates: MessageTemplatesService,
  ) {
    this.characterCount = 0;
  }

  setCharacterCount(event: any) {
    if (event.editor) {
      this.characterCount = event.editor.getLength() - 1;
    }
  }

  updateCharacterCount(event: any, doTextChange: boolean = true) {
    this.characterCount = event.textValue.length;
    if (doTextChange) {
      this.onTextChange();
    }
  }

  addAttachments(files: File[]) {
    files.forEach(file => this.attachmentAdded.emit(file));
  }

  deleteAttachment(attachment: S3Pointer) {
    this.attachmentDeleted.emit(attachment);
  }

  get templatesIndex(): string {
    return this.configService.searchIndex('message_templates');
  }

  onApplyTemplate(data: { template: Template; done: Function }) {
    if (!data || !data.template) {
      return;
    }

    this.trackTemplateInsertion(data.template);

    this.messagingTemplates
      .get(data.template.id)
      .pipe(take(1))
      .subscribe(template => {
        this.insertAndSetCursor(this.currentCursorIndex, template);
        data.done();
      });
  }

  onTextChange() {
    const cursorIndex =
      this.editor.quill.getSelection() &&
      this.editor.quill.getSelection().index;
    if (cursorIndex) {
      this.currentCursorIndex = cursorIndex;
    }
  }

  insertAndSetCursor(insertIndex, template) {
    this.editor.insertText(insertIndex, template);
    this.onTextChange();
  }

  private trackTemplateInsertion(template: Template) {
    this.analytics.track(AnalyticsEvent.TemplateInserted, {
      workflow: 'Charting',
      component: 'AfterVisitGuidance',
      subcomponent: 'Insert Template Button',
      templateId: template.id,
      templateName: template.name,
      templateType: template.internal_user_id ? 'Private' : 'Public',
    });
  }
}
