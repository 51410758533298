// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { setup } from '@onemedical/command-palette';
import { take, tap } from 'rxjs';

import { ConfigService } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { filterTruthy } from '@app/utils';

@Injectable({
  providedIn: 'root',
})
export class CommandPaletteService {
  constructor(
    private configService: ConfigService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  init() {
    this.setup();
  }

  public isElementCommandPaletteElement(element: Element) {
    return element.tagName === 'NINJA-KEYS';
  }

  setup = () => {
    setup({
      params: {
        adminUrl: this.configService.environment.adminApp.host,
        onelifeUrl: this.configService.environment.legacyUi.host,
        templateUrl: this.configService.environment.templatesApp.host,
      },
    });
  };
}
