<div class="banner -warning"
     *ngIf="renewals.length > 0"
     om-layout-gutter
     om-layout="vertical"
     data-cy="rx-renewal-toast-warning">
  {{ countOfHandwrittenPrescriptionsPhrase }}
  <ul>
    <li *ngFor="let renewal of renewals">
      {{ renewal.medForDisplay.name }} {{ renewal.medForDisplay.dispensableTextDesc }}
      <strong *ngIf="renewal.rxReferenceNumber">Rx Ref. # {{ renewal.rxReferenceNumber }}</strong> to
      <strong> {{ renewal.pharmacy.name }}</strong>
    </li>
  </ul>
  <div>Remember, to avoid duplicate requests, you must include the Rx Reference numbers on the prescriptions and
    deliver them to the pharmacy.</div>
</div>
