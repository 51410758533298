// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Comment } from '../shared/comments.type';
import { ProcedureInteractionCommentsState } from './procedure-interaction-comments.reducer';

export enum ProcedureInteractionCommentsActionTypes {
  GET_PROCEDURE_INTERACTION_COMMENTS = '[Comments] Get Procedure Interaction Comments',
  GET_PROCEDURE_INTERACTION_COMMENTS_SUCCESS = '[Comments] Get Procedure Interaction Comments Success',
  GET_PROCEDURE_INTERACTION_COMMENTS_ERROR = '[Comments] Get Procedure Interaction Comments Error',
  CREATE_PROCEDURE_INTERACTION_COMMENT = '[Comments] Create Procedure Interaction Comments',
  CREATE_PROCEDURE_INTERACTION_COMMENT_SUCCESS = '[Comments] Create Procedure Interaction Comments Success',
  CREATE_PROCEDURE_INTERACTION_COMMENT_ERROR = '[Comments] Create Procedure Interaction Comments Error',
}

type ProcedureInteractionCommentsErrorData = any;

interface CreateProcedureInteractionCommentData {
  commentableId: number;
  commentBody: string;
}

export class GetProcedureInteractionComments implements FsaAction<number> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS;
  error = false;

  constructor(public payload: number) {}
}

export class GetProcedureInteractionCommentsSucccess
  implements FsaAction<Comment[]> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS_SUCCESS;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetProcedureInteractionCommentsError
  implements FsaAction<ProcedureInteractionCommentsErrorData> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS_ERROR;
  error = true;

  constructor(public payload: ProcedureInteractionCommentsErrorData) {}
}

export class CreateProcedureInteractionComment
  implements FsaAction<CreateProcedureInteractionCommentData> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT;
  error = false;

  constructor(public payload: CreateProcedureInteractionCommentData) {}
}

export class CreateProcedureInteractionCommentSuccess
  implements FsaAction<Comment> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT_SUCCESS;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreateProcedureInteractionCommentError
  implements FsaAction<ProcedureInteractionCommentsErrorData> {
  readonly type =
    ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT_ERROR;
  error = true;

  constructor(public payload: ProcedureInteractionCommentsErrorData) {}
}

export type ProcedureInteractionCommentsAction =
  | GetProcedureInteractionComments
  | GetProcedureInteractionCommentsSucccess
  | GetProcedureInteractionCommentsError
  | CreateProcedureInteractionComment
  | CreateProcedureInteractionCommentSuccess
  | CreateProcedureInteractionCommentError;

@Injectable()
export class ProcedureInteractionCommentsActions {
  constructor(private store: Store<ProcedureInteractionCommentsState>) {}

  getProcedureInteractionComments(id: number) {
    this.store.dispatch(new GetProcedureInteractionComments(id));
  }

  createProcedureInteractionComment(
    commentableId: number,
    commentBody: string,
  ) {
    this.store.dispatch(
      new CreateProcedureInteractionComment({ commentableId, commentBody }),
    );
  }
}
