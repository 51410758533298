// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { RxChangeRequest } from '@app/features/change-rx/shared/change-rx.type';
import {
  Renewal,
  RenewalCartState,
} from '@app/features/renewals/shared/renewals.type';
import { PendingNewRx } from '@app/modules/rx-cart/shared';

import { RefillText } from '../../shared-rx.type';
import { CheckoutListItemOptions } from './checkout-list-item.type';

@Component({
  selector: 'omg-checkout-list-item',
  templateUrl: './checkout-list-item.component.html',
  styleUrls: ['./checkout-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutListItemComponent implements OnInit {
  @Input() handwrittenConfirmed: UntypedFormControl;
  @Input() readyToSign: UntypedFormControl;
  @Input() messages: { [key: string]: string };
  @Input() medication: PendingNewRx | Renewal | RxChangeRequest;
  @Input() isRenewal = false;
  @Output() checked = new EventEmitter<CheckoutListItemOptions>();
  itemId: number;
  refillText: RefillText;
  showPDMP$: Observable<Boolean>;

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit() {
    this.refillText = this.isRenewal ? 'Total Fills' : 'Refills';
    this.showPDMP$ = this.launchDarklyService.variation$(
      FeatureFlagNames.medsPDMP,
      false,
    );
  }

  onReadyToSign() {
    if (this.readyToSign.disabled) {
      return;
    }
    const readyToSign = this.readyToSign.value;

    this.checked.emit({
      readyToSign,
      pendingSignature: true,
      id: this.medication.id,
    });
  }

  get showMustHandWrite() {
    if (this.isRenewal) {
      return (
        this.medication.mustHandwrite &&
        (<Renewal>this.medication).cartState !== RenewalCartState.denied
      );
    }
    return this.medication.mustHandwrite;
  }

  onHandwrittenConfirmation(confirmed: boolean) {
    this.checked.emit({
      handwrittenConfirmation: confirmed,
      pendingSignature: false,
      id: this.medication.id,
    });
  }
}
