// @ts-strict-ignore
import { TrackEventProperties } from '@app/core/analytics/analytics.type';
import { RegimenForm } from '@app/modules/shared-rx/regimen-form';
import { parseDosage, RxRequest } from '@app/modules/shared-rx/utils';

import {
  findDispensableById,
  findFrequencyIntervalById,
} from './regimen-utils';

export const defaultRegimenFormEventPayload = (
  form: RegimenForm,
  medication: RxRequest,
): Partial<TrackEventProperties> => {
  return {
    workflow: 'Charting',
    component: 'Regimen Editor',
    subcomponent: 'Renewals Cart',
    rxSourceType: medication.className,
    rxSourceId: medication.id,
    medicationRegimenId: form.controls.get('medicationRegimenId').value,
    medicationRegimenTextDesc: form.controls.get('regimenTextDescription')
      .value,
  };
};

export const dispensableRegimenFormEventPayload = (
  form: RegimenForm,
  id?: number,
  textDesc?: string,
): Partial<TrackEventProperties> => {
  const dispensables = form.dispensables || [];
  const dispensableId = id || form.controls.get('dispensableId').value;
  const dispensable = findDispensableById(dispensableId, dispensables);

  return {
    dispensableId: dispensableId,
    dispensableTextDesc:
      textDesc || (dispensable ? dispensable.description : null),
  };
};

export const doseRegimenFormEventPayload = (
  dose: string,
): Partial<TrackEventProperties> => {
  const dosage = parseDosage(dose);

  return {
    doseLow: dosage.doseLow,
    doseHigh: dosage.doseHigh,
  };
};

export const frequencyIntervalRegimenFormEventPayload = (
  form: RegimenForm,
  id?: number,
  textShort?: string,
): Partial<TrackEventProperties> => {
  const frequencyIntervals = form.frequencyIntervals || [];
  const frequencyIntervalId =
    id || form.controls.get('frequencyIntervalId').value;
  const frequencyInterval = findFrequencyIntervalById(
    frequencyIntervalId,
    frequencyIntervals,
  );

  return {
    frequencyIntervalId: frequencyIntervalId,
    frequencyIntervalTextShort:
      textShort || (frequencyInterval ? frequencyInterval.textShort : null),
  };
};

export const prnRegimenFormEventPayload = (
  form: RegimenForm,
  prnId?: number,
  prnDesc?: string,
): Partial<TrackEventProperties> => {
  return {
    usePrn: form.controls.get('usePrn').value,
    prnId: prnId || form.controls.get('prnId').value,
    prnDesc: prnDesc || form.controls.get('prnDescription').value,
  };
};

export const instructionsTextRegimenFormEventPayload = (
  instructionsText: string,
): Partial<TrackEventProperties> => {
  return {
    instructionsText: instructionsText,
  };
};

export const useStructuredDataRegimenFormEventPayload = (
  form: RegimenForm,
): Partial<TrackEventProperties> => {
  return {
    useStructuredData: !form.controls.get('useInstructionsText').value,
  };
};

export const customRegimenFormEventPayload = (
  form: RegimenForm,
): Partial<TrackEventProperties> => {
  return {
    isCustom: !form.controls.get('isCustomRegimen').value,
  };
};

export const durationDaysRegimenFormEventPayload = (
  durationDays: number,
): Partial<TrackEventProperties> => {
  return {
    durationDays: durationDays,
  };
};

export const fullRegimenFormEventPayload = (
  form: RegimenForm,
): Partial<TrackEventProperties> => {
  return {
    medicationRouteId: form.controls.get('medicationRouteId').value,
    ...instructionsTextRegimenFormEventPayload(
      form.controls.get('instructionsText').value,
    ),
    ...useStructuredDataRegimenFormEventPayload(form),
    ...customRegimenFormEventPayload(form),
    ...durationDaysRegimenFormEventPayload(
      form.controls.get('durationDays').value,
    ),
    ...prnRegimenFormEventPayload(form),
    ...frequencyIntervalRegimenFormEventPayload(form),
    ...doseRegimenFormEventPayload(form.controls.get('dose').value),
    ...dispensableRegimenFormEventPayload(form),
  };
};
