// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileSelectors } from '@app/core';

@Component({
  selector: 'omg-change-rx-status-approval',
  templateUrl: './change-rx-status-approval.component.html',
  styleUrls: ['./change-rx-status-approval.component.scss'],
})
export class ChangeRxStatusApprovalComponent implements OnInit {
  providerName$: Observable<string>;
  currentDate: Date;

  @Input()
  denied = false;

  constructor(private profileSelectors: ProfileSelectors) {}

  ngOnInit() {
    this.currentDate = new Date();
    this.providerName$ = this.profileSelectors.primaryIdentityName;
  }
}
