import { ActionReducerMap } from '@ngrx/store';

import {
  routerReducer,
  RouterState,
  routerStateKey,
} from '../router/router.reducer';
import {
  reducer as activeRoutesReducer,
  ActiveRoutesState,
} from './active-routes.reducer';

export interface AppState {
  [routerStateKey]: RouterState;
  activeRoutes: ActiveRoutesState;
}

export const appReducer: ActionReducerMap<Partial<AppState>> = {
  router: routerReducer,
  activeRoutes: activeRoutesReducer,
};
