import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[omgBackgroundImage]',
})
export class BackgroundImageDirective implements OnInit {
  @Input('omgBackgroundImage') image: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.image) {
      this.el.nativeElement.style.backgroundImage = `url('${this.image}')`;
      this.el.nativeElement.style.backgroundSize = 'cover';
    }
  }
}
