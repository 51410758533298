// @ts-strict-ignore
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { StateSelector } from '@app/core/store/shared/state';
import {
  mapCredentialsToDropdownItem,
  sortValidPrescribers,
} from '@app/modules/shared-rx/utils';
import { compareDatesAsc, last } from '@app/utils';

import {
  sortByPharmacyAndCartState,
  sortRenewalsByPharmacy,
} from '../shared/renewal-utils';
import { Renewal, RenewalCartState } from '../shared/renewals.type';
import { RenewalEntityState } from './renewals.reducer';

export class RenewalSelectors extends StateSelector<Renewal> {
  protected selectState = createFeatureSelector<RenewalEntityState>(
    this.stateConfig.statePath,
  );

  protected selectRenewalsCount = createSelector(
    this.selectState,
    state => state.renewalCount,
  );

  protected selectLoading = createSelector(
    this.selectState,
    state => state.loading,
  );

  protected selectPrescribingCredentialsLoading = createSelector(
    this.selectState,
    state =>
      state.prescriberCredentials ? state.prescriberCredentials.loading : true,
  );

  protected selectRenewalCartState = createSelector(
    this.selectEntity,
    renewal => renewal.cartState,
  );

  protected selectCartState = createSelector(
    this.selectState,
    state => state.cartState,
  );

  protected selectCartCompleteAt = createSelector(
    this.selectCartState,
    cartState => cartState.cartCompleteAt,
  );

  protected selectCartId = createSelector(
    this.selectState,
    state => state.cartState.id,
  );

  protected selectPrescribingCredentialById = createSelector(
    this.selectState,
    (state, { id }) =>
      state.prescriberCredentials && state.prescriberCredentials[id],
  );

  protected selectSortedValidPrescribers = createSelector(
    this.selectEntity,
    renewal => sortValidPrescribers(renewal.validPrescribers),
  );

  protected selectEntitiesOrderedByPharmacyAndCartState = createSelector(
    this.selectEntities,
    renewals => sortByPharmacyAndCartState(renewals),
  );

  protected selectApprovedRenewalFillDates = createSelector(
    this.selectEntities,
    (renewals: Partial<Renewal>[], { medicationRouteId: routeId }: any) =>
      !!renewals &&
      last(
        renewals
          .filter(renewal => renewal.cartState === RenewalCartState.approved)
          .filter(({ medicationRouteId }) => medicationRouteId === routeId)
          .map(({ earliestFillDate }) => earliestFillDate)
          .sort(compareDatesAsc),
      ),
  );

  protected selectPendingRenewals = createSelector(
    this.selectEntitiesOrderedByPharmacyAndCartState,
    renewals =>
      renewals.filter(
        renewal => renewal.cartState === RenewalCartState.pending,
      ),
  );

  protected selectPendingRenewalIds = createSelector(
    this.selectPendingRenewals,
    pendingRenewals => pendingRenewals.map(pendingRenewal => pendingRenewal.id),
  );

  protected selectApprovedOrDeniedRenewals = createSelector(
    this.selectEntitiesOrderedByPharmacyAndCartState,
    renewals =>
      renewals.filter(
        renewal => renewal.cartState !== RenewalCartState.pending,
      ),
  );

  protected selectApprovedAndDeniedRenewalsByPharmacy = createSelector(
    this.selectApprovedOrDeniedRenewals,
    renewals => sortRenewalsByPharmacy(renewals),
  );

  protected selectCurrentRenewalId = createSelector(
    this.selectCartState,
    cartState => cartState.currentRenewalId,
  );

  protected selectCurrentRenewal = createSelector(
    this.selectEntitiesDictionary,
    this.selectCurrentRenewalId,
    (renewals, currentRenewalId) => renewals[currentRenewalId],
  );

  protected selectRxCartMeasurements = createSelector(
    this.selectCartState,
    cartState => cartState.rxCartMeasurements,
  );

  get approvedOrDeniedRenewals() {
    return this.store.pipe(select(this.selectApprovedOrDeniedRenewals));
  }

  get cartId() {
    return this.store.pipe(select(this.selectCartId));
  }

  get cartState() {
    return this.store.pipe(select(this.selectCartState));
  }

  get cartCompleteAt() {
    return this.store.pipe(select(this.selectCartCompleteAt));
  }

  get cartComplete() {
    return this.store.pipe(
      select(this.selectCartCompleteAt),
      map(cartCompleteAt => !!cartCompleteAt),
    );
  }

  get entitiesOrderedByPharmacyAndCartState(): Observable<Renewal[]> {
    return this.store.pipe(
      select(this.selectEntitiesOrderedByPharmacyAndCartState),
    );
  }

  get loading() {
    return this.store.pipe(select(this.selectLoading));
  }

  get prescriberCredentialsLoading() {
    return this.store.pipe(select(this.selectPrescribingCredentialsLoading));
  }

  get pendingRenewals() {
    return this.store.pipe(select(this.selectPendingRenewals));
  }

  get pendingRenewalIds() {
    return this.store.pipe(select(this.selectPendingRenewalIds));
  }

  get renewalCount() {
    return this.store.pipe(select(this.selectRenewalsCount));
  }

  get renewalsByPharmacy() {
    return this.store.pipe(
      select(this.selectApprovedAndDeniedRenewalsByPharmacy),
    );
  }

  get currentRenewal() {
    return this.store.pipe(select(this.selectCurrentRenewal));
  }

  get rxCartMeasurements() {
    return this.store.pipe(select(this.selectRxCartMeasurements));
  }

  lastApprovedSiblingFillDate(medicationRouteId: number) {
    return this.store.pipe(
      select(this.selectApprovedRenewalFillDates, { medicationRouteId }),
    );
  }

  renewalCartState(id: number) {
    return this.store.pipe(select(this.selectRenewalCartState, { id }));
  }

  prescribingCredentialsByRenewalId(id: number) {
    return this.store.pipe(
      select(this.selectPrescribingCredentialById, { id }),
    );
  }

  sortedValidPrescribers(id: number) {
    return this.store.pipe(select(this.selectSortedValidPrescribers, { id }));
  }

  credentialDropdownItemsFor(renewalId: number) {
    return this.prescribingCredentialsByRenewalId(renewalId).pipe(
      filter(creds => !!creds && !!creds.prescribingCredentials),
      map(({ prescribingCredentials }) =>
        mapCredentialsToDropdownItem(prescribingCredentials),
      ),
    );
  }
}
