// @ts-strict-ignore
import { ActionReducerMap } from '@ngrx/store';

import { PatientPharmacyState } from '@app/modules/pharmacy-picker/store/patient-pharmacy.reducer';
import { reducer as patientPharmacyReducer } from '@app/modules/pharmacy-picker/store/patient-pharmacy.reducer';
import {
  createStoreMetadataAdapter,
  EntityMetadataMap,
  getEntityMetadataInitialState,
  StoreMetadataMap,
} from '@app/utils';

import { RxCart } from '../shared/rx-cart.type';
import { PendingRxCartItemsState } from './pending-new-rx.reducer';
import { reducer as itemsReducer } from './pending-new-rx.reducer';
import {
  reducer as prescriberCredentialsReducer,
  PrescriberCredentialsState,
} from './prescriber-credentials.reducer';
import { RxCartAction } from './rx-cart.actions';
import * as fromRxCart from './rx-cart.actions';

export interface NewRxCartState {
  rxCart: RxCartState;
  pendingRxCartItems: PendingRxCartItemsState;
  patientPharmacies: PatientPharmacyState;
  prescriberCredentials: PrescriberCredentialsState;
}

export const reducers: ActionReducerMap<NewRxCartState> = {
  rxCart: reducer,
  pendingRxCartItems: itemsReducer,
  patientPharmacies: patientPharmacyReducer,
  prescriberCredentials: prescriberCredentialsReducer,
};

export interface RxCartState {
  entity: RxCart;
  error: any;
  loading: boolean;
  pendingOperations: StoreMetadataMap;
  metadata: EntityMetadataMap<RxCart>;
}

export const initialState: RxCartState = {
  entity: null,
  error: null,
  loading: false,
  pendingOperations: {},
  ...getEntityMetadataInitialState({}),
};

export const metadataAdapter = createStoreMetadataAdapter('pendingOperations');

export function reducer(
  state = initialState,
  action: RxCartAction,
): RxCartState {
  switch (action.type) {
    case fromRxCart.LOAD_RX_CART: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case fromRxCart.LOAD_RX_CART_SUCCESS: {
      return {
        ...state,
        entity: action.payload,
        loading: false,
        error: null,
      };
    }
    case fromRxCart.LOAD_RX_CART_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case fromRxCart.UPDATE_RX_CART: {
      return {
        ...state,
        loading: true,
        error: null,
        ...metadataAdapter.upsertOne(
          action.meta.correlationId,
          { pending: true },
          state,
        ),
      };
    }
    case fromRxCart.UPDATE_RX_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        entity: {
          ...action.payload,
          pharmacyId: action.payload.pharmacyId || state.entity?.pharmacyId,
        },
        ...metadataAdapter.upsertOne(
          action.meta.correlationId,
          { pending: false },
          state,
        ),
      };
    }
    case fromRxCart.UPDATE_RX_CART_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case fromRxCart.REFRESH_RX_CART: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case fromRxCart.REFRESH_RX_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        entity: action.payload,
      };
    }
    case fromRxCart.REFRESH_RX_CART_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case fromRxCart.COMPLETE_RX_CART: {
      return {
        ...state,
        loading: false,
        entity: {
          ...state.entity,
          cartCompleteAt: new Date().toISOString(),
          cartItems: [], // used to reset badge counter
        },
      };
    }
    case fromRxCart.SET_RX_CART_ATTRIBUTES: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...action.payload,
        },
      };
    }
    case fromRxCart.SET_RX_CART_PHARMACY_ID: {
      return {
        ...state,
        entity: {
          ...state.entity,
          pharmacyId: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
