<strong class="padding-half-vertical service-ticket-indications-title"
        omgTheme>Problems Assessed</strong>

<ul om-layout="vertical"
    om-layout-gutter>
  <li *ngFor="let problemCode of visitProcedure.visitProcedureProblemCodes"
      (click)="selectIndication(problemCode)"
      [ngClass]="{ selected: problemCode.primary, clickable: !disabled && !problemCode.cannotBePrimaryProblemCode && canSelectIndication, 'om-messages': !disabled && problemCode.cannotBePrimaryProblemCode && hasAllNonPrimaryProblemCode, 'disabled-indications': disabled }"
      class="om-animate"
      om-layout
      om-layout-align="space-between start">
    <span om-flex="70">{{ problemCode.detailDescription }} &mdash; {{ problemCode.code }}</span>

    <!-- We don't use ngIf here because hovering also displays this span. All visibility logic
         exists in CSS -->
    <span class="right-align visible-on-parent-hover"
          om-flex="30">Chief complaint</span>
  </li>
  <li class="om-messages"
      *ngIf="hasAllNonPrimaryProblemCode && canSelectIndication">
    This diagnosis cannot be primary. Please add a problem indicating the patient's injury, symptom or condition as a
    result of that external cause.
  </li>

  <li *ngIf="!visitProcedure.visitProcedureProblemCodes.length">
    None recorded
  </li>
  <ng-container *ngIf="!problemCodeControl.dirty">
    <div class="error"
         *ngFor="let error of problemCodeControl.errors?.serverErrors">
      {{error}}
    </div>
  </ng-container>
</ul>
