import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NoteContainerComponent } from './components/note-container/note-container.component';

export const notesRoutePath = ':id/edit';

const routes: Routes = [
  {
    path: notesRoutePath,
    component: NoteContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotesRoutingModule {}
