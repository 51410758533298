// @ts-strict-ignore
import { ErrorHandler, Provider } from '@angular/core';
import { environment } from '@environments/environment';

import { ErrorHandlerService } from './error-handler.service';
import { SentryErrorHandler } from './sentry';

const globalErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useClass: ErrorHandlerService,
};

const sentryProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useValue: SentryErrorHandler,
  },
];

export const errorProviders: Provider[] = [];
if (environment.production) {
  errorProviders.push(globalErrorHandlerProvider);
}
if (environment.sentry.enabled) {
  errorProviders.push(sentryProviders);
}
