<button omgButton
        variant="secondary"
        class="original-case"
        om-layout
        om-layout-inline
        om-layout-align="start center"
        om-layout-gutter-mini
        omgStopEvent="click"
        (click)="directToFile()"
        (mouseenter)="onHoverEvent(true)"
        (mouseleave)="onHoverEvent(false)">
  <span class="om-icon icon-attachment"></span>
  <span>{{text}}</span>
  <span class="om-file-link-delete"
        [ngClass]="{'hidden': !hovering}"
        om-layout
        om-layout-inline
        om-layout-align="start center">
    <span class="clickable om-icon icon-delete"
          (click)="onDelete()"
          omgStopEvent="click"
          *ngIf="deletable"></span>
  </span>
</button>
