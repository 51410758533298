import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'omg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(private auth: AuthService) {}

  login() {
    const destination = window.sessionStorage.getItem('path') || '/schedule';

    this.auth.loginWithRedirect({
      appState: { target: destination },
    });
  }
}
