// @ts-strict-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigService } from '@app/core/config';
import {
  ToastMessage,
  ToastMessageService,
} from '@app/shared/components/toast';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService implements OnDestroy {
  private parser = new DOMParser();
  private targetUrl: string;
  private versionCheckIntervalId: any;
  private unsubscribe = new Subject<void>();

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private zone: NgZone,
    private toastService: ToastMessageService,
  ) {
    this.targetUrl = this.config.environment.versionChecker
      ? this.config.environment.versionChecker.targetUrl
      : '';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  versionDiffers(version: string): boolean {
    if (!this.config.environment.codeVersion) {
      return false;
    }
    const strippedVersion = version.replace(/@@/, '');
    return strippedVersion !== this.config.environment.codeVersion;
  }

  parseHtml(data: string) {
    const html = this.parser.parseFromString(data, 'text/html');
    const head = html.head;
    const versionMetaTag = head.querySelector('meta[name="version"]');
    return versionMetaTag ? (versionMetaTag as any).content : '';
  }

  notifyIfVersionDiffers(data: string) {
    const version = this.parseHtml(data);
    if (version) {
      const versionDiffers = this.versionDiffers(version);
      if (versionDiffers) {
        const notificationPayload: ToastMessage = {
          severity: 'info',
          summary: '1Life Updates',
          detail: 'We made some updates, please refresh the page.',
          sticky: true,
        };
        clearInterval(this.versionCheckIntervalId);
        this.zone.run(() => {
          this.toastService.add(notificationPayload);
        });
      }
    }
  }

  checkRemoteTarget() {
    return this.http
      .get(this.targetUrl, { responseType: 'text' })
      .pipe(tap((data: any) => this.notifyIfVersionDiffers(data)));
  }

  startInterval() {
    return this.zone.runOutsideAngular(
      () =>
        (this.versionCheckIntervalId = setInterval(
          () => this.checkRemoteTarget().subscribe(),
          this.config.environment.versionChecker.pollingFrequency,
        )),
    );
  }
}
