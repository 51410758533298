// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { FormModel } from '@app/shared';

import { CommentsService } from '../../shared/comments.service';
import { Comment, Commentable } from '../../shared/comments.type';

@Component({
  selector: 'omg-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  @Input() commentable: Commentable;
  @Output() commentAdded = new EventEmitter<void>();
  @Output() commentRemoved = new EventEmitter<void>();

  get maxCommentLength() {
    return 500;
  }

  comments: Observable<Comment[]>;
  commentFormModel: FormModel;

  constructor(private commentsService: CommentsService) {}

  ngOnInit() {
    this.setupForm();

    this.comments = this.commentsService.get(this.commentable);
  }

  private setupForm() {
    this.commentFormModel = new FormModel(
      new UntypedFormGroup({
        commentBody: new FormControl('', { nonNullable: true }),
      }),
      {
        autosave: false,
        saveFunction: (formValues: { commentBody: string }) => {
          const comment = formValues.commentBody.trim();

          if (comment.length <= 0) {
            return of(false);
          }

          return this.saveComment(comment);
        },
        resetOnSave: true,
      },
    );
  }

  onSaveComment() {
    const comment = this.commentFormModel.get('commentBody').value;
    const trimmedComment = comment.trim();
    this.commentFormModel.setValue({ commentBody: trimmedComment });

    if (
      trimmedComment &&
      trimmedComment.length > 0 &&
      this.commentFormModel.form.valid
    ) {
      this.commentFormModel.save();
    }
  }

  onDeleteComment(commentId: number) {
    this.deleteComment(commentId);
  }

  getCommentLength(): number {
    if (this.commentFormModel.get('commentBody').value) {
      return this.commentFormModel.get('commentBody').value.trim().length;
    }
    return 0;
  }

  private saveComment(comment: string): Observable<boolean> {
    this.commentFormModel.form.get('commentBody').disable();

    return this.commentsService.save(this.commentable, comment).pipe(
      take(1),
      tap(() => {
        this.commentFormModel.get('commentBody').enable();
        this.commentAdded.emit();
      }),
    );
  }

  private deleteComment(commentId: number) {
    this.commentsService
      .delete(this.commentable, commentId)
      .pipe(
        take(1),
        tap(() => {
          this.commentRemoved.emit();
        }),
      )
      .subscribe();
  }
}
