<div om-layout="vertical"
     om-layout-align="space-between"
     omgTheme>
  <div class="padding-normal">
    <strong class="patient-title">
      {{ patient.name }}
    </strong>
    <ul class="om-inline-list -divided">
      <li>{{ (patient.gender === 'M' ? 'Male' : 'Female') }}</li>
      <li>DOB: {{ patient.dateOfBirth | omgDate: '2y' }}</li>
    </ul>
    {{ patient.address }}
    <div *ngIf="patient.phone">Primary Telephone: {{ patient.phone | omgPhoneNumber }}</div>
    <div *ngIf="patient.fax">Fax: {{ patient.fax | omgPhoneNumber }}</div>
    <div *ngIf="patient.beeper">Beeper: {{ patient.beeper | omgPhoneNumber }}</div>
    <div *ngIf="patient.homePhone">Home Telephone: {{ patient.homePhone | omgPhoneNumber }}</div>
    <div *ngIf="patient.workPhone">Work Telephone: {{ patient.workPhone | omgPhoneNumber }}</div>
    <div *ngIf="patient.otherPhone">Other Telephone: {{ patient.otherPhone | omgPhoneNumber }}</div>
  </div>

  <div om-layout="vertical">
    <omg-section-title>
      Medication
    </omg-section-title>
    <div class="om-list">
      <div class="om-item"
           om-layout-align="space-between"
           om-layout>
        <div om-layout="vertical"
             *ngIf="prescribedMedication">
          <strong>Prescribed As</strong>
          <div om-layout="vertical">
            <strong>{{ prescribedMedication.name }}</strong>
            <span>{{ prescribedMedication.instructions }}</span>
            <span>Quantity: {{ prescribedMedication.quantityDescription }}, Refills: {{ prescribedMedication.refills
              }}
            </span>
            <span *ngIf="prescribedMedication.notes">Prescriber Note: {{ prescribedMedication.notes }}</span>
            <span *ngIf="prescribedMedication.sentAt">Written On:
              {{ prescribedMedication.sentAt | omgDate: '2y' }}</span>
            <span *ngIf="prescribedMedication.lastFillDate">Last Filled On:
              {{ prescribedMedication.lastFillDate | omgDate: '2y'}}</span>
          </div>
        </div>
        <div om-flex="50"
             om-layout="vertical"
             *ngIf="!requestedByPatient">
          <strong>Dispensed As</strong>
          <strong>{{ dispensedMedication.name }}</strong>
          <span>{{ dispensedMedication.instructions }}</span>
          <span>Quantity: {{ dispensedMedication.quantityDescription }}, Refills:
            {{ dispensedMedication.refills }}</span>
          <span *ngIf="dispensedMedication.notes">Pharmacy Note: {{ dispensedMedication.notes }}</span>
          <span *ngIf="dispensedMedication.sentAt">Written On: {{ dispensedMedication.sentAt | omgDate: '2y' }}</span>
          <span *ngIf="dispensedMedication.lastFillDate">Last Filled On:
            {{ dispensedMedication.lastFillDate | omgDate: '2y' }}</span>
        </div>
      </div>
    </div>
  </div>

  <omg-section-title>
    <span om-flex="50">Sent to</span>
    <span om-flex="50">Signed by</span>
  </omg-section-title>
  <div om-layout
       om-layout-align="space-between"
       class="padding-normal">
    <div om-layout="vertical">
      <strong>{{ pharmacy.storeName }}</strong>
      <span *ngIf="pharmacy.address">{{ pharmacy.address }}</span>
      <span *ngIf="pharmacy.phone">Ph: {{ pharmacy.phone | omgPhoneNumber }}</span>
      <span *ngIf="pharmacy.fax">Fax: {{ pharmacy.fax | omgPhoneNumber }}</span>
    </div>
    <div om-flex="50"
         om-layout="vertical">
      <strong>{{ prescriber.name }}</strong>
      <div *ngIf="prescriber.deaLicenseNumber">DEA: {{ prescriber.deaLicenseNumber }}</div>
      <div *ngIf="prescriber.licenseNumber">Lic: {{ prescriber.licenseNumber }}</div>
      <div *ngIf="prescriber.phone">Ph: {{ prescriber.phone | omgPhoneNumber }}</div>
      <div *ngIf="prescriber.address">{{ prescriber.address }}</div>
    </div>
  </div>
  <div *ngIf="prescribedMedication?.sentAt">
    <hr class="-flush" />
    <div class="padding-normal">
      <strong>eRx Sent:</strong>
      {{ prescribedMedication.sentAt | omgDate: 'withTime2y' }}
    </div>
  </div>
</div>
