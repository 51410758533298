import { Directive } from '@angular/core';

import { BaseInput } from '../base-input/base-input';

@Directive({
  selector:
    'input[omgInputText]:not([type=checkbox]), input[omgInputText]:not([type=radio])',
  // eslint-disable-next-line
  host: {
    '[class.om-input]': 'true',
  },
})
export class InputTextDirective extends BaseInput {}
