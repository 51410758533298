<omg-section-title>
  After Visit Guidance
</omg-section-title>
<p class="padding-normal visit-guidance-content marked"
   omgTheme
   [innerHTML]="summaryProviderRecommendation.body"></p>
<div *ngFor="let attachment of (summaryProviderRecommendation.s3Pointers)"
     class="padding-normal">
  <omg-file-link [bucket]="attachment.bucket"
                 [key]="attachment.key"
                 [deletable]="false"
                 [text]="attachment.title"></omg-file-link>
</div>
