import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'omg-signed-by-another',
  templateUrl: './signed-by-another.component.html',
})
export class SignedByAnotherComponent {
  @Input() prescriberName: string;
  @Input() isSignedOnBehalfOf: boolean;
}
