// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

import { Item } from './item.type';

@Component({
  selector: 'omg-selected-items-list',
  templateUrl: './selected-items-list.component.html',
  styleUrls: ['./selected-items-list.component.scss'],
})
export class SelectedItemsListComponent {
  @Input() customItemName: string;
  @Input() items: Item[];
  @Input() removable: boolean;
  @Output() removeItem: EventEmitter<Item> = new EventEmitter<Item>();

  @HostBinding('class.emphasized') isHighlighted(item: Item): boolean {
    return item && item.emphasized;
  }

  getRemoveItemTooltipText(customItemName: string): string {
    return customItemName ? `Remove ${customItemName}` : 'Remove Item';
  }

  onRemove(item: Item) {
    this.removeItem.emit(item);
  }
}
