import { ActionReducerMap } from '@ngrx/store';

import {
  MedicationState,
  reducer as medicationReducer,
} from './medication.reducer';
import {
  PatientMedicationState,
  reducer as patientMedicationReducer,
} from './patient-medication.reducer';

export const medicationsRootStatePath = 'medications';

export interface MedicationsRootState {
  medications: MedicationState;
  patientMedications: PatientMedicationState;
}

export const reducers: ActionReducerMap<MedicationsRootState> = {
  medications: medicationReducer,
  patientMedications: patientMedicationReducer,
};
