<ul class="padding-normal-horizontal">
  <li om-layout
      om-layout-gutter
      *ngFor="let item of items">
    <div om-flex="80"
         om-layout-gutter>
      <strong>{{item.title}}</strong> <span *ngIf="isHighlighted(item)">*</span>
      <div *ngIf="item.subTitle">({{item.subTitle}})</div>
      <div *ngIf="item.subDescription">{{item.subDescription}}</div>

    </div>
    <div om-flex>{{item.description}}</div>
    <div *ngIf="removable"
         om-layout
         om-layout-align="start center">
      <span class="om-icon clickable icon-dismiss -gray"
            omgTooltip
            [tooltipText]="getRemoveItemTooltipText(customItemName)"
            tooltipPosition="left"
            omgStopEvent="click"
            (click)="onRemove(item)"></span>
    </div>
  </li>

</ul>
