import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil, tap } from 'rxjs/operators';

import { DetectResizeService } from '@app/shared/directives/detect-resize/detect-resize.service';

import { ChartLayoutConfiguration } from './chart-layout.types';

@Component({
  selector: 'omg-chart-layout',
  templateUrl: './chart-layout.component.html',
  styleUrls: ['./chart-layout.component.scss'],
})
export class ChartLayoutComponent implements OnInit, OnDestroy {
  @Input() configuration: ChartLayoutConfiguration = { expanded: '' };

  private unsubscribe$ = new Subject<void>();

  get timelineExpanded() {
    return this.configuration.expanded === 'timeline';
  }
  get workspaceExpanded() {
    return this.configuration.expanded === 'workspace';
  }

  constructor(private detectResizeService: DetectResizeService) {}

  ngOnInit() {
    const htmlTag: any = document.querySelector('html');
    this.detectResizeService
      .getResizeEvents$(htmlTag, 10)
      .pipe(
        distinctUntilKeyChanged<DOMRectReadOnly>('width'),
        tap(resizeEvent =>
          htmlTag.style.setProperty(
            '--scrollbar-height',
            window.innerHeight - resizeEvent.height + 'px',
          ),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
