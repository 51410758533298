<div *ngIf="procedureCodes?.length <= 2">
  {{ displayText }}
</div>
<div *ngIf="procedureCodes?.length > 2">
  <span omgTooltip
        [tooltipText]="tooltipText"
        tooltipPosition="top">
    {{ displayText }}
  </span>
</div>
