import { Injectable } from '@angular/core';

import { RenewalActions } from '../store/renewals.actions';
import { RenewalSelectors } from '../store/renewals.selectors';
import { RenewalCheckoutForm } from './renewal-checkout-form';
import { RenewalForm } from './renewal-form';
import { Renewal } from './renewals.type';

@Injectable()
export class RenewalFormService {
  constructor(
    private actions: RenewalActions,
    private selectors: RenewalSelectors,
  ) {}

  buildForm(renewal: Renewal, profileId?: number) {
    return new RenewalForm(this.actions, this.selectors, renewal, profileId);
  }

  buildCheckoutForm(
    renewals: Renewal[],
    acceptsDigitalCommunications: boolean,
    cartId: number,
  ) {
    return new RenewalCheckoutForm(
      this.actions,
      renewals,
      acceptsDigitalCommunications,
      cartId,
    );
  }
}
