<div om-layout
     om-layout-align="space-between center">
  <div>
    <strong om-layout>{{confirmationQuestion}}</strong>
    <div *ngIf="confirmationQuestionSubheader">{{confirmationQuestionSubheader}}</div>
  </div>
  <span om-layout
        [attr.om-flex]="confirmButtonsFlex"
        om-layout-gutter
        om-layout-align="space-between center">
    <button omgButton
            variant="flat"
            (click)="onCancel()"
            data-cy="order-nevermind-btn">
      Never mind
    </button>
    <button omgButton
            variant="primary"
            om-flex
            (click)="onConfirm()"
            [disabled]="disabled"
            data-cy="order-confirm-btn">
      {{confirmButtonText}}
    </button>
  </span>
</div>
