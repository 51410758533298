<div class="todo-reassignment-feedback-form-container">
  <div *ngIf="state === State.initial"
       data-cy="form">
    <div class="padding-half"
         om-layout
         om-layout-gutter
         om-layout-align="start center">
      <i class="om-icon icon-magic"></i>
      <strong>Feedback on Message Assignment</strong>
    </div>
    <div om-layout
         [formGroup]="form"
         class="padding-expanded-horizontal">
      <div>
        <div om-layout="vertical"
             class="padding-half-vertical">
          <label class="todo-reassignment-feedback-radio-label plain-text">
            <input omgRadioButton
                   [formControlName]="categoryFormName"
                   type="radio"
                   name="category"
                   value="roleDoesNotSupport" />
            My role does not support these types of messages
          </label>
          <label class="todo-reassignment-feedback-radio-label plain-text">
            <input omgRadioButton
                   [formControlName]="categoryFormName"
                   type="radio"
                   name="category"
                   value="incorrectLabel" />
            The label found was incorrect
          </label>
        </div>
        <div>Anything else?</div>
        <input omgInputText
               [formControlName]="otherCommentsFormName"
               class="todo-reassignment-feedback-text-input"
               om-layout-fill
               placeholder="Other comments" />
        <div class="padding-half-vertical">
          <div om-layout-gutter>
            <button omgButton
                    [disabled]="form.invalid"
                    (click)="submitFeedback()"
                    variant="primary">Send Feedback
            </button>
            <button omgButton
                    (click)="cancel.emit()"
                    variant="flat">Never mind
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="padding-half"
       om-layout
       om-layout-align="start center"
       *ngIf="state === State.gratitude"
       data-cy="gratitude"><strong>Thank you for the feedback</strong></div>
</div>
