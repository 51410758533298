import {
  InternalUserAssignee,
  InternalUserAssigneeResponse,
  InternalUserPreferences,
  InternalUserPreferencesResponse,
} from './internal-user-preferences.type';

/* istanbul ignore next */
export const mapInternalUserAssigneeResponseToEntity = (
  data: InternalUserAssigneeResponse,
): InternalUserAssignee => ({
  id: data.id,
  name: data.name,
  role: data.role,
  identifier: data.identifier,
  outUntil: data.out_until,
  label: `${data.name} (${data.role})`,
});

/* istanbul ignore next */
export const mapInternalUserPreferencesReponseToEntity = (
  data: InternalUserPreferencesResponse,
): InternalUserPreferences => ({
  id: data.id,
  internalUserId: data.internal_user_id,
  isVideoNotificationEnabled: data.is_video_notification_enabled,
  videoAppointmentFilters: data.video_appointment_filters,
  textTemplateTypeIds: data.text_template_type_ids,
  useNewOnelife: data.use_new_onelife,
  defaultSendAs: data.default_send_as,
  defaultSendAsAssignee: mapInternalUserAssigneeResponseToEntity(
    data.default_send_as_assignee,
  ),
});
