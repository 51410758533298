<div *ngIf="prescriber"
     om-layout="vertical">
  <div>Signed on behalf of: {{prescriber.name}}</div>
  <div>{{ prescriber.address }}</div>
  <div>
    <span *ngIf="prescribingCredential?.deaLicenseNumber">DEA: {{prescribingCredential.deaLicenseNumber}}</span>
    <span *ngIf="prescribingCredential?.serviceArea && prescribingCredential?.stateOfIssue">
      ({{prescribingCredential.serviceArea}}, {{prescribingCredential.stateOfIssue}})
    </span>
  </div>
</div>
<div *ngIf="prescribingCredential?.supervisingPhysician">
  Supervisor: {{ supervisingPhysicianName }}
  <span *ngIf="supervisingPhysicianCredential?.deaLicenseNumber">
    (DEA: {{ supervisingPhysicianCredential.deaLicenseNumber }} - {{ supervisingPhysicianCredential.stateOfIssue }})
  </span>
</div>
