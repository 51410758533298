// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { getReactionSeverityText } from '@app/modules/allergies/shared/allergies-utils';

import { SummaryPatientAllergy } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-allergies',
  templateUrl: './linked-allergies.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedAllergiesComponent {
  @Input() signed: boolean;
  @Input() noKnownAllergies: boolean;
  @Input() patientAllergies: SummaryPatientAllergy[];
  @Output() unlink = new EventEmitter();

  reactionSeverityText = getReactionSeverityText;

  trackByFn = (index, patientAllergy: SummaryPatientAllergy) =>
    patientAllergy.id || index;

  deleteFromNote() {
    this.unlink.emit();
  }
}
