// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { TimelineActions } from '@app/features/timeline/store/timeline.actions';
import {
  lettersPath,
  notesPath,
} from '@app/features/workspace/shared/workspace-utils';
import { MessagingService } from '@app/modules/messaging/shared/messaging.service';
import { MessagingActions } from '@app/modules/messaging/store/messaging.actions';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { ToastMessageService } from '@app/shared/components/toast';
import { httpStatus, isEqual } from '@app/utils';

import { LetterTemplate } from '../shared/letter.type';
import {
  letterAnalyticsBaseAttributes,
  letterSignButtonAnalyticsEvent,
} from '../shared/letter.utils';
import { MLNoteRetitleFeedbackApi } from '../shared/ml-note-retitle-feedback.api';
import { NoteApiService } from '../shared/note-api.service';
import { NoteResponse } from '../shared/note-api.type';
import { getPostTitle, isTreatMeNowNote } from '../shared/note-utils';
import { Note } from '../shared/note.type';
import {
  AttachNoteDocumentsToPost,
  CloneNote,
  CloneNoteError,
  CloneNoteSuccess,
  DeleteLetter,
  DeleteNote,
  DeleteNoteError,
  DeleteNoteSuccess,
  GenerateLetter,
  GenerateLetterError,
  GenerateLetterSuccess,
  LoadNote,
  LoadNoteError,
  LoadNoteSuccess,
  LoadNoteWithTodo,
  LoadNoteWithTodoError,
  LoadNoteWithTodoSuccess,
  LoadTemplate,
  LoadTemplateError,
  NewPostFromNote,
  NewPostFromNoteError,
  NewPostFromNoteSuccess,
  NoteActionTypes,
  RecategorizeNote,
  RecategorizeNoteError,
  RecategorizeNoteSuccess,
  RedactNote,
  RedactNoteError,
  RedactNoteSuccess,
  SaveNote,
  SaveNoteError,
  SaveNoteSuccess,
  SendMLNoteRetitleDismissedFeedback,
  SendMLNoteRetitleFeedbackError,
  SendMLNoteRetitleFormFeedback,
  UnfileNote,
  UnfileNoteError,
  UnfileNoteSuccess,
  UpdateNote,
  UpdateNoteError,
  UpdateNoteSuccess,
} from './note.actions';
import { NoteSelectors } from './note.selectors';

@Injectable()
export class NoteEffects {
  constructor(
    private action$: Actions,
    private noteApi: NoteApiService,
    private mlNoteRetitleFeedbackApi: MLNoteRetitleFeedbackApi,
    private todoActions: TodoActions,
    private patientSelectors: PatientSelectors,
    private messagingService: MessagingService,
    private messagingActions: MessagingActions,
    private noteSelectors: NoteSelectors,
    private router: Router,
    private summariesActions: SummariesActions,
    private toastService: ToastMessageService,
    private errorHandler: ErrorHandlerService,
    private timelineActions: TimelineActions,
    private analytics: AnalyticsService,
  ) {}

  loadNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<LoadNote>(NoteActionTypes.LOAD_NOTE),
      switchMap(action =>
        this.noteApi.get(action.payload).pipe(
          map(note => {
            return new LoadNoteSuccess(note);
          }),
          catchError(error =>
            of(new LoadNoteError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
      ),
    ),
  );

  loadTemplate$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<LoadTemplate>(NoteActionTypes.LOAD_TEMPLATE),
      withLatestFrom(this.patientSelectors.patientId),
      mergeMap(([action, patientId]) =>
        this.noteApi
          .getLetterTemplate(action.payload.templateId, patientId)
          .pipe(
            map((letterTemplate: LetterTemplate) => {
              return new UpdateNote({
                id: action.payload.letterId,
                subject: letterTemplate.name,
                body: letterTemplate.body,
              } as Note);
            }),
            catchError(error =>
              of(
                new LoadTemplateError(this.errorHandler.handleErrorSafe(error)),
              ),
            ),
          ),
      ),
    ),
  );

  loadNoteWithTodo$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<LoadNoteWithTodo>(NoteActionTypes.LOAD_NOTE_WITH_TODO),
      mergeMap(action =>
        this.noteApi.getNoteWithTodo(action.payload).pipe(
          map(([note, todo]: [Note, Todo]) => {
            this.todoActions.loadTodoSuccess(todo);
            return new LoadNoteWithTodoSuccess({
              ...note,
              todoId: todo.id,
              todo,
            });
          }),
          catchError(error =>
            of(
              new LoadNoteWithTodoError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  saveNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<SaveNote>(NoteActionTypes.SAVE_NOTE),
      mergeMap(action =>
        this.patientSelectors.patientId.pipe(
          mergeMap(patientId =>
            this.noteApi.save(patientId, action.payload).pipe(
              map(note => new SaveNoteSuccess(note)),
              catchError(error =>
                of(new SaveNoteError(this.errorHandler.handleErrorSafe(error))),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  updateNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<UpdateNote>(NoteActionTypes.UPDATE_NOTE),
      withLatestFrom(this.noteSelectors.currentNote),
      mergeMap(([action, currentNote]) =>
        this.noteApi.update(action.payload).pipe(
          tap(note => {
            if (!isEqual(currentNote.subject, note.subject)) {
              this.timelineActions.refreshTimeline();
            }
          }),
          map(
            note =>
              new UpdateNoteSuccess({
                ...note,
                todoId: action.payload.todoId,
              }),
          ),
          catchError(error => {
            this.handleNoteSavingError(error);
            return of(
              new UpdateNoteError(this.errorHandler.handleError(error)),
            );
          }),
        ),
      ),
    ),
  );

  deleteNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<DeleteNote | DeleteLetter>(
        NoteActionTypes.DELETE_NOTE,
        NoteActionTypes.DELETE_LETTER,
      ),
      mergeMap(action =>
        this.noteApi.delete(action.payload).pipe(
          tap(() => {
            this.toastService.add({
              severity: 'success',
              detail: `Your ${
                action.type === NoteActionTypes.DELETE_LETTER
                  ? 'letter'
                  : 'note'
              } has been deleted`,
            });
            this.timelineActions.refreshTimeline();
            this.summariesActions.closeWorkspaceItem();
          }),
          map(() => new DeleteNoteSuccess(action.payload)),
          catchError(error =>
            of(new DeleteNoteError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
      ),
    ),
  );

  deleteLetter$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<DeleteLetter>(NoteActionTypes.DELETE_LETTER),
        tap(action =>
          this.analytics.track(AnalyticsEvent.LetterDraftDeleted, {
            ...letterAnalyticsBaseAttributes(action.payload),
            subcomponent: 'Delete Button',
          }),
        ),
      ),
    { dispatch: false },
  );

  unfileNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<UnfileNote>(NoteActionTypes.UNFILE_NOTE),
      mergeMap(action =>
        this.noteApi.unfile(action.payload).pipe(
          tap(() => {
            this.toastService.add({
              severity: 'success',
              detail:
                'Your document has been unfiled. The note has been removed from this chart and the document has been returned to the document inbox.',
            });
            this.timelineActions.refreshTimeline();
            this.summariesActions.closeWorkspaceItem();
          }),
          map(() => new UnfileNoteSuccess(action.payload)),
          catchError(error =>
            of(new UnfileNoteError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
      ),
    ),
  );

  newPostFromNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<NewPostFromNote>(NoteActionTypes.NEW_POST_FROM_NOTE),
      mergeMap(action => {
        const associatedLabId = action.payload.documents
          .filter(doc => doc.forLab === true)
          .map(doc => doc.parentId)[0];
        const postTitle = getPostTitle(action.payload);

        return this.messagingService
          .createPost({
            topic: postTitle,
            labId: associatedLabId,
            noteId: isTreatMeNowNote(action.payload)
              ? action.payload.id
              : undefined,
          })
          .pipe(
            map(
              post =>
                new AttachNoteDocumentsToPost({
                  note: action.payload,
                  post,
                }),
            ),
            catchError(error =>
              of(
                new NewPostFromNoteError(
                  this.errorHandler.handleErrorSafe(error),
                ),
              ),
            ),
          );
      }),
    ),
  );

  attachNoteDocumentsToPost$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<AttachNoteDocumentsToPost>(
        NoteActionTypes.ATTACH_NOTE_DOCUMENTS_TO_POST,
      ),
      mergeMap(action =>
        this.patientSelectors.patientId.pipe(
          mergeMap(patientId =>
            this.noteApi
              .attachNoteDocumentsToPost(patientId, action.payload.note)
              .pipe(
                map(
                  allDocs =>
                    new NewPostFromNoteSuccess({
                      ...action.payload.post,
                      s3Pointers: [
                        ...action.payload.post.s3Pointers,
                        ...allDocs,
                      ],
                    }),
                ),
                catchError(err =>
                  of(
                    new NewPostFromNoteError(
                      this.errorHandler.handleErrorSafe(err),
                    ),
                  ),
                ),
              ),
          ),
        ),
      ),
    ),
  );

  newPostFromNoteSuccess$: Observable<Action> = createEffect(
    () =>
      this.action$.pipe(
        ofType<NewPostFromNoteSuccess>(
          NoteActionTypes.NEW_POST_FROM_NOTE_SUCCESS,
        ),
        mergeMap(action =>
          this.messagingService.updatePost(action.payload).pipe(
            tap(() => this.messagingActions.setCurrentPost(action.payload.id)),
            map(() => action),
          ),
        ),
      ),
    { dispatch: false },
  );

  recategorizeNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<RecategorizeNote>(NoteActionTypes.RECATEGORIZE_NOTE),
      mergeMap(action =>
        this.noteApi
          .recategorize(action.payload.noteId, action.payload.template)
          .pipe(
            map(note => new RecategorizeNoteSuccess(note)),
            catchError(error => of(new RecategorizeNoteError(error))),
          ),
      ),
    ),
  );

  recategorizeNoteSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<RecategorizeNoteSuccess>(
          NoteActionTypes.RECATEGORIZE_NOTE_SUCCESS,
        ),
        withLatestFrom(this.noteSelectors.currentNote),
        tap(([action, note]: [RecategorizeNoteSuccess, Note]) => {
          this.toastService.add({
            severity: 'success',
            detail: `Document recategorized to ${note.noteType.name}`,
          });
          this.timelineActions.closeItem();
          this.timelineActions.refreshTimeline();
          this.summariesActions.closeWorkspaceItem();
        }),
      ),
    { dispatch: false },
  );

  cloneNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<CloneNote>(NoteActionTypes.CLONE_NOTE),
      withLatestFrom(this.patientSelectors.patientId),
      mergeMap(([action, patientId]) =>
        this.noteApi.cloneLetter(patientId, action.payload).pipe(
          map(letter => new CloneNoteSuccess(letter)),
          catchError(error => of(new CloneNoteError(error))),
        ),
      ),
    ),
  );

  cloneNoteSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<CloneNoteSuccess>(NoteActionTypes.CLONE_NOTE_SUCCESS),
        withLatestFrom(this.patientSelectors.patientId),
        tap(([action, patientId]) => {
          this.toastService.add({
            severity: 'success',
            detail: `Your letter has been cloned`,
          });
          this.timelineActions.refreshTimeline();
          this.router.navigateByUrl(lettersPath(patientId, action.payload.id));
        }),
      ),
    { dispatch: false },
  );

  sendMlNoteRetitleFormFeedback$ = createEffect(() =>
    this.action$.pipe(
      ofType<SendMLNoteRetitleFormFeedback>(
        NoteActionTypes.SEND_ML_NOTE_RETITLE_FORM_FEEDBACK,
      ),
      mergeMap(action => {
        return this.mlNoteRetitleFeedbackApi
          .saveFormFeedback(action.feedback)
          .pipe(
            map(() => new LoadNoteWithTodo(action.feedback.noteId)),
            catchError(error => of(new SendMLNoteRetitleFeedbackError(error))),
          );
      }),
    ),
  );

  sendMlNoteRetitleDismissedFeedback$ = createEffect(() =>
    this.action$.pipe(
      ofType<SendMLNoteRetitleDismissedFeedback>(
        NoteActionTypes.SEND_ML_NOTE_RETITLE_DISMISSED_FEEDBACK,
      ),
      mergeMap(action => {
        return this.mlNoteRetitleFeedbackApi
          .saveDismissedFeedback(action.note)
          .pipe(
            map(() => new LoadNoteWithTodo(action.note.id)),
            catchError(error => of(new SendMLNoteRetitleFeedbackError(error))),
          );
      }),
    ),
  );

  redactNote$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<RedactNote>(NoteActionTypes.REDACT_NOTE),
      mergeMap(action =>
        this.noteApi.redact(action.payload).pipe(
          map(note => new RedactNoteSuccess(note)),
          catchError(error => of(new RedactNoteError(error))),
        ),
      ),
    ),
  );

  redactNoteSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<RedactNoteSuccess>(NoteActionTypes.REDACT_NOTE_SUCCESS),
        tap(() => {
          this.toastService.add({
            severity: 'success',
            detail: 'Note has been redacted',
          });
          this.timelineActions.closeItem();
          this.timelineActions.refreshTimeline();
          this.summariesActions.closeWorkspaceItem();
        }),
      ),
    { dispatch: false },
  );

  generateLetter$ = createEffect(() =>
    this.action$.pipe(
      ofType<GenerateLetter>(NoteActionTypes.GENERATE_LETTER),
      tap(action =>
        this.analytics.track(
          AnalyticsEvent.LetterSigned,
          letterSignButtonAnalyticsEvent(action.payload),
        ),
      ),
      withLatestFrom(this.patientSelectors.patientId),
      switchMap(([action, patientId]) =>
        this.noteApi.generateLetter(action.payload, patientId).pipe(
          map(() => {
            this.analytics.track(
              AnalyticsEvent.LetterGenerationSuccess,
              letterSignButtonAnalyticsEvent(action.payload),
            );
            return new GenerateLetterSuccess(action.payload);
          }),
          catchError(error => {
            this.analytics.track(
              AnalyticsEvent.LetterGenerationError,
              letterSignButtonAnalyticsEvent(action.payload),
            );
            return of(new GenerateLetterError(error));
          }),
        ),
      ),
    ),
  );

  generateLetterSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<GenerateLetterSuccess>(NoteActionTypes.GENERATE_LETTER_SUCCESS),
        withLatestFrom(this.patientSelectors.patientId),
        tap(([action, patientId]) => {
          this.timelineActions.refreshTimeline();
          this.router.navigateByUrl(
            notesPath(patientId, action.payload, 'edit'),
          );
        }),
      ),
    { dispatch: false },
  );

  generateLetterError$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<GenerateLetterError>(NoteActionTypes.GENERATE_LETTER_ERROR),
        tap(() =>
          this.toastService.add({
            severity: 'warn',
            detail: `Failed to generate letter PDF, please try again.`,
          }),
        ),
      ),
    { dispatch: false },
  );

  private handleNoteSavingError(response: HttpErrorResponse) {
    if (response.status === httpStatus.CONFLICT) {
      const updatedNote = response.error as NoteResponse;
      this.handleConflictError(updatedNote);
    }
  }

  private handleConflictError(updatedNote: NoteResponse) {
    if (updatedNote.signed_at) {
      this.toastService.add({
        severity: 'warn',
        detail: 'This note has already been signed, and cannot be edited.',
      });
    }
  }
}
