<div *ngIf="familyHealthHistory as familyMembers">
  <omg-section-title>
    <div om-layout
         om-layout-align="space-between center"
         om-layout-fill>
      <span>Family Health History</span>
      <span (click)="deleteFromNote()"
            *ngIf="!signed"
            class="om-icon clickable fa fa-trash"
            omgTooltip
            tooltipPosition="left"
            tooltipText="Delete from Note"></span>
    </div>
  </omg-section-title>
  <ul class="padding-normal"
      om-layout="vertical"
      om-layout-gutter>
    <li *ngFor="let familyMember of familyMembers"
        class="om-animate">
      <strong>{{familyMember.displayName | titlecase}}{{familyMember.sex && " " + familyMember.sex}}{{familyMember.age ? ", " + familyMember.age : ""}}{{familyMember.deceased ? ", Deceased" : ""}}
      </strong>
      <div *ngFor="let familyMemberCondition of familyMember.familyMemberConditions">
        {{ familyMemberCondition.description }}
        {{ familyMemberCondition.ageOfOnset && "(Age" + familyMemberCondition.ageOfOnset + ")" }}
      </div>
    </li>
    <div *ngIf="familyHealthHistory[0].legacyNote as legacyNote"
         om-layout
         om-layout="vertical"
         om-layout-fill>
      <strong>Notes</strong>
      <p> {{ legacyNote}} </p>
    </div>
  </ul>
</div>
