<div om-layout="vertical"
     om-layout-gutter-large>
  <div *ngIf="(comments | ngrxPush) as comments; else pending">
    <div om-layout="vertical"
         *ngFor="let comment of comments; let last = last;">
      <div om-layout
           om-layout-align="space-between center"
           om-layout-gutter>
        <div om-layout
             om-layout-gutter>
          <strong>{{comment.createdBy}}</strong>
          <span class="caption"
                [title]="comment.createdAt | omgDate:'withTime2y'">
            {{comment.createdAt | omgDate:'withTime2y'}}
          </span>
        </div>
        <a class="om-link"
           *ngIf="!!comment.actions.delete"
           (click)="onDeleteComment(comment.id)">Delete
        </a>
      </div>
      <p>{{comment.body}}</p>
      <hr *ngIf="last"
          class="-spaced" />
    </div>
  </div>
  <ng-template #pending>
    <div om-layout
         om-layout-gutter
         om-layout-align="center">
      <i class="fa fa-lg fa-spinner fa-pulse"></i>
    </div>
  </ng-template>
</div>
<label>
  <div om-layout
       om-layout-align="space-between center">
    <span>Add a new comment</span>
    <span class="caption"
          [ngClass]="{ error: commentFormModel.form.invalid }">
      {{ maxCommentLength - getCommentLength() }}
      characters
      remaining</span>
  </div>
  <omg-chart-text-box [control]="commentFormModel.get('commentBody')"
                      quillFormat="text"
                      [singleLine]="true"
                      [maxLength]="maxCommentLength"
                      placeholderText="Write a comment and hit 'enter' to save"
                      [insertionEventProps]="{
                        component: 'Comments',
                        subcomponent: 'Comment Text Box'
                      }"
                      (enterPressed)="onSaveComment()">
  </omg-chart-text-box>
</label>
