import { PrescriptionHistoryPharmacy } from '../../../modules/medications/shared/patient-medications.type';
import { RenewalPharmacy } from '../../../modules/pharmacy-picker/shared/pharmacy.type';

export const getPharmacyDisplay = (
  pharmacy: RenewalPharmacy | PrescriptionHistoryPharmacy,
) => {
  if (!pharmacy) {
    return;
  }

  let pharmacyDisplay = `${pharmacy.name}`;
  if (pharmacy.address && pharmacy.address.displayAddressStreet !== 'Unknown') {
    pharmacyDisplay += ` @ ${pharmacy.address.displayAddressStreet}`;
  }

  return pharmacyDisplay;
};
