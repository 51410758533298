import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LetterContainerComponent } from './letter-container/letter-container.component';

export const letterRoutePath = ':id/edit';

const routes: Routes = [
  {
    path: letterRoutePath,
    component: LetterContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LetterRoutingModule {}
