import { Action } from '@ngrx/store';

export const updateSectionsLinkable = '[Encounter] Update Sections Linkable';
export const loadSummary = '[Encounter] Load Summary';
export const closeSummary = '[Encounter] Close Summary';

export class UpdateSectionsLinkable implements Action {
  readonly type = updateSectionsLinkable;

  constructor(public payload: boolean) {}
}

export class LoadSummary implements Action {
  readonly type = loadSummary;

  constructor(public payload: any) {}
}

export class CloseSummary implements Action {
  readonly type = closeSummary;

  constructor(public payload: any) {}
}

export type EncounterAction =
  | UpdateSectionsLinkable
  | LoadSummary
  | CloseSummary;
