<form [formGroup]="form.controls">
  <div class="padding-normal"
       om-layout-gutter
       om-layout="vertical">
    <div *ngIf="form.controls.errors?.required && !form.controls.pristine"
         class="om-messages">
      Authorization number is required. To approve without authorization number, check "Submit approval without
      authorization number".
    </div>
    <label om-layout-gutter-mini
           *ngIf="form.controls.get('authNumber')">
      <div>Authorization number
        <span class="om-icon icon-alert-info"
              omgTooltip
              tooltipText="Used to track authorization request with insurer"
              tooltipPosition="right"></span>
      </div>
      <input om-flex="80"
             omgInputText
             data-cy="change-rx-prior-auth-number"
             formControlName="authNumber" />
    </label>
    <div *ngIf="form.controls.get('authNumber').errors?.maxlength"
         class="om-messages">
      Authorization number cannot exceed {{priorAuthNumberMaxLength}} characters
    </div>
    <omg-checkbox label="Submit approval without authorization number"
                  data-cy="change-rx-submit-without-auth-number"
                  formControlName="submitWithoutAuthNumber"></omg-checkbox>
  </div>
</form>
