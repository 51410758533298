import { Component, Input, OnInit } from '@angular/core';

import { GoogleMapsMarker, GoogleMapsOptions } from './google-maps.type';

const defaultSettings: GoogleMapsOptions = {
  height: 200,
  percent: false,
  minZoom: null,
  maxZoom: null,
  zoom: 12,
  fullscreenControl: true,
  scrollwheel: false,
  center: { lat: 0, lng: 0 },
};

declare var google: any;

@Component({
  selector: 'omg-google-maps',
  templateUrl: './google-maps.component.html',
})
export class GoogleMapsComponent implements OnInit {
  @Input() options: GoogleMapsOptions = defaultSettings;
  @Input() markers: GoogleMapsMarker[];

  overlays: any[];

  ngOnInit() {
    if (this.markers && this.markers.length > 0) {
      this.overlays = [...this.markers.map(this.mapMarkerToGoogleMarker)];
    }
  }

  private mapMarkerToGoogleMarker(marker: GoogleMapsMarker) {
    return new google.maps.Marker({
      position: {
        lat: marker.lat,
        lng: marker.lng,
      },
      title: marker.title,
      draggable: marker.draggable,
    });
  }
}
