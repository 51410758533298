import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PatientTimelinePostsComponent } from './components/patient-timeline-posts/patient-timeline-posts.component';

export const timelinePostsRoutePath = ':id/edit';

const routes: Routes = [
  {
    path: timelinePostsRoutePath,
    component: PatientTimelinePostsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TimelinePostsRoutingModule {}
