import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import { deleteCommentReducer } from './comments.reducer.utils';
import {
  ProcedureInteractionCommentsAction,
  ProcedureInteractionCommentsActionTypes,
} from './procedure-interaction-comments.actions';

export const procedureInteractionCommentsStatePath =
  'procedureInteractionComments';

export interface ProcedureInteractionCommentsState
  extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

export function selectProcedureInteractionCommentsId(
  comments: Comment,
): number {
  return comments.id;
}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectProcedureInteractionCommentsId,
});

export const procedureInteractionCommentsInitialState: ProcedureInteractionCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function procedureInteractionCommentsReducer(
  state = procedureInteractionCommentsInitialState,
  action: ProcedureInteractionCommentsAction,
): ProcedureInteractionCommentsState {
  switch (action.type) {
    case ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS: {
      return { ...state, pending: true };
    }

    case ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS_SUCCESS: {
      state = { ...state, error: null, pending: false };
      return adapter.setAll(action.payload, state);
    }

    case ProcedureInteractionCommentsActionTypes.GET_PROCEDURE_INTERACTION_COMMENTS_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT: {
      return { ...state, error: null, pending: true };
    }

    case ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case ProcedureInteractionCommentsActionTypes.CREATE_PROCEDURE_INTERACTION_COMMENT_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, action, adapter);
    }
  }
}
