import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-procedure-code-display',
  templateUrl: './procedure-code-display.component.html',
})
export class ProcedureCodeDisplayComponent {
  @Input() procedureCodes: string[];

  get tooltipText() {
    const codeCounts = {};
    this.procedureCodes.forEach(code => {
      codeCounts[code] = codeCounts[code] + 1 || 1;
    });
    const arrayOfCodeCountStrings = Object.entries(codeCounts).map(
      codeCount => {
        if (codeCount[1] === 1) {
          return codeCount[0];
        } else {
          return codeCount[0] + '(' + codeCount[1] + ')';
        }
      },
    );

    return arrayOfCodeCountStrings.join(', ');
  }

  get displayText() {
    if (!this.procedureCodes || this.procedureCodes.length === 0) {
      return '-';
    }

    if (this.procedureCodes.length <= 2) {
      if (this.procedureCodes[0] === this.procedureCodes[1]) {
        return this.procedureCodes[0] + '(2)';
      } else {
        return this.procedureCodes.join(', ');
      }
    }

    return this.procedureCodes[0] + '+';
  }
}
