<strong class="padding-half-vertical service-ticket-assessed-problems-title"
        omgTheme>Problems Assessed</strong>

<ul om-layout="vertical"
    om-layout-gutter>
  <li *ngFor="let assessedProblem of visitProcedure.assessedProblems"
      (click)="setPrimaryProblem(assessedProblem)"
      [ngClass]="{
      selected: visitProcedure.primaryAssessedProblemHistoryId === assessedProblem.id,
      clickable: !assessedProblem.problemCode.cannotBePrimaryProblemCode && canSelectPrimaryProblem,
      'disabled-indications': disabled,
      'om-messages': !disabled && assessedProblem.problemCode.cannotBePrimaryProblemCode && noProblemsCanBePrimary}"
      class="om-animate"
      om-layout
      om-layout-align="space-between start">
    <span om-flex="70">
      {{ assessedProblem.clinicalDescription }}<span *ngIf="assessedProblem.problemCodeLocation">,
        {{ assessedProblem.problemCodeLocation.name}}</span>
      &mdash;
      {{ assessedProblem.problemCode.code }}
    </span>

    <!-- We don't use ngIf here because hovering also displays this span. All visibility logic
         exists in CSS -->
    <span class="right-align visible-on-parent-hover"
          om-flex="30">Chief complaint</span>
  </li>

  <li class="om-messages"
      *ngIf="noProblemsCanBePrimary && canSelectPrimaryProblem">
    This diagnosis cannot be primary. Please add a problem indicating the patient's injury, symptom or condition as a
    result of that external cause.
  </li>

  <li *ngIf="noProblems">
    None recorded
  </li>
  <ng-container *ngIf="!primaryProblem.dirty">
    <div class="error"
         *ngFor="let error of primaryProblem.errors?.serverErrors">
      {{ error }}
    </div>
  </ng-container>
</ul>
