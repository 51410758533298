<ng-container *ngIf="patientInfo$ | ngrxPush as patientInfo">
  <div class="padding-normal-horizontal"
       *ngIf="patientInfo.hasIncompleteDemographics">
    <div class="banner -error">
      The patient’s full name, date of birth, address, and gender are required for sending a prescription.
      <a target="_blank"
         [omgLegacyUiHref]="'/' + patientInfo.id + '/account'">update the patient's profile</a> and reload this page
      when finished.
    </div>
  </div>
</ng-container>
