import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

function noop(text: string): string {
  return text;
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  renderer.codespan = noop;
  renderer.code = noop;

  return {
    renderer: renderer,
  };
}
