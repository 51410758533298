// @ts-strict-ignore
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { AppState } from '@app/core/store/store/app-store.reducer';
import { ProcedureInteraction } from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import { getProcedureInteraction } from '@app/features/procedure-interaction/store/procedure-interaction.actions';
import { ProcedureInteractionSelectors } from '@app/features/procedure-interaction/store/procedure-interaction.selectors';

import { ProcedureSuggestion } from '../../shared/procedure-suggestion.type';
import { buildMlProcedureBillingSuggestionEventPayload } from '../../shared/procedure-suggestion.utils';
import { ProcedureSuggestionActions } from '../../store/procedure-suggestion.actions';

@Component({
  selector: 'omg-procedure-suggestion-procedure-interaction-form',
  templateUrl:
    './procedure-suggestion-procedure-interaction-form.component.html',
  styleUrls: [
    './procedure-suggestion-procedure-interaction-form.component.scss',
  ],
})
export class ProcedureSuggestionProcedureInteractionFormComponent
  implements OnInit {
  @Input() procedureInteractionId: number;
  @Input() procedureSuggestion: ProcedureSuggestion;
  @Input() patientId: number;

  @Output() dismiss = new EventEmitter();

  procedureInteraction?: ProcedureInteraction;
  unsubscribe = new Subject<void>();

  constructor(
    private procedureSuggestionActions: ProcedureSuggestionActions,
    private procedureInteractionSelectors: ProcedureInteractionSelectors,
    private store: Store<AppState>,
    private changeRef: ChangeDetectorRef,
    public analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.setUpProcedureInteraction();
  }

  private setUpProcedureInteraction() {
    this.store.dispatch(
      getProcedureInteraction({ id: this.procedureInteractionId }),
    );
    this.procedureInteractionSelectors
      .get()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(procedureInteraction => {
        this.procedureInteraction = procedureInteraction;
        this.changeRef.markForCheck();
      });
  }

  deleteProcedureInteraction() {
    this.procedureSuggestionActions.deleteProcedureInteraction({
      id: this.procedureInteractionId,
      summaryId: this.procedureSuggestion.summaryId,
    });
    this.trackDiscardAnalytics();
  }

  saveProcedureInteraction() {
    this.procedureSuggestionActions.saveProcedureInteraction({
      procedureInteractionId: this.procedureInteractionId,
      patientId: this.patientId,
      summaryId: this.procedureSuggestion.summaryId,
    });
    this.trackSaveAnalytics();
  }

  onDismiss() {
    this.dismiss.emit();
  }

  private trackSaveAnalytics() {
    this.analytics.track(
      AnalyticsEvent.MlProcedureBillingSuggestionSaved,
      buildMlProcedureBillingSuggestionEventPayload(
        this.procedureSuggestion,
        this.procedureInteraction.procedureSummary,
      ),
    );
  }

  private trackDiscardAnalytics() {
    this.analytics.track(
      AnalyticsEvent.MlProcedureBillingSuggestionChangesDiscarded,
      buildMlProcedureBillingSuggestionEventPayload(this.procedureSuggestion),
    );
  }
}
