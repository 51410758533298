<span *ngIf="resolvableImage"
      om-layout="vertical"
      [ngClass]="{ 'overlay': overlay }"
      [omgBackgroundImage]="resolvableImage"></span>
<img *ngIf="url"
     hidden
     [src]="url"
     (error)="fallbackToInitials()" />
<span *ngIf="!resolvableImage"
      om-layout="vertical"
      [ngClass]="{ 'overlay': overlay }"
      om-layout-align="center"
      class="combo-{{colorCombo}} {{size}}">
  <strong>{{initials}}</strong>
</span>
