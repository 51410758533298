import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-insertion-indicator',
  templateUrl: './insertion-indicator.component.html',
  styleUrls: ['./insertion-indicator.component.scss'],
})
export class InsertionIndicatorComponent {
  @Input() isActive: boolean;
}
