// This file is to keep the special additional growth chart info (downs, preterm)
import {
  ageRange0to2,
  ageRange2to18,
  AgeRangeInMonths,
  dsAgeRange0to2,
  dsAgeRange2to18,
} from './growth-charts.type';

const basicCharts = [
  { label: '0 - 2 years', value: ageRange0to2 },
  {
    label: '2 - 18 years',
    value: ageRange2to18,
  },
];

const downsCharts = [
  {
    label: 'Down Syndrome: 0 - 2 year',
    value: dsAgeRange0to2,
  },
  {
    label: 'Down Syndrome: 2 - 18 years',
    value: dsAgeRange2to18,
  },
];

export const hasDownSyndrome = problems => {
  return problems.find(
    ({ active, problemType }) =>
      active &&
      (problemType.clinicalDescription.includes('Down syndrome') ||
        problemType.clinicalDescription.includes("Down's syndrome")),
  );
};

export const growthChartSets = (downs: boolean = false) => {
  if (downs) {
    return basicCharts.concat(downsCharts);
  }
  return basicCharts;
  // add preterm charts here
};

export const growthChartSourceText = (
  ageRangeSelectedOption: AgeRangeInMonths,
) => {
  if (ageRangeSelectedOption.description === 'infant') {
    return 'Source: WHO Child Growth Standards';
  } else if (ageRangeSelectedOption.description === 'child') {
    return 'Source: Developed by the National Center for Health Statistics in collaboration with the National Center for Chronic Disease Prevention and Health Promotion (2000).';
  } else if (ageRangeSelectedOption.description === 'downsyndrome') {
    return 'Source: NIH - https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/';
  }
};
