// @ts-strict-ignore
import { SimpleChanges } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';

import { SearchIndex } from '@app/core';
import { TrackEventProperties } from '@app/core/analytics/analytics.type';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';

export interface InsertionState {
  startIndex: number;
  searchTerm: string;
}

export type InsertionPositionStyle = Record<string, string | number>;

export type InsertionPreviewAlignment = 'top' | 'bottom';

export interface InsertionPosition {
  insertionStyle: InsertionPositionStyle;
  insertionPreviewAlignment: InsertionPreviewAlignment;
  destroyInsertion: boolean;
}

export interface InsertionTriggerConfiguration {
  [key: string]: SearchIndex;
}

export interface QuillContentChangedEvent {
  content: any;
  delta: any;
  editor: any;
  html: string | null;
  oldDelta: any;
  source: string;
  text: string;
}

const sanitizeForRegex = (term: string) => {
  return term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const highlightText = (text: string, highlightTerm: string) => {
  const highlightTermLowerNoNbsp = highlightTerm
    .trim()
    .replace(/\xA0/g, ' ')
    .split(' ')
    .filter(Boolean);
  return highlightTermLowerNoNbsp.reduce(
    (acc, term) =>
      acc.replace(
        new RegExp(`(\\b${sanitizeForRegex(term)})`, 'gi'),
        match => `<span class="insertion-highlight">${match}</span>`,
      ),
    text,
  );
};

const getInlineInsertionPositionStyles = (
  position: 'top' | 'bottom',
  quillClientRect,
  quillBounds,
  topOffset,
  screenHeight,
) => {
  const conditionalStyling =
    position === 'top'
      ? { 'bottom.px': screenHeight - (quillClientRect.top + quillBounds.top) }
      : { 'top.px': quillClientRect.top + quillBounds.top + topOffset };
  return {
    position: 'fixed',
    'z-index': 1050,
    'left.px': quillClientRect.left + quillBounds.left,
    ...conditionalStyling,
  };
};

export const getInlineInsertionPosition = (
  startIndex: number,
  quillInstance: QuillEditorComponent,
  quillElement: HTMLElement,
  windowInnerHeight: number,
): InsertionPosition => {
  const topOffset = 16;
  const maxHeight = 288;
  const HEADER_HEIGHT = 150;

  const quillEditorClientRect = quillElement
    .querySelector('.ql-editor')
    .getBoundingClientRect();
  const bounds = quillInstance.quillEditor.getBounds(startIndex);
  const topPosition = quillEditorClientRect.top + bounds.top + topOffset;

  const insertionPosition =
    topPosition + maxHeight > windowInnerHeight ? 'top' : 'bottom';

  const insertionStyle = getInlineInsertionPositionStyles(
    insertionPosition,
    quillEditorClientRect,
    bounds,
    topOffset,
    windowInnerHeight,
  );
  const insertionPreviewAlignment =
    insertionPosition === 'top' ? 'bottom' : 'top';

  return {
    insertionStyle,
    insertionPreviewAlignment,
    destroyInsertion: topPosition < HEADER_HEIGHT,
  };
};

export const isWhitespace = (str: string): boolean =>
  !str.replace(/\s/g, '').length;

export const isNotInlineInsertionScrollEvent = event =>
  !(<HTMLElement>event.target)?.className?.includes('insertion-results');

export const getAnalyticsProperties = (
  options: {
    template?: Template;
    term?: string;
  },
  insertionEventProps: Partial<TrackEventProperties>,
): Partial<TrackEventProperties> => {
  const { template, term } = options;
  const extraProps =
    template && term
      ? {
          templateId: template.id,
          templateName: template.name,
          templateType: template.internal_user_id ? 'Personal' : 'Public',
          inputString: term,
        }
      : {};

  return {
    workflow: 'Charting',
    ...extraProps,
    ...insertionEventProps,
  };
};

export const removeSlashTerm = (
  insertionQuillInstance: QuillEditorComponent,
  startIndex: number,
  searchTerm: string,
) => {
  if (startIndex !== null && searchTerm !== null) {
    const slashIndex = startIndex - 1;
    const searchTermLength = searchTerm.length + 1;
    insertionQuillInstance.quillEditor.deleteText(slashIndex, searchTermLength);
  }
};

const isValidPrecedingChar = (char: string) =>
  ['\xa0', '', ' ', '\n'].includes(char);

export const isCurrentCharIndexGreaterThanTriggerIndex = (
  insertionQuillInstance: QuillEditorComponent,
  triggerIndex: number,
) => insertionQuillInstance.quillEditor.getSelection().index <= triggerIndex;

export const isValidInsertionTrigger = (
  insertionQuillInstance: QuillEditorComponent,
  triggersIndexes: InsertionTriggerConfiguration,
) => (event: KeyboardEvent): boolean => {
  const isInsertionTriggerKey = Object.keys(triggersIndexes).includes(
    event.key,
  );
  if (isInsertionTriggerKey) {
    const curIndex = insertionQuillInstance.quillEditor.getSelection().index;
    const charBefore = insertionQuillInstance.quillEditor.getText(
      curIndex - 2,
      1,
    );
    return curIndex === 1 || isValidPrecedingChar(charBefore);
  }
  return false;
};

export const searchTermReducer = (
  insertionQuillInstance: QuillEditorComponent,
) => (acc: InsertionState, key: string): InsertionState => {
  const endIndex = acc.startIndex + acc.searchTerm.length;
  if (key.length === 1) {
    const searchTerm = insertionQuillInstance.quillEditor.getText(
      acc.startIndex,
      endIndex + 1 - acc.startIndex,
    );
    return { ...acc, searchTerm };
  }
  if (key === 'Backspace') {
    const searchTerm = insertionQuillInstance.quillEditor.getText(
      acc.startIndex,
      endIndex - 1 - acc.startIndex,
    );
    return { ...acc, searchTerm };
  }
  return { ...acc };
};

export const searchTermReducerInitialState = (startIndex: number) => ({
  startIndex,
  searchTerm: '',
});

export const insertionTemplateSelected = (changes: SimpleChanges) =>
  changes.selectedTemplate && changes.selectedTemplate.currentValue;

export const insertionDestroyedWithoutTemplateSelection = (
  changes: SimpleChanges,
) =>
  !changes.selectedTemplate &&
  changes.isInlineInsertionActive &&
  changes.isInlineInsertionActive.currentValue === false &&
  changes.isInlineInsertionActive.previousValue !== null;

export const insertionActivated = (changes: SimpleChanges) =>
  changes.isInlineInsertionActive &&
  changes.isInlineInsertionActive.currentValue === true;

export const quillFocused = (changes: SimpleChanges) =>
  changes.isQuillInFocus && changes.isQuillInFocus.currentValue;
