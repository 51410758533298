import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import { deleteCommentReducer } from './comments.reducer.utils';
import {
  RenewalCommentsAction,
  RenewalCommentsActionTypes,
} from './renewal-comments.actions';

export const renewalCommentsStatePath = 'renewalComments';

export interface RenewalCommentsState extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

export function selectRenewalCommentsId(comments: Comment): number {
  return comments.id;
}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectRenewalCommentsId,
});

export const renewalCommentsInitialState: RenewalCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function renewalCommentsReducer(
  state = renewalCommentsInitialState,
  action: RenewalCommentsAction,
): RenewalCommentsState {
  switch (action.type) {
    case RenewalCommentsActionTypes.GetRenewalComments: {
      return { ...state, pending: true };
    }

    case RenewalCommentsActionTypes.GetRenewalCommentsSuccess: {
      state = { ...state, error: null, pending: false };
      return adapter.setAll(action.payload, state);
    }

    case RenewalCommentsActionTypes.GetRenewalCommentsError: {
      return { ...state, error: action.payload, pending: false };
    }

    case RenewalCommentsActionTypes.CreateRenewalComment: {
      return { ...state, error: null, pending: true };
    }

    case RenewalCommentsActionTypes.CreateRenewalCommentSuccess: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case RenewalCommentsActionTypes.CreateRenewalCommentError: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, action, adapter);
    }
  }
}
