import { PatientPronounsCode } from './patient.type';

type PatientPronounsMap = {
  [k in PatientPronounsCode]?: {
    label: string;
  }
};

export const patientPronouns: PatientPronounsMap = {
  they: { label: 'They/Them' },
  she: { label: 'She/Her' },
  he: { label: 'He/Him' },
};
