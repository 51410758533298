// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ChartViewer,
  PresenceService,
} from '@app/features/presence/presence.service';

@Component({
  selector: 'omg-presence',
  templateUrl: './presence.component.html',
})
export class PresenceComponent implements OnInit {
  chartViewers$: Observable<Array<ChartViewer>>;

  constructor(private presenceService: PresenceService) {}

  ngOnInit() {
    this.chartViewers$ = this.presenceService.chartViewers$;
  }

  trackByFn = (_: any, chartViewer: ChartViewer) => chartViewer.internalUserId;
}
