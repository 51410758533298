import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PostCommentsState,
  postCommentsStatePath,
  selectAll,
  selectEntities,
} from './post-comments.reducer';

export const selectPostCommentsState = createFeatureSelector<PostCommentsState>(
  postCommentsStatePath,
);

export const selectPostComments = createSelector(
  selectPostCommentsState,
  selectEntities,
);

export const selectAllPostComments = createSelector(
  selectPostCommentsState,
  selectAll,
);

export const selectPostCommentsError = createSelector(
  selectPostCommentsState,
  state => state.error,
);

export const selectPostCommentsPending = createSelector(
  selectPostCommentsState,
  state => state.pending,
);
