import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  selectAll,
  selectEntities,
  SummaryCommentsState,
  summaryCommentsStatePath,
} from './summary-comments.reducer';

export const selectSummaryCommentsState = createFeatureSelector<
  SummaryCommentsState
>(summaryCommentsStatePath);

export const selectSummaryComments = createSelector(
  selectSummaryCommentsState,
  selectEntities,
);

export const selectAllSummaryComments = createSelector(
  selectSummaryCommentsState,
  selectAll,
);

export const selectSummaryCommentsError = createSelector(
  selectSummaryCommentsState,
  state => state.error,
);

export const selectSummaryCommentsPending = createSelector(
  selectSummaryCommentsState,
  state => state.pending,
);
