// @ts-strict-ignore
import { Component, Input } from '@angular/core';

import { CollapseDirective } from '@app/shared';

@Component({
  selector: 'omg-cart-item-more-options-toggle',
  templateUrl: './cart-item-more-options-toggle.component.html',
})
export class CartItemMoreOptionsToggleComponent {
  @Input() collapseProvider: CollapseDirective;
  @Input() visible: boolean;

  constructor() {}
}
