import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReferenceDataApiService } from './shared/reference-data-api.service';
import { ReferenceDataStoreModule } from './store/reference-data-store.module';
import { ReferenceDataEffects } from './store/reference-data.effects';
import {
  referenceDataPath,
  referenceDataReducer,
} from './store/reference-data.reducer';

@NgModule({
  imports: [
    ReferenceDataStoreModule,
    StoreModule.forFeature(referenceDataPath, referenceDataReducer),
    EffectsModule.forFeature([ReferenceDataEffects]),
  ],
  providers: [ReferenceDataApiService],
})
export class ReferenceDataModule {}
