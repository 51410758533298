import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function atLeastOneValue(
  group: UntypedFormGroup,
): ValidationErrors | null {
  const hasValue = Object.values(group.value).some(value => !valueIsNullOrUndefined(value));
  return hasValue ? null : { noValue: true };
}

function valueIsNullOrUndefined(value){
  return (value === undefined || value == null || value.length <= 0) ? true : false;
}
