import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  RadioControlValueAccessor,
  UntypedFormControl,
} from '@angular/forms';

import {
  VisitProcedure,
  VisitProcedureProblemCode,
} from '../../shared/visit-procedure.type';

@Component({
  selector: 'omg-service-ticket-indications',
  templateUrl: './service-ticket-indications.component.html',
  styleUrls: ['./service-ticket-indications.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ServiceTicketIndicationsComponent),
    },
  ],
})
export class ServiceTicketIndicationsComponent
  extends RadioControlValueAccessor
  implements OnInit {
  @Input() visitProcedure: VisitProcedure;
  @Input() problemCodeControl: UntypedFormControl;
  @Input() disabled?: boolean;

  get canSelectIndication(): boolean {
    return this.visitProcedure.requiredParams.includes('chief_complaint');
  }

  get hasAllNonPrimaryProblemCode(): boolean {
    return (
      this.visitProcedure.visitProcedureProblemCodes.length > 0 &&
      this.visitProcedure.visitProcedureProblemCodes.every(
        problem_code => problem_code.cannotBePrimaryProblemCode === true,
      )
    );
  }

  selectIndication(problemCode: VisitProcedureProblemCode) {
    if (
      !this.canSelectIndication ||
      problemCode.cannotBePrimaryProblemCode ||
      this.disabled
    ) {
      return;
    }

    // Update UI immediately
    this.visitProcedure.visitProcedureProblemCodes.forEach(
      pc => (pc.primary = false),
    );
    problemCode.primary = true;

    this.problemCodeControl.setValue(problemCode.id);
    this.problemCodeControl.markAsDirty();
  }

  /* istanbul ignore next */
  writeValue(value: string) {
    this.value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn) {
    this.onChange = fn;
  }
}
