import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangeItemDetailComponent } from '../change-rx/components/change-item-detail/change-item-detail.component';
import { ChangeRxComponent } from '../change-rx/components/change-rx/change-rx.component';
import { RenewalItemDetailComponent } from './components/renewal-item-detail/renewal-item-detail.component';
import { RenewalListComponent } from './components/renewal-list/renewal-list.component';
import { RenewalsComponent } from './components/renewals/renewals.component';

export const renewalsDetailRoutePath = ':id';
export const changeDetailRoutePath = ':id';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: RenewalsComponent,
    children: [
      {
        path: '',
        component: RenewalListComponent,
      },
      {
        path: renewalsDetailRoutePath,
        component: RenewalItemDetailComponent,
      },
    ],
  },
  {
    path: 'change',
    component: ChangeRxComponent,
    children: [
      {
        path: changeDetailRoutePath,
        component: ChangeItemDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class RenewalsRoutingModule {}
