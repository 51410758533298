<iframe class="printable-document-iframe"
        frameborder="0"
        height="0"
        hidden></iframe>
<div class="printable-document-contents"
     *ngIf="note"
     hidden>
  <div class="padding-normal">
    <omg-patient-demographics [patient]="patient"
                              [summary]="summary"
                              [note]="note">
    </omg-patient-demographics>
    <div om-layout="vertical"
         om-flex="90"
         om-layout-gutter
         omgTheme
         class="printable-note-body"
         [innerHtml]="note.body"></div>
  </div>
</div>
