import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SectionLinkedQueueState } from './section-linked-queue.reducer';

export const selectSectionLinkedQueueState = createFeatureSelector<
  SectionLinkedQueueState
>('sectionLinkedQueue');

export const selectSectionLinkedHead = createSelector(
  selectSectionLinkedQueueState,
  (state: SectionLinkedQueueState) =>
    (state && state.length === 1 && state[0]) || null,
);
