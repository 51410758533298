<ng-container *ngIf="(approvedOrDeniedRenewals$ | ngrxPush) as renewals">
  <div class="om-list"
       [class.padding-normal]="!isListView"
       *ngIf="renewals.length > 0">
    <omg-section-title>
      Ready for Checkout
    </omg-section-title>
    <omg-pdmp-dialogue-banner *ngIf="(showPdmpDialogueBanner$ | ngrxPush)"
                              [cartId]="cartId$ | ngrxPush"
                              [cartItems]="renewals"
                              (confirmedReportViewed)="onPdmpConfirmation(renewals, $event)">
    </omg-pdmp-dialogue-banner>
    <div *ngFor="let renewal of renewals; first as focus"
         [class.list-item]="!isListView">
      <omg-ready-for-checkout-item [renewal]="renewal"
                                   [focus]="focus"></omg-ready-for-checkout-item>
    </div>

    <div class="padding-normal">
      <div class="banner om-item -warning"
           *ngIf="checkoutAttempted && checkoutPrevented">
        Confirm review of PDMP Report for controlled substance prescriptions before checkout.
      </div>
    </div>

    <omg-ready-for-checkout-action-bar *ngIf="renewals.length > 0"
                                       [renewals]="renewals"
                                       [acceptsDigitalCommunications]="acceptsDigitalCommunications$ | ngrxPush"
                                       [incompleteDemographics]="incompleteDemographics$ | ngrxPush"
                                       [cartId]="cartId$ | ngrxPush"
                                       [profile]="profile$ | ngrxPush"
                                       [isProvider]="isProvider$ | ngrxPush"
                                       [loading]="loading$ | ngrxPush"
                                       [isListView]="isListView"
                                       [checkoutPrevented]="checkoutPrevented"
                                       (checkoutAttempted)="checkoutAttempted = $event">
    </omg-ready-for-checkout-action-bar>
  </div>
</ng-container>
