// @ts-strict-ignore
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { AutoCompleteComponent } from '@app/shared';
import { DialogService } from '@app/shared/components/dialog';

import { Post } from '../../shared/messaging.type';
import { DeleteMessageComponent } from '../delete-message/delete-message.component';
import { MoveMessageComponent } from '../move-message/move-message.component';

enum ActionItems {
  MOVE_TO_ANOTHER_THREAD = 1,
  DELETE_MESSAGE = 2,
}

@Component({
  selector: 'omg-messaging-product-specialist-options',
  templateUrl: './messaging-product-specialist-options.component.html',
  styleUrls: ['./messaging-product-specialist-options.component.scss'],
})
export class MessagingProductSpecialistOptionsComponent implements OnInit {
  @Input() post: Post;

  items = [
    {
      id: ActionItems.MOVE_TO_ANOTHER_THREAD,
      name: 'Move Thread to Another Chart',
    },
  ];

  @ViewChild('optionsDropdown')
  private ref: AutoCompleteComponent;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    const hasDeleteFlag = this.launchDarklyService.variation(FeatureFlagNames.new1lifeMessagingDeletion, false);

    if (hasDeleteFlag) {
      this.items.push({
        id: ActionItems.DELETE_MESSAGE,
        name: 'Delete Message Thread',
      });
    }
  }

  get messageContainsDraft(): boolean {
    return (
      this.post.draft || !!this.post.messages.find(message => message.draft)
    );
  }

  get isVisible(): boolean {
    return !this.messageContainsDraft;
  }

  onItemClick(event) {
    // Work around to not have item selected
    this.ref.writeValue(null);

    if (event.id === ActionItems.MOVE_TO_ANOTHER_THREAD) {
      this.dialogService.open(MoveMessageComponent, {
        data: {
          post: this.post,
        },
      });
    } else if (event.id === ActionItems.DELETE_MESSAGE) {
      this.dialogService.open(DeleteMessageComponent, {
        data: {
          post: this.post,
        },
      });
    }
  }

  toggleOptions() {
    this.ref.toggle();
  }
}
