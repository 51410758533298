import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'omg-note-to-recipient',
  templateUrl: './note-to-recipient.component.html',
  styleUrls: ['./note-to-recipient.component.scss'],
})
export class NoteToRecipientComponent implements OnChanges {
  @Input() maxLength: number;
  @Input() recipient = 'Recipient';
  @Input() placeholder = '';
  @Input() note = new FormControl('', { nonNullable: true });
  @Input() addenda: string;
  @Input() autofillPlaceholder = false;
  @Input() required = false;

  @Output() noteFocus: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() noteFocusout: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes.note &&
      !changes.note.firstChange &&
      changes.note.currentValue.value !== changes.note.previousValue.value
    ) {
      this.updateMaxLength(changes.note.currentValue.value);
    }
  }

  updateMaxLength(text: string) {
    const fullText = this.mergeTextWithAddenda(text);
    this.maxLength = !!fullText
      ? this.maxLength - fullText.length
      : this.maxLength;
  }

  mergeTextWithAddenda(text: string) {
    return this.addenda ? text.concat(this.addenda) : text;
  }

  setNoteValueIfBlank(placeholder: string, autofillPlaceholder: boolean) {
    if (!this.note.value?.trim() && autofillPlaceholder) {
      this.note.patchValue(placeholder);
    }
  }

  exceedsMaxLength(): boolean {
    return this.note.invalid && this.charsRemaining() !== this.maxLength;
  }

  charsRemaining(): number {
    if (this.note.value) {
      if (this.note.value.endsWith('\n'))
        return this.maxLength - this.note.value.length + 1;
      return this.maxLength - this.note.value.length;
    }
    return this.maxLength;
  }
}
