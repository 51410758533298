import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import {
  SummaryHealthGoal,
  SummaryHealthMaintenanceNote,
} from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-health-maintenance',
  templateUrl: './linked-health-maintenance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedHealthMaintenanceComponent {
  @Input() signed: boolean;
  @Input() healthMaintenanceNote: SummaryHealthMaintenanceNote;
  @Input() healthGoals: SummaryHealthGoal[];
  @Output() unlink = new EventEmitter();
  vaccineRecommendationEnabled: boolean;

  trackByFn = (index, healthGoal: SummaryHealthGoal) => healthGoal.id || index;

  constructor(ld: LaunchDarklyService) {
    this.vaccineRecommendationEnabled = ld.variation<boolean>(
      FeatureFlagNames.vaccineRecommendation,
      false,
    );
  }

  deleteFromNote() {
    this.unlink.emit();
  }
}
