import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { loginPath, logoutPath } from './core';
import { LoginGuard } from './core/auth/shared/login-guard';
import { LogoutGuard } from './core/auth/shared/logout.guard';
import { LaunchDarklyResolver } from './core/launch-darkly/launchdarkly.resolver';
import { AppShellComponent } from './features/app-shell/components/app-shell/app-shell.component';
import { EmptyComponent } from './features/app-shell/components/empty/empty.component';
import { LoginComponent } from './features/app-shell/components/login/login.component';
import { ScheduleComponent } from './features/app-shell/components/schedule/schedule.component';
import { UnauthorizedComponent } from './features/app-shell/components/unauthorized/unauthorized.component';
import { ProfileResolver } from './features/app-shell/shared/profile.resolver';
import { RedirectComponent } from './redirect/redirect.component';

export const routes: Routes = [
  { path: loginPath, component: LoginComponent, canActivate: [LoginGuard] },
  { path: logoutPath, component: EmptyComponent, canActivate: [LogoutGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    resolve: {
      profile: ProfileResolver,
      launchdarkly: LaunchDarklyResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'schedule',
      },
      { path: 'schedule', pathMatch: 'prefix', component: ScheduleComponent },
      {
        path: 'redirect/:id',
        pathMatch: 'prefix',
        component: RedirectComponent,
      },
      {
        path: 'patients/:id/chart',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./features/patient-chart/patient-chart.module').then(
            m => m.PatientChartModule,
          ),
      },
    ],
  },
  { path: '**', component: EmptyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  declarations: [RedirectComponent],
  providers: [ProfileResolver, LaunchDarklyResolver],
})
export class AppRoutingModule {}
