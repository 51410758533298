import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

/**
 * This is a component, but used as a directive. This allows for the usage of
 * scoped styles rather than global CSS. If there is a future need to share
 * input styles (as part of a design system for instance), then it may make
 * sense to convert this into a directive and use global CSS classes instead
 * (which could be shared across different applications).
 */
@Component({
  // eslint-disable-next-line  @angular-eslint/component-selector
  selector: 'input[omgRadioButton]',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  @HostBinding('attr.type') private type = 'radio';
}
